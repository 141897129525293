import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { FaCheck } from 'react-icons/fa'
import LoadingComponent from '../Shared/Loading.component'
import TableComponent from '../Shared/Table.component'
import ModalComponent from '../Shared/Modal.component'
import { updateEstimatedDate } from '../../services/accounts.service'
import { confirmInstallation } from '../../services/systems.service'
import { tagGroupListStatus } from './SectionsDetailsAccount/SectionGeneral/utilsStatus'
import ModalPrepareDelivery from '../ModalPrepareDelivery'
import { ModalConfirmInstallation } from '../Shared/ModalConfirmInstallation'

const headers = [
  'NOMBRE',
  'TELÉFONO',
  'PRODUCTO',
  'COMERCIO',
  'ESTATUS',
  'INSTALACIÓN',
  'COMENTARIOS',
  'ESTAUS DEL CRÉDITO',
  'ACCIONES',
]

const headersDefault = [
  'NOMBRE',
  'TELÉFONO',
  'PRODUCTO',
  'COMERCIO',
  'UBICACIÓN',
  'ENTREGA',
  'INSTALACIÓN',
  'COMENTARIOS',
]

export default function ToInstallTableComponent(props) {
  const {
    handleClick,
    isStopped,
    hidden,
    rowsData,
    updateTable,
    handleOpenModalDetails,
    tableDefault,
    handleOpenModalInstallationImages,
    handleOpenModalConfirmInstallationDates,
    rol,
  } = props

  const [currentCredit, setCurrentCredit] = useState()
  const [showModalPrepareDelivery, setShowModalPrepareDelivery] =
    useState(false)
  const [showModalConfirmInstallation, setShowModalConfirmInstallation] =
    useState(false)

  const handlePrepareDelivery = (e, credit) => {
    e.stopPropagation()
    setCurrentCredit(credit)
    setShowModalPrepareDelivery(true)
  }

  function handleEstimatedDate(date, item) {
    item.estimated_install_date = date.slice(0, 10)
    item.instalation_date = date
    updateEstimatedDate(item).then(() => {
      updateTable()
    })
  }

  const handleConfirmInstall = (creditId) => {
    toast.success('Instalación confirmada exitosamente!', {
      position: toast.POSITION.TOP_RIGHT,
    })
    confirmInstallation(creditId).then(() => {
      window.location.reload()
    })
  }

  function getColumns(headers, data) {
    const columns = []
    for (let i = 0; i < headers.length; i++) {
      columns.push({
        Header: headers[i],
        accessor: data[i],
      })
    }
    return columns
  }

  function getData(rows) {
    const data = []
    rows.forEach((row) => {
      data.push({
        full_name: row.client_info?.full_name,
        ID_Client: row.client_info?.id,
        profile: row.client_info?.profile,
        phone: row.client_info?.phone,
        product: row.product_info?.model,
        comercio: row.distributor_info?.name,
        location: (
          <a
            onClick={(event) => {
              window.open(row.client_info.map)
              event.stopPropagation()
            }}
            className="btn b-primary"
          >
            {row?.client_info?.state || 'Mapa'}
          </a>
        ),
        estatus:
          row.client_info?.bio_test == null ||
          !row.client_info?.ine ||
          !row.client_info?.ine_back ||
          !row.client_info?.comprobante ? (
            <>
              <span
                key={tagGroupListStatus[0].items[0].title}
                className={`badge badge-${tagGroupListStatus[0].items[0].color} mr-2 mb-2`}
              >
                <div className="tag-container">
                  {tagGroupListStatus[0].items[0].title}
                </div>
              </span>
              {row.client_info?.bio_test == null ? (
                <span
                  key={tagGroupListStatus[0].items[4].title}
                  className={`badge badge-${tagGroupListStatus[0].items[4].color} mr-2 mb-2`}
                >
                  <div className="tag-container">
                    {tagGroupListStatus[0].items[4].title}
                  </div>
                </span>
              ) : null}
              {!row.client_info?.comprobante ? (
                <span
                  key={tagGroupListStatus[0].items[2].title}
                  className={`badge badge-${tagGroupListStatus[0].items[2].color} mr-2 mb-2`}
                >
                  <div className="tag-container">
                    {tagGroupListStatus[0].items[2].title}
                  </div>
                </span>
              ) : null}
            </>
          ) : (
            <span
              key={tagGroupListStatus[0].items[3].title}
              className={`badge badge-${tagGroupListStatus[0].items[3].color} mr-2 mb-2`}
            >
              <div className="tag-container">
                {tagGroupListStatus[0].items[3].title}
              </div>
            </span>
          ),
        estimated_install_date: tableDefault ? (
          row.instalation_date && row.tracking_number ? (
            <button
              className="btn btn-warning"
              onClick={(e) => handlePrepareDelivery(e, row)}
            >
              Preparado con fecha y guía
            </button>
          ) : row.instalation_date ? (
            <button
              className="btn btn-success"
              onClick={(e) => handlePrepareDelivery(e, row)}
            >
              Preparado con fecha
            </button>
          ) : row.tracking_number ? (
            <button
              className="btn btn-info"
              onClick={(e) => handlePrepareDelivery(e, row)}
            >
              Preparado con guía
            </button>
          ) : (
            <button
              className="btn b-primary"
              onClick={(e) => handlePrepareDelivery(e, row)}
            >
              Preparar
            </button>
          )
        ) : (
          <>
            {row.instalation_date && (
              <span className="badge badge-success">
                {row.instalation_date}
              </span>
            )}
            {row.tracking_link && (
              <>
                <span className="badge badge-info">{row.tracking_link}</span>
                <span className="badge badge-info">{row.tracking_number}</span>
              </>
            )}
          </>
        ),
        system:
          row.tags_list?.includes('gg') && row.product_info?.nfc_available ? (
            'Confirmación automática con medidor NFC'
          ) : row.system ? (
            <button
              type="button"
              className="btn b-primary"
              onClick={(e) => {
                e.stopPropagation()
                setCurrentCredit(row)
                setShowModalConfirmInstallation(true)
              }}
            >
              Confirmar
            </button>
          ) : (
            'Sin medidor asignado...'
          ),
        comments: (
          <button
            className="btn b-primary"
            onClick={(e) => handleOpenModalDetails(e, row.id)}
          >
            <i className="fa fa-eye" aria-hidden="true" />
          </button>
        ),
        estatus_credito: tableDefault || row.credit_status_readable,
        action:
          row.credit_status === 'APR' ? (
            <button
              className="btn b-primary"
              onClick={(event) =>
                handleOpenModalInstallationImages(event, row.id)
              }
            >
              Adjuntar
            </button>
          ) : row.credit_status === 'EVAT' ? (
            rol === 'DS' &&
            (row.status_photos === 'UPL' ||
              row.status_photos === 'REJ' ||
              row.status_photos === 'APR') ? (
              <button
                className={
                  row.status_photos === 'REJ' ? 'btn-3' : 'btn b-primary'
                }
                onClick={(event) =>
                  handleOpenModalInstallationImages(event, row.id)
                }
              >
                {row.status_photos === 'REJ' ? 'Rechazadas' : 'Ver'}
              </button>
            ) : (
              <button
                className={
                  row.status_photos === 'REJ'
                    ? 'btn btn-warning'
                    : 'btn b-primary'
                }
                onClick={(event) =>
                  handleOpenModalInstallationImages(event, row.id)
                }
              >
                {rol === 'GR' && row.status_photos === 'APR'
                  ? 'Ver'
                  : row.status_photos === 'REJ'
                  ? 'Rechazadas'
                  : 'Ver'}
              </button>
            )
          ) : row.credit_status === 'CON' ? (
            <button
              className="btn btn-secondary"
              onClick={(e) => handleOpenModalConfirmInstallationDates(e, row)}
            >
              {row.status_dates_proposed === 'REV' && rol === 'GR' ? (
                <FaCheck />
              ) : (row.status_dates_proposed === 'REV' && rol === 'DS') ||
                (row.status_dates_proposed === 'REJ' && rol === 'GR') ? (
                'Esperando confirmación'
              ) : row.status_dates_proposed === 'REJ' && rol === 'DS' ? (
                'Fechas rechazadas'
              ) : (
                '-'
              )}
            </button>
          ) : row.credit_status === 'ATI' ? (
            <button
              className="btn b-primary"
              onClick={(e) => {
                e.stopPropagation()
                setCurrentCredit(row)
              }}
            >
              Preparar
            </button>
          ) : null,
        id: row.id,
      })
    })
    return data
  }

  return (
    <div className="box-table" hidden={hidden}>
      {isStopped && (
        <div className="table-responsive">
          {tableDefault ? (
            <TableComponent
              rowsData={getData(rowsData)}
              columnsData={getColumns(headersDefault, [
                'full_name',
                'phone',
                'product',
                'comercio',
                'location',
                'estimated_install_date',
                'system',
                'comments',
              ])}
              handleClick={handleClick}
            />
          ) : (
            <TableComponent
              rowsData={getData(rowsData)}
              columnsData={getColumns(headers, [
                'full_name',
                'phone',
                'product',
                'comercio',
                'estatus',
                'estimated_install_date',
                'comments',
                'estatus_credito',
                'action',
              ])}
              handleClick={handleClick}
            />
          )}
        </div>
      )}
      <ModalPrepareDelivery
        key={`${currentCredit?.id}prepare`}
        show={showModalPrepareDelivery}
        credit={currentCredit}
        onClose={() => setShowModalPrepareDelivery(false)}
        onSubmitted={() => {
          setShowModalPrepareDelivery(false)
          updateTable()
          setTimeout(() => setCurrentCredit(undefined))
        }}
      />
      <ModalConfirmInstallation
        key={`${currentCredit?.id}confirm`}
        show={showModalConfirmInstallation}
        creditId={currentCredit?.id}
        onClose={() => setShowModalConfirmInstallation(false)}
        onSubmitted={() => {
          setShowModalConfirmInstallation(false)
          updateTable()
          setTimeout(() => setCurrentCredit(undefined))
        }}
      />
      {!isStopped && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}
