import React, { useState, useEffect } from 'react'

/**
 * A list with the names of all months
 */
const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

/**
 * Credit state component: shows information about the credit before registering
 * @param {Credit} credit the registering credit
 * @param {function} handleFinishVerification on finish verification callback
 * @param {boolean} spinner whether to show a loading animation in the submit button
 */
const EstadoCredito = ({ credit, handleFinishVerification, spinner }) => { 

  /**
   * the current window inner width
   */
  const [width, setWidth] = useState(window.innerWidth)

  /**
   * On submit event handler
   * @param {UUID} id the credit id
   */
  const handleFinish = (id) => {
    handleFinishVerification(id)
  }

  /**
   * Updates the width value with the current window inner width
   */
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  /**
   * Adds a resize event listener to store the current window inner width in a
   * state
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  /**
   * Logged user branch id, if user_type == CS
   */
  const branchId = localStorage.getItem('branchId') || ''

  /**
   * Formated to_install date
   */
  const date = new Date(credit?.to_install_date)
  const formatted_date =
    date.getDate() +
    ' de ' +
    months[date.getMonth()] +
    ', ' +
    date.getFullYear()

  return (
    <div
      className={
        width < 672
          ? 'resultado-container container-fluid qr-code-containers-movil'
          : 'resultado-container container-fluid qr-code-containers'
      }
    >
      <div className='row w-100'>
        <div
          className={
            width < 672
              ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 resultado-title-movil-comprobante'
              : 'col-lg-12 col-md-12 col-sm-12 col-xs-12 resultado-title'
          }
        >
          <h6>COMPROBANTE</h6>
          <div className='row w-100 approve-credit-container'>
            <div className='col text-center'>
              <h5 className='text-white'>Compra confirmada</h5>
            </div>
          </div>
          <div className='row w-100 h-auto data-credit-container'>
            <div className='col ml-2 mt-3'>
              <div className='row'>
                <div className={width < 614 ? 'col h4-movil' : 'col'}>
                  <h4>{credit?.client_info.full_name}</h4>
                </div>
              </div>
              <div className='row mt-4 justify-content-around'>
                {width < 672
                  ? (
                    <>
                      <div
                        className={
                        width < 672
                          ? 'col-lg-5 col-md-5 col-sm-12 col-xs-12 w-100 border comprobante-container-movil'
                          : 'col-lg-5 col-md-5 col-sm-12 col-xs-12 w-100 border comprobante-container'
                      }
                      >
                        <h6 className='m-3'>{formatted_date}</h6>
                        <ul className='m-2'>
                          <li>
                            <span className='span-data'>
                              Cantidad de productos: 1
                            </span>
                          </li>
                          <li>
                            <span className='span-data'>
                              {' '}
                              Nombre del producto:
                            </span>
                            <small>
                              {credit?.product_info.model}
                            </small>
                          </li>
                          <li>
                            <span className='span-data'>ID del producto:</span>

                            <small>
                              {credit?.product_info.id}
                            </small>
                          </li>
                        </ul>
                        <h4 className='mb-3 mt-3'>
                          Pago inicial:${credit?.amounts.initial_amount}MXN
                        </h4>
                        <h4 className='mb-3 mt-3'>
                          Pago semanal:${credit?.amounts.week}MXN
                        </h4>
                      </div>
                    </>
                    )
                  : (
                    <>
                      <div className='col-lg-5 col-md-5 col-sm-12 col-xs-12 w-100 border comprobante-container'>
                        <h6 className='m-3'>Datos del comercio</h6>
                        <ul className='m-2'>
                          <li>
                            <span className='span-data'>
                              Nombre del comercio:
                            </span>&nbsp;
                            <small>
                              {credit?.distributor_info.name}
                            </small>
                          </li>
                          <li>
                            <span className='span-data'>Sucursal:</span>&nbsp;
                            <small>{branchId}</small>
                          </li>
                        </ul>
                      </div>
                      <div className='col-lg-5 col-md-5 col-sm-12 col-xs-12 w-100 border comprobante-container'>
                        <h6 className='m-3'>{formatted_date}</h6>
                        <ul className='m-2'>
                          <li>
                            <span className='span-data'>
                              Cantidad de productos: 1
                            </span>
                          </li>
                          <li>
                            <span className='span-data'>
                              {' '}
                              Nombre del producto:
                            </span>
                            <small>
                              {credit?.product_info.model}
                            </small>
                          </li>
                          <li>
                            <span className='span-data'>ID del producto:</span>

                            <small>
                              {credit?.product_info.id}
                            </small>
                          </li>
                        </ul>
                        <h4 className='mb-3 mt-3'>
                          Pago inicial:${credit?.amounts.initial_amount}MXN
                        </h4>
                        <h4 className='mb-3 mt-3'>
                          Pago semanal:${credit?.amounts.week}MXN
                        </h4>
                      </div>
                    </>
                    )}
              </div>
              <div className='row mt-5'>
                <div
                  className={
                    width < 614
                      ? 'col text-center p-text-movile'
                      : 'col text-center'
                  }
                >
                  <p>
                    Enviaremos una copia del comprobante al cliente por whatsapp
                    y correo electrónico
                  </p>
                </div>
              </div>
              <div className='row mt-2 mb-3'>
                <div className='col d-flex justify-content-center'>
                  <button
                    className='btn-2 b-secondary-2 w-50'
                    onClick={() => handleFinish(credit?.id)}
                    disabled={spinner}
                  >
                    {spinner
                      ? (
                        <>
                          <span className='spinner-border spinner-border-sm' />
                          {' Enviando...'}
                        </>
                        )
                      : (
                          'Terminar'
                        )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EstadoCredito
