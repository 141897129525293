export const tagGroupList = [
  {
    group: 'Promociones',
    items: [
      { title: 'Día de las madres', color: 'warning' },
      { title: 'Hot Sale', color: 'info' },
      { title: 'Buen Fin', color: 'light' },
      { title: 'Día del padre', color: 'danger' },
      { title: 'Testimonio', color: 'info' },
      { title: 'Regreso a clases', color: 'success' },
    ],
  },
  {
    group: 'Modelos de Eva.',
    items: [
      { title: 'Modelo Eva. 1', color: 'primary' },
      { title: 'Modelo Eva. 2', color: 'secondary' },
      { title: 'Modelo Eva. 3', color: 'success' },
    ],
  },
  {
    group: 'Producto',
    items: [{ title: 'Producto retirado', color: 'danger' }],
  },
]
