import axios from 'axios'
import { toast } from 'react-toastify'
import { baseUrl, origin, originMsProducts, originMS, tokenMS } from './constants'

export const patchRequestAPI = (url, data) =>
  axios({
    url: origin + baseUrl + url,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error
    })

export const getRequestAPI = (url) =>
  axios({
    url: origin + baseUrl + url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error
    })

export const postRequestAPI = (url, data) =>
  axios({
    url: origin + baseUrl + url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (typeof errorDate === 'object') {
        Object.keys(errorDate).forEach((item) => {
          toast.error(`${item?.toUpperCase()}: ${errorDate[item][0]}`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
      } else if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        const message =
          errorDate[0] || 'Error en el servidor, intenta nuevamente'
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error.response
    })

  export const putRequestAPI = (url, data) =>
    axios({
      url: originMS + baseUrl + url,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': 'es-es',
        Authorization: "Bearer " + tokenMS, // eslint-disable-line
      },
      data,
    })
      .then((response) => response)
      .catch((error) => {
        const errorDate = error?.response?.data
        if (typeof errorDate === 'object') {
          Object.keys(errorDate).forEach((item) => {
            toast.error(`${item?.toUpperCase()}: ${errorDate[item][0]}`, {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
        } else if (errorDate?.detail) {
          toast.error(errorDate.detail, {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          const message =
            errorDate[0] || 'Error en el servidor, intenta nuevamente'
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
        throw error.response
      })
  

// JUST FOR GRAVITI ADMINITRATOR PLATFORM
export const deleteRequestAPI = (url, data) =>
  axios({
    url: origin + baseUrl + url,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error
    })
export const getRequestMsAPI = (url) =>
  axios({
    url: originMsProducts + url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error
    })
