import React, { useState, useReducer, useEffect } from 'react'
import {Form, FormGroup, ButtonGroup, Modal} from 'react-bootstrap'
import './style.css'
import Swal from 'sweetalert2'
import { Button } from 'react-bootstrap'
import Switch from '@mui/material/Switch'
import { toast } from 'react-toastify'
import {
  createProductBranch,
  getUserPayload,
} from '../../../services/constants'
import { createMsProductBranch } from '../../../services/product.service'
import Previews from '../Dropzone/dropzone.component'
import SpecificationsForm from './SpecificationsForm'

// Services
import msMerchantsService from '../../../services/ms/ms-merchants-service'
import msProductsService from '../../../services/ms/ms-products-service'

const initialProductData = {
  description: '',
  price: '',
  stock: '',
  model: '',
  images: [],
  warranty: 1,
  warranty_description: '',
  UPC: '',
  SKU: '',
  gp_product_id: '',
  brand_id: '',
  subcategory_id: '',
  external_category: '',
  need_photos: false,
  nfc_available: false,
  shipping_cost: '',
  shipping_coverage: '',
  installation_cost: '',
  installation_coverage: '',
}

function ModalAgregarProducto({ show, onClose, handleCreateProduct, product }) {
  const [productData, dispatchProductData] = useReducer(
    (state, action) => ({ ...state, ...action }),
    initialProductData
  )

  const [stepModal, setStepModal] = useState(1)
  const [brands, setBrands] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [fragments, setFragments] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  /**
   * Effect that inherits shipping/installation price/coverage from merchant
   */
  useEffect(() => {
    if (show && !product && !productData.shipping_coverage) {
      ;(async () => {
        const payload = getUserPayload()
        const merchantData = await msMerchantsService.getByUser(payload.id)
        const merchant = await msMerchantsService.get(merchantData.merchant_id)
        dispatchProductData({
          shipping_coverage: merchant.shipping_coverage?.join(',') || '',
          shipping_cost: merchant.shipping_cost || '',
          installation_coverage:
            merchant.installation_coverage?.join(',') || '',
          installation_cost: merchant.instalation_cost || '',
        })
      })()
    }
    if (show && brands.length === 0) {
      msProductsService.getBrands().then((brands) => setBrands(brands))
      msProductsService
        .getSubcategories()
        .then((categories) =>
          setSubcategories(
            categories.sort((a, b) => a.name.localeCompare(b.name))
          )
        )
    }
  }, [show])

  useEffect(() => {
    if (product) {
      dispatchProductData({ ...product, ...product.product })
      setFragments(
        product.product.specifications?.map((f) =>
          f.suffix ? { ...f, value: f.value.replace(` ${f.suffix}`, '') } : f
        ) || product.product.subcategory.specifications_template
      )
    } else {
      dispatchProductData(initialProductData)
    }
  }, [product])

  const handleProductTypeChange = (e) => {
    dispatchProductData({ subcategory_id: e.target.value })
    const actualProductSubCategory = subcategories.find(
      (t) => t.id === e.target.value
    )
    setFragments(actualProductSubCategory?.specifications_template || [])
  }

  /**
   * onsubmit event handler. Creates product on AQ
   * @param {SubmitEvent} e the submit event
   */
  const submitData = (e) => {
    e.preventDefault()
    if (stepModal !== 2) {
      if (!productData.images.length) {
        toast.error('Debes ingresar al menos una imagen', {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }
      setStepModal(stepModal + 1)
      return
    }

    const formData = new FormData(); // eslint-disable-line

    const brand = brands.find((b) => b.id === productData.brand_id)
    const subcategory = subcategories.find(
      (b) => b.id === productData.subcategory_id
    )

    const specifications = fragments?.map((f) => ({
      ...f,
      value:
        f.value
          .replace('{{MODEL}}', productData.model)
          .replace('{{BRAND}}', brand.name)
          .replace('{{CATEGRORY}}', subcategory.name) +
        (f.suffix ? ` ${f.suffix}` : ''),
    }))

    for (const key of Object.keys(initialProductData)) {
      formData.append(key, productData[key])
    }
    formData.append('category_id', subcategory.supercategory_id)
    formData.append('specifications', JSON.stringify(specifications))

    if (!productData.nfc_available) {
      formData.delete('installation_cost')
      formData.delete('installation_coverage')
    }

    formData.delete('images')
    for (const image of productData.images.filter(
      (img) => typeof img !== 'string'
    )) {
      formData.append('images', image)
    }

    // upload
    setIsSubmitting(true)
    if (product) {
      // edit
      if (productData.images.some((img) => typeof img === 'string')) {
        formData.append(
          'saved_images',
          productData.images.filter((img) => typeof img === 'string').join(',')
        )
      }

      msProductsService
        .updateProductBranch(product.id, formData)
        .then((res) => {
          toast.success('Producto editado con éxito', {
            position: toast.POSITION.TOP_RIGHT,
          })
          onClose()
          handleCreateProduct(res)
        })
        .catch((error) => {
          toast.error(error.response.data.description, {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
        .finally(() => setIsSubmitting(false))
    } else {
      // create
      createMsProductBranch(createProductBranch(), formData)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire(
              'Graviti revisara el producto dado de alta, en cuanto lo apruebe aparecera en tu lista',
              '',
              'success'
            )
            onClose()
            handleCreateProduct(res.data)
          }
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  const handleInputChange = (e) =>
    dispatchProductData({ [e.target.name]: e.target.value })

  return (
    <Modal show={show} onHide={() => onClose()} centered size="xl">
      <Form onSubmit={(e) => submitData(e)}>
        <Modal.Body>
          <div className="container">
            <h3 className="text-center">Nuevo producto</h3>
            <ButtonGroup aria-label="Basic example" className="d-flex mb-4">
              <Button
                size="lg"
                className={stepModal === 1 ? 'active_btn' : 'inactive_btn'}
                onClick={() => setStepModal(1)}
              >
                Características
              </Button>
              <Button
                size="lg"
                className={stepModal === 2 ? 'active_btn' : 'inactive_btn'}
                onClick={() => setStepModal(2)}
              >
                Datos de Envío
              </Button>
              {/* <Button
              size={"lg"}
              className={stepModal === 3 ? "active_btn" : "inactive_btn"}
              onClick={() => setStepModal(3)}
            >
              Agregar Variaciones
            </Button> */}
            </ButtonGroup>
            {stepModal === 1 ? (
              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Form.Label htmlFor="subcategory_id">Tipo de producto</Form.Label>
                    <Form.Select
                      name="subcategory_id"
                      value={productData.subcategory_id}
                      id="subcategory_id"
                      onChange={handleProductTypeChange}
                    >
                      <option value="menu">Selecciona una categoría</option>
                      {subcategories?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup>
                    <Form.Label htmlFor="brand">Marca</Form.Label>
                    <Form.Select
                      name="brand_id"
                      id="brand_id"
                      value={productData.brand_id}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">selecciona</option>
                      {brands?.map((brandItem) => (
                        <option value={brandItem.id} key={brandItem.id}>
                          {brandItem.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <Form.Label htmlFor="price">Precio base</Form.Label>
                    <Form.Control
                      type="number"
                      name="price"
                      value={productData.price}
                      id="price"
                      min="0"
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <Form.Label htmlFor="SKU">SKU</Form.Label>
                    <Form.Control
                      type="text"
                      name="SKU"
                      value={productData.SKU}
                      id="SKU"
                      onChange={handleInputChange}
                      required
                      pattern="^[a-zA-Z0-9\-]+$"
                      title="Ingresa solo letras, números y guiones"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <Form.Label htmlFor="stock">En Stock</Form.Label>
                    <Form.Control
                      type="number"
                      name="stock"
                      value={productData.stock}
                      id="stock"
                      min="0"
                      required
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <Form.Label HtmlFor="gp_product_id">ID Ecommerce</Form.Label>
                    <Form.Control
                      type="text"
                      name="gp_product_id"
                      value={productData.gp_product_id}
                      id="gp_product_id"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
                <SpecificationsForm
                  fragments={fragments}
                  onChange={setFragments}
                />
                <div className="col-md-6">
                  <FormGroup>
                    <Form.Label htmlFor="exampleSelect">Modelo</Form.Label>
                    <Form.Control
                      type="text"
                      name="model"
                      value={productData.model}
                      id="exampleSelect"
                      onChange={handleInputChange}
                      required={false}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <FormGroup>
                    <Form.Label htmlFor="description">Descripción</Form.Label>
                    <Form.Control
                      type="textarea"
                      name="description"
                      value={productData.description}
                      id="description"
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <Form.Label htmlFor="exampleSelect">Agregar Fotos</Form.Label>
                </div>
                <Previews
                  files={productData.images}
                  onFilesAccepted={(files) =>
                    dispatchProductData({ images: files })
                  }
                />
              </div>
            ) : stepModal === 2 ? (
              <div className="row">
                <div className="col-12 d-flex">
                  <Form.Label htmlFor="nfc_available" className="h2">
                    ¿Ofreces instalación de este producto?
                  </Form.Label>
                  <Switch
                    defaultChecked={productData.nfc_available}
                    size="medium"
                    id="nfc_available"
                    name="nfc_available"
                    onChange={() =>
                      dispatchProductData({
                        nfc_available: !productData.nfc_available,
                      })
                    }
                    color="primary"
                  />
                </div>
                <div className="col-12 mt-4">
                  <h3>Envío</h3>
                </div>
                <div className="col-4">
                  <FormGroup>
                    <Form.Label htmlFor="shipping_cost">Costo de envío</Form.Label>
                    <Form.Control
                      type="number"
                      name="shipping_cost"
                      value={productData.shipping_cost}
                      id="shipping_cost"
                      onChange={handleInputChange}
                      placeholder="1234.56"
                      min="0"
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-8">
                  <FormGroup>
                    <Form.Label htmlFor="shipping_coverage">
                      Cobertura(s) de envío: Códigos postales (primeros 2
                      dígitos) separados por COMAS
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="shipping_coverage"
                      value={productData.shipping_coverage}
                      id="shipping_coverage"
                      onChange={handleInputChange}
                      placeholder="01, 02 ,03..."
                      required
                      pattern="^([0-9]{2},)*[0-9]{2}$"
                      title="Debes ingresar los primeros 2 digitos de cada código postal donde tengas cobertura, separados por comas."
                    />
                  </FormGroup>
                </div>
                {productData.nfc_available && (
                  <>
                    <div className="col-12 mt-4">
                      <h3>Instalación</h3>
                    </div>
                    <div className="col-4">
                      <FormGroup>
                        <Form.Label htmlFor="installation_cost">
                          Costo de instalación
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="installation_cost"
                          value={productData.installation_cost}
                          id="installation_cost"
                          onChange={handleInputChange}
                          required
                          min="0"
                          placeholder="1234.56"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-8">
                      <FormGroup>
                        <Form.Label htmlFor="installation_coverage">
                          Cobertura(s) de instalación: Códigos postales
                          (primeros 2 dígitos) separados por COMAS
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="installation_coverage"
                          value={productData.installation_coverage}
                          id="installation_coverage"
                          onChange={handleInputChange}
                          required
                          pattern="^([0-9]{2},)*[0-9]{2}$"
                          title="Debes ingresar los primeros 2 digitos de cada código postal donde tengas cobertura, separados por comas."
                          placeholder="01, 02 ,03..."
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => onClose()}>
            Descartar
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {stepModal === 1 ? 'Siguiente' : 'Subir producto'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalAgregarProducto
