import axios from 'axios'
import { toast } from 'react-toastify'
import { origin, baseUrl } from './constants'

/**
 * Base request
 */
const baseRequest = axios.create({
  baseURL: `${origin}${baseUrl}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'es-es',
    Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
  },
})

export const getSystems = () =>
  axios({
    url: `${origin + baseUrl}systems/?page_size=1000`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data.results)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor. Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getSystemsInStock = () =>
  axios({
    url: `${origin + baseUrl}systems/stock/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor. Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const assignedSystem = (data) => {
  const formData = new FormData(); // eslint-disable-line

  if (data.serie !== null && data.equipo !== null) {
    formData.append('equipo', data.equipo)
    formData.append('serie', data.serie)
  } else if (data.serie === null && data.equipo !== null) {
    formData.append('equipo', data.equipo)
  } else if (data.serie !== null && data.equipo === null) {
    formData.append('serie', data.serie)
  } else {
    formData.append('system', data.id)
    formData.append('instalation_date', data.instalation_date)
  }

  return axios({
    url: `${origin + baseUrl}credits/${data.id_credit}/assignment/`,
    method: 'patch',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data: formData,
  }).then((response) => {
    toast.success('Crédito actualizado correctamente', {
      position: toast.POSITION.TOP_RIGHT,
    })
    return response.data.results
  })
}

export const assignSystemByDistributor = (data) => {
  const formData = new FormData()
  formData.append('system', data.system_id)

  return axios({
    url: `${origin + baseUrl}credits/${
      data.id_credit
    }/assignment/by-distributor/`,
    method: 'patch',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    data: formData,
  })
    .then((response) => {
      toast.success('Crédito actualizado correctamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response.data.results
    })
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor (Marcas). Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
}

export const confirmInstallation = (credit_id, installed_date = null) => {
  const formData = new FormData()
  if (installed_date) formData.append('installed_date', installed_date)

  return baseRequest.patch(
    `credits/${credit_id}/confirm/installation/by-distributor/`,
    formData
  )
}
