import React from 'react'
import './featuredInfo.css'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

export default function FeaturedInfo({
  gmv,
  sales,
  deposits,
  lastSales,
  gmvLast,
}) {
  const gvmPercentage = gmv && gmvLast ? ((gmv - gmvLast) / gmvLast) * 100 : 0
  const salesPorcentajes = ((sales - lastSales) / lastSales) * 100 || 0

  const gvm_formated = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2,
  }).format(gmv || 0.0)

  const deposits_formated = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2,
  }).format(deposits)

  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">GMV</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{gmv && gvm_formated}</span>
          <span className="featuredMoneyRate">
            {gvmPercentage.toFixed(2)}%{' '}
            {gmv > gmvLast ? (
              <ArrowUpward className="featuredIcon" />
            ) : (
              <ArrowDownward className="featuredIcon negative" />
            )}
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Depositado</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{deposits_formated}</span>
        </div>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Unidades Vendidas</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{sales}</span>
          <span className="featuredMoneyRate">
            {salesPorcentajes.toFixed(2)}%{' '}
            {sales > lastSales ? (
              <ArrowUpward className="featuredIcon" />
            ) : (
              <ArrowDownward className="featuredIcon negative" />
            )}
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
    </div>
  )
}
