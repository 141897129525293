// Axios
import axios from 'axios'

// Utilities
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

// Constants
import { msUsersPrefix, originMS, tokenMS } from '../constants'

/**
 * Axios base request
 */
const baseRequest = axios.create({
  baseURL: `${originMS}${msUsersPrefix}`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenMS, // eslint-disable-line
  },
})

const defaultErrorHandler = (err) => {
  const { data } = err.response
  let message = data.detail || data.description || data.errors
  if (message instanceof Object) {
    message = (
      <div>
        {Object.keys(message).map((key) => (
          <>
            {`${key}: ${JSON.stringify(message[key])}`}
            <br />
          </>
        ))}
      </div>
    )
  }
  toast.error(message || 'Error en el servidor, Intenta nuevamente', {
    position: toast.POSITION.TOP_RIGHT,
  })
  throw err
}

/**
 * Performs login into MS users
 * @param {*} username
 * @param {*} password
 * @returns
 */
const login = (username, password) =>
  baseRequest.post('/login', { username, password }).then((res) => res.data)

/**
 * Retrieves a shopper
 * @param {*} id
 * @returns
 */
const getShopper = (id, handleError = true) => {
  const request = baseRequest.get(`/admin/${id}`).then((res) => res.data)
  if (handleError) request.catch(defaultErrorHandler)
  return request
}

/**
 * Retrieves a shopper
 * @param {*} page
 * @returns
 */
const getUnrelatedShopper = (page = 1, search = '', handleError = true) => {
  const request = baseRequest
    .get(`/shopper/unrelated?page=${page}&search=${search}`)
    .then((res) => res.data)
  if (handleError) request.catch(defaultErrorHandler)
  return request
}

const getShopperId = (id, handleError = true) => {
  const request = baseRequest
    .get(`/admin/${id}`)
    .then((res) => res.data)
  if (handleError) request.catch(defaultErrorHandler)
  return request
}

/**
 * Retrieves a shopper by aq_id
 * @param {*} id
 * @returns
 */
const getShopperByAqId = (aq_id, handleError = true) => {
  const request = baseRequest.get(`/admin/aq/${aq_id}`).then((res) => res.data)
  if (handleError) request.catch(defaultErrorHandler)
  return request
}

/**
 * Creates a new shopper
 * @param {*} shopper
 * @returns
 */
const createShopper = (shopper) =>
  baseRequest
    .post('/shopper', shopper)
    .then((res) => res.data)
    .catch(defaultErrorHandler)

/**
 * Updates a shopper
 * @param {*} id
 * @param {*} shopper
 * @returns
 */
const updateShopper = (id, shopper) =>
  baseRequest
    .patch(`/shopper/${id}`, shopper)
    .then((res) => res.data)
    .catch(defaultErrorHandler)

/**
 * Decodes a Bearer from MS users
 * @param {*} Bearer
 */
const decodeToken = (token) => jwtDecode(token)

const service = {
  login,
  decodeToken,
  createShopper,
  updateShopper,
  getShopper,
  getShopperByAqId,
  getUnrelatedShopper,
  getShopperId,
}

export default service
