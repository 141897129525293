import React, { useState, useEffect } from 'react'
import './accountingDetails.css'
import axios from 'axios'
import PaginatorComponent from '../../Shared/Paginator.component'
import Loading from '../../Shared/Loading.component'
import TransactionsDetailsTableComponent from './transactionsDetailsTable.component'
import { origin, baseUrl } from '../../../services/constants'
import {
  getTransactionsInMonth,
  getTransactionsPerMonth,
} from '../../../services/transactions.service'

const headersInstallations = [
  'ID de contrato',
  'Nombre',
  'Producto',
  'Fecha de Transacción',
  'Método de pago',
  'Capital de Distribuidores',
  'Capital de Graviti',
  'Intereses',
  'IVA intereses',
  'Comisiones',
  'IVA comisiones',
  'Transaccionado',
  'Comentarios',
]

export default function TransactionsDetailsComponent(props) {
  const [monthProp, setMonth] = useState(props.match.params.month)
  const [yearProp, setYear] = useState(props.match.params.year)
  const [rows, setRows] = useState([])
  const [dataMonth, setdataMonth] = useState(null)
  const [activePage, setactivePage] = useState(1)
  const [monthPage] = useState(props.match.params.propMonthPage)
  const [itemsCountPerPage] = useState(10)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [isStopped, setIsStopped] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState({})

  useEffect(() => {
    getTransactionsInMonth(yearProp, monthProp).then(({ results, count }) => {
      setRows(results)
      setTotalItemsCount(count)
      setIsStopped(true)
    })
  }, [])

  useEffect(() => {
    getTransactionsPerMonth(monthPage).then(({ results }) => {
      const selectedMonth = results.filter(
        (m) =>
          Number(m.start_period.slice(5, 7)) === Number(monthProp) &&
          Number(m.start_period.slice(0, 4)) === Number(yearProp)
      )
      setdataMonth(selectedMonth[0])
      setIsStopped(true)
    })
  }, [])

  const handlePaginator = (yearProp, monthProp, number) => {
    setactivePage(number)
    setRows([])
    setIsStopped(true)
    getTransactionsInMonth(yearProp, monthProp, number).then(
      ({ results, count }) => {
        setRows(results)
        setTotalItemsCount(count)
        setIsStopped(true)
      }
    )
  }

  const handleRedirectPageDetail = (id) => {
    const path = `/cuentas/detalles/${id}`
    window.location.pathname = path
  }

  const getAsyncFile = (task, requestLoop) => {
    axios({
      url: `${origin}${baseUrl}services/poll_async_results/front/${task}/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
      },
    })
      .then((response) => {
        const {
          status,
          data: { location, filename },
        } = response
        if (status === 200) {
          setFile({
            location,
            filename,
          })
          setIsLoading(false)
          clearInterval(requestLoop)
        } else if (status === 500) {
          setIsLoading(false)
          clearInterval(requestLoop)
        }
      })
      .catch((error) => {
        console.error(error)
        console.error(error.response)
        clearInterval(requestLoop)
        setIsLoading(false)
      })
  }

  const getTransactionExport = () => {
    setFile({})
    setIsLoading(true)
    axios({
      url: `${origin}${baseUrl}transactions/sales-by-period/export/${yearProp}/${monthProp}/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
      },
    })
      .then((response) => {
        const task = response?.data?.task_id
        const requestLoop = setInterval(
          () => getAsyncFile(task, requestLoop),
          1000
        )
      })
      .catch((error) => {
        console.error(error)
        console.error(error.response)
        setIsLoading(false)
      })
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-7 col-md-7">
          <h1>{`TRANSACCIONES REALIZADAS EN ${monthProp}/${yearProp}`} </h1>
        </div>

        <div className="col-lg-5 col-md-5">
          <div className="box-information">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <table>
                  <tbody>
                    <tr className="celdaText text-center">
                      <td>{'Capital: '}</td>
                      <td>{dataMonth?.capital?.toFixed(2) || 0}</td>
                    </tr>
                    <tr className="celdaText text-center">
                      <td>{'Intereses: '}</td>
                      <td>{dataMonth?.interests?.toFixed(2) || 0}</td>
                    </tr>
                    <tr className="celdaText text-center">
                      <td>{'IVA intereses: '}</td>
                      <td>{dataMonth?.iva_interests?.toFixed(2) || 0}</td>
                    </tr>
                    <tr className="celdaText text-center">
                      <td>{'Comisiones: '}</td>
                      <td>{dataMonth?.commisions?.toFixed(2) || 0}</td>
                    </tr>
                    <tr className="celdaText text-center">
                      <td>{'IVA comisiones: '}</td>
                      <td>{dataMonth?.iva_commisions?.toFixed(2) || 0}</td>
                    </tr>
                    <tr className="celdaText text-center">
                      <td>{'Transaccionado: '}</td>
                      <td>{dataMonth?.transacted?.toFixed(2) || 0}</td>
                    </tr>
                    <tr className="celdaText text-center">
                      <td>{'Total de transacciones: '}</td>
                      <td>{dataMonth?.count || 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg col-md">
                <table>
                  <tbody>
                    <tr className="celdaText text-center">
                      <td>
                        <a
                          onClick={(e) => {
                            e.preventDefault()
                            getTransactionExport()
                          }}
                          href=""
                        >
                          <black>Descargar reporte</black>
                        </a>
                        {isLoading && <Loading isStopped={false} blue />}
                        {file.location && (
                          <h6 style={{ marginTop: '15%', marginBottom: '15%' }}>
                            <a href={file.location}>
                              <black>{file.filename}</black>
                            </a>
                          </h6>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <TransactionsDetailsTableComponent
            isStopped={isStopped}
            headers={headersInstallations}
            rows={rows}
            hidden={false}
            handleClick={handleRedirectPageDetail}
          />
          <br />
          <PaginatorComponent
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            handlePageChange={(number) => {
              handlePaginator(yearProp, monthProp, number)
            }}
          />
        </div>
      </div>
    </div>
  )
}
