export const newLeadSteps = [
  {
    id: 1,
    name: '01 Producto y Verificación',
  },
  {
    id: 2,
    name: '02 Información personal',
  },
  {
    id: 3,
    name: '03 Información laboral',
  },
  {
    id: 4,
    name: '04 Información adicional',
  },
]

export const editLeadSteps = [
  {
    id: 1,
    name: '01 Información personal',
  },
  {
    id: 2,
    name: '02 Producto',
  },
  {
    id: 3,
    name: '03 Información laboral',
  },
  {
    id: 4,
    name: '04 Información adicional',
  },
]
