import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import './css/modal.css'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

class HowToPayComponent extends Component {
  render() {
    return (
      <Popup
        trigger={
          <img src={this.props.icon} alt="pago" style={{ cursor: 'pointer' }} />
        }
        modal
        closeOnDocumentClick
      >
        {(close) => (
          <>
            {/* <a className="close" onClick={close}> &times; </a> */}
            <div className="container" style={{ padding: '40px' }}>
              <div className="row">
                <div className="col text-center">
                  <h1>Elige tu forma de pago</h1>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <Tabs defaultActiveKey="bbva" id="uncontrolled-tab-example">
                    <Tab eventKey="bbva" title="BBVA">
                      <div className="container">
                        <div className="row">
                          <div className="col">
                            <br />
                            <strong>Desde BBVA</strong>
                            <br />
                            <ol>
                              <li>
                                Desde el menú de "Pagar" seleccione la opción
                                "De Servicios" e ingrese el siguiente "Número de
                                convenio CIE"
                                <table
                                  className="table table-borderless table-sm"
                                  style={{ marginTop: '15px', width: '60%' }}
                                >
                                  <thead />
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Número de convenio CIE:</strong>
                                      </td>
                                      <td>1422286 (Este campo nunca cambia)</td>
                                    </tr>
                                    <tr />
                                    <tr />
                                  </tbody>
                                </table>
                              </li>
                              <li>
                                Ingrese los datos de registro para concluir con
                                la operación
                                <table
                                  className="table table-borderless table-sm"
                                  style={{ marginTop: '15px', width: '100%' }}
                                >
                                  <thead />
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Referencia:</strong>
                                      </td>
                                      <td>
                                        Aquí va el campo de{' '}
                                        <strong>"Concepto de pago"</strong> de
                                        la tabla de desembolsos
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Importe:</strong>
                                      </td>
                                      <td>
                                        Aquí va el campo de{' '}
                                        <strong>"Monto"</strong> de la tabla de
                                        desembolsos
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Concepto:</strong>
                                      </td>
                                      <td>Desembolso Graviti</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="otros" title="Otros bancos">
                      <div className="container">
                        <div className="row">
                          <div className="col">
                            <br />
                            <strong>Desde cualquier otro banco</strong>
                            <br />
                            <ol>
                              <li>
                                Ingresa a la sección de transferencias y pagos o
                                pagos a otros bancos y proporciona los datos de
                                la transferencia
                                <table
                                  className="table table-borderless table-sm"
                                  style={{ marginTop: '15px', width: '100%' }}
                                >
                                  <thead />
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Beneficiario:</strong>
                                      </td>
                                      <td>Vitaluz</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Banco destino:</strong>
                                      </td>
                                      <td>BBVA Bancomer</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Clabe:</strong>
                                      </td>
                                      <td>012914002014222862</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Concepto de pago:</strong>
                                      </td>
                                      <td>
                                        Aquí va el campo de{' '}
                                        <strong>"Concepto de pago"</strong> de
                                        la tabla de desembolsos
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Referencia:</strong>
                                      </td>
                                      <td>1422286 (Este campo nunca cambia)</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Importe:</strong>
                                      </td>
                                      <td>
                                        Aquí va el campo de{' '}
                                        <strong>"Monto"</strong> de la tabla de
                                        desembolsos
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    )
  }
}

export default HowToPayComponent
