const initialState = {
  login: [],
  error: null,
  loading: false,
}

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
