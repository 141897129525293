import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import debounce from 'lodash.debounce'
import { patchRequestAPI } from '../../services/requests.service'
import { changeProductStatus } from '../../services/constants'
import ProductsTableComponent from './productsTable.component'
import PaginatorComponent from '../Shared/Paginator.component'
import SearchProduct from './SearchProduct/searchProduct'
import ModalAgregarProducto from './SearchProduct/modalAgregar'
import ModalListProducto from './SearchProduct/modalList'

// Services
import msProductsService from '../../services/ms/ms-products-service'

const headersProducts = [
  'ID',
  'SKU',
  'Nombre de producto',
  'Marca',
  'Categoría',
  'Precio',
  'Activo',
  '',
]

const ITEMS_COUNT_PER_PAGE = 10

function ProductsComponent() {
  const [rows, setRows] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [isStopped, setIsStopped] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState()
  const [show, setShow] = useState(false)
  const [list, setList] = useState(false)
  const [productList, setProductList] = useState(null)
  const [modalKey, setModalKey] = useState(0)

  const [search, setSearch] = useState()

  const loadProducts = useCallback(
    debounce((page, pageCount, search) => {
      setIsStopped(true)
      msProductsService
        .getProducts(page, pageCount, search)
        .then((response) => {
          setIsStopped(false)
          setRows(response.data.results)
          setTotalItemsCount(response.data.total)
        })
    }, 500),
    []
  )

  useEffect(() => {
    loadProducts(activePage, ITEMS_COUNT_PER_PAGE, search)
  }, [loadProducts, activePage, search])

  const handleLockProduct = (data) => {
    Swal.fire({
      title: '¿Estás seguro de que quieres cambiar el status del producto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        patchRequestAPI(changeProductStatus(), {
          id: data.id,
          active: data.active,
        })
        Swal.fire('Listo!')
      }
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
  }

  const handleAddProduct = () => {
    setSelectedProduct(null)
    setShow(true)
  }

  const handleCreateProduct = (data) => {
    setModalKey(modalKey + 1)
    if (selectedProduct) {
      setRows(rows.map((r) => (r.id === selectedProduct.id ? data : r)))
      setSelectedProduct(null)
    }
  }

  const handleEditProduct = (product) => {
    setSelectedProduct(product)
    setShow(true)
  }

  const deleteProduct = (id) => {
    Swal.fire({
      title: '¿Estás seguro de que quieres eliminar el producto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        msProductsService
          .deleteProductBranch(id)
          .then((response) => {
            loadProducts(activePage, ITEMS_COUNT_PER_PAGE)
            Swal.fire('Producto eliminado!')
          })
          .catch((e) => {
            toast.error('El producto no se eliminó!', {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
      }
    })
  }

  const productActivate = (product, value) => {
    const formData = new FormData()
    formData.append('active', value)
    setIsStopped(true)
    msProductsService
      .updateProductBranch(product.id, formData)
      .then((response) => {
        loadProducts(activePage, ITEMS_COUNT_PER_PAGE, search)
      })
  }

  return (
    <>
      <ModalListProducto
        list={list}
        setList={setList}
        productList={productList}
        handleLockProduct={handleLockProduct}
      />
      <ModalAgregarProducto
        key={modalKey}
        product={selectedProduct}
        show={show}
        onClose={() => setShow(false)}
        handleCreateProduct={handleCreateProduct}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 offset-3">
            <SearchProduct value={search} onChange={setSearch} />
          </div>
          <div className="col-md-3">
            <button
              className="btn-2 b-primary"
              onClick={() => handleAddProduct()}
            >
              Nuevo producto
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-9 col-sm-12 col-xs-12">
            <h4>Productos({totalItemsCount})</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <ProductsTableComponent
              isStopped={isStopped}
              headers={headersProducts}
              rows={rows}
              filterProduct={rows}
              onEdit={handleEditProduct}
              onDeleteClick={deleteProduct}
              activate={productActivate}
            />
            <br />
            <PaginatorComponent
              activePage={activePage}
              itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
              totalItemsCount={totalItemsCount}
              handlePageChange={setActivePage}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductsComponent
