import React, { useContext } from 'react'
import * as CurrencyFormat from 'react-currency-format'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import InputComponent from '../../Shared/Inputs/input'
import SelectComponent from '../../Shared/Inputs/select.component'
import { createCredit } from '../../../services/accounts.service'
import shopper from '../../../services/ms/ms-users-service'

// Context
import CreditFormContext from '../../../containers/CreditForm/context'

function AdditionalInformation({
  currentStep,
  afterValid,
  previous,
  cancel,
  setExtraForm,
  extraForm,
}) {
  const { clientInfo, setClientInfo, creditInfo, lastCredit } =
    useContext(CreditFormContext)
  const history = useHistory()

  if (currentStep !== 4) {
    return null
  }

  const rendererQuestions = (questions, question, answers, setFieldValue) => {
    const questionRenderer = {
      select_params: (
        <SelectComponent
          key={questions[question].text}
          label={questions[question].text}
          data={Object.keys(questions[question].answers || {}).map((q) => ({
            name: q,
            id: q,
          }))}
          option={(value) => {
            setFieldValue(`answers.${question}`, value)
          }}
          value={answers ? answers[question] : ''}
          id="id"
          name="name"
        />
      ),
      select: (
        <SelectComponent
          key={questions[question].text}
          label={questions[question].text}
          data={Object.keys(questions[question].answers || {}).map((q) => ({
            name: questions[question].answers[q],
            id: q,
          }))}
          option={(value) => {
            setFieldValue(`answers.${question}`, value)
          }}
          value={answers ? answers[question] : ''}
          id="name"
          name="name"
        />
      ),
      boolean: (
        <SelectComponent
          key={questions[question].text}
          label={questions[question].text}
          data={Object.keys(questions[question].answers || {}).map((q) => ({
            name: q,
            id: questions[question].answers[q],
          }))}
          option={(value) => {
            setFieldValue(`answers.${question}`, value)
          }}
          value={answers ? answers[question] : ''}
          id="id"
          name="name"
        />
      ),
      int: (
        <>
          <CurrencyFormat
            key={questions[question].text}
            placeholder={questions[question].text}
            prefix={questions[question].prefix}
            suffix={questions[question].sufix}
            className="input-g2"
            onChange={(e) => {
              setFieldValue(`answers.${question}`, e.target.value)
            }}
            value={answers ? answers[question] : ''}
          />
          <span className="labelInput">{questions[question].text}</span>
        </>
      ),
      str: (
        <>
          <input
            key={questions[question].text}
            placeholder={questions[question].text}
            type="text"
            className="input-g2"
            onChange={(e) => {
              setFieldValue(`answers.${question}`, e.target.value)
            }}
            value={answers ? answers[question] : ''}
          />
          <span className="labelInput">{questions[question].text}</span>
        </>
      ),
    }
    return (
      <div className="col-12 col-md-6 my-2">
        {questionRenderer[questions[question].type]}
      </div>
    )
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setClientInfo((s) => ({ ...s, ...values }))
    shopper
      .updateShopper(clientInfo?.id, { ...clientInfo, ...values })
      .then((response) => {
        createCredit(clientInfo?.id, creditInfo)
          .then((response) => {
            if (response.status !== 500) {
              toast.success('Crédito creado correctamente!', {
                position: toast.POSITION.TOP_RIGHT,
              })
              if (lastCredit?.id) {
                history.push(`/cuentas/detalles/${lastCredit.id}`)
              } else {
                history.push('/cuentas')
              }
            } else {
              toast.error('El crédito no se creo', {
                position: toast.POSITION.TOP_RIGHT,
              })
            }
          })
          .catch((err) => {
            toast.error('El crédito no se creo', {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
      })
      .catch((err) => {
        toast.error('Los datos del cliente no se actualizaron', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    setSubmitting(false)
  }

  return (
    <div>
      <Formik initialValues={clientInfo} onSubmit={handleSubmit}>
        {({ values, handleChange, isSubmitting }) => (
          <Form>
            <div className="row my-1">
              <div className="col-12">
                <h3 className="text-primary-graviti font-weight-bold">
                  Información adicional
                </h3>
              </div>
            </div>
            <div className="row my-1">
              <div className="col-12 col-md-6">
                <Field
                  name="count_home"
                  value="0"
                  required
                  type="number"
                  component={InputComponent}
                  validate={(value) =>
                    value < 0 && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        La cantidad no puede ser negativa
                      </p>
                    )
                  }
                  placeholder="Cantidad de dependientes"
                />
                <span className="labelInput">Número de dependientes</span>
                <ErrorMessage name="count_home" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="income_family"
                  component={InputComponent}
                  placeholder="$"
                  type="number"
                  validate={(value) =>
                    value ? (
                      value < 0 ? (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          La cantidad no puede ser negativa
                        </p>
                      ) : (
                        value < values.income && (
                          <p className="mt-3 mb-0 pb-0 text-danger">
                            La cantidad no puede ser menor al ingreso personal
                          </p>
                        )
                      )
                    ) : (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">
                  Escriba el ingreso familiar aquí
                </span>
                <ErrorMessage name="income_family" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="monthly_expenditure"
                  component={InputComponent}
                  placeholder="$"
                  type="number"
                  validate={(value) =>
                    value ? (
                      value < 0 && (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          La cantidad no puede ser negativa
                        </p>
                      )
                    ) : (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">
                  Escriba el gasto mensual aquí
                </span>
                <ErrorMessage name="monthly_expenditure" component="div" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={previous}
                  disabled={isSubmitting}
                  block
                >
                  Atrás
                </Button>
              </div>
              <div className="col-12 col-md-4">
                <Button
                  type="submit"
                  variant="secondary"
                  size="lg"
                  block
                  disabled={isSubmitting}
                >
                  Siguiente
                </Button>
              </div>
              <div className="col-12 col-md-4">
                <Button
                  variant="outline-light"
                  size="lg"
                  onClick={cancel}
                  disabled={isSubmitting}
                  block
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AdditionalInformation
