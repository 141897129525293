import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'

import VerificarProducto from './VerificarProducto'
import VerificacionInformacion from './VerificaciónInformacion'
import Resultado from './Resultado'
import EstadoCredito from './EstadoCredito'
import NotFound from './NotFound'

const VerificationQR = ({
  handleSubmitMerchant,
  onChangeMerchant,
  value,
  eventKey,
  credit,
  handleConfirm,
  handleCancel,
  handleFinishVerification,
  modal,
  onChangeModal,
  handleChangeModal,
  onChangeBackButton,
  onDetectedMerchant,
  onNewScanResult,
  decodedResults,
  spinner,
  closeModal
}) => {
  const [key, setKey] = useState('01')

  const [width, setWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    setKey(eventKey)

    window.addEventListener('resize', handleResize)
  }, [eventKey])

  return (
    <div className='nav-container'>
      <Tabs
        defaultActiveKey={key}
        transition={false}
        activeKey={key}
        id='noanim-tab-example'
        className={width < 672 ? 'd-none' : 'p-0 h-75 font-weight-bold'}
        style={{ fontSize: '15px' }}
      >
        <Tab
          eventKey='01'
          title='01 VERIFICAR PRODUCTO'
          tabClassName='tab-styles tab-styles-qr tab'
        >
          <VerificarProducto
            handleSubmitMerchant={handleSubmitMerchant}
            onChangeMerchant={onChangeMerchant}
            value={value}
            onDetectedMerchant={onDetectedMerchant}
            modal={modal}
            onChangeModal={onChangeModal}
            closeModal={closeModal}
          />
        </Tab>
        <Tab
          eventKey='02'
          title='02 VERIFICAR INFORMACIÓN'
          tabClassName='tab-styles tab-styles-qr'
        >
          <VerificacionInformacion />
        </Tab>
        <Tab
          eventKey='03'
          title='03 RESULTADO'
          tabClassName='tab-styles tab-styles-qr'
        >
          <Resultado
            credit={credit}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        </Tab>
        <Tab
          eventKey='04'
          title='04 ESTADO DEL CRÉDITO'
          tabClassName='tab-styles tab-styles-qr'
        >
          <EstadoCredito
            credit={credit}
            spinner={spinner}
            handleFinishVerification={handleFinishVerification}
          />
        </Tab>
        <Tab eventKey='05'>
          <NotFound onChangeBackButton={onChangeBackButton} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default VerificationQR
