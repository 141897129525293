import React, { useState, useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button } from 'react-bootstrap'
import RfcFacil from 'rfc-facil'
import moment from 'moment'
import UploadFileComponent from '../../Shared/UploadFile'
import { getneighborhoods } from '../../../services/share.service'
import InputComponent from '../../Shared/Inputs/input'
import { generateCURP } from '../../../services/accounts.service'
import statesMexico from '../../../assets/states.json'
// Context
import CreditFormContext from '../../../containers/CreditForm/context'

const statesJson = JSON.parse(JSON.stringify(statesMexico))

const normalize = (str) =>
  str
    .toLowerCase()
    .replace('á', 'a')
    .replace('é', 'e')
    .replace('í', 'i')
    .replace('ó', 'o')
    .replace('ú', 'u')
    .toUpperCase()

function PersonalInformation({ currentStep, afterValid, previous, cancel }) {
  const [neighborhoods, setNeighborhoods] = useState([])
  const { clientInfo, setClientInfo } = useContext(CreditFormContext)

  if (currentStep !== 2) {
    return null
  }

  const handleGenerateRFC = (values, setFieldValue) => {
    if (values.birthday !== '' && values.birthday !== null) {
      const date = values.birthday.split('-')

      const rfc = RfcFacil.forNaturalPerson({
        name: clientInfo.name,
        firstLastName: clientInfo.lastname,
        secondLastName: clientInfo.second_lastname,
        day: date[2],
        month: date[1],
        year: date[0],
      })
      setFieldValue('rfc', rfc)
    }
  }

  const handleGenerateCURP = (values, setFieldValue) => {
    if (values.birthday !== '' && values.gender !== '' && values.state !== '') {
      const statesJson = JSON.parse(JSON.stringify(statesMexico))
      const normalize = (str) =>
        str
          .toLowerCase()
          .replace('á', 'a')
          .replace('é', 'e')
          .replace('í', 'i')
          .replace('ó', 'o')
          .replace('ú', 'u')
          .toUpperCase()
      generateCURP(
        values.birthday,
        statesJson.find((s) => s.state === normalize(values.state))?.id || 'MC',
        clientInfo.second_lastname,
        clientInfo.name,
        clientInfo.lastname,
        values.gender === 'H' ? 'Masculino' : 'Femenino'
      ).then((curp) => {
        setFieldValue('curp', curp)
      })
    }
  }

  const getAddressByZip = (e, setFieldValue) => {
    setFieldValue('zip', e.target.value)
    if (e.target?.value && e.target.value.length === 5) {
      getneighborhoods(e.target?.value).then((neighborhood) => {
        if (neighborhood !== null) {
          setNeighborhoods(neighborhood.colonias)
          setFieldValue('municipality', neighborhood?.municipio)
          setFieldValue('city', neighborhood?.ciudad)
          setFieldValue('state_name', neighborhood?.estado)
          setFieldValue(
            'state',
            statesJson.find((s) => s.state === normalize(neighborhood?.estado))
              ?.id || 'DESCONOCIDO'
          )
          setFieldValue('country', 'México')
        }
      })
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setClientInfo((s) => ({ ...s, ...values }))
    setSubmitting(false)
    afterValid()
  }

  return (
    <div>
      <Formik initialValues={clientInfo} onSubmit={handleSubmit}>
        {({
          values,
          isSubmitting,
          handleChange,
          setFieldValue,
          setTouched,
        }) => (
          <Form>
            <div className="row my-4">
              <div className="col-12">
                <h3 className="text-primary-graviti font-weight-bold">
                  Información Personal
                </h3>
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="lastname"
                  component={InputComponent}
                  placeholder="Escribe el Apellido aquí"
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Apellido paterno</span>
                <ErrorMessage name="lastname" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="second_lastname"
                  component={InputComponent}
                  placeholder="Escribe el Apellido aquí"
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Apellido materno</span>
                <ErrorMessage name="second_lastname" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="name"
                  component={InputComponent}
                  placeholder="Escribe el Nombre aquí"
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Nombre</span>
                <ErrorMessage name="name" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <select
                  className="input-g2"
                  onChange={handleChange}
                  value={values?.marital_status || '-'}
                  name="marital_status"
                  required
                >
                  <option value="">-</option>
                  <option value="Soltero">Soltero</option>
                  <option value="Casado">Casado</option>
                  <option value="Viudo">Viudo</option>
                  <option value="Divorciado">Divorciado</option>
                  <option value="Unión Libre">Unión Libre</option>
                </select>
                <span className="labelInput">Estado civil</span>
                <ErrorMessage name="marital_status" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="rfc"
                  component={InputComponent}
                  placeholder="Se requiere nombre, apellidos y fecha de nacimiento"
                  disabled
                  validate={
                    !values.rfc && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">RFC</span>
                <ErrorMessage name="rfc" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="curp"
                  component={InputComponent}
                  disabled
                  placeholder="Requiere nombre, nacimiento, genero, estado"
                  validate={
                    !values.curp && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">CURP</span>
                <ErrorMessage name="curp" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-6 col-md-3">
                <Field
                  name="birthday"
                  component={InputComponent}
                  type="date"
                  placeholder="AAAA-MM-DD"
                  onBlur={() => {
                    setTouched({'birthday':true})
                    handleGenerateRFC(values, setFieldValue)
                  }}
                  validate={() => 
                    moment().diff(moment(values.birthday), 'years') < 18 
                      && <p className='mt-3 mb-0 pb-0 text-danger'>
                        Debe ser mayor de edad
                      </p>
                  }
                />
                <span className="labelInput">Fecha de nacimiento</span>
                <ErrorMessage name="birthday" component="div" />
              </div>
              <div className="col-6 col-md-3">
                <select
                  className="input-g2"
                  onChange={handleChange}
                  value={values?.gender || '-'}
                  onBlur={(e) =>
                    setFieldValue(
                      'gender',
                      e.target.value !== '-' ? e.target.value : ''
                    )
                  }
                  name="gender"
                >
                  <option value="-">-</option>
                  <option value="H">Masculino</option>
                  <option value="M">Femenino</option>
                </select>
                <span className="labelInput">Género</span>
                <ErrorMessage name="gender" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="email"
                  component={InputComponent}
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Correo electrónico</span>
                <ErrorMessage name="email" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="phone"
                  component={InputComponent}
                  validate={(value) =>
                    value ? (
                      value.length != 10 ? (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          El número debe contener 10 digitos
                        </p>
                      ) : (
                        value < 0 && (
                          <p className="mt-3 mb-0 pb-0 text-danger">
                            No se aceptan números negativos
                          </p>
                        )
                      )
                    ) : (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">N° de teléfono personal</span>
                <ErrorMessage name="phone" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="home_phone"
                  component={InputComponent}
                  validate={(value) =>
                    value ? (
                      value.length != 10 ? (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          El número debe contener 10 digitos
                        </p>
                      ) : (
                        value < 0 && (
                          <p className="mt-3 mb-0 pb-0 text-danger">
                            No se aceptan números negativos
                          </p>
                        )
                      )
                    ) : (
                      ''
                    )
                  }
                />
                <span className="labelInput">N° de teléfono fijo</span>
                <ErrorMessage name="home_phone" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="street"
                  component={InputComponent}
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Dirección</span>
                <ErrorMessage name="street" component="div" />
              </div>
              <div className="col-6 col-md-3">
                <Field
                  name="exterior"
                  component={InputComponent}
                  placeholder="No. Exterior"
                  validate={(value) =>
                    value ? (
                      value < 0 && (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          El número no puede ser negativo
                        </p>
                      )
                    ) : (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">No. Exterior</span>
                <ErrorMessage name="exterior" component="div" />
              </div>
              <div className="col-6 col-md-3">
                <Field
                  name="interior"
                  component={InputComponent}
                  placeholder="No. Interior"
                  validate={(value) =>
                    value
                      ? value < 0 && (
                          <p className="mt-3 mb-0 pb-0 text-danger">
                            El número no puede ser negativo
                          </p>
                        )
                      : ''
                  }
                />
                <span className="labelInput">No. Interior</span>
                <ErrorMessage name="interior" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="zip"
                  component={InputComponent}
                  onBlur={() => handleGenerateCURP(values, setFieldValue)}
                  onChange={(e) => getAddressByZip(e, setFieldValue)}
                  validate={(value) =>
                    value ? (
                      value.length !== 5 && (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          El código debe contener 5 digitos
                        </p>
                      )
                    ) : (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Código Postal</span>
                <ErrorMessage name="zip" component="div" />
              </div>
              <div className="col-12 col-md-6">
                {neighborhoods && (
                  <select
                    className="input-g2"
                    onChange={handleChange}
                    value={values?.neighborhood || '-'}
                    name="neighborhood"
                    required
                  >
                    <option value={values?.neighborhood}>
                      {values?.neighborhood}
                    </option>
                    {neighborhoods.length > 0 && (
                      <>
                        {neighborhoods.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                )}
                <span className="labelInput">Colonia</span>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="municipality"
                  component={InputComponent}
                  disabled
                />
                <span className="labelInput">Municipio</span>
                <ErrorMessage name="municipality" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <Field name="city" component={InputComponent} />
                <span className="labelInput">Ciudad</span>
                <ErrorMessage name="city" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field name="state_name" component={InputComponent} disabled />
                <span className="labelInput">Estado</span>
                <ErrorMessage name="state_name" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="reference_street"
                  component={InputComponent}
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Entre calle</span>
                <ErrorMessage name="reference_street" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="reference_street_other"
                  component={InputComponent}
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Y calle</span>
                <ErrorMessage name="reference_street_other" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="additional_reference"
                  component={InputComponent}
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Referencia adicional</span>
                <ErrorMessage name="additional_reference" component="div" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={previous}
                  disabled={isSubmitting}
                  block
                >
                  Atrás
                </Button>
              </div>
              <div className="col-12 col-md-4">
                <Button type="submit" variant="secondary" size="lg" block>
                  Siguiente
                </Button>
              </div>
              <div className="col-12 col-md-4">
                <Button
                  variant="outline-light"
                  size="lg"
                  onClick={cancel}
                  disabled={isSubmitting}
                  block
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PersonalInformation
