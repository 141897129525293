import React from 'react'
import { Col, Row } from 'react-bootstrap'
import SearchBar from '../SearchBar'

function SearchBars(props) {
  const { value, onChangeNamePhone, handleSubmitSearch } = props
  return (
    <Row>
      <Col>
        <SearchBar
          value={value}
          onChange={onChangeNamePhone}
          handleSubmitSearch={handleSubmitSearch}
          placeholder="Buscar por teléfono o nombre"
        />
      </Col>
    </Row>
  )
}

export default SearchBars
