import React from 'react'

function SelectComponent(props) {
  const { option, value, data, label, id, name, loading } = props

  return (
    <>
      <select
        className="input-g2"
        onChange={(e) => option(e.target.value)}
        value={value}
        name={name}
      >
        <option value="-">
          {loading
            ? 'Cargando...'
            : data?.length === 0
            ? '-'
            : `Selecciona ${label}`}
        </option>
        {data?.length > 0 &&
          data?.map((item) => (
            <option value={item[`${id}`]} key={item[`${id}`]}>
              {item.name ||
                item.model ||
                item.product.model ||
                `${item.weeks} Semanas x ${item.pay_per_week} MXN`}
            </option>
          ))}
      </select>
      <span className="labelInput">{label}</span>
    </>
  )
}

export default SelectComponent
