import axios from 'axios'
import { toast } from 'react-toastify'
import { origin, baseUrl } from './constants'

export const getCountsGraviti = () =>
  axios({
    url: `${origin + baseUrl}graviti/count/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getFinancials = () =>
  axios({
    url: `${origin + baseUrl}financial/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDistribuitor = () =>
  axios({
    url: `${origin + baseUrl}distributor/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
