import React from 'react'
import './index.css'

function BarItem({ header, rejected, unfinished, idx, stop, count }) {
  const creditsStyles =
    rejected || unfinished
      ? 'bar-item-progress bg-primary'
      : idx >= stop
      ? 'bar-item-progress bg-light text-dark'
      : 'bar-item-progress bg-primary'
  const getPercentage = () => {
    const countPercentage = 100 / count
    return `${countPercentage}%`
  }
  return (
    <div style={{ width: getPercentage(), marginRight: 2 }}>
      <div
        className={`progress-bar ${creditsStyles} border border-primary mb-1 `}
        style={{ height: 20 }}
      />
      <div className="text-container">{header}</div>
    </div>
  )
}

export default BarItem
