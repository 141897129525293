import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import UploadFileComponent from '../../Shared/UploadFile'
import { updateCredit } from '../../../services/accounts.service'
import LoadingComponent from '../../Shared/Loading.component'

class InvoicesProductComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.creditInfo.id,
      xml: null,
      pdf: null,
      pdf_validated: false,
      xml_validated: false,
      product: this.props.creditInfo.product,
      isStopped: true,
    }

    this.handleInputValue = this.handleInputValue.bind(this)
    this.handleUploadInvoice = this.handleUploadInvoice.bind(this)
  }

  handleInputValue(value, input) {
    this.setState({ [input]: value })
  }

  handleUploadInvoice() {
    this.setState({ isStopped: false })
    updateCredit(this.state).then((response) => {
      if (response.status === 202) {
        setTimeout(() => {
          window.location.reload(false)
        }, 1000)
      } else {
        this.setState({ isStopped: true })
      }
    })
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <p>
              Monto a Facturar: $ {this.props.creditInfo.distributor_amount} MXN
            </p>
          </Col>
        </Row>
        <Row>
          {this.state.isStopped === true &&
            this.props.creditInfo.pdf === null &&
            this.props.creditInfo.xml === null && (
              <>
                <Col>
                  <UploadFileComponent
                    typeFile="Factura PDF"
                    onChangeFile={(file) => this.handleInputValue(file, 'pdf')}
                    id="pdf"
                  />
                </Col>
                <Col>
                  <UploadFileComponent
                    typeFile="Factura XML"
                    onChangeFile={(file) => this.handleInputValue(file, 'xml')}
                    id="xml"
                  />
                </Col>
                {/* <Col>
                  <div>
                    <div>
                      ¿Factura PDF validada?
                      <label className='ml-4'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='pdf_validated'
                          value='true'
                          onChange={(e) =>
                            this.handleInputValue(
                              e.currentTarget.value,
                              'pdf_validated'
                            )}
                        />
                        Si
                      </label>
                    </div>
                    <div>
                      <label className='ml-4'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='pdf_validated'
                          value='false'
                          onChange={(e) =>
                            this.handleInputValue(
                              e.currentTarget.value,
                              'pdf_validated'
                            )}
                        />
                        No
                      </label>
                    </div>
                  </div>
                  <div>
                    <div>
                      ¿Factura XML validada?
                      <label className='ml-4'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='xml_validated'
                          value='true'
                          onChange={(e) =>
                            this.handleInputValue(
                              e.currentTarget.value,
                              'xml_validated'
                            )}
                        />
                        Si
                      </label>
                    </div>
                    <div>
                      <label className='ml-4'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='xml_validated'
                          value='false'
                          onChange={(e) =>
                            this.handleInputValue(
                              e.currentTarget.value,
                              'xml_validated'
                            )}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </Col> */}
                <Col>
                  <br />
                  <button
                    className="btn-2 b-primary"
                    onClick={this.handleUploadInvoice}
                  >
                    Cargar factura
                  </button>
                </Col>
              </>
            )}

          {this.state.isStopped === false && (
            <LoadingComponent isStopped={this.state.isStopped} />
          )}
        </Row>
      </Container>
    )
  }
}

export default InvoicesProductComponent
