import React, { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import { Button } from 'react-bootstrap'
import InputComponent from '../../Shared/Inputs/input'

// Context
import CreditFormContext from '../../../containers/CreditForm/context'

const jobs = [
  'Albañil',
  'Chofer',
  'Comerciante',
  'Dueño',
  'Empleado',
  'Encargado',
  'Independiente',
  'Negocio propio',
  'OTROS',
  'Propietario',
  'Vendedor',
]

const industries = [
  'Alimentos',
  'Automotriz',
  'Comercio',
  'Comida',
  'Construcción',
  'Educación',
  'Gobierno',
  'Independiente',
  'OTROS',
  'Publico',
  'Restaurante',
  'Retail',
  'Salud',
  'Servicios',
  'Transporte',
]

const grades = [
  'N/A',
  'Primaria',
  'Secundaria',
  'Preparatoria',
  'Universidad',
  'Postgrado',
]

function JobInformation({ currentStep, afterValid, previous, cancel }) {
  const { clientInfo, setClientInfo } = useContext(CreditFormContext)

  const handleSubmit = (values, { setSubmitting }) => {
    setClientInfo((s) => ({ ...s, ...values }))
    setSubmitting(false)
    afterValid()
  }

  if (currentStep !== 3) {
    return null
  }

  return (
    <div>
      <Formik initialValues={clientInfo} onSubmit={handleSubmit}>
        {({ values, handleChange, isSubmitting }) => (
          <Form>
            <div className="row my-1">
              <div className="col-12">
                <h3 className="text-primary-graviti font-weight-bold">
                  Información Laboral
                </h3>
              </div>
            </div>
            <div className="row my-1">
              <div className="col-12 col-md-6">
                <select
                  className="input-g2"
                  onChange={handleChange}
                  name="position"
                  value={values.position}
                  required
                >
                  <option value="">Puesto</option>
                  {jobs.map((job, i) => (
                    <option value={job} key={`${i}_${job}`}>
                      {job}
                    </option>
                  ))}
                </select>
                <span className="labelInput">Puesto</span>
              </div>
              <div className="col-12 col-md-6">
                <Field
                  name="company_name"
                  component={InputComponent}
                  placeholder="Escriba el nombre de la empresa aquí"
                  validate={(value) =>
                    !value && (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Nombre de la empresa</span>
                <ErrorMessage name="company_name" component="div" />
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <select
                  className="input-g2"
                  onChange={handleChange}
                  name="type_activity"
                  value={values.type_activity}
                  required
                >
                  <option value="">Giro de la empresa</option>
                  {industries.map((item, i) => (
                    <option value={item} key={`${i}_${item}`}>
                      {item}
                    </option>
                  ))}
                </select>
                <span className="labelInput">Giro de la empresa</span>
              </div>
              <div className="col-12 col-md-6" />
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="income"
                  component={InputComponent}
                  placeholder="Escriba el ingreso mensual aquí"
                  validate={(value) =>
                    value ? (
                      value < 0 && (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          La cantidad no puede ser negativa
                        </p>
                      )
                    ) : (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Ingreso mensual</span>
                <ErrorMessage name="income" component="div" />
              </div>
              <div className="col-12 col-md-6">
                <select
                  className="input-g2"
                  onChange={handleChange}
                  name="grade"
                  value={values.grade}
                  required
                >
                  <option value="">Seleccione grado de estudios</option>
                  {grades.map((item, i) => (
                    <option value={item} key={`${i}_${item}`}>
                      {item}
                    </option>
                  ))}
                </select>
                <span className="labelInput">Grado de estudios</span>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <Field
                  name="time_activity_year"
                  component={InputComponent}
                  placeholder="Antigüedad"
                  validate={(value) =>
                    value ? (
                      value < 0 && (
                        <p className="mt-3 mb-0 pb-0 text-danger">
                          La cantidad no puede ser negativa
                        </p>
                      )
                    ) : (
                      <p className="mt-3 mb-0 pb-0 text-danger">
                        Este campo es requerido
                      </p>
                    )
                  }
                />
                <span className="labelInput">Antigüedad</span>
                <ErrorMessage name="company_name" component="div" />
              </div>
            </div>
            <div className="row my-1 pt-2">
              <div className="col-12">
                <h3 className="text-primary-graviti font-weight-bold">
                  Referencias Personales
                </h3>
              </div>
            </div>
            {values?.personal_references && (
              <FieldArray name="personal_references">
                <>
                  {values.personal_references.length > 0 ? (
                    values.personal_references.map((reference, index) => (
                      <div key={index}>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <Field
                              name={`personal_references[${index}].name`}
                              component={InputComponent}
                              placeholder="Escriba el nombre del puesto aquí"
                              validate={(value) =>
                                !value && (
                                  <p className="mt-3 mb-0 pb-0 text-danger">
                                    Este campo es requerido
                                  </p>
                                )
                              }
                            />
                            <span className="labelInput">Nombre completo</span>
                            <ErrorMessage
                              name={`personal_references[${index}].name`}
                              component="div"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <Field
                              name={`personal_references[${index}].phone`}
                              component={InputComponent}
                              placeholder="Escriba el número de teléfono aquí"
                              validate={(value) =>
                                value ? (
                                  value.length != 10 ? (
                                    <p className="mt-3 mb-0 pb-0 text-danger">
                                      El número debe contener 10 digitos
                                    </p>
                                  ) : (
                                    value < 0 && (
                                      <p className="mt-3 mb-0 pb-0 text-danger">
                                        El número no puede ser negativo
                                      </p>
                                    )
                                  )
                                ) : (
                                  <p className="mt-3 mb-0 pb-0 text-danger">
                                    Este campo es requerido
                                  </p>
                                )
                              }
                            />
                            <span className="labelInput">Teléfono</span>
                            <ErrorMessage
                              name={`personal_references[${index}].phone`}
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="row my-4">
                          <div className="col-12 col-md-6">
                            <Field
                              name={`personal_references[${index}].relationship`}
                              component={InputComponent}
                              placeholder="Escriba el parentesco aquí"
                              validate={(value) =>
                                !value && (
                                  <p className="mt-3 mb-0 pb-0 text-danger">
                                    Este campo es requerido
                                  </p>
                                )
                              }
                              maxLength={10}
                            />
                            <span className="labelInput">Parentesco</span>
                            <ErrorMessage
                              name={`personal_references[${index}].relationship`}
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <Field
                            name="personal_references[0].name"
                            component={InputComponent}
                            placeholder="Escriba el nombre aquí"
                            validate={(value) =>
                              !value && (
                                <p className="mt-3 mb-0 pb-0 text-danger">
                                  Este campo es requerido
                                </p>
                              )
                            }
                          />
                          <span className="labelInput">Nombre completo</span>
                          <ErrorMessage
                            name="personal_references[0].name"
                            component="div"
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <Field
                            name="personal_references[0].phone"
                            component={InputComponent}
                            placeholder="Escriba el número de teléfono aquí"
                            validate={(value) =>
                              value ? (
                                value.length != 10 ? (
                                  <p className="mt-3 mb-0 pb-0 text-danger">
                                    El número debe contener 10 digitos
                                  </p>
                                ) : (
                                  value < 0 && (
                                    <p className="mt-3 mb-0 pb-0 text-danger">
                                      El número no puede ser negativo
                                    </p>
                                  )
                                )
                              ) : (
                                <p className="mt-3 mb-0 pb-0 text-danger">
                                  Este campo es requerido
                                </p>
                              )
                            }
                          />
                          <span className="labelInput">Teléfono</span>
                          <ErrorMessage
                            name="personal_references[0].phone"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="row my-4">
                        <div className="col-12 col-md-6">
                          <Field
                            name="personal_references[0].relationship"
                            component={InputComponent}
                            placeholder="Escriba el parentesco aquí"
                            validate={(value) =>
                              !value && (
                                <p className="mt-3 mb-0 pb-0 text-danger">
                                  Este campo es requerido
                                </p>
                              )
                            }
                          />
                          <span className="labelInput">Parentesco</span>
                          <ErrorMessage
                            name="personal_references[0].relationship"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </FieldArray>
            )}
            <div className="row">
              <div className="col-12 col-md-4">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={previous}
                  disabled={isSubmitting}
                  block
                >
                  Atrás
                </Button>
              </div>
              <div className="col-12 col-md-4">
                <Button type="submit" variant="secondary" size="lg" block>
                  Siguiente
                </Button>
              </div>
              <div className="col-12 col-md-4">
                <Button
                  variant="outline-light"
                  size="lg"
                  onClick={cancel}
                  disabled={isSubmitting}
                  block
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default JobInformation
