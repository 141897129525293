import React from 'react'

function SearchBar({ value, onChange, handleSubmitSearch, placeholder }) {
  return (
    <form onSubmit={handleSubmitSearch}>
      <div className="box">
        <div className="container-1">
          <span className="icon">
            <i className="fa fa-search" />
          </span>
          <input
            type="search"
            id="search"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="form-control"
          />
        </div>
      </div>
    </form>
  )
}

export default SearchBar
