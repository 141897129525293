import React, { useState, useEffect } from 'react'
import Popup from 'reactjs-popup'
import Carousel from 'react-bootstrap/Carousel'
import { FaTrash, FaCheck, FaTimes, FaUpload } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { createInstallationPhoto } from '../../services/accounts.service'
import Loading from '../Shared/Loading.component'
import './style.css'

function ModalInstallationImages(props) {
  const {
    isOpen,
    onClose,
    onDeletePhoto,
    onValidatePhoto,
    photos,
    credit,
    onUpdatePhotos,
    onUpdateCreditStatus,
    onUpdatePartialCredit,
    rol,
  } = props
  const [photosToUpload, setPhotosToUpload] = useState([])
  const [firstDate, setFirstDate] = useState(null)
  const [secondDate, setSecondDate] = useState(null)
  const [activeTab, setActiveTab] = useState('photos')
  const [uploading, setUploading] = useState(false)
  const notNeedPhotos = !credit?.need_photos
  const handleChangeFile = (event) => {
    setPhotosToUpload(event.target.files)
  }

  const handleUploadImage = () => {
    setUploading(true)
    Array.from(photosToUpload).map((photo) =>
      createInstallationPhoto(photo, credit.id).then(() => {
        onUpdatePhotos(credit.id)
        setUploading(false)
      })
    )
  }

  const handleSaveInstallationDates = () => {
    /* if (credit.created_from !== 'GP') {
      console.log(firstDate)
      console.log(secondDate)
      if (firstDate !== null && secondDate !== null) {
        const data = {
          credit_status: 'TOS',
          status_photos: 'APR',
          dates_proposed: JSON.stringify({
            firstDate: firstDate,
            secondDate: secondDate
          }),
          status_dates_proposed: 'APR'
        }
        setFirstDate(null)
        setSecondDate(null)
        setActiveTab('photos')
        onUpdatePartialCredit(data, credit.id)
      } else {
        toast.warning('Las fechas son obligatorias para aprobar las fotos.', {
          position: toast.POSITION.TOP_RIGHT
        })
        setActiveTab('dates')
      }
    } */
    const data = {
      credit_status: 'TOS',
      status_photos: 'APR',
    }
    setActiveTab('photos')
    onUpdatePartialCredit(data, credit.id)
  }

  const anyPhotoInvalid = photos.some((photo) => !photo.validated)

  if (!isOpen) {
    return null
  }
  if (credit && photos) {
    const statusPhotos = credit.status_photos
    const carouselComponent = () => {
      if (photos.length === 0) {
        return (
          <p className="m-5">
            No hay imagenes del lugar de instalación, por favor suba algunas
            para poder pasar a revisión.
          </p>
        )
      }
      return (
        <Carousel>
          {photos.map((image) => (
            <Carousel.Item key={image.id}>
              <img
                className="ModalII__carousel_image d-block w-100"
                src={image.image}
                alt=""
              />
              <Carousel.Caption>
                {(statusPhotos === 'UPL' ||
                  (statusPhotos === 'REJ' && !image.validated)) &&
                  rol === 'GR' && (
                    <button
                      className="btn btn-danger"
                      onClick={() => onDeletePhoto(image.id)}
                    >
                      <FaTrash />
                    </button>
                  )}

                {rol === 'DS' && statusPhotos === 'REV' && (
                  <>
                    {image.validated ? (
                      <button
                        className="btn btn-dark"
                        onClick={() => onValidatePhoto(image.id, false)}
                      >
                        <FaTimes />
                        <span> Rechazar</span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-info"
                        onClick={() => onValidatePhoto(image.id, true)}
                      >
                        <FaCheck />
                        <span> Aprobar</span>
                      </button>
                    )}
                  </>
                )}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )
    }

    return (
      <Popup
        open={isOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll
      >
        <div className="ModalII">
          <div className="ModalII__header">
            <div className="ModalII__header-title">
              <h4 className="">Fotos del lugar donde se instalará</h4>
              <span className="badge badge-dark">
                {credit.status_photos === 'APR'
                  ? 'Fotos Aprobadas'
                  : credit.status_photos === 'REJ'
                  ? 'Fotos Rechazadas'
                  : credit.status_photos === 'REV'
                  ? 'Fotos En Revisión'
                  : 'Subiendo Fotos'}
              </span>
            </div>
            <span
              className="ModalII__header-close"
              onClick={() => {
                setUploading(false)
                onClose()
              }}
            >
              &times;
            </span>
          </div>

          <ul
            className="nav nav-tabs"
            id="installationPhotosTab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={
                  activeTab === 'photos' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => setActiveTab('photos')}
              >
                Fotos
              </a>
            </li>
            {/* rol === 'DS' && credit.created_from !== 'GP' && (
              <a
                className={activeTab === 'dates' ? 'nav-link active' : 'nav-link'}
                onClick={() => setActiveTab('dates')}
              >
                Fechas de instalación
              </a>
            ) */}
          </ul>
          <div className="tab-content">
            <div
              className={
                activeTab === 'photos'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
            >
              <div className="ModalII__carousel">
                {carouselComponent()}
                <p className="mt-2">
                  <small>
                    <strong>***</strong>
                    Solo se pueden eliminar las fotos que no fueron validadas
                    por el distribuidor o antes de enviarlas a revisión.
                  </small>
                </p>
              </div>

              {statusPhotos !== 'APR' && rol === 'GR' && (
                <div className="ModalII__upload">
                  <input
                    type="file"
                    multiple
                    accept="image/png, image/jpeg"
                    onChange={handleChangeFile}
                  />
                  <button
                    className="btn btn-warning"
                    onClick={handleUploadImage}
                    disabled={uploading || photosToUpload.length === 0}
                  >
                    {uploading ? (
                      <>
                        <span className="spinner-border spinner-border-sm" />
                        <span> Subiendo...</span>
                      </>
                    ) : (
                      <>
                        <FaUpload />
                        <span> Subir Imágenes</span>
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
            {rol === 'DS' && credit.created_from !== 'GP' && (
              <div
                className={
                  activeTab === 'dates'
                    ? 'tab-pane fade show active'
                    : 'tab-pane fade'
                }
              >
                <h5>
                  Proponer fechas de instalación (fecha y hora) para aprobar
                  fotos.
                </h5>
                <div className="form-group">
                  <label>Primera fecha</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(e) => setFirstDate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Segunda fecha</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(e) => setSecondDate(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="ModalII__footer row">
            {(photos.length > 0 || notNeedPhotos) && (
              <>
                {(statusPhotos === 'UPL' || statusPhotos === 'REJ') &&
                  rol === 'GR' && (
                    <div className="col-4">
                      <button
                        className="btn-2 b-primary"
                        onClick={() =>
                          onUpdateCreditStatus('EVAT', 'REV', null)
                        }
                      >
                        Enviar a evaluación
                      </button>
                    </div>
                  )}

                {statusPhotos === 'REV' && rol === 'DS' && (
                  <>
                    {anyPhotoInvalid ? (
                      <div className="col-4">
                        <button
                          className="btn-2 btn-warning"
                          onClick={() =>
                            onUpdateCreditStatus(null, 'REJ', null)
                          }
                        >
                          Guardar
                        </button>
                      </div>
                    ) : (
                      <div className="col-4">
                        <button
                          className="btn-2 b-primary"
                          onClick={() => handleSaveInstallationDates()}
                        >
                          Aprovar imágenes
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            <div className="col-4">
              <button
                className="btn-3"
                onClick={() => {
                  setUploading(false)
                  onClose()
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }
  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      lockScroll
    >
      <Loading />
    </Popup>
  )
}

export default ModalInstallationImages
