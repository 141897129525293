// Axios
import axios from 'axios'

// Constants
import { msMerchantsPrefix, originMS, tokenMS } from '../constants'

/**
 * Axios base request
 */
const baseRequest = axios.create({
  baseURL: `${originMS}${msMerchantsPrefix}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'es-es',
    Authorization: "Bearer " + tokenMS, // eslint-disable-line
  },
})

/**
 * Performs login into MS users
 * @param {*} username
 * @param {*} password
 * @returns
 */
const getAll = () =>
  baseRequest
    .post('/list', { page: 1, per_page: 100, order: 'asc' })
    .then((res) => res.data.data.data)

const get = (id) => baseRequest.get(`/${id}`).then((res) => res.data.data)

const getByUser = (id) =>
  baseRequest.get(`/user/${id}`).then((res) => res.data.data)

const service = { getAll, get, getByUser }

export default service
