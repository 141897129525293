// Axios
import axios from 'axios'

// Constants
import { msFmEngineV2Prefix, originMS, tokenMS } from '../constants'

/**
 * Axios base request
 */
const baseRequest = axios.create({
  baseURL: `${originMS}${msFmEngineV2Prefix}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'es-es',
    Authorization: "Bearer " + tokenMS, // eslint-disable-line
  },
})

/**
 * Performs login into MS users
 * @param {*} username
 * @param {*} password
 * @returns
 */
const getFinancialModels = (
  productBranchId,
  discount = 0,
  userId = undefined
) =>
  baseRequest
    .get('/', {
      // TODO: add user_id as query param
      params: {
        product_id: productBranchId,
        discount,
        user_id: userId,
      },
    })
    .then((res) => res.data.data)

/**
 * Parses financial models response to a list of {name, id}
 * @param {*} financialModels
 * @returns
 */
const parseToOptions = (financialModels) => [
  ...financialModels.credit?.map((m, i) => ({
    name: `${m.preapproved ? 'PREAPROBADO:' : ''} ${m.weeks} Semanas X $${
      m.pay_per_week
    }`,
    id: `credit-${i}`,
  })),
  ...(financialModels.warranty?.map((m, i) => ({
    name: `${m.preapproved ? 'PREAPROBADO:' : ''} (Garantía Graviti) ${
      m.weeks
    } Semanas X $${m.pay_per_week}`,
    id: `warranty-${i}`,
  })) || []),
]

const service = { getFinancialModels, parseToOptions }

export default service
