import React, { useState, useEffect } from 'react'

import { createHashHistory } from 'history'
import './css/sideMenu.css'
import SearchMerchant from '../SearchMerchant/index'
import QRScanner from './Camera/QRScanner'
import MobileQR from './MobileQR'

export const history = createHashHistory()

export default function SideMenuComponent({
  onSelectStatus,
  rol,
  menu,
  onClick,
  handleSubmitMerchant,
  onChangeMerchant,
  onDetectedMerchant,
  onChangeRegistrarButton,
  closeModal,
  modal,
  onChangeModal,
}) {
  const [itemSelected, setItemSelected] = useState(0)
  // const [modal, setModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth)

  function handleStatus(item, i) {
    setItemSelected(i)
    onSelectStatus(item)
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  const handleRedirect = () => {
    onChangeRegistrarButton()
    if (width < 672) {
      window.scroll({
        top: 3500,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <QRScanner
        modal={modal}
        onDetectedMerchant={onDetectedMerchant}
        closeModal={closeModal}
      />

      <ul className="side-menu">
        {['DS', 'CS'].includes(rol) ? (
          width < 672 ? (
            <>
              <MobileQR modal={modal} onChangeModal={onChangeModal} />
              <div className="row mt-5">
                <div className="col">
                  <p>
                    Si no puedes escanear, ingresa el código a continuación:
                  </p>
                </div>
              </div>
              <SearchMerchant
                handleSubmitMerchant={handleSubmitMerchant}
                onChangeMerchant={onChangeMerchant}
              />
              <div className="row mt-3 mb-3">
                <div className="col">
                  <button
                    className="btn-2 b-secondary-2"
                    onClick={handleRedirect}
                  >
                    Verificar
                  </button>
                </div>
              </div>
            </>
          ) : (
            <button className="btn-2 b-primary" onClick={handleRedirect}>
              Registrar compra
            </button>
          )
        ) : null}

        {rol === 'GR' && (
          <button className="btn-2 b-primary" onClick={onClick}>
            Agregar cuenta
          </button>
        )}
        {rol === 'GR' && (
          <button
            className="btn-2 b-primary"
            onClick={() =>
              handleStatus(
                { status: 'Sin credito', count: 0, key: 'unrelated' },
                -1
              )
            }
          >
            Clientes sin crédito
          </button>
        )}
        {menu.map((item, i) => {
          const isSelected = itemSelected === i
          const isDropMenu = ['APR', 'REJ', 'CON', 'SYNC', 'NSYNC'].includes(
            item.key
          )
          const styles = isSelected
            ? isDropMenu
              ? 'item-selected side-menu-drop'
              : 'item-selected'
            : isDropMenu
            ? 'item side-menu-drop'
            : 'item'
          return (
            <li
              key={i}
              className={styles}
              onClick={() => handleStatus(item, i)}
            >
              {item.status}
              <span className="length-list">{item.count}</span>
            </li>
          )
        })}
      </ul>
    </>
  )
}
