import React from 'react'
import ApprovedTableComponent from '../../components/Accounts/approvedTable.component'
import EvaluationTable from '../../components/Accounts/evaluationTable.component'
import ClientsTableComponent from '../../components/Accounts/clientsTable.component'
import UnfinishedTableComponent from '../../components/Accounts/unfinishedTable.component'
import ToInstallTableComponent from '../../components/Accounts/toInstall.component'
import InProcessTable from '../../components/Accounts/inProcessTable.component'
import PaginatorComponent from '../../components/Shared/Paginator.component'
import ConfirmTable from '../../components/ConfirmTable'
import { PenalizedTable } from '../../components/Accounts'
import ToSignTableComponent from '../../components/Accounts/toSign.component'
import VerificationQR from '../../components/Shared/DistributorView/index'
import UnrelatedTableComponent from '../../components/Accounts/unrelatedTable.component'

export default function AccountsTable(props) {
  const {
    isStopped,
    rol,
    rows,
    hidden,
    onClick,
    onStatus,
    activePage,
    onPaginator,
    itemsCountPerPage,
    totalItemsCount,
    onOpenModalDocuments,
    onOpenModalDetails,
    onOpenModalInstallationImages,
    onOpenModalConfirmInstallationDates,
    credit,
    tableDefault,
    handleEstimatedDate,
    handleConfirmInstall,
    qrExperience,
    handleSubmitMerchant,
    onChangeMerchant,
    notFound,
    handleChangeModal,
    onDetectedMerchant,
    decodedResults,
    onNewScanResult,
    value,
    eventKey,
    handleConfirm,
    handleCancel,
    handleFinishVerification,
    modal,
    onChangeModal,
    spinner,
    closeModal,
  } = props

  return (
    <>
      {!qrExperience && hidden === 'PRO' && (
        <InProcessTable
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'PRO'}
          handleClick={onClick}
          handleOpenModalDetails={onOpenModalDetails}
          credit={credit}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'ATI' })}
          rol={rol}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
        />
      )}

      {!qrExperience && hidden === 'EVA' && (
        <EvaluationTable
          rol={rol}
          isStopped={isStopped}
          creditStatus={hidden}
          rowsData={rows || []}
          hidden={hidden !== 'EVA'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          updateTable={() => onStatus({ key: 'ATI' })}
          handleEstimatedDate={handleEstimatedDate}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
        />
      )}

      {!qrExperience && hidden === 'EVAT' && (
        <EvaluationTable
          rol={rol}
          isStopped={isStopped}
          creditStatus={hidden}
          rowsData={rows || []}
          hidden={hidden !== 'EVAT'}
          handleClick={onClick}
          credit={credit}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          handleOpenModalDetails={onOpenModalDetails}
          updateTable={() => onStatus({ key: 'ATI' })}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
        />
      )}

      {!qrExperience && hidden === 'CON' && (
        <ConfirmTable
          rol={rol}
          isStopped={isStopped}
          creditStatus={hidden}
          rowsData={rows || []}
          hidden={hidden !== 'CON'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}

      {!qrExperience && hidden === 'ATI' && (
        <ToInstallTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'ATI'}
          handleClick={onClick}
          credit={credit}
          updateTable={() => onStatus({ key: 'ATI' })}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
        />
      )}

      {!qrExperience && hidden === 'APR' && (
        <ApprovedTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'APR'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}

      {!qrExperience && hidden === 'REJ' && (
        <ApprovedTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          creditStatus="REJ"
          hidden={hidden !== 'REJ'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalDetails={onOpenModalDetails}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          tableDefault={tableDefault}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}
      {!qrExperience && hidden === 'TOS' && (
        <ToSignTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'TOS'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalDocuments={onOpenModalDocuments}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}
      {!qrExperience && ['INS', 'SYNC', 'NSYNC'].includes(hidden) && (
        <ClientsTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={false}
          handleClick={onClick}
          credit={credit}
          handleOpenModalDocuments={onOpenModalDocuments}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}

      {!qrExperience && hidden === 'FN' && (
        <UnfinishedTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'FN'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}

      {!qrExperience && hidden === 'NC' && (
        <UnfinishedTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'NC'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}
      {!qrExperience && hidden === 'CC' && (
        <PenalizedTable
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'CC'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalDocuments={onOpenModalDocuments}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'ATI' })}
        />
      )}
      {qrExperience && ['DS', 'CS'].includes(rol) && (
        <VerificationQR
          handleSubmitMerchant={handleSubmitMerchant}
          onChangeMerchant={onChangeMerchant}
          handleChangeModal={handleChangeModal}
          onDetectedMerchant={onDetectedMerchant}
          onNewScanResult={onNewScanResult}
          decodedResults={decodedResults}
          value={value}
          notFound={notFound}
          eventKey={eventKey}
          credit={credit}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          handleFinishVerification={handleFinishVerification}
          modal={modal}
          onChangeModal={onChangeModal}
          closeModal={closeModal}
          spinner={spinner}
        />
      )}
      {!qrExperience && hidden === 'unrelated' && (
        <UnrelatedTableComponent
          rol={rol}
          isStopped={isStopped}
          rowsData={rows || []}
          hidden={hidden !== 'unrelated'}
          handleClick={onClick}
          credit={credit}
          handleOpenModalDocuments={onOpenModalDocuments}
          handleOpenModalDetails={onOpenModalDetails}
          tableDefault={tableDefault}
          handleOpenModalInstallationImages={onOpenModalInstallationImages}
          handleOpenModalConfirmInstallationDates={
            onOpenModalConfirmInstallationDates
          }
          handleEstimatedDate={handleEstimatedDate}
          handleConfirmInstall={handleConfirmInstall}
          updateTable={() => onStatus({ key: 'unrelated' })}
        />
      )}
      <br />
      {!qrExperience && (
        <PaginatorComponent
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          handlePageChange={(number) => onPaginator(number)}
        />
      )}
    </>
  )
}
