import React from 'react'
import * as CurrencyFormat from 'react-currency-format'
import LoadingComponent from '../../Shared/Loading.component'
import { origin, baseUrl } from '../../../services/constants'

export default function TransactionsDetailsTableComponent({
  isStopped,
  rows,
  hidden,
  handleClick,
  headers,
}) {
  const handleIdAccount = (item) => handleClick(item)

  const procesingDate = (date = '0000-00-00', length) => {
    const processedDate = date.slice(0, length)
    return processedDate.split('-')
  }

  const getFullDate = (date) => {
    const [year, month, day] = procesingDate(date, 10)
    return `${day}/${month}/${year}`
  }

  const deactivateOnClick = (e) => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
  }

  return (
    <div className="box-table" hidden={hidden}>
      {isStopped && (
        <div className="table-responsive">
          <table className="table table-hover table-graviti">
            <thead>
              <tr>
                {headers.map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.map((item, i) => (
                <tr key={i} onClick={() => handleIdAccount(item.credit_id)}>
                  <td className="textPush">{item.contract_id}</td>
                  <td className="textPush">{item.name}</td>
                  <td className="textPush">{item.product}</td>
                  <td className="textPush">{getFullDate(item.sale_date)}</td>
                  <td className="textPush">{item.payment_method}</td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.distributor_capital}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.graviti_capital}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.interests}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.IVA_interests}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.commision}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.IVA_commision}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.transacted}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td
                    className="textPush"
                    onClick={(e) => deactivateOnClick(e)}
                  >
                    <a
                      href={`${origin}${baseUrl}transactions/sales-by-client/${item.credit_id}/`}
                    >
                      <black>Descargar reporte</black>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isStopped === false && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}
