import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import {Form, FormGroup} from 'react-bootstrap'
import './style.css'
import Swal from 'sweetalert2'

function ModalListProducto({ list, setList, productList, handleLockProduct }) {
  const [producto, setProducto] = useState(null)
  const handleData = (e) => {
    const data = {
      id: e.target.value,
      active: true,
    }
    setProducto(data)
  }

  const handleActivate = () => {
    if (producto.id !== 'Selecciona') {
      handleLockProduct(producto)
      setList(false)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: '<a href="">Dato no encontrado</a>',
      })
    }
  }
  if (!list) {
    return null
  }
  return (
    <Popup
      open={list}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      lockScroll
    >
      <div className="Modal" style={{ padding: '25px' }}>
        <div
          className="Modal__header"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="Modal__header-title">
            <h4>Activar producto</h4>
          </div>
        </div>

        <Form>
          <FormGroup>
            <Form.Label htmlFor="exampleSelect">Productos</Form.Label>
            <Form.Select
              name="select"
              id="exampleSelect"
              onChange={(e) => handleData(e)}
            >
              <option value="menu">selecciona</option>
              {productList?.map((product) => (
                <option value={product.id}>{product.product_info.model}</option>
              ))}
            </Form.Select>
          </FormGroup>

          <div className="ModalII__footer row">
            <div className="col-12">
              <button
                className="btn-success"
                type="button"
                onClick={() => handleActivate()}
              >
                Activar
              </button>
              <button className="btn-3" onClick={() => setList(false)}>
                Cancelar
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Popup>
  )
}

export default ModalListProducto
