import React from 'react'
import { SharedTable } from '.'

const headers = [
  'Nombre',
  'Teléfono',
  'Producto',
  'Estatus',
  'Sucursal',
  'NFC Activado',
  'Instalación',
  'Comentarios',
  'Estatus del crédito',
  'Acciones',
]
const headersDefault = [
  'Id Contrato',
  'Nombre',
  'Teléfono',
  'Estatus',
  'Sucursal',
  'NFC Activado',
  'Documentos',
  'Días Restantes',
  'Comentarios',
]

const keysData = [
  'full_name',
  'phone',
  'product',
  'estatus',
  'branch',
  'nfc_available',
  'estimated_install_date',
  'comments',
  'estatus_credito',
  'action',
]

const keysDataDefault = [
  'contract_id',
  'full_name',
  'phone',
  'credit_pay_status',
  'branch',
  'nfc_available',
  'documents',
  'service_remaining',
  'comments',
]

export default function ClientsTableComponent(props) {
  const newProps = {
    ...props,
    headers,
    headersDefault,
    keysData,
    keysDataDefault,
  }

  return <SharedTable {...newProps} />
}
