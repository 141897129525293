import React, { useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ProductSelected from '../Accounts/NewAccount/ProductSelected'
import PersonalInformation from '../Accounts/NewAccount/PersonalInformation'
import JobInformation from '../Accounts/NewAccount/JobInformation'
import AdditionalInformation from '../Accounts/NewAccount/AdditionalInformation'
import { titleSteps } from './utils'
import './style.css'
// Contexts
import CreditFormContext from '../../containers/CreditForm/context'

function WizardClient() {
  const location = useLocation()
  const history = useHistory()
  const [extraForm, setExtraForm] = useState(false)
  const [currentStep, setCurrentStep] = useState(
    location?.hash?.split('#/')[1] ? parseInt(location.hash.split('#/')[1]) : 1
  )
  const [totalSteps] = useState(4)

  const { clientInfo, creditInfo, lastCredit } = useContext(CreditFormContext)

  const _next = async () => {
    const step = currentStep === totalSteps ? totalSteps : currentStep + 1
    setCurrentStep(step)
  }

  const _prev = () => {
    const step = currentStep - 1 === 0 ? 1 : currentStep - 1
    setCurrentStep(step)
    history.push(`#/${step}`)
  }

  const _cancel = () => {
    if (lastCredit?.id) {
      history.push(`/cuentas/detalles/${lastCredit.id}`)
    } else {
      history.push('/cuentas')
    }
  }

  return (
    <div>
      <div className="row">
        {Object.keys(titleSteps).map((step) => (
          <div className="col d-flex align-items-center flex-nowrap" key={step}>
            <span
              className={`d-none d-lg-block ${
                currentStep === parseInt(step)
                  ? 'tab-section-selected'
                  : 'tab-section-unselected'
              }`}
            >
              {titleSteps[step].name}
            </span>
            <span
              className={`d-lg-none ${
                currentStep === parseInt(step)
                  ? 'tab-section-selected'
                  : 'tab-section-unselected'
              }`}
            >
              {titleSteps[step].name.slice(0, 2)}
            </span>
          </div>
        ))}
      </div>
      <hr className="separator" />
      <ProductSelected
        currentStep={currentStep}
        afterValid={_next}
        cancel={_cancel}
      />
      <PersonalInformation
        currentStep={currentStep}
        afterValid={_next}
        previous={_prev}
        cancel={_cancel}
      />
      <JobInformation
        currentStep={currentStep}
        afterValid={_next}
        previous={_prev}
        cancel={_cancel}
      />
      <AdditionalInformation
        currentStep={currentStep}
        afterValid={_next}
        previous={_prev}
        cancel={_cancel}
        extraForm={extraForm}
        setExtraForm={setExtraForm}
      />
    </div>
  )
}

export default WizardClient
