import React, { useState } from 'react'
import SearchMerchant from '../../SearchMerchant/index'
import QRScanner from '../Camera/QRScanner'

const VerificarProducto = ({
  handleSubmitMerchant,
  onChangeMerchant,
  value,
  onDetectedMerchant,
  onChangeModal,
  closeModal
}) => {
  const [modal, setModal] = useState(false);

  /**
   * Closes the modal and calls onDetectMechant callback
   * @param {String} code the code readed by the QR Scanner
   */
  const handleDetectMerchant = (code) => {
    setModal(false)
    onDetectedMerchant(code)
  }

  return (
    <>
      <QRScanner
        setModal={setModal}
        modal={modal}
        onDetectedMerchant={handleDetectMerchant}
      />
      <div className='container-fluid qr-code-containers'>
        <div className='row'>
          <div className='col-6'>
            <div
              className='row'
              style={{ marginTop: '50px', marginLeft: '30px' }}
            >
              <div className='col'>
                <h6>Ingresa el código a continuación:</h6>
              </div>
            </div>
            <div className='row'>
              <div
                className='col'
                style={{ marginTop: '10px', marginLeft: '30px' }}
              >
                <SearchMerchant
                  handleSubmitMerchant={handleSubmitMerchant}
                  onChangeMerchant={onChangeMerchant}
                  value={value}
                />
              </div>
            </div>
            <div className='row'>
              <div
                className='col'
                style={{ marginTop: '30px', marginLeft: '58px' }}
              >
                <button
                  className='btn-2 b-secondary-2'
                  onClick={handleSubmitMerchant}
                  style={{ width: '90%' }}
                >
                  Verificar
                </button>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='row' style={{ marginTop: '50px' }}>
              <div className='col text-center'>
                <h6>Verfificación por código QR</h6>
              </div>
            </div>
            <div className='row'>
              <div className='col' style={{ marginTop: '10px' }}>
                <div className='row'>
                  <div className='col text-center' style={{ fontSize: '54px' }}>
                    <i className='fa fa-qrcode' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col d-flex justify-content-center'>
                    <button
                      className='btn-2 b-secondary-2'
                      style={{ width: '90%' }}
                      onClick={() => {
                        setModal(!modal)
                      }}
                    >
                      Escanear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerificarProducto
