import axios from 'axios'
import { toast } from 'react-toastify'
import { origin, baseUrl } from './constants'

export const getPayments = (id) =>
  axios({
    url: `${origin + baseUrl}transactions/sales/${id}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data.results)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getPaymentsAll = (number) =>
  axios({
    url: `${origin + baseUrl}transactions/sales/?page=${number}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDisbursement = () =>
  axios({
    url: `${origin + baseUrl}accounting/references/instalation/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDisbursementDetails = (reference, page) =>
  axios({
    url: `${origin + baseUrl}accounting/references/${reference}/?page=${page}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDisbursementDetailsInfo = (reference) =>
  axios({
    url: `${origin + baseUrl}accounting/references/${reference}/information/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getPayoutsDetails = (startDate, endDate, page) =>
  axios({
    url: `${
      origin + baseUrl
    }accounting/payouts/sales/?start_date=${startDate}&end_date=${endDate}&page=${page}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getPayoutsSumary = (startDate, endDate) =>
  axios({
    url: `${
      origin + baseUrl
    }accounting/payouts/sales/summary/?start_date=${startDate}&end_date=${endDate}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDepositsDistributor = () =>
  axios({
    url: `${origin + baseUrl}accounting/distributor/transference/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error(
          'Error en el servidor (Depósitos distribuidor). Intente de nuevo',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        )
      }
      return error.response
    })

export const getDepositsDistribuitorDetails = (id, page) =>
  axios({
    url: `${
      origin + baseUrl
    }accounting/distributor/transference/period/${id}/?page=${page}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error(
          'Error en el servidor (Depósitos Detalles). Intente de nuevo',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        )
      }
      return error.response
    })

export const getDepositsDistribuitorDSSummary = (id) =>
  axios({
    url: `${
      origin + baseUrl
    }accounting/distributor/transference/period/${id}/summary/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error(
          'Error en el servidor (Depósitos Detalles). Intente de nuevo',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        )
      }
      return error.response
    })

export const getSalesDistributor = (year, month) =>
  axios({
    url: `${origin + baseUrl}transactions/distributor-sales/${year}/${month}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getMerchantPayouts = (id) => {
  const formData = new FormData()
  formData.append('id', id)
  return axios({
    url: `${origin + baseUrl}accounting/transferences/merchants/`,
    data: formData,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
}

export const getLastSalesDistributor = (year, month) =>
  axios({
    url: `${
      origin + baseUrl
    }transactions/distributor-last-sales/${year}/${month}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data.results)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDistributorDeposits = (year, month) =>
  axios({
    url: `${
      origin + baseUrl
    }transactions/distributor-deposits/${year}/${month}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data.total)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDistributorLastDeposits = (year, month) =>
  axios({
    url: `${
      origin + baseUrl
    }transactions/distributor-last-deposits/${year}/${month}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDistributorSalesProducts = (year, month) =>
  axios({
    url: `${
      origin + baseUrl
    }transactions/distributor-products-sales/${year}/${month}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getDistributorSalesChart = (year) =>
  axios({
    url: `${origin + baseUrl}transactions/distributor-chart/${year}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
