// React
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

// Styles
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const root = createRoot(document.getElementById('root'))
root.render(<App />)
