import React, { useEffect, useState } from 'react'
import SideMenuComponent from '../Shared/SideMenu.component'
import Dashboard from './Dashboard/home/Dashboard'
import DepositsTableComponent from './depositsTable.component'
import {
  getDepositsDistributor,
  getDisbursement,
} from '../../services/payments.service'
import PaginatorComponent from '../Shared/Paginator.component'
import HowToPayComponent from '../Shared/ModalHowToPay.component'
import pagoHelp from '../../assets/icons/PagoHelp.svg'
import invoices from '../../assets/icons/Facturas.svg'
import InvoicesComponent from '../Shared/ModalInvoices.component'
import { getDeposits } from '../../services/deposits.service'
import DepositsTableDSComponent from './depositsTableDS.component'
import { getTransactionsPerMonth } from '../../services/transactions.service'
import TransactionsByMonthTableComponent from './transactionsByMonthTableComponent'
import { baseUrl, origin } from '../../services/constants'

export default function DashboardComponent() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h1>SALES</h1>
        </div>
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            style={{ marginTop: "20px" }} // eslint-disable-line
        >
          <div className="col-lg-12 col-md-12">
            <Dashboard />
          </div>
        </div>
      </div>
    </div>
  )
}
