import React from 'react'
import { FaCheck } from 'react-icons/fa'
import LoadingComponent from '../Shared/Loading.component'
import TableComponent from '../Shared/Table.component'
import { tagGroupListStatus } from './SectionsDetailsAccount/SectionGeneral/utilsStatus'

const headers = [
  'Nombre',
  'Teléfono',
  'Producto',
  'Estatus',
  'Instalaciòn',
  'Comentarios',
  'Estatus del crédito',
  'Acciones',
]
const headersDefault = ['Nombre', 'Teléfono', 'Correo', 'Fecha de registro']

function UnrelatedTableComponent(props) {
  const {
    hidden,
    rowsData,
    isStopped,
    handleClick,
    handleOpenModalDetails,
    tableDefault,
    handleEstimatedDate,
    updateTable,
    handleOpenModalInstallationImages,
    handleOpenModalConfirmInstallationDates,
    rol,
  } = props

  function getColumns(headers, data) {
    const columns = []
    for (let i = 0; i < headers.length; i++) {
      columns.push({
        Header: tableDefault ? headersDefault[i] : headers[i],
        accessor: data[i],
      })
    }
    return columns
  }

  function getData(rows) {
    const data = []
    rows.forEach((row) => {
      const register = new Date(row.created_at * 1000).toUTCString()
      data.push({
        name: row.name,
        ID_Client: row.id,
        phone: row.phone,
        mail: row.email,
        register_date: register,
        id: row.id,
      })
    })
    return data
  }

  return (
    <div className="box-table" hidden={hidden}>
      {isStopped && (
        <div className="table-responsive">
          {tableDefault ? (
            <TableComponent
              rowsData={getData(rowsData)}
              columnsData={getColumns(headersDefault, [
                'name',
                'phone',
                'mail',
                'register_date',
              ])}
              handleClick={handleClick}
            />
          ) : (
            <TableComponent
              rowsData={getData(rowsData)}
              columnsData={getColumns(headers, [
                'full_name',
                'phone',
                'product',
                'estatus',
                'estimated_install_date',
                'comments',
                'estatus_credito',
                'action',
              ])}
              handleClick={handleClick}
            />
          )}
        </div>
      )}
      {!isStopped && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}

export default UnrelatedTableComponent
