// Origins

import jwtDecode from 'jwt-decode'

export const origin = process.env.REACT_APP_ORIGIN_AQUARIUS
export const originMmf = process.env.REACT_APP_ORIGIN_MMF
export const originMS = process.env.REACT_APP_ORIGIN_MS
export const originKYC = process.env.REACT_APP_ORIGIN_KYC
export const originCirculo = process.env.REACT_APP_ORIGIN_CIRCULO

export const tokenMMF = process.env.REACT_APP_MMF_TOKEN
export const tokenMS = process.env.REACT_APP_MS_TOKEN
export const tokenKYC = process.env.REACT_APP_KYC_TOKEN
export const tokenAquarius = localStorage.getItem('token') // eslint-disable-line

export const baseUrl = 'api/v1/'
export const url = window.location.or

// MS Prefixes
export const msUsersPrefix = 'api/v1/users'
export const msProductsPrefix = 'api/v1/products'
export const msMerchantsPrefix = 'api/v1/merchants'
export const msFmEngineV2Prefix = 'api/v2/fm-engine'

// Catálogo
export const productsDistribuitor = (page, pageSize, query = '') =>
  `distributor/product/?page=${page}&page_size=${pageSize}&search=${query}`
// Get products from MS
export const getListProducts = (page, pageSize, query = '') =>
  `product_branch/?page=${page}&page_count=${pageSize}&search=${query}`
export const detailProduct = (id) => `product/${id}/`

export const tagType = () => 'product/tag-type/'

export const createProduct = () => 'product/product_merchant/'
export const createProductBranch = () => 'product_branch/'

// Cliente
export const resetFico = (id) => `clients/resetFico/${id}/`

export const resendSignatureEndpoint = () =>
  'digital_signature/resend-document/'
// Financiera

// Distribuidor
export const getDistribuitors = () => 'distributor/?page_size=100'

// Distributor Product
export const changeProductStatus = () => 'distributor/distributor_product/'

// Crédito
export const getCredits = (status, pageSize) =>
  `credits/?filter=${status}&page_size=${pageSize}`

export const changeCreditStatus = (id) => `credits/${id}/set-status`

export const unfinishedCredit = (id) => `credits/${id}/not_concluded/`
export const finishedCredit = (id) => `credits/${id}/concluded/`
export const getCreditById = (id) => `credits/${id}/`

// Tienda
export const sendSMS = () => 'store/code/'
export const reSendSMS = () => 'store/code/resend/'
export const validateCode = () => 'store/code/verification/'

// Autentificación

// Mensajeria

// Transacciones

// Sistemas
export const getSystems = () => 'systems/stock/'

// Contabilidad

// Graviti
export const getTerms = (id) =>
  `graviti/term/?page_size=100&distributor_product=${id}`
export const getBrands = () => `product/brand/?page_size=1000`
// return `graviti/brands/?page_size=100&distributor=${id}`;

export const getTypes = () => `product/type/?page_size=1000`
// return `graviti/type/?page_size=100&distributor=${id}&brand=${brand}`;

export const getProductCategories = () => `categories/?all=true`
// return `graviti/type/?page_size=100&distributor=${id}&brand=${brand}`;

export const getProductSubCategories = () => `categories/?subcategories=true`
// return `graviti/type/?page_size=100&distributor=${id}&brand=${brand}`;

export const getProductBrands = () => `brands/`
// return `graviti/type/?page_size=100&distributor=${id}&brand=${brand}`;

export const getProducts = (id, brand, type) =>
  `graviti/product/?page_size=100&distributor=${id}&brand=${brand}&type=${type}`

// GravitiyPay

// Usuarios
export const resetPassword = (clientId) => `users/admin/${clientId}/password`

export const getRCC = (client) => client.kyc_prescoring_id
  ? `${originKYC}clients/${client.kyc_prescoring_id}/rcc_report/`
  : `${originCirculo}${client.rcc}`

// Token data
export const getUserPayload = () => {
  try {
    return jwtDecode(localStorage.getItem('token'))
  } catch (err) {
    return undefined
  }
}

// sepomex
export const tokenSEPOMEX = process.env.REACT_APP_TOKEN_SEPOMEX

export const originMsProducts = `${process.env.REACT_APP_ORIGIN_MS}api/v1/products/`
