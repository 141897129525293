import React, { useState, useEffect } from 'react'
import InstallationsDetailsTableComponent from './installationsDetailsTable.component'
import './accountingDetails.css'
import pdf from '../../../assets/icons/pdf.svg'
import xml from '../../../assets/icons/xml.svg'
import {
  getDisbursementDetails,
  getDisbursementDetailsInfo,
} from '../../../services/payments.service'
import PaginatorComponent from '../../Shared/Paginator.component'

const headersInstallations = [
  'ID',
  'Nombre',
  'Producto',
  'Fecha de Instalación',
  'Distribuidor',
  'Total',
  'Comentarios',
]

export default function InstallatiosDetailsComponent({ match }) {
  const [reference, setReference] = useState(match.params.id)
  const [rows, setRows] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [itemsCountPerPage] = useState(10)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [isStopped, setIsStopped] = useState(false)
  const [info, setInfo] = useState(null)

  useEffect(() => {
    getDisbursementDetails(reference, activePage).then(({ results, count }) => {
      setRows(results)
      setTotalItemsCount(count)
      setIsStopped(true)
    })
  }, [])

  useEffect(() => {
    getDisbursementDetailsInfo(reference).then((response) => {
      setInfo(response)
    })
  }, [])

  const handlePaginator = (number) => {
    setActivePage(number)
    setRows([])
    setIsStopped(true)
    getDisbursementDetails(reference, number).then(({ results, count }) => {
      setRows(results)
      setTotalItemsCount(count)
      setIsStopped(true)
    })
  }

  const handleRedirectPageDetail = (id) => {
    const path = `/cuentas/detalles/${id}`
    window.location.pathname = path
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-7 col-md-7">
          <h1>DETALLE DE LOS DESEMBOLSOS</h1>
        </div>

        <div className="col-lg-5 col-md-5">
          <div className="box-information">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <table>
                  <tbody>
                    <tr className="celdaText text-center">
                      <td>Núm. de instalaciones:</td>
                      <td>{info === null ? 0 : info.installations}</td>
                    </tr>
                    <tr className="celdaText">
                      <td>Total transferido:</td>
                      <td>
                        <strong>
                          $ {info === null ? 0 : info.total.toLocaleString()}{' '}
                          MXN
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-4 col-md-4">
                <table>
                  <tbody>
                    <tr className="celdaText text-center">
                      {info !== null && info.pdf !== null && (
                        <td>
                          <a href={info.pdf}>
                            <img className="icons" src={pdf} alt="pdf" />
                          </a>
                        </td>
                      )}

                      {info !== null && info.xml !== null && (
                        <td>
                          <a href={info.xml}>
                            <img className="icons" src={xml} alt="xml" />
                          </a>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <InstallationsDetailsTableComponent
            isStopped={isStopped}
            headers={headersInstallations}
            rows={rows}
            hidden={false}
              handleClick={ handleRedirectPageDetail } // eslint-disable-line
          />
          <br />
          <PaginatorComponent
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            handlePageChange={(number) => {
              handlePaginator(number)
            }}
          />
        </div>
      </div>
    </div>
  )
}
