import React, { useMemo, useEffect } from 'react'
import { useTable } from 'react-table'

function TableComponent(props) {
  const {
    rowsData = [],
    columnsData = [],
    handleClick,
    handleRowClick = true,
    hiddenColumns = [],
  } = props
  useEffect(() => {}, [rowsData, columnsData])

  const data = useMemo(() => rowsData, [rowsData])
  const columns = useMemo(() => columnsData, [columnsData])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      initialState: {
        hiddenColumns,
      },
    })

  return (
    <table {...getTableProps()} className="table table-hover table-graviti">
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, i) => (
              <th {...column.getHeaderProps()} key={i}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps({
                onClick: handleRowClick
                  ? () => handleClick(row.original.ID_Client)
                  : null,
              })}
              key={index}
              className="textPush"
            >
              {row.cells.map((cell, index) => (
                <td
                  className="align-middle"
                  {...cell.getCellProps()}
                  key={index}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TableComponent
