import React from 'react'
import document from '../../../../assets/icons/document.svg'

function DocumentPhoto({ url, title }) {
  return (
    <div>
      <img src={document} alt={title} style={{ width: 52, height: 37 }} />
      <div>{title}</div>
      <a href={url} target="_blank" rel="noreferrer">
        <strong>Descargar</strong>
      </a>
    </div>
  )
}

export default DocumentPhoto
