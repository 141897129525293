// Axios
import axios from 'axios'
import { toast } from 'react-toastify'

// Constants
import { msProductsPrefix, originMS, tokenMS } from '../constants'

/**
 * Axios base request
 */
const baseRequest = (withLocalToken = false) =>
  axios.create({
    baseURL: `${originMS}${msProductsPrefix}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
    Authorization: "Bearer " + (withLocalToken ? localStorage.getItem('token') : tokenMS), // eslint-disable-line
    },
  })

const defaultErrorHandler = (err) => {
  const { data } = err.response
  let message = data.detail || data.description || data.errors
  if (message instanceof Object) {
    message = (
      <div>
        {Object.keys(message).map((key) => (
          <p>{`${key}: ${message[key]}`}</p>
        ))}
      </div>
    )
  }
  toast.error(message || 'Error en el servidor, Intenta nuevamente', {
    position: toast.POSITION.TOP_RIGHT,
  })
  throw err
}

/**
 * Performs login into MS users
 * @param {*} username
 * @param {*} password
 * @returns
 */
const getBrands = () =>
  baseRequest()
    .get('/brands/')
    .then((res) => res.data)

const getSubcategories = () =>
  baseRequest()
    .get('/categories/?subcategories=true&detail=true')
    .then((res) => res.data)

const getProducts = (page = 0, pageCount = 10, search = '', subcategory = '') =>
  baseRequest(true).get('/product_branch/', {
    params: {
      page,
      page_count: pageCount,
      search,
      subcategory,
    },
  })

const getAvailableProducts = (
  merchant_id = null,
  brand_id = null,
  subcategory_id = null,
  search = null
) =>
  baseRequest()
    .get('/product_branch/available/', {
      params: {
        page: 1,
        page_count: 10000,
        merchant_id,
        brand_id,
        subcategory_id,
        search,
      },
    })
    .then((res) => res.data.results)

const getProduct = (product_id) =>
  baseRequest()
    .get(`/product_branch/${product_id}/?detail=true`)
    .then((res) => res.data)

const getNonValidatedProduct = (page = 1, search = '') =>
  baseRequest(true)
    .get(`/product_branch/non-validated/?page=${page}&search=${search}`)
    .then((res) => res.data)

const validateProduct = (id) =>
  baseRequest(true).put(`/product_branch/${id}/validate/`)

const updateProductBranch = (id, product) =>
  baseRequest(true)
    .put(`/product_branch/${id}/`, product)
    .then((res) => res.data)

const deleteProductBranch = (id) =>
  baseRequest(true).delete(`/product_branch/${id}/`)

/* Promos */
const getPromos = (page = 1, pageCount = 10) =>
  baseRequest(true)
    .get('/promos/', {
      params: {
        page,
        page_count: pageCount,
      },
    })
    .then((res) => res.data)
    .catch(defaultErrorHandler)

const getPromo = (id) =>
  baseRequest(true)
    .get(`/promos/${id}/`)
    .then((res) => res.data)
    .catch(defaultErrorHandler)

const getProductBranchByPromo = (id) =>
  baseRequest(true)
    .get(`/promos/${id}/product_branch/`)
    .then((res) => res.data)
    .catch(defaultErrorHandler)

const getAllAvailableProductBranchForPromo = () =>
  baseRequest(true)
    .get(`/promos/product_branch/available/`)
    .then((res) => res.data)
    .catch(defaultErrorHandler)

const updatePromo = (id, promo) =>
  baseRequest(true)
    .put(`/promos/${id}/`, promo)
    .then((res) => res.data)
    .catch(defaultErrorHandler)

const createPromo = (promo) =>
  baseRequest(true)
    .post(`/promos/`, promo)
    .then((res) => res.data)
    .catch(defaultErrorHandler)

const service = {
  getBrands,
  getSubcategories,
  getProducts,
  getAvailableProducts,
  getProduct,
  getNonValidatedProduct,
  validateProduct,
  updateProductBranch,
  deleteProductBranch,
  getPromos,
  getPromo,
  getProductBranchByPromo,
  getAllAvailableProductBranchForPromo,
  updatePromo,
  createPromo,
}

export default service
