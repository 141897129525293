// React
import React, { useState, useEffect, useMemo } from 'react'

// React Bootstrap
import { Card } from 'react-bootstrap'

// Assets
import firma from '../../../assets/firma.png'

/**
 * Credit Verification result view.
 * @param {Credit} credit the verified credit
 * @param {function} handleConfirm the on confirm callback
 * @param {function} handleCancel the on cancel callback
 */
const Resultado = ({ credit, handleConfirm, handleCancel }) => {
  /**
   * Whether user has confirmed the information about this credit
   */
  const [confirm, setConfirm] = useState(true)

  /**
   * The window innerWith value
   */
  const [width, setWidth] = useState(window.innerWidth)

  /**
   * The on resize callback
   */
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  /**
   * Inits the on resize event listener
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div
      className={
        width < 672
          ? 'resultado-container container-fluid qr-code-containers-movil'
          : 'resultado-container container-fluid qr-code-containers'
      }
    >
      <div className='row flex-grow-1'>
        <div
          className={
            width < 672
              ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 resultado-title-movil'
              : 'col-lg-12 col-md-12 col-sm-12 col-xs-12 resultado-title'
          }
        >
          <h6>RESULTADO:</h6>
          <div className='row w-100 approve-credit-container'>
            <div className='col text-center'>
              <h5 className='text-white'>Crédito aprobado</h5>
            </div>
          </div>
          <div className='row w-100 h-auto data-credit-container'>
            <div className='col ml-2 mt-3'>
              <div className='row'>
                <div className={width < 614 ? 'col h4-movil' : 'col'}>
                  <h4>{credit?.client_info.full_name}</h4>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col'>
                  <h6 className={width < 614 && 'movil-titles'}>
                    Información del cliente:
                  </h6>
                  <p className='text-justify'>RFC: {credit?.client_info.curp}</p>
                  <p className='text-justify'>CURP: {credit?.client_info.rfc}</p>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <h4 className={width < 614 && 'movil-titles'}>Resumen:</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                      <h5 className={width < 614 && 'movil-titles'}>
                        &#8226; Foto ine
                      </h5>
                      <div className='d-flex justify-content-center bg-light bg-gradient border '>
                        <Card className='w-50'>
                          <Card.Img
                            variant='top'
                            src={credit?.client_info.ine}
                            className='card-height'
                          />
                        </Card>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                      <h5 className={width < 614 && 'movil-titles'}>
                        &#8226; Foto selfie
                      </h5>
                      <div className='d-flex justify-content-center bg-light bg-gradient border'>
                        <Card className='w-50'>
                          <Card.Img
                            variant='top'
                            src={credit?.client_info.ine_back}
                            className='card-height'
                          />
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {width < 672
                ? null
                : (
                  <>
                    <div className='row mt-5'>
                      <div className='col'>
                        <h5>Foto producto</h5>
                      </div>
                    </div>

                    <div className='row justify-content-around'>
                      <div className='col-3 d-flex justify-content-center bg-light bg-gradient border'>
                        <Card className='w-75'>
                          <Card.Img
                            variant='top'
                            src={credit?.product_info.image}
                            className='card-height'
                          />
                        </Card>
                      </div>
                      <div className='col-3 d-flex justify-content-center bg-light bg-gradient border'>
                        <Card className='w-75'>
                          <Card.Img
                            variant='top'
                            src={credit?.product_info.image}
                            className='card-height'
                          />
                        </Card>
                      </div>
                      <div className='col-3 d-flex justify-content-center bg-light bg-gradient border'>
                        <Card className='w-50 align-self-center'>
                          <Card.Img
                            variant='top'
                            src={firma}
                            className='card-height-contrato'
                          />
                        </Card>
                      </div>
                    </div>
                  </>
                  )}

              <div className='row mt-5'>
                {width < 672
                  ? (
                    <>
                      <div className='col'>
                        <h5>&#8226; Contrato firmado</h5>
                        <a
                          href={credit?.contrato_firmado}
                          alt='Contrato firmado'
                          target='_blank' rel='noreferrer'
                        >
                          Ver aquí
                        </a>
                      </div>
                    </>
                    )
                  : (
                    <>
                      <div className='col'>
                        <h5>&#8226; Información del producto</h5>
                        <blockquote>
                          <p className='text-justify '>
                            <small>
                              {credit?.product_info.model}
                            </small>
                            <br/>
                            <small>
                              SKU: {credit?.product_info.sku}
                            </small>
                          </p>
                        </blockquote>
                      </div>
                      <div className='col'>
                        <h5>&#8226; Precio a pagar</h5>
                        <blockquote>
                          <p className='text-justify '>
                            <small>
                              ${credit?.pay_data_info?.pay_per_week} semanales
                            </small>
                            <br/>
                            <small>
                              {credit?.pay_data_info?.weeks} semanas
                            </small>
                          </p>
                        </blockquote>
                      </div>
                      <div className='col'>
                        <h5>&#8226; Contrato firmado</h5>
                        <a
                          href={credit?.contrato_firmado}
                          alt='Contrato firmado'
                          target='_blank' rel='noreferrer'
                        >
                          Ver aquí
                        </a>
                      </div>
                    </>
                    )}
              </div>
              <div className='row mt-5'>
                <div className='col'>
                  <div className='custom-control custom-checkbox checkbox-lg'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='inlineCheckbox1'
                      value='option1'
                      onChange={() => setConfirm(!confirm)}
                    />
                    <label
                      className='form-check-label checkbox-label'
                      htmlFor='inlineCheckbox1'
                    >
                      Verifica que esta información sea correcta
                    </label>
                  </div>
                  {confirm && (
                    <p style={{ color: 'red' }}>
                      * Campo obligatorio para confirmar
                    </p>
                  )}
                </div>
              </div>
              <div className='row mt-5 mb-3'>
                <div className='col-6'>
                  <button
                    className='btn-2 b-secondary-2'
                    disabled={confirm}
                    onClick={() => handleConfirm()}
                  >
                    Confirmar
                  </button>
                </div>
                <div className='col-6'>
                  <button
                    className='btn-2 b-light'
                    onClick={() => handleCancel()}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resultado
