// React
import { useEffect, useState } from 'react'

// Assets
import agua from '../../../assets/icons/agua.svg'
import garantia from '../../../assets/icons/garantia.svg'
import regadera from '../../../assets/icons/regadera.svg'
import temperatura from '../../../assets/icons/temperatura.svg'
import tubos from '../../../assets/icons/tubos.svg'
import vida from '../../../assets/icons/vida.svg'

// Components
import IconURLComponent from '../../Shared/IconUrl.component'
import Autocomplete from '../../Autocomplete/Autocomplete.component'

// Services
import msProductsService from '../../../services/ms/ms-products-service'
import { getRequestAPI } from '../../../services/requests.service'
import { getSystems } from '../../../services/constants'

function ProductSelectedComponent({ handleChange, credit }) {
  const [systems, setSystems] = useState([])
  const [products, setProducts] = useState([])

  useEffect(() => {
    msProductsService.getAvailableProducts().then((results) => {
      setProducts(results)
    })

    getRequestAPI(getSystems()).then((response) => {
      setSystems(response.data)
    })
  }, [])

  const meters = systems.map((system) => (
    <option value={system.id} key={system.system_id}>
      {system.system_id}
    </option>
  ))
  meters.unshift(
    <option value="" key="{system.system_id}">
      -----
    </option>
  )
  const { product_branch } = credit?.product_data || {}
  return (
    <>
      <br />
      <br />

      {!!product_branch && (
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center">
            <img
              src={product_branch.product?.image}
              alt={product_branch.product?.model}
              style={{ width: '50%' }}
            />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col">
                <h4>{product_branch.product?.model}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>{product_branch.product?.merchant.name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ display: 'inline-flex' }}>
                <IconURLComponent
                  text={`${product_branch.product?.life_time} Vida útil`}
                  icon={vida}
                  url={null}
                />
                <IconURLComponent
                  text={product_branch.product?.capacity}
                  icon={agua}
                  url={null}
                />
                <IconURLComponent
                  text={`${product_branch.product?.warranty} años de garantía`}
                  icon={garantia}
                  url={null}
                />
                <IconURLComponent
                  text={`${product_branch.product?.services} duchas al día`}
                  icon={regadera}
                  url={null}
                />
                <IconURLComponent
                  text={product_branch.product?.temp_retention}
                  icon={temperatura}
                  url={null}
                />
                <IconURLComponent
                  text={product_branch.product?.size}
                  icon={tubos}
                  url={null}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <p>Total: $ {product_branch.price} MXN</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h4>Descripción</h4>
                <p>{product_branch.product?.description}</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h4>Garantía</h4>
                <p>{product_branch.product?.warranty_description}</p>
              </div>
            </div>
            {credit.system_info && (
              <div className="">
                <div className="row">
                  <div className="col">
                    <h4>Cambiar medidor: {credit?.system_info?.serie}</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-3 col-sm-12 col-xs-12">
                    <select
                      disabled
                      className="input-g2"
                      id="cars2"
                      value={credit.system}
                      onChange={(e) => handleChange(e.target.value, 'system')}
                    >
                      {meters}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          <br />
        </div>
      )}
    </>
  )
}

export default ProductSelectedComponent
