// React
import React, { useState, useEffect } from 'react'

import jwtDecode from 'jwt-decode'

// Styles
import './styles.css'

function MenuMainComponent() {
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) return
    const payload = jwtDecode(token)
    setRoles(payload.roles)
  }, [])

  return (
    <div>
      <ul>
        <img
          src="/logoGravitiTransparente.svg"
          alt="Graviti"
          className="logo"
        />
        {/* GRAVITI */}
        {localStorage.getItem("user_type") === "GR" && ( // eslint-disable-line
          <>
            <a className="linkMenu" href="/graviti">
              <li className="li-05 text-center">
                <img
                  className="iconMenu"
                  src="/iconsMenu/graviti.svg"
                  alt="Compañia"
                />{' '}
                <span>Graviti</span>
              </li>
            </a>
            <a className="linkMenu" href="/cuentas">
              <li className="li-02 text-center">
                {' '}
                <img
                  className="iconMenu"
                  src="/iconsMenu/cuentas.svg"
                  alt="Cuentas"
                />{' '}
                <p className="text-center mb-0">Cuentas</p>
              </li>
            </a>
            <a className="linkMenu" href="/promociones">
              <li className="li-05">
                <img
                  className="iconMenu"
                  src="/iconsMenu/discounts.svg"
                  alt="Promociones"
                />
                <p className="text-center mb-0">Promociones</p>
              </li>
            </a>
            {roles.includes('acquisition_admin') && (
              <a className="linkMenu" href="/notificaciones">
                <li className="li-03">
                  {' '}
                  <img
                    className="iconMenu"
                    src="/iconsMenu/letter.svg"
                    alt="Notificaciones"
                  />{' '}
                  <p className="text-center mb-0">Notificaciones</p>
                </li>
              </a>
            )}
          </>
        )}

        {/* FINANCIERA */}
        {localStorage.getItem("user_type") === "FN" && ( // eslint-disable-line
          <>
            <a className="linkMenu" href="/cuentas">
              <li className="li-02">
                {' '}
                <img
                  className="iconMenu"
                  src="/iconsMenu/cuentas.svg"
                  alt="Cuentas"
                />{' '}
                <p className="text-center mb-0">Cuentas</p>
              </li>
            </a>
            <a className="linkMenu" href="/contabilidad">
              <li className="li-03">
                {' '}
                <img
                  className="iconMenu"
                  src="/iconsMenu/contabilidad.svg"
                  alt="Contabilidad"
                />{' '}
                <p className="text-center mb-0">Contabilidad</p>
              </li>
            </a>
            {roles.includes('acquisition_admin') && (
              <a className="linkMenu" href="/notificaciones">
                <li className="li-03">
                  {' '}
                  <img
                    className="iconMenu"
                    src="/iconsMenu/letter.svg"
                    alt="Notificaciones"
                  />{' '}
                  <p className="text-center mb-0">Notificaciones</p>
                </li>
              </a>
            )}
          </>
        )}

        {/* DISTRIBUIDOR */}
        {localStorage.getItem("user_type") === "DS" && ( // eslint-disable-line
          <>
            <a className="linkMenu" href="/cuentas">
              <li className="li-02">
                {' '}
                <img
                  className="iconMenu"
                  src="/iconsMenu/cuentas.svg"
                  alt="Cuentas"
                />{' '}
                <p className="text-center mb-0">Cuentas</p>
              </li>
            </a>
            <a className="linkMenu" href="/productos">
              {' '}
              <li className="li-04">
                {' '}
                <img
                  className="iconMenu"
                  src="/iconsMenu/products.svg"
                  alt="Productos"
                />{' '}
                <p className="text-center mb-0">Productos</p>
              </li>
            </a>
            <a className="linkMenu" href="/promociones">
              <li className="li-05">
                <img
                  className="iconMenu"
                  src="/iconsMenu/discounts.svg"
                  alt="Promociones"
                />
                <p className="text-center mb-0">Promociones</p>
              </li>
            </a>
            <a className="linkMenu" href="/contabilidad">
              <li className="li-03">
                {' '}
                <img
                  className="iconMenu"
                  src="/iconsMenu/contabilidad.svg"
                  alt="Contabilidad"
                />{' '}
                <p className="text-center mb-0">Contabilidad</p>
              </li>
            </a>
            <a className="linkMenu" href="/dashboard">
              <li className="li-03">
                {' '}
                <img
                  className="iconMenu"
                  src="/iconsMenu/charts.svg"
                  alt="Contabilidad"
                />{' '}
                <p className="text-center mb-0">Dashboard</p>
              </li>
            </a>
            {roles.includes('acquisition_admin') && (
              <a className="linkMenu" href="/notificaciones">
                <li className="li-03">
                  {' '}
                  <img
                    className="iconMenu"
                    src="/iconsMenu/letter.svg"
                    alt="Notificaciones"
                  />{' '}
                  <p className="text-center mb-0">Notificaciones</p>
                </li>
              </a>
            )}
          </>
        )}
        {/* CASHIER USER */}
        {localStorage.getItem("user_type") === "CS" && ( // eslint-disable-line
          <a className="linkMenu" href="/cuentas">
            <li className="li-02">
              {' '}
              <img
                className="iconMenu"
                src="/iconsMenu/cuentas.svg"
                alt="Cuentas"
              />{' '}
              <p className="text-center mb-0">Cuentas</p>
            </li>
          </a>
        )}
      </ul>
    </div>
  )
}

export default MenuMainComponent
