import axios from 'axios'
import { toast } from 'react-toastify'
import { origin, tokenAquarius, originKYC, tokenKYC } from './constants'

// Get detail credits
export const getCreditDetails = async (id) => {
  try {
    const response = await axios({
      url: `${origin}api/v2/clients/${id}/credits/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': 'es-es',
        Authorization: `Bearer ${tokenAquarius}`, // eslint-disable-line
      },
      params: {
        detail: true,
      },
    })
    return response
  } catch (error) {
    const errorDate = error.response.data
    if (errorDate.detail) {
      toast.error(errorDate.detail, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.error('Error en el servidor, intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    return error.response
  }
}

// Get detail kyc
export const getKycData = async (id) => {
  try {
    const response = await axios({
      url: `${originKYC}clients/${id}/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': 'es-es',
        Authorization: `Token ${tokenKYC}`, // eslint-disable-line
      },
    })
    return response
  } catch (error) {
    const errorDate = error.response.data
    if (errorDate.detail) {
      toast.error(errorDate.detail, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.error('Error en el servidor, intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    return error.response
  }
}

// Create credit
export const createCreditService = async (data, clientId) => {
  // remove id_firebase from data
  delete data.id_firebase
  try {
    const response = await axios({
      url: `${origin}api/v2/clients/${clientId}/credits/`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'es-es',
        Authorization: `Bearer ${tokenAquarius}`,
      },
      data,
    })
    return response
  } catch (error) {
    const errorDate = error?.response?.data
    if (errorDate?.detail) {
      toast.error(errorDate.detail, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else if (Object.values(errorDate).length > 0) {
      Object.keys(errorDate).forEach((item) => {
        toast.error(`${item}: ${errorDate[item][0]}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    } else {
      toast.error('Error en el servidor, intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    throw error.response
  }
}
