import axios from 'axios'
import { toast } from 'react-toastify'
import {
  origin,
  originKYC,
  baseUrl,
  tokenAquarius,
  tokenKYC,
} from './constants'
import shopper from './ms/ms-users-service'

/**
 * Default headers
 * @returns
 */
const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
})

/**
 * Default error handler
 * @param {*} error
 * @returns
 */
const defaultErrorHandler = (error) => {
  switch (error.response.status) {
    case 400:
      if (error.response.data.mail) {
        toast.error('El correo electrónico ingresado ya existe', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else if (error.response.data.phone) {
        toast.error('El Teléfono ingresado ya existe', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else if (error.response.data.product) {
        toast.error('Seleccionar un producto para el crédito', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error(error.response.data.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      break
    case 404:
      toast.error(error.response.data.detail, {
        position: toast.POSITION.TOP_RIGHT,
      })
      break
    default:
      console.log(error.response)
      toast.error('Error en el servidor, Intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
      break
  }

  return error.response
}

// Count
export const getCreditsCount = () =>
  axios({
    url: `${origin + baseUrl}credits/count/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      const fakeEmptyResponse = { data: [] }
      return fakeEmptyResponse
    })

// Get credits account by status
export const getCreditsByStatus = (filter, pageSize, page) =>
  axios({
    url: `${origin}api/v2/credits/?credit_status=${filter}&page=${page}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

// Get credits account by name
export const getCreditsByName = (name = '', page) =>
  axios({
    url: `${origin}api/v2/credits/?search=${name}&page=${page}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

// Get credits account by name
export const getCreditsByPhone = (phone = '', page) =>
  axios({
    url: `${origin}api/v2/credits/?search=${phone}&page=${page}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

// Creates a credit
export const createCredit = (clientId, data) =>
  axios
    .post(`${origin}api/v2/clients/${clientId}/credits/`, data, {
      headers: getHeaders(),
    })
    .catch(defaultErrorHandler)

// Create new candidate
export const createCandidate = (data) => {
  const formData = new FormData(); // eslint-disable-line
  for (const key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      if (data[key].constructor === {}.constructor) {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    }
  }
  return axios({
    url: `${origin + baseUrl}clients/`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data: formData,
  })
    .then((response) => {
      toast.success('Candidato creado correctamente !', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response
    })
    .catch(defaultErrorHandler)
}

// Update Contract Credit
export const updateCredit = (ogData) => {
  const data = { ...ogData }
  if (data.system === '') {
    delete data.system
  }
  const formData = new FormData(); // eslint-disable-line
  for (const key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    }
  }

  return axios({
    url: `${origin + baseUrl}credits/${data.id}/`,
    method: 'patch',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data: formData,
  })
    .then((response) => {
      toast.success('Crédito actualizado correctamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response
    })
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
}

// aprobar credito
export const approveCredit = (data) =>
  axios({
    url: `${origin + baseUrl}credits/${data.id}/financial/response/`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data,
  })
    .then((response) => {
      toast.success('Cambio de estatus exitosamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response
    })
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error.response
    })

// update DATE estimated credito
export const updateEstimatedDate = (data) =>
  axios({
    url: `${origin + baseUrl}credits/${data.id}/install_date/`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data,
  })
    .then((response) => {
      toast.success('Fecha de instalación actualizada', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response
    })
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      console.log(error)
      console.log(error.response)
      return error.response
    })

// Get detail credits
export const getCreditDetail = (id) =>
  axios({
    url: `${origin + baseUrl}credits/${id}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => error.response)

// Get User Fake
export const getUser = () =>
  axios({
    url: 'https://uinames.com/api/?ext&region=mexico',
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.error) {
        toast.error(errorDate.error, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const generateCURP = (
  birthday,
  entity_birth,
  motherName,
  name,
  lastName,
  gender
) => {
  const options = {
    method: 'POST',
    url: `${originKYC}validators/curp/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${tokenKYC}`,
    },
    data: {
      name,
      last_name: lastName,
      mother_name: motherName,
      birthday,
      gender: gender === 'Masculino' ? 'H' : 'M',
      entity_birth,
    },
  }
  return axios
    .request(options)
    .then((response) => response.data.curp)
    .catch(() => '')
}

export const updateCreditStatus = (data, id) =>
  axios({
    url: `${origin}${baseUrl}credits/${id}/photos_status/`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`, // eslint-disable-line
    },
    data,
  })
    .then((response) => {
      toast.success('Crédito actualizado correctamente!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response.data
    })
    .catch((error) => {
      toast.error(`Error en el servidor, Intenta nuevamente. ${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return error
    })

export const getListInstallationPhotosByCreditId = (id) =>
  axios({
    url: `${origin}${baseUrl}credits/photos/?credit_id=${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`, // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => error)

export const getCreditPhotosStatus = (id) =>
  axios({
    url: `${origin}${baseUrl}credits/${id}/photos_status/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`, // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => error)

export const deleteInstallationPhoto = (photoId) =>
  axios({
    url: `${origin}${baseUrl}credits/photos/${photoId}/`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`, // eslint-disable-line
    },
  })
    .then((response) => {
      toast.success('Foto eliminada correctamente!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response
    })
    .catch((error) => {
      toast.error(`Error en el servidor, Intenta nuevamente. ${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return error.response
    })

export const validateInstallationPhoto = (photoId, validated) =>
  axios({
    url: `${origin}${baseUrl}credits/photos/${photoId}/`,
    method: 'patch',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`, // eslint-disable-line
    },
    data: { validated },
  })
    .then((response) => {
      toast.success('Foto actualizada correctamente!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response.data
    })
    .catch((error) => {
      toast.error(`Error en el servidor, Intenta nuevamente. ${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return error.response
    })

export const createInstallationPhoto = (photo, id) => {
  const formData = new FormData(); // eslint-disable-line
  formData.append('image', photo)
  formData.append('credit_id', id)
  formData.append('validated', true)
  return axios({
    url: `${origin}${baseUrl}credits/photos/`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem("token")}`, // eslint-disable-line
    },
    data: formData,
  })
    .then((response) => {
      toast.success('Imagen cargada con éxito!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response
    })
    .catch((error) => {
      toast.error(`Error en el servidor, Intenta nuevamente. ${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return error.response
    })
}

export const updateCreditTags = (tags, id) =>
  axios({
    url: `${origin}${baseUrl}credits/${id}/tags/`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${tokenAquarius}`,
    },
    data: tags,
  })
    .then((response) => {
      toast.success('Etiquetas actualizadas correctamente!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response.data
    })
    .catch((error) => {
      toast.error(
        `Error en el servidor, Intenta nuevamente. ${
          error?.response?.data?.non_field_errors[0] || error
        }`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      )
      throw error
    })

export const sendTicket = (id) =>
  axios({
    url: `${origin}api/v2/credits/send-ticket/`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${tokenAquarius}`,
    },
    data: {
      credit_id: id,
    },
  })
    .then((response) => {
      toast.success('Comprobante enviado exitosamente!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return response.data
    })
    .catch((error) => {
      toast.error(`Error en el servidor, Intenta nuevamente. ${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return error
    })

function slugify(str) {
  const map = {
    '-': ' ',
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  }

  for (const pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern)
  }

  return str
}

// Create client
export const createClientService = async (data) => {
  const formData = new FormData(); // eslint-disable-line
  for (const key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      if (data[key].constructor === {}.constructor) {
        formData.append(key, JSON.stringify(data[key]))
      } else if (key === 'personal_references') {
        formData.append('personal_references', JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
        formData.append('password', 'Qwert12345')
      }
    }
  }
  try {
    const response = await axios({
      url: `${origin}api/v2/clients/creation/`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        'Accept-Language': 'es-es',
        Authorization: `Bearer ${tokenAquarius}`, // eslint-disable-line
      },
      data: formData,
    })
    return response
  } catch (error) {
    const errorDate = error?.response?.data
    if (typeof errorDate === 'object') {
      Object.keys(errorDate).forEach((item) => {
        toast.error(`${item?.toUpperCase()}: ${errorDate[item][0]}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    } else if (errorDate?.detail) {
      toast.error(errorDate.detail, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      const message = errorDate[0] || 'Error en el servidor, intenta nuevamente'
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    throw error.response
  }
}
// Update client
export const updateClientService = async (data, clientId) => {
  const formData = new FormData(); // eslint-disable-line
  for (const key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      if (data[key].constructor === {}.constructor) {
        formData.append(key, JSON.stringify(data[key]))
      } else if (key === 'personal_references') {
        formData.append('personal_references', JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    }
  }
  try {
    const response = await axios({
      url: `${origin}api/v2/clients/${clientId}/`,
      method: 'patch',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        'Accept-Language': 'es-es',
        Authorization: `Bearer ${tokenAquarius}`, // eslint-disable-line
      },
      data: formData,
    })
    return response
  } catch (error) {
    const errorDate = error?.response?.data
    if (typeof errorDate === 'object') {
      if (Object.keys(errorDate)[0] === 'detail') {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        Object.keys(errorDate).forEach((item) => {
          toast.error(`${item?.toUpperCase()}: ${errorDate[item][0]}`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
      }
    } else if (errorDate?.detail) {
      toast.error(errorDate.detail, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      const message = 'Error en el servidor, intenta nuevamente'
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    throw error.response
  }
}
// Get detail client
export const getClientDetail = async (id) => {
  try {
    const response = await axios({
      url: `${origin}api/v2/clients/${id}/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': 'es-es',
        Authorization: `Bearer ${tokenAquarius}`, // eslint-disable-line
      },
    })
    return response
  } catch (error) {
    const errorDate = error?.response?.data
    if (errorDate?.detail) {
      toast.error(errorDate.detail, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.error('Error en el servidor, intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    throw error
  }
}

// Get unrelated clients
export const getUnrelatedClients = (filter, pageSize, page) =>
  axios({
    url: `${origin}api/v2/clients/UnrelatedClients/?page=${page}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
