import axios from 'axios'
import { toast } from 'react-toastify'
import { origin, baseUrl } from './constants'

export const getTransactionsPerMonth = (number = 1) =>
  axios({
    url: `${origin}${baseUrl}transactions/sales-by-period/?page=${number}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      // const errorData = error.response.data
      toast.error('Error en el servidor, Intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })

      console.log(error.response)
      return error.response
    })

export const getTransactionsInMonth = (year, month, pageNumber = 1) =>
  axios({
    url: `${origin}${baseUrl}transactions/sales-by-period/${year}/${month}/?page=${pageNumber}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      // const errorData = error.response.data
      toast.error('Error en el servidor, Intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })

      console.log(error.response)
      return error.response
    })

export const getExportByMonth = () => `${origin}${baseUrl}transactions/report/`

export const getExportInMonth = (year, month) =>
  `${origin}${baseUrl}transactions/report/${year}/${month}`
