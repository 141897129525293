import axios from 'axios'
import { toast } from 'react-toastify'
import {
  origin,
  baseUrl,
  originMmf,
  tokenMMF,
  originMsProducts,
} from './constants'

export const getAllProducts = () =>
  axios({
    url: `${origin + baseUrl}credits/financial/products/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor (Productos). Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error.response
    })

// Brand
export const getBrand = () =>
  axios({
    url: `${origin + baseUrl}product/brand/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data.results)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor (Marcas). Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

// Types
export const getTypesProduct = () =>
  axios({
    url: `${origin + baseUrl}product/type/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    params: {
      page: 1,
      page_size: 1000,
    },
  })
    .then((response) => response.data.results)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor (Tipos). Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

// Get Products by Brand/Type
export const getProductsFilter = (brand, type, search) =>
  axios({
    url: `${origin + baseUrl}distributor/product/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    params: {
      page: 1,
      page_size: 1000,
      product__brand: brand,
      product__product_type: type,
      search,
    },
  })
    .then((response) => response.data.results)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error(
          'Error en el servidor (Productos Filtro). Intente de nuevo',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        )
      }
      return error.response
    })

// Get Product by ID
export const getDetailProduct = (id) =>
  axios({
    url: `${origin + baseUrl}distributor/product/${id}/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error?.response?.data
      if (errorDate?.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor (Producto ID). Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      throw error
    })

export const getPurchaseReason = (productId) =>
  axios({
    url: `${origin + baseUrl}product/purchase-reason/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    params: {
      productId,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor (Producto ID). Intente de nuevo', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const createProductByDistributor = (url, data) =>
  axios({
    url: origin + baseUrl + url,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
export const createMsProductBranch = (url, data) =>
  axios({
    method: 'post',
    url: originMsProducts + url,
    headers: {
      Authorization: localStorage.getItem('token'), // eslint-disable-line
      ContentType: 'multipart/form-data',
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      const { data } = error.response
      let message = data.detail || data.description
      if (message instanceof Object) {
        message = Object.keys(message)
          .map((key) => `${key}: ${message[key]}`)
          .join('<br/>')
      }
      toast.error(message || 'Error en el servidor, Intenta nuevamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return error.response
    })

export const updateProductByDistributor = (url, data) =>
  axios({
    url: origin + baseUrl + url,
    method: 'patch',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
      Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
    },
    data,
  })
    .then((response) => response)
    .catch((error) => {
      const errorDate = error.response.data
      if (errorDate.detail) {
        toast.error(errorDate.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error en el servidor, Intenta nuevamente', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })

export const getTerms = async (productId, distributorId) => {
  try {
    const response = await axios({
      url: `${origin}${baseUrl}product/products/plazos/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': 'es-es',
        Authorization: "Bearer " + localStorage.getItem("token"), // eslint-disable-line
      },
      params: {
        product_id: productId,
        distributor_id: distributorId,
      },
    })
    return response.data
  } catch (error) {
    return error.response
  }
}

export const getFinancialModels = async (
  distributor,
  distributorProductFinancial,
  price,
  discount = 0
) =>
  axios({
    url: `${originMmf}?distributor_id=${distributor}&product_id=${distributorProductFinancial}&price=${price}&discount=${discount}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenMMF}`,
    },
  })
    .then((response) => response.data)
    .then((data) => data.data)
    .catch((error) => {
      throw error
    })
