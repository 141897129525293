import React from 'react'
import * as CurrencyFormat from 'react-currency-format'
import LoadingComponent from '../Shared/Loading.component'

export default function InstallationsTableComponent({
  isStopped,
  rows,
  hidden,
  handleClick,
  headers,
}) {
  const procesingDate = (date = '0000-00') => {
    const processedDate = date.slice(0, 7)
    return processedDate.split('-')
  }

  const readableDate = (date) => {
    const [year, month] = procesingDate(date)
    return month.concat('/', year)
  }

  const handleKeyTransaction = (date) => {
    const [year, month] = procesingDate(date)
    const key = year.concat('/', month)
    handleClick(key)
  }
  return (
    <div className="box-table" hidden={hidden}>
      {isStopped === false && (
        <div className="table-responsive">
          <table className="table table-hover table-graviti">
            <thead>
              <tr>
                {headers.map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.map((item, i) => (
                <tr
                  key={i}
                  onClick={() => handleKeyTransaction(item?.start_period)}
                >
                  <td className="textPush">
                    {readableDate(item?.start_period)}
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item?.capital || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item?.interests || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item?.iva_interests || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item?.commisions || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item?.iva_commisions || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item?.transacted || 0}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                      decimalScale={2}
                    />
                  </td>
                  <td className="textPush">{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isStopped === true && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}
