import React from 'react'
import ProgressBar from '../../components/ProgressBar'
import BarItem from '../../components/BarItem'
import { documentsHeaders } from './utils'

function DocumentsProgressBar(props) {
  const {
    contract = null,
    contractValidation = false,
    pdf = null,
    xml = null,
    pdfValidation = false,
    xmlValidation = false,
    equipo = null,
    serie = null,
    equipoValidation = false,
    serieValidation = false,
  } = props
  return (
    <ProgressBar>
      <BarItem
        documents
        exists={contract}
        existsValidation={contract && contractValidation}
        header={documentsHeaders.contract}
      />
      <BarItem
        documents
        exists={pdf}
        existsValidation={pdf && pdfValidation}
        header={documentsHeaders.pdf}
      />
      <BarItem
        documents
        exists={xml}
        existsValidation={xml && xmlValidation}
        header={documentsHeaders.xml}
      />
      <BarItem
        documents
        exists={equipo && serie}
        existsValidation={equipoValidation && serieValidation}
        header={documentsHeaders.photos}
      />
    </ProgressBar>
  )
}

export default DocumentsProgressBar
