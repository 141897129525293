// React
import React, { useState, useEffect } from 'react'

// Components
import PromosTable from '../PromosTable'

// services
import msProductsService from '../../../services/ms/ms-products-service'
import PaginatorComponent from '../../Shared/Paginator.component'
import LoadingComponent from '../../Shared/Loading.component'

/**
 * Promo list component
 */
function PromosComponent({ history }) {
  /**
   * List of promos related to logged user
   */
  const [promos, setPromos] = useState()

  /**
   * Current active page
   */
  const [activePage, setActivePage] = useState(1)

  /**
   * Total items count
   */
  const [totalItemsCount, setTotalItemsCount] = useState(0)

  /**
   * Loading
   */
  const [loading, setLoading] = useState(false)

  /**
   * Retrieves promos to show on table
   */
  useEffect(() => {
    setLoading(true)
    msProductsService.getPromos(activePage).then((response) => {
      setPromos(response.results)
      setTotalItemsCount(response.total)
      setLoading(false)
    })
  }, [activePage])

  /**
   * Opens the `PromoUpdateComponent` using sthe given promo, or
   * `PromoDetailsComponent` if logged user is a graviti user
   * @param {Promo} promo the selected promo
   */
  const handlePromoClick = (promo) => {
    const role = localStorage.getItem('user_type')
    if (role === 'GR' && !promo.is_graviti_discount) {
      history.push(`/promociones/detalles/${promo.id}`)
    } else {
      history.push(`/promociones/editar/${promo.id}`)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <h1>PROMOCIONES</h1>
        </div>
        <div className="col-md">
          <button
            className="btn-2 b-primary"
            onClick={(e) => history.push('/promociones/crear/')}
          >
            Agregar promoción
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {loading ? (
            <LoadingComponent />
          ) : (
            <PromosTable
              promos={promos}
              loading={promos === undefined}
              onPromoClick={handlePromoClick}
            />
          )}
          <PaginatorComponent
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={totalItemsCount}
            handlePageChange={setActivePage}
          />
        </div>
      </div>
    </div>
  )
}

export default PromosComponent
