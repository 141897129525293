// React
import React, { useEffect, useState } from 'react'

// Toastify
import { toast } from 'react-toastify'

// Services
import msProductsService from '../../../services/ms/ms-products-service'

// Components
import LoadingComponent from '../../Shared/Loading.component'
import { PromoTags } from '../PromosTable'
import PromoExtraPercentageFieldUpdate from '../PromoExtraPercentageFieldUpdate'

// Styles
import '../PromoProductSelector/style.css'
import { Link } from 'react-router-dom'

/**
 * Promo details page
 */
function PromoDetailsComponent({ history, match }) {
  /**
   * The queried promo
   */
  const [promo, setPromo] = useState()

  /**
   * The promo's products
   */
  const [products, setProducts] = useState()

  /**
   * promo id from route params
   */
  const { id } = match.params

  /**
   * Retrieves the promo and its products using the given id
   */
  useEffect(() => {
    if (id) {
      msProductsService.getPromo(id).then((promo) => {
        setPromo(promo)
      })
      msProductsService.getProductBranchByPromo(id).then((products) => {
        setProducts(products)
      })
    }
  }, [id])

  /**
   * the onSubmit callback for the `PromoExtraPercentageFieldUpdate` component.
   * Updates the promo in the `promos` list and shows a success message
   * in a toast
   * @param {Promo} promo the updated promo
   */
  const handlePromoExtraPercentageSubmit = (promo) => {
    setPromo(promo)
    toast.success('Descuento actualizado con éxito!', {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  /**
   * Formats an ISO date string
   * @param {string} isoDate the iso date string
   * @returns a formated date string
   */
  const formatISODate = (isoDate) => {
    const date = new Date(isoDate)
    return date.toLocaleDateString('es-ES', {
      day: 'numeric',
      weekday: 'long',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return (
    <div className="container-fluid">
      <div className="row mb-5">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h1>{promo?.name}</h1>
        </div>
      </div>
      <div className="box-table">
        {!promo ? (
          <LoadingComponent />
        ) : (
          <div className="row">
            <div className="col-sm-6">
              <h4>Distribuidor</h4>
              <p>{promo.merchant_name}</p>

              <h4>Tags</h4>
              <p>
                <PromoTags promo={promo} />
              </p>

              <h4>Descripción</h4>
              <p>{promo.description}</p>

              <div className="row">
                <div className="col-sm">
                  <h4>Porcentaje</h4>
                  <p>{promo.percentage}%</p>
                </div>
                <div className="col-sm">
                  <h4>Porcentaje extra</h4>
                  <PromoExtraPercentageFieldUpdate
                    promo={promo}
                    onSubmit={handlePromoExtraPercentageSubmit}
                  />
                </div>
              </div>

              <h4>Vigencia:</h4>
              <p>
                {formatISODate(promo.start_date)}
                &nbsp;-&nbsp;
                {formatISODate(promo.expiration_date)}
              </p>
            </div>
            <div className="col-sm-6">
              <h4>Productos asociados</h4>
              <div className="product-list">
                {products?.map((product) => (
                  <div className="d-flex align-items-center">
                    <img
                      className="product-img"
                      src={product.product.image}
                      alt={product.product.model_display}
                    />
                    <p>{product.product.model_display}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <Link to="/promociones/" className="btn b-primary" role="button">
            Regresar
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PromoDetailsComponent
