import React, { useState, useEffect } from 'react'
import './login.css'
import { toast } from 'react-toastify'
import logoGraviti from '../../assets/logoGravitiTransparente.svg'
import { loginUser } from '../../services/login.service'
import Loading from '../../components/Shared/Loading.component'

// Services
import msUsersService from '../../services/ms/ms-users-service'

export default function LoginComponent() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('user_type') !== null) {
      const path = '/cuentas'
      window.location.pathname = path
    }
  }, [])

  const handleChange = (input) => (event) => { // eslint-disable-line
    switch (input) {
      case 'username':
        setUsername(event.target.value)
        break
      case 'password':
        setPassword(event.target.value)
        break
      default:
        break
    }
  }

  const handleLogin = (e) => {
    e.preventDefault()
    setLoading(true)
    msUsersService
      .login(username, password)
      .then((response) => {
        const { token } = response
        localStorage.setItem('token', token)
        const payload = msUsersService.decodeToken(token)
        if (payload.roles.includes('acquisition')) {
          localStorage.setItem('user_type', 'GR')
        } else if (payload.roles.includes('financial')) {
          localStorage.setItem('user_type', 'FN')
        } else if (payload.roles.includes('merchant')) {
          localStorage.setItem('user_type', 'DS')
        } else {
          throw new Error({
            response: 'No tienes permisos para iniciar sesión',
          })
        }
        window.location.pathname = '/cuentas'
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        const message = error?.response?.data?.message
        if (message) {
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.error('Error al iniciar sesión, intenta nuevamente.', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      })
  }

  return (
    <div className="box-login">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-5 col-sm-8 col-xs-8 text-center">
            <img src={logoGraviti} alt="Graviti Aquarius" />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-5 col-sm-8 col-xs-8 text-center">
            <p className="version">Aquarius V.2.0</p>
          </div>
        </div>
        {loading ? (
          <Loading isStopped={false} white />
        ) : (
          <form onSubmit={handleLogin}>
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-5 col-sm-8 col-xs-8">
                <input
                  type="text"
                  value={username}
                  className="input-g"
                  placeholder="Correo electrónico"
                  onChange={handleChange('username')}
                  autoFocus
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-5 col-sm-8 col-xs-8">
                <input
                  type="password"
                  className="input-g"
                  placeholder="Contraseña"
                  onChange={handleChange('password')}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-5 col-sm-8 col-xs-8">
                <button className="btn-1 b-accent" type="submit">
                  Entrar
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
