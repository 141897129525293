import React, { useState, useEffect } from 'react'
import validator from 'validator'
import { toast } from 'react-toastify'
import UploadFileComponent from '../../Shared/UploadFile'
import { getneighborhoods } from '../../../services/share.service'
import statesMexico from '../../../assets/states.json'

const normalize = (str) =>
  str
    .toLowerCase()
    .replace('á', 'a')
    .replace('é', 'e')
    .replace('í', 'i')
    .replace('ó', 'o')
    .replace('ú', 'u')
    .toUpperCase()

export default function PersonalInformationComponent({
  handleChange,
  client,
  storedZip,
  RFC,
  CURP,
}) {
  const [neighborhoods, setNeighborhoods] = useState([])
  const { kycPrescoringInfo } = client

  useEffect(() => {
    if (storedZip) {
      getneighborhoods(storedZip).then(handleRetrieveNeighborhood)
    }
  }, [storedZip])

  const handleInputValue = (value, input) => {
    handleChange(value, input)
    if (input === 'zip' && value !== '' && value.length > 4) {
      setNeighborhoods([])
      getneighborhoods(value).then(handleRetrieveNeighborhood)
    }
  }

  const handleRetrieveNeighborhood = (neighborhood) => {
    if (!neighborhood) return
    setNeighborhoods(neighborhood.colonias)
    handleChange(neighborhood?.estado, 'state_name')
    handleChange(
      statesJson.find((s) => s.state === normalize(neighborhood?.estado))?.id ||
        'DESCONOCIDO',
      'state'
    )
    handleChange('México', 'country')
    handleChange(neighborhood?.ciudad, 'city')
    handleChange(neighborhood?.municipio, 'municipality')
  }

  const statesJson = JSON.parse(JSON.stringify(statesMexico))
  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Nombre"
            onChange={(e) => handleInputValue(e.target.value, 'name')}
            value={client?.name || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Apellido paterno"
            onChange={(e) => handleInputValue(e.target.value, 'lastname')}
            value={client?.lastname || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Apellido materno"
            onChange={(e) =>
              handleInputValue(e.target.value, 'second_lastname')
            }
            value={client?.second_lastname || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="labelInput">Fecha de nacimiento</span>
          <input
            className="input-g2"
            type="date"
            placeholder="Fecha de nacimiento"
            disabled={
              client?.name === '' &&
              (client?.last_name === '' || client?.mother_name === '')
            }
            onBlur={(e) => RFC(e.target.value)}
            onChange={(e) => handleInputValue(e.target.value, 'birthday')}
            value={client?.birthday || ''}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="text-danger labelInput">**Campo obligatorio</span>
          <select
            className="input-g2"
            onChange={(e) => handleInputValue(e.target.value, 'entity_birth')}
            onBlur={(e) => CURP(e.target.value)}
            value={client?.entity_birth || ''}
          >
            <option hidden selected>
              Lugar de nacimiento
            </option>
            {statesJson.map((entity_birth) => (
              <option value={entity_birth.id}>{entity_birth.state}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="text-danger labelInput">**Campo obligatorio</span>
          <select
            className="input-g2"
            onChange={(e) => handleInputValue(e.target.value, 'gender')}
            onBlur={(e) => CURP(e.target.value)}
            value={client?.gender || ''}
          >
            <option hidden selected>
              Genero
            </option>
            <option value="M">Femenino</option>
            <option value="H">Masculino</option>
          </select>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="labelInput">RFC</span>
          <input
            disabled
            className="input-g2"
            placeholder="RFC"
            onChange={(e) => handleInputValue(e.target.value, 'rfc')}
            value={client?.rfc || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="labelInput">CURP</span>
          <input
            disabled
            className="input-g2"
            placeholder="CURP"
            onChange={(e) => handleInputValue(e.target.value, 'curp')}
            value={client?.curp || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Celular"
            onBlur={(e) => {
              if (!validator.isMobilePhone(e.target.value)) {
                toast.warn('Formato de teléfono incorrecto')
              }
            }}
            onChange={(e) => handleInputValue(e.target.value, 'phone')}
            value={client?.phone || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Teléfono Fijo (10 dígitos)"
            onChange={(e) => handleInputValue(e.target.value, 'home_phone')}
            value={client?.home_phone || ''}
          />
        </div>

        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="text-danger labelInput">**Campo obligatorio</span>
          <input
            type="text"
            className="input-g2"
            placeholder="Correo electrónico"
            onBlur={(e) => {
              if (!validator.isEmail(e.target.value)) {
                toast.warn('Formato de correo electrónico incorrecto')
              }
            }}
            onChange={(e) => handleInputValue(e.target.value, 'email')}
            value={client?.email || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="labelInput">Estado civil</span>
          <select
            className="input-g2"
            onChange={(e) => handleInputValue(e.target.value, 'marital_status')}
            value={client?.marital_status || ''}
          >
            <option value="-">-</option>
            <option value="Soltero">Soltero</option>
            <option value="Casado">Casado</option>
            <option value="Viudo">Viudo</option>
            <option value="Divorciado">Divorciado</option>
            <option value="Unión Libre">Unión Libre</option>
          </select>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col">
          <h4>
            Dirección -{' '}
            <small className="h6">
              Campos obligatorios para generar FICO y poder aprobar.
            </small>
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Calle"
            onChange={(e) => handleInputValue(e.target.value, 'street')}
            value={client?.street || ''}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="No. Ext"
            onChange={(e) => handleInputValue(e.target.value, 'exterior')}
            value={client?.exterior || ''}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="No. Int"
            onChange={(e) => handleInputValue(e.target.value, 'interior')}
            value={client?.interior || ''}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="CP"
            onChange={(e) => handleInputValue(e.target.value, 'zip')}
            value={client?.zip || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          {neighborhoods && (
            <>
              <span className="labelInput">Colonia</span>
              <select
                className="input-g2"
                onChange={(e) =>
                  handleInputValue(e.target.value, 'neighborhood')
                }
                value={client?.neighborhood || ''}
              >
                <option value="-">-</option>
                {neighborhoods.length > 0 &&
                  neighborhoods.map((item, i) => (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  ))}
              </select>
            </>
          )}

          {!neighborhoods && (
            <input
              type="text"
              className="input-g2"
              placeholder="Colonia"
              value={this.props.state?.neighborhood || ''}
              onChange={(e) => handleInputValue(e.target.value, 'neighborhood')}
            />
          )}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Municipio"
            onChange={(e) => handleInputValue(e.target.value, 'municipality')}
            value={client?.municipality || ''}
            disabled
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Estado"
            onChange={(e) => {
              handleInputValue(e.target.value, 'state_name')
              handleInputValue(
                statesJson.find((s) =>
                  s.name.includes(normalize(e.target.value))
                ).id,
                'state'
              )
            }}
            value={client?.state_name || ''}
            disabled
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Ciudad"
            onChange={(e) => handleInputValue(e.target.value, 'city')}
            value={client?.city || ''}
            disabled
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="País"
            onChange={(e) => handleInputValue(e.target.value, 'country')}
            value={client?.country || ''}
            disabled
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col">
          <small className="h6">Referencia de domicilio.</small>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <span className="labelInput">Entre calle</span>
          <input
            type="text"
            className="input-g2"
            placeholder="Ej. Calle 1"
            onChange={(e) =>
              handleInputValue(e.target.value, 'reference_street')
            }
            value={client?.reference_street || ''}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <span className="labelInput">Y calle</span>
          <input
            type="text"
            className="input-g2"
            placeholder="Ej. Calle 2"
            onChange={(e) =>
              handleInputValue(e.target.value, 'reference_street_other')
            }
            value={client?.reference_street_other || ''}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <span className="labelInput">Referencia adicional.</span>
          <input
            type="text"
            className="input-g2"
            placeholder="Ej. Sahuan blanco, fachada azul"
            onChange={(e) =>
              handleInputValue(e.target.value, 'additional_reference')
            }
            value={client?.additional_reference || ''}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col">
          <h4>Documentos</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <UploadFileComponent
            typeFile="INE (Frente)"
            onChangeFile={(file) => handleInputValue(file, 'ine')}
            id="ine"
            url={kycPrescoringInfo?.identification_front}
          />
          <span className="text-danger labelInput">**Campo obligatorio</span>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <UploadFileComponent
            typeFile="INE (Atrás)"
            onChangeFile={(file) => handleInputValue(file, 'ine_back')}
            id="ine_back"
            url={kycPrescoringInfo?.identification_back}
          />
          <span className="text-danger labelInput">**Campo obligatorio</span>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <UploadFileComponent
            typeFile="Comprobante de domicilio"
            onChangeFile={(file) => handleInputValue(file, 'comprobante')}
            id="comprobante"
            url={client?.comprobante}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <UploadFileComponent
            typeFile="Reporte Círculo de Crédito"
            onChangeFile={(file) => handleInputValue(file, 'selfie')}
            id="selfie"
            url={kycPrescoringInfo?.selfie}
          />
        </div>
      </div>
    </>
  )
}
