import React, { useState, useEffect } from 'react'
import cloud from '../../../assets/cloud.png'

const NotFound = ({ onChangeBackButton }) => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])
  return (
    <div
      className={
        width < 672
          ? 'container-fluid qr-code-containers-movil-spinner'
          : 'container-fluid qr-code-containers'
      }
    >
      <div className='row text-center'>
        <div className='col'>
          <div
            style={{
              display: 'block',
              width: '100%',
              padding: 30
            }}
          >
            <h4>¡LO SENTIMOS ESTE CRÉDITO NO FUE ENCONTRADO!</h4>
            <img
              src={cloud}
              className='img-fluid shadow-4 mt-3 mb-5'
              alt='...'
            />
            <h6>
              Indica al comprador que pruebe con <br />
              otro producto.
            </h6>
            {width < 672
              ? null
              : (
                <button
                  className='btn-2 b-secondary-2'
                  onClick={onChangeBackButton}
                  style={{ width: '50%' }}
                >
                  Volver al inicio
                </button>
                )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
