export const tagGroupListStatus = [
  {
    group: 'Estatus',
    items: [
      { title: 'Datos pendientes', color: 'danger' },
      { title: 'Falta INE', color: 'warning' },
      { title: 'Falta Comprobante', color: 'warning' },
      { title: 'Datos Completos', color: 'success' },
      { title: 'Falta Biometría', color: 'warning' },
    ],
  },
]
