import React from 'react'
import './style.css'

function Tags({ title, tagFound, handleDeleteBadge }) {
  return (
    <span
      className={`badge badge-${
        tagFound?.color || 'dark'
      } p-2 mr-2 my-2 border-radius-0`}
    >
      <div className="text tag-container">
        {title}
        {tagFound && (
          <button className="close" onClick={() => handleDeleteBadge(title)}>
            <span
              aria-hidden="true"
              value={title}
              className={`text text-${
                tagFound?.color || 'primary'
              }-graviti-tag`}
            >
              &times;
            </span>
          </button>
        )}
      </div>
    </span>
  )
}

export default Tags
