import React from 'react'
import LoadingComponent from '../Shared/Loading.component'

function FinancialTableComponent(props) {
  const { hidden, isStopped, headers, rows } = props
  return (
    <div className="box-table" hidden={hidden}>
      {isStopped && (
        <table className="table table-hover table-graviti">
          <thead>
            <tr>
              {headers?.map((item, i) => (
                <th key={i}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.map((item, i) => (
              <tr key={i}>
                <td className="textPush">{item?.name}</td>
                <td className="textPush">{item?.responsible}</td>
                <td className="textPush">{item?.phone}</td>
                <td>{item?.full_address}</td>
                <td className="textPush">{item?.human_creation_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isStopped && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}

export default FinancialTableComponent
