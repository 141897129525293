import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import './style.css'
import { tagGroupList } from '../Accounts/SectionsDetailsAccount/SectionGeneral/utils'
import { updateCreditTags } from '../../services/accounts.service'

export default class ModalDetailsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTag: '',
      comments: null,
    }
    this.handleDeleteBadge = this.handleDeleteBadge.bind(this)
    this.handleSelectedTag = this.handleSelectedTag.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ isStopped: true })
    const data = {
      id: this.props.credit.id,
      coment: this.state.comments,
    }
    this.props.handleComments(data)
  }

  handleDeleteBadge(tag) {
    const { credit } = this.props
    const tagsFiltered = credit.tags_list.filter((tagOld) => tag !== tagOld)
    this.setState({ tags: tagsFiltered }, () => {
      const newTags = credit.tags.split(',').filter((item) => item !== tag)
      const tagsToString = newTags.join(',')
      updateCreditTags({ tags: tagsToString }, credit.id).then((response) => {
        this.props.handleCloseModalDetails()
        this.setState({ selectedTag: '' })
      })
    })
  }

  handleSelectedTag(e) {
    const { value } = e.target
    const { credit } = this.props
    this.setState({ selectedTag: value }, () => {
      const oldTags = credit.tags ? credit.tags.split(',') : []
      oldTags.push(this.state.selectedTag)
      updateCreditTags({ tags: oldTags.toString() }, credit.id).then(
        (response) => {
          this.props.handleCloseModalDetails()
          this.setState({ selectedTag: '' })
        }
      )
    })
  }

  render() {
    const { selectedTag } = this.state

    const { isOpen, handleCloseModalDetails } = this.props
    if (!isOpen) {
      return null
    }
    return (
      <Popup
        open={isOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll
      >
        <div className="Modal">
          <div className="Modal__header">
            <div className="Modal__header-title">
              <h4 className="">Comentarios</h4>
            </div>
            <span
              className="Modal__header-close"
              onClick={() => handleCloseModalDetails()}
            >
              X
            </span>
          </div>

          {this.props.credit ? (
            <div className="Modal__Content row">
              <div className="display-flex">
                <p>
                  <b>Nombre: </b>
                  {this.props.credit?.client_info.name}{' '}
                  {this.props.credit.client_info.last_name}
                </p>
                <p>
                  <b>Telefóno: </b> {this.props.credit?.client_info.phone}{' '}
                </p>
                <p>
                  <b>Estatus: </b>{' '}
                  {this.props.credit?.credit_last_status_readable}{' '}
                </p>
                <p>
                  <b>Distribuidor: </b>{' '}
                  {this.props.credit?.distributor_info?.name}{' '}
                </p>
                <p>
                  <b>Producto: </b>{' '}
                  {this.props.credit?.product_info?.brand?.name},{' '}
                  {this.props.credit?.product_info?.model}{' '}
                </p>
                <p>
                  <b>Comentarios: </b>
                  {this.props.credit?.comments}
                </p>
              </div>
              <div className="row rowComments">
                <div className="col-md-9">
                  <input
                    className="input-g2 inputComments"
                    type="textArea"
                    placeholder="Comentario"
                    onChange={(e) =>
                      this.setState({ comments: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-3 col-lg-3">
                  <button
                    className="btn-2 b-primary"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    <i className="fa fa-plus" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr />
                  <p>
                    <strong>Etiquetas:</strong>
                  </p>
                  {this.props.credit?.tags_list.map((title) => {
                    const tagsConcat = tagGroupList[0].items.concat(
                      tagGroupList[1].items
                    )
                    const tagFound = tagsConcat.find(
                      (tagConcat) => tagConcat.title === title
                    )
                    return (
                      <span
                        key={title}
                        className={`badge badge-${
                          tagFound?.color || 'dark'
                        } mr-2 mb-2`}
                      >
                        <div className="tag-container">
                          {title}
                          {tagFound && (
                            <button
                              className="close"
                              onClick={() => this.handleDeleteBadge(title)}
                            >
                              <span aria-hidden="true" value={title}>
                                &times;
                              </span>
                            </button>
                          )}
                        </div>
                      </span>
                    )
                  })}
                  <select
                    className="form-control mb-5"
                    value={selectedTag}
                    onChange={this.handleSelectedTag}
                  >
                    <option>Agregar etiqueta</option>
                    {tagGroupList.map((group) => (
                      <optgroup key={group.group} label={group.group}>
                        {group.items.map((tag, key) => {
                          const found = this.props.credit?.tags_list.find(
                            (title) => title === tag?.title
                          )
                          return (
                            <option
                              value={tag?.title}
                              key={tag?.title + key}
                              disabled={found}
                            >
                              {tag?.title}
                            </option>
                          )
                        })}
                      </optgroup>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Popup>
    )
  }
}
