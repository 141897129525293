import React from 'react'
import { Col, Row } from 'react-bootstrap'

function SearchProduct({ onChange, value }) {
  return (
    <Row>
      <Col>
        <>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="box">
              <div className="container-1">
                <input
                  type="text"
                  id="search"
                  className="form-control p-4"
                  placeholder="Buscar"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                />
              </div>
            </div>
          </form>
        </>
      </Col>
    </Row>
  )
}

export default SearchProduct
