import React, { useState, useEffect } from 'react'
import { getPurchaseReason } from '../../../services/product.service'
import SelectComponent from '../../Shared/Inputs/select.component'
import LoadingComponent from '../../Shared/Loading.component'

// Service
import msMerchantsService from '../../../services/ms/ms-merchants-service'
import msProductsService from '../../../services/ms/ms-products-service'
import msFmEngineService from '../../../services/ms/ms-fmengine-service'

function LeadValidationComponent({
  handleChange,
  hidden,
  client,
  credit,
  onClickButton,
}) {
  const [distributors, setDistributors] = useState([])
  const [distributor, setDistributor] = useState(undefined)
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState(undefined)
  const [types, setTypes] = useState([])
  const [type, setType] = useState(undefined)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState(undefined)
  const [terms, setTerms] = useState([])
  const [term, setTerm] = useState('')
  const [models, setModels] = useState([])
  const [isStopped, setIsStopped] = useState(true)

  useEffect(() => {
    msMerchantsService.getAll().then((merchants) => {
      setDistributors(merchants)
    })
    return function cleanup() {
      setDistributors([])
    }
  }, [])

  function handleValidateCode() {
    onClickButton(1)
  }

  function handleGetBrands(id) {
    setDistributor(id)
    setTypes([])
    setType('')
    setBrands([])
    setBrand('')
    setProducts([])
    setProduct('')
    setTerms([])
    msProductsService.getBrands().then((response) => {
      setBrands(response)
    })
  }

  function handleGetTypes(id) {
    setBrand(id)
    setTypes([])
    setType('')
    setProducts([])
    setProduct('')
    setTerms([])
    msProductsService.getSubcategories().then((response) => {
      setTypes(response.sort((a, b) => a.name.localeCompare(b.name)))
    })
  }

  function handleGetProducts(id) {
    setType(id)
    setTerms([])
    getPurchaseReason(id).then((response) => {
      handleChange(response.results, 'reason_purchase')
      if (response.results.length === 1) {
        handleChange(response.results[0].id, 'reason_purchase_selected')
      }
    })
    msProductsService
      .getAvailableProducts(distributor, brand, id)
      .then((response) => {
        setProducts(response)
        // handleChange(response.data.results[0]?.product_info?.product_type?.service?.questions, 'questions')
      })
  }

  function handleGetTerms(id) {
    handleChange(id, 'product_branch')
    setProduct(id)
    msFmEngineService.getFinancialModels(id, 0).then((models) => {
      setModels(models)
      setTerms(msFmEngineService.parseToOptions(models))
    })
  }

  return (
    <>
      {isStopped && (
        <div className="container-fluid" hidden={hidden}>
          <div className="row">
            <div className="col">
              <h4>Producto</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <SelectComponent
                label="Distribuidor"
                data={distributors}
                option={(value) => handleGetBrands(value)}
                value={distributor}
                id="id"
                name="name"
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
              <SelectComponent
                label="Marca"
                data={brands}
                option={(value) => handleGetTypes(value)}
                value={brand}
                id="id"
                name="name"
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
              <SelectComponent
                label="Tipo"
                data={types}
                option={(value) => handleGetProducts(value)}
                value={type}
                id="id"
                name="name"
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <SelectComponent
                label="Producto"
                data={products}
                option={(value) => handleGetTerms(value)}
                value={product}
                id="id"
                name="model"
              />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <SelectComponent
                label="Plazo"
                data={terms}
                option={(value) => {
                  setTerm(value)
                  const t = value.split('-')
                  handleChange(models[t[0]][t[1]], 'pay_data')
                  handleChange(t[0] === 'credit', 'first_pay')
                }}
                value={term}
                id="id"
                name="name"
              />
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col">
              <h4>Información Personal</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <input
                type="text"
                className="input-g2"
                value={client.name}
                placeholder="Nombre"
                onChange={(e) => handleChange(e.target.value, 'name')}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <input
                type="text"
                className="input-g2"
                value={client.lastname}
                placeholder="Apellido Paterno"
                onChange={(e) => handleChange(e.target.value, 'lastname')}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <input
                type="text"
                className="input-g2"
                value={client.second_lastname}
                placeholder="Apellido Materno"
                onChange={(e) =>
                  handleChange(e.target.value, 'second_lastname')
                }
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <input
                type="number"
                className="input-g2"
                value={client.phone}
                placeholder="Teléfono"
                maxLength="10"
                onChange={(e) => {
                  let phoneGotten = e.target.value
                  if (phoneGotten.length > 10) {
                    phoneGotten = phoneGotten.slice(0, 10 - phoneGotten.length)
                  }
                  handleChange(phoneGotten, 'phone')
                }}
              />
            </div>
          </div>
          <br />
          <br />

          <div className="row justify-content-end">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <button
                className="btn-2 b-primary"
                onClick={() => handleValidateCode()}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      )}

      {!isStopped && <LoadingComponent isStopped={isStopped} />}
    </>
  )
}

export default LeadValidationComponent
