// React
import React, { useState } from 'react'

// React router
import { useParams, useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import {
  updateCredit,
  generateCURP,
  createCredit,
  updateClientService,
} from '../../services/accounts.service'
import LoadingComponent from '../../components/Shared/Loading.component'
import { getRequestAPI } from '../../services/requests.service'
import { getCreditById } from '../../services/constants'
import Wizard from '../../components/Wizard'
import './index.css'
import RfcFacil from 'rfc-facil'

// Services
import msUsersService from '../../services/ms/ms-users-service'

// Defaults
import { clientDefault, creditDefault } from './defaults'

function AccountForm({ newLead }) {
  const [client, setClient] = useState(clientDefault)
  const [storedZip, setStoredZip] = useState()
  const [credit, setCredit] = useState(creditDefault)

  const [tabIndex, setTabIndex] = useState(1)
  const [isStopped, setIsStopped] = useState(false)

  const { id } = useParams()
  const history = useHistory()

  useState(() => {
    if (!newLead) {
      getRequestAPI(getCreditById(id)).then((response) => {
        const { data } = response
        const { client_info, kyc_prescoring_info: kycPrescoringInfo } = data
        const { address_reference } = client_info
        setClient({
          ...client_info,
          kycPrescoringInfo,
          lastname: client_info.last_name,
          second_lastname: client_info.mother_name,
          email: client_info.mail,
          reference_street: address_reference.street,
          reference_street_other: address_reference.street_other,
          additional_reference: address_reference.additional_reference,
          state_name: '',
          city: '',
          country: 'México',
          income: client_info.income || data.income,
          count_home: client_info.count_home || data.count_home,
          income_family: client_info.income_family || data.income_family,
          monthly_expenditure:
            client_info.monthly_expenditure || data.monthly_expenditure,
        })
        setStoredZip(client_info.zip)
        setCredit(data)
      })
    }
  }, [newLead])

  const handleClickButton = async (type) => {
    const value = tabIndex + type
    switch (value) {
      case 1:
      case 2:
        setTabIndex(value)
        break
      case 3:
      case 4:
        setTabIndex(value)
        break
      case 5:
        setIsStopped(true)

        const clientData = { ...client }
        if (
          clientData.personal_references.length === 2 &&
          !clientData.personal_references[1].name
        ) {
          clientData.personal_references.pop()
        }

        if (newLead) {
          // creating user at MS user
          let shopper
          if (!client.id) {
            try {
              shopper = await msUsersService.createShopper(clientData)
              setClient({ ...clientData, id: shopper.id })
            } catch (error) {
              setIsStopped(false)
              return
            }
          } else {
            shopper = client
          }
          try {
            const response = await createCredit(shopper.id, credit)
            toast.success('Candidato creado correctamente !', {
              position: toast.POSITION.TOP_RIGHT,
            })
            const path = `cuentas/detalles/${response.data.id}/`
            window.location.pathname = path
          } catch (err) {
            setIsStopped(false)
          }
        } else {
          try {
            // trying updating client at Users MS
            await msUsersService.updateShopper(client.id, clientData)
          } catch (err) {
            if (err.response.status === 404) {
              // trying to update client by aq_id
              const msUserData = await msUsersService.getShopperByAqId(
                client.aq_id
              )
              await msUsersService.updateShopper(msUserData.id, clientData)
            } else {
              setIsStopped(false)
              return
            }
          }
          // updating credit at AQ
          const creditData = {}
          for (const key of Object.keys(creditDefault)) {
            creditData[key] = credit[key]
          }
          creditData.created_by = ''
          const response = await updateCredit(creditData)
          if (response.status === 202) {
            const path = `cuentas/detalles/${credit.id}/`
            window.location.pathname = path
          }
          setIsStopped(false)
        }
        break
      default:
        break
    }
  }

  const handleChangeInput = (value, input) => {
    if (input in client) {
      setClient((s) => ({ ...s, [input]: value }))
    } else {
      setCredit((s) => ({ ...s, [input]: value }))
    }
  }

  const handleGenerateRFC = () => {
    const date = client.birthday.split('-')

    const rfc = RfcFacil.forNaturalPerson({
      name: client.name,
      firstLastName: client.lastname,
      secondLastName: client.second_lastname,
      day: date[2],
      month: date[1],
      year: date[0],
    })

    setClient({ ...client, rfc })
  }

  const handleGenerateCURP = () => {
    if (
      client.birthday !== '' &&
      client.entity_birth !== '' &&
      client.gender !== ''
    ) {
      generateCURP(
        client.birthday,
        client.entity_birth,
        client.second_lastname,
        client.name,
        client.lastname,
        client.gender
      ).then((curp) => {
        setClient({ ...client, curp })
      })
    }
  }

  const handleGoBack = () => {
    history.push('/cuentas')
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <h1>{newLead ? 'NUEVO CANDIDATO' : 'EDITAR INFORMACIÓN'}</h1>
        </div>
      </div>
      <br />
      <div className="box-white">
        {isStopped ? (
          <LoadingComponent isStopped={isStopped} />
        ) : (
          <Wizard
            newLead={newLead}
            tabIndex={tabIndex}
            isStopped={isStopped}
            onChangeInput={handleChangeInput}
            onGenerateRFC={handleGenerateRFC}
            onGenerateCURP={handleGenerateCURP}
            onClickButton={handleClickButton}
            onGoBack={handleGoBack}
            client={client}
            storedZip={storedZip}
            credit={credit}
          />
        )}
      </div>
    </div>
  )
}

export default AccountForm
