import React, { Component} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NavBar from 'react-bootstrap/Navbar'

import GeneralInformationComponent from '../../components/Accounts/SectionsDetailsAccount/SectionGeneral/index'
import FinancialInformationComponent from '../../components/Accounts/SectionsDetailsAccount/financial.component'
import CreditsComponent from '../../components/Accounts/SectionsDetailsAccount/SectionCredits'
import ProductInformationComponent from '../../components/Accounts/SectionsDetailsAccount/product.component'
import ContractInformationComponent from '../../components/Accounts/SectionsDetailsAccount/contract.component'
import InvoicesProductComponent from '../../components/Accounts/SectionsDetailsAccount/invoicesProduct.component'
import PaymentsInformationComponent from '../../components/Accounts/SectionsDetailsAccount/payments.component'
import SpecificationsComponent from '../../components/Accounts/SectionsDetailsAccount/SectionSpecifications'
import ModalConfirmInstallationDate from '../../components/ModalConfirmInstallationDate'
import DocumentsProgressBar from '../DocumentsProgressBar'

import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai'
import { Nav } from 'react-bootstrap'

import DocumentPhoto from '../../components/Accounts/SectionsDetailsAccount/SectionGeneral/photo'

import ModalComponent from '../../components/Shared/Modal.component'

import { toast } from 'react-toastify'
// Services
import {
  postRequestAPI,
  patchRequestAPI
} from '../../services/requests.service'
import {
  unfinishedCredit,
  finishedCredit,
  resetPassword,
  changeCreditStatus
} from '../../services/constants'
import {
  approveCredit,
  updateCreditStatus,
} from '../../services/accounts.service'
import {
  getCreditDetails,
  getKycData,
} from '../../services/credits.service'
import shopper from '../../services/ms/ms-users-service'
import { Icon } from '@mui/material'

// Rejectable credit status list
const REJECTABLE_STATUS = ['EVA', 'APR', 'EVAT', 'TOS', 'ATI']

export default class AccountDetailsComponent extends Component {
    constructor (props) {
        super(props)
        
        this.state = {
          id: this.props.match.params.id,
          collapseNumber: 1,
          iconPos: true,
          creditsInfo: [],
          credits:[],
          isStopped: true,
          credit_status: null,
          comments: null,
          password: '',
          rol: localStorage.getItem("user_type"), // eslint-disable-line
          loading: {
            documents: false
          },
          currentCredit: null,
          isOpenModalConfirmInstallationDates: false,
          UserData: [],
          clientInfo: null,
          kycData: [],
        }

        this.createNewPassword = this.createNewPassword.bind(this)
        this.getCreditDetail = this.getCreditDetail.bind(this)
        this.handleApproved = this.handleApproved.bind(this)
        this.handleUnfinished = this.handleUnfinished.bind(this)
        this.handleCloseModalConfirmInstallationDates =
          this.handleCloseModalConfirmInstallationDates.bind(this)
        this.handleOpenModalConfirmInstallationDates =
          this.handleOpenModalConfirmInstallationDates.bind(this)
        this.handleUpdatePartialCredit = this.handleUpdatePartialCredit.bind(this)
      }

      componentDidMount() {
        this.createNewPassword()
        this.getCreditDetail()
      }

      componentDidUpdate(prevProps, prevState){
        if(this.state.credits !== prevState.credits){
          this.setState({client_info: this.state?.credits?.client_info})
        }
        if(this.state.creditsInfo !== prevState.creditsInfo && this.state.creditsInfo.length != '0'){
          this.setState({clientInfo: this.state.creditsInfo?.[0]?.client_info})
        }
      }

      handleSelect=(eventKey)=>{
        this.state.creditsInfo.forEach(credit => {
            if (credit.id === eventKey) {
                this.setState({ credits: credit, isStopped: false })
            }
        })
      }
      
      getUserData(){
        shopper.getShopperId(this.state.id).then((data) => {
          this.setState({ UserData:data })
        })
      }
      // Probar

      handleCloseModalConfirmInstallationDates () {
        this.setState({
          isOpenModalConfirmInstallationDates: false,
          dates_proposed: null,
          status_dates_proposed: null,
          currentCredit: null,
        })
      }
    
      handleOpenModalConfirmInstallationDates (event, data) {
        event.stopPropagation()
        this.setState({
          isOpenModalConfirmInstallationDates: true,
          currentCredit: data,
        })
      }
    
      handleUpdatePartialCredit (data, id) {
        this.setState({ loading: { documents: true } })
        updateCreditStatus(data, id).then((data) => {
          this.setState({ currentCredit: data, loading: { documents: false } })
          window.location.reload()
        })
      }

      handleFinished (status) {
        this.setState({ credit_status: status }, () => {
          patchRequestAPI(
            finishedCredit(this.state.creditInfo.id),
            this.state
          ).then((response) => {
            if (response.status === 200) {
              window.location.reload()
            }
          })
        })
      }

      handleApproved (status) {
        this.setState({ credit_status: status }, () => {
          approveCredit(this.state).then((r) => {
            if (r.status === 200) {
              window.location.reload()
            }
          })
        })
      }
    
      handleUnfinished (status) {
        this.setState({ credit_status: status }, () => {
          patchRequestAPI(
            unfinishedCredit(this.state.creditInfo.id),
            this.state
          ).then((response) => {
            if (response.status === 200) {
              window.location.reload()
            }
          })
        })
      }

      // canPenalizeCredit () {
      //   return (
      //     ['GR', 'FN'].includes(this.state.rol) &&
      //     this.state.creditsInfo?.[0]?.credit_status === 'INS'
      //   )
      // }
      
      //


      getCreditDetail () {
        getCreditDetails(this.state.id).then( (response) => {     
            if(response.data.length == '0' || response.status == 404){
              this.getUserData()
            } else{  
              this.setState({ creditsInfo: response.data, credits: response.data[0], isStopped: false })
              if(response?.data?.[0]?.kyc_prescoring_id != "" && response?.data?.[0]?.kyc_prescoring_id != null){
                getKycData(response.data[0].kyc_prescoring_id).then( (response) => {
                  if(response.data.length != '0' && response.status != 404){
                    this.setState({ kycData: response.data })
                  }
                })
              }
            }
        })
      }
    
      setCreditStatus (status) {
        this.setState({ credit_status: status }, () => {
          patchRequestAPI(changeCreditStatus(this.state.creditInfo.id), {
            credit_status: status,
            comments: this.state.comments || ''
          }).then((response) => {
            if (response.status === 200) {
              window.location.reload()
            }
          })
        })
      }
    
      createNewPassword () {
        // Generate a random password with a long number ensuring at least 8 digits
        const newPasswordNumber = Math.floor(Math.random() * 160000000000)
        const newPassword = newPasswordNumber.toString(16)
        this.setState({ password: newPassword })
      }
    
      handleClickCollapse (item) {
        if(item==this.state.collapseNumber && this.state.iconPos){
          this.setState({iconPos: false})
        }else if(item==this.state.collapseNumber && !this.state.iconPos){
          this.setState({iconPos: true})
        }else{
          this.setState({ collapseNumber: item, iconPos:true })
        }
      }
    
    handleResetPassword (clientId) {
        const { password, enteredPassword } = this.state
        const newPassword = enteredPassword || password
        postRequestAPI(resetPassword(clientId), { "password":newPassword, "password_confirmation":newPassword })
          .then(() => {
            window.location.reload()
          })
          .catch((e) => console.log(e))
    }

    _getCreditDetails () {
      // Obtener detalle de los creditos del cliente
      const { client_info } = this.state?.creditsInfo?.[0]?.clientId
      getCreditDetails(client_info.id).then((response) => {
        this.setState({ creditsInfo: response.data, isStopped: false })
      })
    }

    render () {
        const { rol, loading, currentCredit, isOpenModalConfirmInstallationDates, clientInfo, 
          creditsInfo, collapseNumber, iconPos, UserData, credits, kycData } =
          this.state
          return (
            <div className='container-fluid'>
              <div className='box-table' id='accordionExample'>
                
                <div
                className={
                    collapseNumber === 1
                    ? 'bg accordion-collapse'
                    : 'bg accordion-collapse-default'
                }
                onClick={() => this.handleClickCollapse(1)}
                data-toggle='collapse'
                data-target='#generalInformation'
                aria-expanded='true'
                aria-controls='generalInformation'
                id='headingOne'
                >
                  {collapseNumber==1 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>} 
                  INFORMACIÓN DE LA CUENTA
                </div>
                <div
                className='collapse show'
                id='generalInformation'
                aria-labelledby='headingOne'
                data-parent='#accordionExample'
                >
                  <h3>
                    INFORMACIÓN DE LA CUENTA{' '}
                    <span
                        hidden={['FN', 'DS', 'CS'].includes(rol)}
                        style={{
                            fontSize: '15px',
                            justifyContent: 'space-around',
                            alignItems: 'center'
                        }}
                    />
                    {rol === 'GR' && (
                        <ModalComponent
                        like='link'
                        text='Cambiar Contraseña'
                        onClick={() =>
                            this.handleResetPassword(clientInfo?.id)}
                        comments={(e) => this.setState({ enteredPassword: e })}
                        h1='Restablecer contraseña'
                        sub={`Puedes escribir una contraseña o copiar la generada: ${this.state.password}`}
                        button='Restablecer'
                        textArea
                        />
                    )}
                  </h3>
                  <pre>{creditsInfo?.length != '0' 
                    ? clientInfo?.full_name.toUpperCase()
                    : `${UserData?.name || ''} ${UserData?.lastname || ''}`.toUpperCase()
                  }
                  
                  </pre>
                  <Container fluid>
                    <Row  xs={2} md={4} lg={4}>
                      {(credits?.client_info?.last_name || clientInfo?.ine) && 
                        (<>
                          {(kycData?.identification_front != "" && kycData?.identification_front != null) &&
                          <Col>
                            <DocumentPhoto
                              title='Identificación Oficial Front'
                              url={kycData?.identification_front}
                            />
                          </Col>}
                          {(kycData?.identification_back != "" && kycData?.identification_back != null) &&
                          <Col>
                            <DocumentPhoto
                              title='Identificación Oficial Back'
                              url={kycData?.identification_back}
                            />
                          </Col>}
                          {(kycData?.selfie != "" && kycData?.selfie != null) &&
                          <Col>
                            <DocumentPhoto
                              title='selfie'
                              url={kycData?.selfie}
                            />
                          </Col>}
                        </>                    
                        )
                      }
                    </Row>
                  </Container>
                  <br/>
                  <h3> GENERAL</h3>
                  <div>
                    <GeneralInformationComponent
                      id={this.state.id}
                      creditsInfo={creditsInfo}
                      rol={rol}
                      handleUpdate={this.getCreditDetail}
                      loading={loading.documents}
                      credit={currentCredit}
                      isOpen={isOpenModalConfirmInstallationDates}
                      onClose={this.handleCloseModalConfirmInstallationDates}
                      handleOpenModalConfirmInstallationDates={
                        this.handleOpenModalConfirmInstallationDates
                      }
                      onUpdatePartialCredit={this.handleUpdatePartialCredit}
                    />
                  </div>
                  <ModalConfirmInstallationDate
                    rol={rol}
                    loading={loading.documents}
                    credit={currentCredit}
                    isOpen={isOpenModalConfirmInstallationDates}
                    onClose={this.handleCloseModalConfirmInstallationDates}
                    onOpenModalDetails={
                      this.handleOpenModalConfirmInstallationDates
                    }
                    onUpdatePartialCredit={this.handleUpdatePartialCredit}
                  />
                </div>
                {/* Show Credits */}
                {creditsInfo.length != '1' && (
                  <NavBar fixed="bottom" style={{padding:0, marginLeft:110}}>
                    <Container justify style={{padding:0, marginLeft:1}}>
                      <Nav variant='tabs' className="me-auto" onSelect={this.handleSelect}>
                        {creditsInfo?.map(item => (
                            <Nav.Item key={item?.id}>
                                <Nav.Link eventKey={item?.id} className='bg-secondary-tabs-graviti' active={item?.id==this.state.credits?.id}>
                                  <div >
                                    {item?.product_info?.model}
                                  </div>
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                      </Nav>
                    </Container>
                  </NavBar>
                )}
                <Row>
                  <Col>
                    {['DS','GR','FN'].includes(rol) && (
                      <>
                        <div
                          className={
                            collapseNumber === 3
                              ? 'accordion-collapse'
                              : 'accordion-collapse-default'
                          }
                          onClick={() => this.handleClickCollapse(3)}
                          data-toggle='collapse'
                          data-target='#creditsInformation'
                          aria-expanded='true'
                          aria-controls='creditsInformation'
                          id='headingSeven'
                        >
                          {collapseNumber==3 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>}
                          CRÉDITOS
                        </div>
                        <div
                          className='collapse'
                          id='creditsInformation'
                          aria-labelledby='headingSeven'
                          data-parent='#accordionExample'
                        >
                          <CreditsComponent
                            data={creditsInfo.length != '0' ? [credits] : UserData}
                            updateLocalCredit={this._getCreditDetails}
                            history={this.props.history}
                          />
                        </div>
                      </>
                    )}
                    {(creditsInfo.length != '0') && (
                      <>
                      {credits?.client_info ? rol !== 'DS' && (
                        <>
                          <div
                            className={
                              collapseNumber === 2
                                ? 'accordion-collapse'
                                : 'accordion-collapse-default'
                            }
                            onClick={() => this.handleClickCollapse(2)}
                            data-toggle='collapse'
                            data-target='#financialInformation'
                            aria-expanded='true'
                            aria-controls='financialInformation'
                            id='headingTwo'
                          >
                            {collapseNumber==2 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>}
                            FINANCIAMIENTO
                          </div>
                          <div
                            className='collapse'
                            id='financialInformation'
                            aria-labelledby='headingTwo'
                            data-parent='#accordionExample'
                            
                          >
                            <FinancialInformationComponent
                              creditInfo={credits}
                            />
                          </div>
                        </>
                      ): ''}
                      {credits?.client_info ? (<>
                        <div
                          className={
                            collapseNumber === 4
                              ? 'accordion-collapse'
                              : 'accordion-collapse-default'
                          }
                          onClick={() => this.handleClickCollapse(4)}
                          data-toggle='collapse'
                          data-target='#productInformation'
                          aria-expanded='true'
                          aria-controls='productInformation'
                          id='headingThree'
                        >
                          {collapseNumber==4 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>}
                          PRODUCTO
                        </div>
                        <div
                          className='collapse'
                          id='productInformation'
                          aria-labelledby='headingThree'
                          data-parent='#accordionExample'
                        >
                        <ProductInformationComponent
                          creditInfo={credits}
                        />
                        </div>
                      </>) : ''
                      }
                      {((rol === 'DS' && credits?.contrato !== null)||
                        (rol === 'FN' && credits?.contrato === null))
                        && (
                          <>
                            <div
                              className={
                                collapseNumber === 5
                                  ? 'accordion-collapse'
                                  : 'accordion-collapse-default'
                              }
                              data-toggle='collapse'
                              data-target='#contractInformation'
                              onClick={() => this.handleClickCollapse(5)}
                              aria-expanded='true'
                              aria-controls='contractInformation'
                              id='headingFour'
                            >
                              {collapseNumber==5 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>}
                              CONTRATO
                            </div>
                            <div
                              className='collapse'
                              id='contractInformation'
                              aria-labelledby='headingFour'
                              data-parent='#accordionExample'
                            >
                              <br />
                                <ContractInformationComponent
                                  creditInfo={credits}
                                  rol={rol}
                                />
                              <br />
                            </div>
                          </>
                      )}
                      {credits?.xml === null &&
                        credits?.pdf === null &&
                        localStorage.getItem("user_type") === "DS" && // eslint-disable-line
                        credits?.credit_status === 'INS' && (
                          <>
                            <div
                              className={
                                collapseNumber === 6
                                  ? 'accordion-collapse'
                                  : 'accordion-collapse-default'
                              }
                              onClick={() => this.handleClickCollapse(6)}
                              data-toggle='collapse'
                              data-target='#invoicesProduct'
                              aria-expanded='true'
                              aria-controls='contractInformation'
                              id='headingSix'
                            >
                              {collapseNumber==6 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>}
                              FACTURA
                            </div>
                            <div
                              className='collapse'
                              id='invoicesProduct'
                              aria-labelledby='headingSix'
                              data-parent='#accordionExample'
                            >
                              <InvoicesProductComponent
                                creditInfo={credits}
                                rol={rol}
                              />
                            </div>
                          </>
                      )}
                      {credits?.client_info ? localStorage.getItem("user_type") !== "DS" && ( // eslint-disable-line
                        <>
                          <div
                            className={
                              collapseNumber === 7
                                ? 'accordion-collapse'
                                : 'accordion-collapse-default'
                            }
                            onClick={() => this.handleClickCollapse(7)}
                            data-toggle='collapse'
                            data-target='#paysInformation'
                            aria-expanded='true'
                            aria-controls='contractInformation'
                            id='headingFives'
                          >
                            {collapseNumber==7 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>}
                            PAGOS
                          </div>
                          <div
                            className='collapse'
                            id='paysInformation'
                            aria-labelledby='headingFive'
                            data-parent='#accordionExample'
                          >
                            <PaymentsInformationComponent
                              creditInfo={credits}
                            />
                          </div>
                        </>
                      ): ''}
                      {credits?.client_info ? localStorage.getItem("user_type") !== "FN" && ( // eslint-disable-line
                        <>
                          <div
                            className={
                              collapseNumber === 8
                                ? 'accordion-collapse'
                                : 'accordion-collapse-default'
                            }
                            onClick={() => this.handleClickCollapse(8)}
                            data-toggle='collapse'
                            data-target='#specificationsInformation'
                            aria-expanded='true'
                            aria-controls='contractInformation'
                            id='headingSix'
                          >
                            {collapseNumber==8 && iconPos ? <AiFillCaretRight/> : <AiFillCaretDown/>}
                            INFORMACIÓN DE INSTALACIÓN
                          </div>
                          <div
                            className='collapse'
                            id='specificationsInformation'
                            aria-labelledby='headingSix'
                            data-parent='#accordionExample'
                          >
                            <SpecificationsComponent
                              creditInfo={credits}
                              rol={rol}
                            />
                          </div>
                        </>
                      ) : ''}
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
        )
    }
}