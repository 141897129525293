// React
import React from 'react'
import { FormGroup, Form } from 'react-bootstrap'

function InputFragment({ fragment, onChange }) {
  return (
    <div className="input-group">
      <Form.Control
        as={fragment.options ? 'select' : 'input'}
        type={fragment.type || 'text'}
        name={fragment.title}
        id={fragment.title}
        placeholder={fragment.placeholder}
        value={fragment.value}
        onChange={onChange}
        required
        pattern={fragment.regex}
        title={fragment.regex_feedback || fragment.placeholder}
        min="0"
        step="any"
      >
        {fragment.options && (
          <>
            <option value="">Selecciona una opción</option>
            {fragment.options.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </>
        )}
      </Form.Control>
      {fragment.suffix && (
        <div className="input-group-append">
          <div className="input-group-text">{fragment.suffix}</div>
        </div>
      )}
    </div>
  )
}

function SpecificationsForm({ fragments, onChange }) {
  if (!fragments || !fragments.length) return null

  const updateFragment = (fragments, fragment) =>
    fragments.map((f) => (f.title === fragment.title ? fragment : f))

  const buildTemplate = (template, fragments) =>
    template
      .match(/\{\{[a-zA-Z]+\}\}/g)
      ?.reduce(
        (acum, current) =>
          acum.replace(
            current,
            fragments.find((f) => f.title === current.replace(/\{|\}/g, ''))
              .value
          ),
        template
      )

  return fragments
    .filter((fragment) => !fragment.value || !fragment.hidden)
    .map((fragment) => (
      <div className="col-md-6" key={fragment.title}>
        <FormGroup>
          <Form.Label htmlFor={fragment.title}>{fragment.title}</Form.Label>
          {fragment.subfragments ? (
            <div className="row">
              {fragment.subfragments.map((subfragment) => (
                <div className="col" key={fragment.title}>
                  <InputFragment
                    fragment={subfragment}
                    onChange={(e) => {
                      const subfragments = updateFragment(
                        fragment.subfragments,
                        {
                          ...subfragment,
                          value: e.target.value,
                        }
                      )
                      return onChange(
                        updateFragment(fragments, {
                          ...fragment,
                          subfragments,
                          value: buildTemplate(fragment.template, subfragments),
                        })
                      )
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <InputFragment
              fragment={fragment}
              onChange={(e) =>
                onChange(
                  updateFragment(fragments, {
                    ...fragment,
                    value: e.target.value,
                  })
                )
              }
            />
          )}
        </FormGroup>
      </div>
    ))
}

export default SpecificationsForm
