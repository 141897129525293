import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import * as CurrencyFormat from 'react-currency-format'
import { useAuth } from './auth'
import currency from './assets/icons/currency.svg'
import MenuMainComponent from './components/Shared/MenuMain'
import close from './assets/icons/close.svg'
import menu from './assets/icons/menu.svg'

const handleLogout = () => {
  localStorage.clear(); // eslint-disable-line
  window.location.reload()
}

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useAuth()

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <>
            <div id="sidebar">
              <img
                className="close-menu"
                src={close}
                alt="Close Menu"
                width="25"
              />
              <br />
              <MenuMainComponent />
            </div>
            <div className="header">
              <div className="container-fluid">
                <div className="row">
                  {localStorage.getItem("user_type") === "GR" && ( // eslint-disable-line
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12" />
                  )}

                  {localStorage.getItem("user_type") === "FN" && ( // eslint-disable-line
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="balance-box">
                        <div className="row">
                          <img
                            className="open-menu"
                            src={menu}
                            alt="Abrir Menu"
                            width="25"
                          />
                          <div className="col-1">
                            <img
                              className="icon-currency"
                              src={currency}
                              alt="moneda"
                            />
                          </div>
                          <div className="col">
                            {' '}
                            <p className="balance">
                              Saldo por pagar:{' '}
                              <strong>
                                <CurrencyFormat
                                    value={localStorage.getItem( // eslint-disable-line
                                      'amount_deposit'
                                    ) || 0
                                  }
                                  displayType="text"
                                  thousandSeparator
                                  prefix="$ "
                                />
                              </strong>{' '}
                              <br />
                              Saldo por recibir:{' '}
                              <strong>
                                <CurrencyFormat
                                    value={localStorage.getItem( // eslint-disable-line
                                      'amount_pendient'
                                    ) || 0
                                  }
                                  displayType="text"
                                  thousandSeparator
                                  prefix="$ "
                                />
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {localStorage.getItem("user_type") === "DS" && ( // eslint-disable-line
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="balance-box">
                        <div className="row">
                          <img
                            className="open-menu"
                            src={menu}
                            alt="Abrir Menu"
                            width="25"
                          />
                          <div className="col-1">
                            <img
                              className="icon-currency"
                              src={currency}
                              alt="moneda"
                            />
                          </div>
                          <div className="col">
                            <p className="balance">
                              Saldo por depositar:{' '}
                              <strong>
                                <CurrencyFormat
                                  decimalScale={2}
                                    value={localStorage.getItem( // eslint-disable-line
                                      'amount_pendient'
                                    ) || 0
                                  }
                                  displayType="text"
                                  thousandSeparator
                                  prefix="$ "
                                />
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-right">
                    <p className="infoUser">
                      <a href="/perfil" className="user-info-link">
                        <strong>{localStorage.getItem('name')} </strong>{' '} {/* eslint-disable-line */}
                      </a>
                      |{' '}
                      <span
                        onClick={(e) => handleLogout()}
                        style={{ cursor: 'pointer' }}
                      >
                        Cerrar Sesión
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="main">
              <Component {...props} />
            </div>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default PrivateRoute
