import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'

const VerificacionInformacion = ({
  handleSubmitMerchant,
  onChangeMerchant,
  value
}) => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])
  return (
    <Container fluid
      className={
        width < 672
          ? 'container-fluid qr-code-containers-movil-spinner'
          : 'container-fluid qr-code-containers'
      }
    >
      <Row className='text-center'>
        <Col>
          <div
            style={{
              display: 'block',
              width: '100%',
              padding: 30
            }}
          >
            <h4>Verificando información</h4>
            <h6>
              Espera a que se cargue la información, <br />
              por favor no cierres esta ventana
            </h6>
            <Spinner
              style={{ width: '9rem', height: '9rem', marginTop: '5%' }}
              children={false}
              color='#132c51'
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default VerificacionInformacion
