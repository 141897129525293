// React
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingComponent from '../../Shared/Loading.component'
import PromoForm from '../PromoForm'
import PromoProductSelector from '../PromoProductSelector'

// Services
import msProductsService from '../../../services/ms/ms-products-service'

const promoDefault = {
  name: '',
  description: '',
  percentage: '',
  graviti_extra_percentage: 0,
  active: true,
  start_date: '',
  expiration_date: '',
}

/**
 * Promo detail component
 */
function PromoUpdateComponent({ match, history }) {
  /**
   * promo to edit
   */
  const [promo, setPromo] = useState()

  /**
   * Basic promo data to edit on `PromoForm`
   */
  const [basicInputData, setBasicInputData] = useState(promoDefault)

  /**
   * A list of `DistributorProduct` id to be assosiated with the promo
   */
  const [selectedProducts, setSelectedProducts] = useState([])

  /**
   * Flag: blocks the submit button while data is uploading
   */
  const [isSubmitting, setIsSubmitting] = useState(false)

  /**
   * promo id from route params
   */
  const { id } = match.params

  /**
   * Retrieves the promo on page loaded using the given id
   */
  useEffect(() => {
    id &&
      msProductsService.getPromo(id).then((promo) => {
        setPromo(promo)
        const promoData = {}
        for (const key of Object.keys(promoDefault)) {
          promoData[key] = promo[key]
        }
        setBasicInputData(promoData)
        setSelectedProducts(promo.product_branch)
      })
  }, [id])

  /**
   * Sends promo data to update it in database
   * @param {SubmitEvent} e the form submit event
   */
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const data = { ...basicInputData, products: selectedProducts }
    try {
      if (promo) await msProductsService.updatePromo(promo.id, data)
      else await msProductsService.createPromo(data)
      toast.success('Promoción guardado correctamente', {
        position: toast.POSITION.TOP_RIGHT,
      })
      history.push('/promociones/')
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row mb-5">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h1>{id ? 'EDITAR PROMOCION' : 'CREAR PROMOCION'}</h1>
        </div>
      </div>
      <div className="box-table">
        {id && !promo ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="row">
              <div className="col-md">
                <h2 className="h4 text-uppercase">Detalles de la promoción</h2>

                <PromoForm data={basicInputData} onChange={setBasicInputData} />
              </div>
              <div className="col-md">
                <h2 className="h4 text-uppercase">Productos asociados</h2>

                <PromoProductSelector
                  selectedItems={selectedProducts}
                  onChange={setSelectedProducts}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end mt-5">
              <button
                className="btn btn-lg b-primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Guardar
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PromoUpdateComponent
