import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import './css/modal.css'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const months = [
  'ENE',
  'FEB',
  'MAR',
  'ABR',
  'MAY',
  'JUN',
  'JUL',
  'AGO',
  'SEP',
  'OCT',
  'NOV',
  'DIC',
]

export default class InvoicesComponent extends Component {
  render() {
    return (
      <Popup
        trigger={
          <img src={this.props.icon} alt="pago" style={{ cursor: 'pointer' }} />
        }
        modal
        closeOnDocumentClick
      >
        {(close) => (
          <>
            {/* <a className="close" onClick={close}> &times; </a> */}
            <div className="container" style={{ padding: '40px' }}>
              <div className="row">
                <div className="col text-center">
                  <h1>Facturas</h1>
                  <br />
                </div>
              </div>

              <Tabs defaultActiveKey="2019" id="uncontrolled-tab-example">
                <Tab eventKey="2019" title="2019">
                  <div className="row">
                    <div className="col">
                      {months.map((item, i) => (
                        <div key={i} className="box-month">
                          <span>{item}</span>
                          <br />
                          <span className="link-invoices">PDF</span>
                          <span className="link-invoices">XML</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </>
        )}
      </Popup>
    )
  }
}
