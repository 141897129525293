import React, { Component } from 'react'
import moment from 'moment'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getPayments } from '../../../services/payments.service'

const headersPayments = [
  'ID',
  'Transaccionado',
  'Comisión Graviti',
  'Depositado',
  'Concepto',
  'Fecha de pago',
  'Fecha de expiración',
]

export default class PaymentsInformationComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.creditInfo.id,
      payments: [],
      creditInfo: this.props.creditInfo,
    }

    getPayments(this.state.id).then((response) => {
      this.setState({ payments: response })
    })
  }

  render() {
    const {creditInfo} = this.props
    return (
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <table className="table table-borderless table-sm">
              <thead />
              <tbody>
                <tr>
                  <td className="headTable">Total:</td>
                  <td>
                    ${' '}
                    {creditInfo.credit_initial_amount.toLocaleString()}{' '}
                    MXN
                  </td>
                </tr>
                <tr>
                  <td className="headTable">Pagado: </td>
                  <td>
                    ${' '}
                    {creditInfo.credit_pay_information.total_payed.toLocaleString()}{' '}
                    MXN
                  </td>
                </tr>
                <tr>
                  <td className="headTable">Por pagar: </td>
                  <td>
                    ${' '}
                    {creditInfo.credit_pay_information.remaining.toLocaleString()}{' '}
                    MXN
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            {/* <ChartComponent
                            columns={[["Progreso", creditInfo.credit_pay_information.payment_percentage]]}
                            type="gauge"
                            color={{
                                pattern: ["#60B044", "#60B044", "#60B044", "#60B044"],
                                threshold: {
                                values: [30, 60, 90, 100]
                                }
                            }}
                            legend = {{
                                show: false
                              }}
                            /> */}
            <br />
            <Row>
              <Col>
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{
                      width: `${creditInfo.credit_pay_information.payment_percentage}%`,
                    }}
                    aria-valuenow={
                      creditInfo.credit_pay_information.months_payed
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <p>{`${creditInfo.credit_pay_information.months_payed} de ${creditInfo.pay_data_info.weeks} pagos`}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {this.state.payments.length != '0' && (
            <table className="table table-hover table-graviti">
              <thead>
                <tr>
                  {headersPayments.map((item, i) => (
                    <th key={i}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.payments.map((item, i) => {

                  const humanSaleDate = item ? moment(item?.sale_date).format('DD/MM/YYYY HH:MM') : ''
                  const humanExpiringDate = item ? moment(item?.expiring_date).format('DD/MM/YYYY HH:MM') : ''
                  return (
                    <tr key={i}>
                      <td className="textPush">{i + 1}</td>
                      <td className="textPush">
                        $ {item.transacted.toLocaleString()} MXN
                      </td>
                      <td className="textPush">
                        $ {item.commision.toLocaleString()} MXN
                      </td>
                      <td className="textPush">
                        $ {item.payed.toLocaleString()} MXN
                      </td>
                      <td className="textPush">
                        {item.initial_pay === true ? 'Pago inicial' : item.packages}
                      </td>
                      <td className="textPush">{humanSaleDate}</td>
                      <td className="textPush">{humanExpiringDate}</td>
                    </tr>
                  )}
                )}
              </tbody>
            </table>
          )}
        </Row>
      </Container>
    )
  }
}
