// React
import debounce from 'lodash.debounce'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Swal from 'sweetalert2'

// Services
import msProductsService from '../../../services/ms/ms-products-service'

// Styles
import './style.css'

/**
 * Display a list of `DistributorProduct` with a checkbox
 * @param {list<uuid>} selectedItems a list of `DistributorProduct` ids
 * @param {function} onChange onchange event handler
 */
function PromoProductSelector({ selectedItems, onChange }) {
  /**
   * The role of the logged user, retrieved from localStorage
   */
  const role = localStorage.getItem('user_type')

  /**
   * Whether to show a spinner in the product list
   */
  const [loading, setLoading] = useState(false)

  /**
   * The list of available `DistributorProduct` to select
   */
  const [products, setProducts] = useState([])

  /**
   * Selected category to filter the product list
   */
  const [selectedCategory, setSelectedCategory] = useState()

  /**
   * The list of categories for the category filter. Only for
   * GR users
   */
  const [categories, setCategories] = useState([])

  /**
   * Query to filter products by model
   */
  const [query, setQuery] = useState('')

  /**
   * Debounced function that query the list of products using `selectedCategory`
   * and `query` as filters
   */
  const searchProducts = useCallback(
    debounce((selectedCategory, query) => {
      setLoading(true)
      msProductsService
        .getProducts(1, 100, query, selectedCategory)
        .then((response) => {
          setProducts(response.data.results)
          setLoading(false)
        })
    }, 500),
    []
  )

  useEffect(() => {
    msProductsService
      .getSubcategories()
      .then((categories) =>
        setCategories(categories.sort((a, b) => a.name.localeCompare(b.name)))
      )
  }, [])

  /**
   * Retrieves all `DistributorProduct` assosiated with the logged user and
   * stores them in `products` state. If role is DS, then requests all products,
   * otherwise requests only first 50 products and loads the rest lazily
   */
  useEffect(() => {
    searchProducts(selectedCategory, query)
  }, [searchProducts, query, selectedCategory])

  /**
   * A map of categories to filter the product list. If role is GR, then shows
   * all product types in select choices. Otherwise, it shows only categories
   * from the product list
   */
  const categoriesMap = useMemo(() => {
    const categoriesMap = {}
    for (const category of categories) {
      categoriesMap[category.id] = category.name
    }
    return categoriesMap
  }, [products, categories])

  /**
   * Removes or adds a product id to the `selectedItems` list according to
   * `e.target.value` value
   * @param {InputEvent} e the onchange event from product checkbox
   * @param {DistributorProduct} product the product to be added or removed
   */
  const handleItemCheckboxChange = (e, product) => {
    if (e.target.checked) {
      onChange([...selectedItems, product.id])
    } else {
      onChange(selectedItems.filter((p) => p !== product.id))
    }
  }

  /**
   * Adds all `filteredProducts` ids to `selectedItems`
   */
  const selectAllFilteredProducts = () => {
    const newSelectedItems = [...selectedItems]
    for (const product of products) {
      if (!selectedItems.includes(product.id)) {
        newSelectedItems.push(product.id)
      }
    }
    onChange(newSelectedItems)
  }

  /**
   * Removes all `filteredProducts` ids from `selectedItems`
   */
  const unselectAllFilteredProducts = () => {
    const toRemoveList = products.map((p) => p.id)
    const newSelectedItems = selectedItems.filter(
      (item) => !toRemoveList.includes(item)
    )
    onChange(newSelectedItems)
  }

  /**
   * Adds all products related to the logged user to `selectedItems`
   */
  const selectAllProducts = () => {
    Swal.fire({
      title: 'Agregar todos los productos',
      text:
        'Esta acción agrega todos los productos activos y validados al ' +
        'descuento actual. ¿Quieres continuar?',
      icon: 'warning',
      showLoaderOnConfirm: true,
      preConfirm: msProductsService.getAllAvailableProductBranchForPromo,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        onChange(result.value)
      }
    })
  }

  /**
   * Removes all products related to the promo
   */
  const unselectAllProducts = () => {
    Swal.fire({
      title: 'Remover todos los productos',
      text:
        'Esta acción deselecciona todos los productos asociados al' +
        'descuento actual. ¿Quieres continuar?',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        onChange([])
      }
    })
  }

  return (
    <>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="query"
          name="query"
          placeholder="Buscar por nombre..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <select
        className="form-control mb-3"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="">Filtrar por categoría...</option>
        {Object.entries(categoriesMap).map((category) => (
          <option key={category[0]} value={category[0]}>
            {category[1]}
          </option>
        ))}
      </select>
      <ul className="list-group product-list">
        {loading ? (
          <div className="text-center">
            <span className="spinner-border spinner-border-sm" />
          </div>
        ) : (
          ''
        )}
        {products.map((product) => (
          <li className="list-group-item d-flex" key={product.id}>
            <div className="col">
              <label htmlFor={product.id}>
                {product.product.model_display}
              </label>
            </div>
            <div className="col-auto">
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={product.id}
                  name={product.id}
                  checked={selectedItems.includes(product.id)}
                  onChange={(e) => handleItemCheckboxChange(e, product)}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="d-flex justify-content-end mt-3">
        <div className="dropdown">
          <button
            className="btn b-primary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Acciones
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <button
              className="dropdown-item"
              onClick={selectAllFilteredProducts}
            >
              Agregar todos en la lista
            </button>
            <button
              className="dropdown-item"
              onClick={unselectAllFilteredProducts}
            >
              Remover todos en la lista
            </button>
            <button className="dropdown-item" onClick={selectAllProducts}>
              Agregar todos los productos
            </button>
            <button className="dropdown-item" onClick={unselectAllProducts}>
              Remover todos los productos
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PromoProductSelector
