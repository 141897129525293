import React, { Component } from 'react'
import { toast } from 'react-toastify'
import SearchBars from '../../components/SearchBars'
import SideMenuComponent from '../../components/Shared/SideMenu.component'
import AccountsTable from '../AccountsTable'
import ModalInstallationImages from '../../components/ModalInstallationImages'
import ModalDocuments from '../../components/ModalDocuments'
import ModalConfirmInstallationDate from '../../components/ModalConfirmInstallationDate'
import ModalDetails from '../../components/ModalDetails/Index'
import {
  updateEstimatedDate,
  sendTicket,
  getCreditsByStatus,
  getCreditsCount,
  getCreditPhotosStatus,
  getListInstallationPhotosByCreditId,
  deleteInstallationPhoto,
  validateInstallationPhoto,
  updateCreditStatus,
  getCreditDetail,
  getCreditsByName,
  getCreditsByPhone,
  approveCredit,
} from '../../services/accounts.service'
import shopper from '../../services/ms/ms-users-service'
import { confirmInstallation } from '../../services/systems.service'

export default class AccountsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showTable: 'PRO',
      menuAccounts: [],
      rows: [],
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      isStopped: false,
      showFilters: false,
      rol: localStorage.getItem("user_type"), // eslint-disable-line
      namePhoneSearch: '',
      distributorSearch: '',
      sellerSearch: '',
      productSearch: '',
      serialNumber: '',
      startDate: '',
      endDate: '',
      isOpenModalInstallationImages: false,
      isOpenModalConfirmInstallationDates: false,
      isOpenModalDocuments: false,
      isOpenModalDetails: false,
      photos: [],
      currentCredit: null,
      statusPhotos: null,
      loading: {
        documents: false,
      },
      tableDefault: true,
      code: '',
      notFound: false,
      qrExperience: false,
      eventKey: '01',
      modal: false,
      width: window.innerWidth,
      spinner: false,
      product: '',
    }

    this.handleStatus = this.handleStatus.bind(this)
    this.handleShowFilters = this.handleShowFilters.bind(this)
    this.handleRedirectPageDetail = this.handleRedirectPageDetail.bind(this)
    this.handleRedirectNewAccount = this.handleRedirectNewAccount.bind(this)
    this.handlePaginator = this.handlePaginator.bind(this)
    this.handleProductSearch = this.handleProductSearch.bind(this)
    this.handleNamePhoneSearch = this.handleNamePhoneSearch.bind(this)
    this.handleDistributorSearch = this.handleDistributorSearch.bind(this)
    this.handleSellerSearch = this.handleSellerSearch.bind(this)
    this.handleOpenModalInstallationPhotos =
      this.handleOpenModalInstallationPhotos.bind(this)
    this.handleCloseModalInstallationPhotos =
      this.handleCloseModalInstallationPhotos.bind(this)
    this.handleCloseModalDetails = this.handleCloseModalDetails.bind(this)
    this.handleOpenModalDocuments = this.handleOpenModalDocuments.bind(this)
    this.handleOpenModalDetails = this.handleOpenModalDetails.bind(this)
    this.handleOpenModalConfirm = this.handleOpenModalConfirm.bind(this)
    this.handleValidatePhoto = this.handleValidatePhoto.bind(this)
    this.handleDeletePhoto = this.handleDeletePhoto.bind(this)
    this.handleGetInstallationPhotos =
      this.handleGetInstallationPhotos.bind(this)
    this.handleUpdateCreditStatus = this.handleUpdateCreditStatus.bind(this)
    this.handleOpenModalConfirmInstallationDates =
      this.handleOpenModalConfirmInstallationDates.bind(this)
    this.handleCloseModalConfirmInstallationDates =
      this.handleCloseModalConfirmInstallationDates.bind(this)
    this.handleUpdatePartialCredit = this.handleUpdatePartialCredit.bind(this)
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
    this.handleComments = this.handleComments.bind(this)
    this.handleEstimatedDate = this.handleEstimatedDate.bind(this)
    this.handleConfirmInstall = this.handleConfirmInstall.bind(this)
    this.handleSubmitMerchant = this.handleSubmitMerchant.bind(this)
    this.onChangeMerchant = this.onChangeMerchant.bind(this)
    this.handleChangeModal = this.handleChangeModal.bind(this)
    this.onDetectedMerchant = this.onDetectedMerchant.bind(this)
    this.onNewScanResult = this.onNewScanResult.bind(this)
    this.onChangeRegistrarButton = this.onChangeRegistrarButton.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleFinishVerification = this.handleFinishVerification.bind(this)
    this.onChangeModal = this.onChangeModal.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onChangeBackButton = this.onChangeBackButton.bind(this)
    this.onChangeProductInput = this.onChangeProductInput.bind(this)
  }

  componentDidMount() {
    const firstShowTable =
      this.state.rol === 'GR' ? this.state.showTable : 'EVAT'
    this.setState({ showTable: firstShowTable })
    this.loadCreditCounts()
    this.handleStatus({ key: firstShowTable })
    window.addEventListener('resize', this.handleResize())
  }

  /**
   * Request the credit count for each credit status
   */
  loadCreditCounts() {
    getCreditsCount().then((menu) => {
      this.setState({ menuAccounts: menu.data })
    })
  }

  handleEstimatedDate(date, item, updateTable) {
    item.estimated_install_date = date.slice(0, 10)
    item.instalation_date = date
    updateEstimatedDate(item).then(() => {
      updateTable()
    })
  }

  handleConfirmInstall(creditId) {
    toast.success('Instalación confirmada exitosamente!', {
      position: toast.POSITION.TOP_RIGHT,
    })
    confirmInstallation(creditId).then(() => {
      window.location.reload()
    })
  }

  handleShowFilters(e) {
    this.setState({ showFilters: e.target.checked })
  }

  /**
   * Requests the credits with the given item.key credit status and stores them
   * in state to show in table component
   * @param {object} item the item clicked in the side menu component
   */
  handleStatus(item) {
    this.setState(
      {
        rows: [],
        activePage: 1,
        totalItemsCount: 0,
        itemsCountPerPage: 10,
        showTable: item.key,
        isStopped: false,
        tableDefault: true,
      },
      () => {
        if (item.key === 'unrelated') {
          shopper.getUnrelatedShopper().then((rows) => {
            this.setState({
              rows: rows.data,
              itemsCountPerPage: rows.pagination.per_page,
              totalItemsCount: rows.pagination.total,
              isStopped: true,
            })
          })
        } else {
          getCreditsByStatus(
            item.key,
            this.state.itemsCountPerPage,
            this.state.activePage
          ).then((rows) => {
            this.setState({
              rows: rows.data.results,
              totalItemsCount: rows.data.count,
              isStopped: true,
            })
          })
        }
      }
    )
  }

  handleRedirectPageDetail(id) {
    this.props.history.push(`/cuentas/detalles/${id}`)
  }

  handleRedirectNewAccount() {
    this.props.history.push('/nuevo-lead/')
  }

  handlePaginator(number) {
    const searchTerm = this.state.namePhoneSearch
    if (this.state.tableDefault) {
      this.setState({ activePage: number, rows: [], isStopped: false }, () => {
        if (this.state.showTable === 'unrelated') {
          shopper.getUnrelatedShopper(this.state.activePage).then((rows) => {
            this.setState({ rows: rows.data, isStopped: true })
          })
        } else {
          getCreditsByStatus(
            this.state.showTable,
            this.state.itemsCountPerPage,
            this.state.activePage
          ).then((rows) => {
            this.setState({ rows: rows.data.results, isStopped: true })
          })
        }
      })
    } else {
      this.setState({ activePage: number, rows: [], isStopped: false }, () => {
        if (!searchTerm) {
          getCreditsByName(
            searchTerm,
            this.state.activePage,
            this.state.itemsCountPerPage
          ).then((rows) => {
            this.setState({ rows: rows.data.results, isStopped: true })
          })
        } else {
          getCreditsByPhone(
            searchTerm,
            this.state.activePage,
            this.state.itemsCountPerPage
          ).then((rows) => {
            this.setState({ rows: rows.data.results, isStopped: true })
          })
        }
      })
    }
  }

  handleComments(dato) {
    const data = {
      id: dato.id,
      comments: dato.coment,
    }
    approveCredit(data).then((r) => {
      if (r.status === 200) {
        window.location.reload()
      }
    })
  }

  handleNamePhoneSearch(e) {
    this.setState({ namePhoneSearch: e.target.value })
  }

  onChangeMerchant(e) {
    this.setState({ code: e.target.value })
  }

  onChangeProductInput(e) {
    this.setState({ product: e.target.value })
  }

  onDetectedMerchant(code) {
    const id = code[0].decodedText
    this.setState({ code: id })

    this.handleSubmitMerchant()
  }

  handleDistributorSearch(e) {
    this.setState({
      distributorSearch: e.target.value,
      searchTermType: 'DIST',
    })
  }

  handleSellerSearch(e) {
    this.setState({ sellerSearch: e.target.value, searchTermType: 'SLL' })
  }

  handleProductSearch(e) {
    this.setState({ productSearch: e.target.value, searchTermType: 'PRD' })
  }

  handleSerialNoSearch(e) {
    this.setState({ serialNumber: e.target.value, searchTermType: 'SRN' })
  }

  handleStartDateChange(e) {
    this.setState({ startDate: e.target.value, searchTermType: 'RNGD' })
  }

  handleEndDateChange(e) {
    this.setState({ endDate: e.target.value, searchTermType: 'RNGD' })
  }

  handleSubmitSearch(e) {
    e.preventDefault()
    const searchTerm = this.state.namePhoneSearch
    this.setState({
      activePage: 1,
      totalItemsCount: 0,
      rows: [],
      isStopped: false,
    })
    if (searchTerm === '') {
      if (this.state.showTable === 'unrelated') {
        shopper.getUnrelatedShopper().then((rows) => {
          this.setState({
            rows: rows.data,
            itemsCountPerPage: rows.pagination.per_page,
            totalItemsCount: rows.pagination.total,
            isStopped: true,
          })
        })
      } else {
        getCreditsByName(
          searchTerm,
          this.state.activePage,
          this.state.itemsCountPerPage
        ).then((rows) => {
          this.setState({
            rows: rows.data.results,
            activePage: 1,
            isStopped: true,
            totalItemsCount: rows.data.count,
            tableDefault: false,
          })
        })
      }
    } else if (this.state.showTable === 'unrelated') {
      shopper.getUnrelatedShopper(1, searchTerm).then((rows) => {
        this.setState({
          rows: rows.data,
          itemsCountPerPage: rows.pagination.per_page,
          totalItemsCount: rows.pagination.total,
          isStopped: true,
        })
      })
    } else {
      getCreditsByPhone(
        searchTerm,
        this.state.activePage,
        this.state.itemsCountPerPage
      ).then((rows) => {
        this.setState({
          rows: rows.data.results,
          activePage: 1,
          isStopped: true,
          totalItemsCount: rows.data.count,
          tableDefault: false,
        })
      })
    }
  }

  handleSubmitMerchant() {
    // e.preventDefault();
    const { code } = this.state

    getCreditDetail(code).then((response) => {
      if (response.status !== 404 && response.data.credit_status === 'ATI') {
        setTimeout(() => {
          this.setState({ eventKey: '03', currentCredit: response.data })
        }, 2000)
        this.setState({ qrExperience: true, modal: false, eventKey: '02' })
      } else {
        this.setState({ qrExperience: true, eventKey: '05', modal: false })
      }
    })
  }

  handleConfirm() {
    this.setState({ eventKey: '04' })
  }

  handleCancel() {
    this.setState({ eventKey: '01' })
  }

  /**
   * On credit verification callback. Sends ticket by email, changes credit
   * status to INS and reload view with the INS table
   * @param {UUID} id the verified credit id
   */
  handleFinishVerification(id) {
    this.setState({ spinner: true })
    sendTicket(id).then((response) => {
      this.setState({ spinner: false, qrExperience: false, code: '' })
      if (this.state.width < 672) {
        this.setState({ qrExperience: false })
      } else {
        this.setState({ eventKey: '01' })
      }
      this.loadCreditCounts()
      this.handleStatus({ key: 'INS' })
    })
  }

  handleDetectedMerchant() {
    // e.preventDefault();
    const { code } = this.state
    getCreditDetail(code).then((response) => {
      if (response.status !== 404) {
        const path = `/cuentas/detalles/${response.data.id}`
        window.location.pathname = path
      } else {
        this.setState({ notFound: true })
      }
    })
  }

  handleChangeModal() {
    this.setState({ notFound: false })
  }

  handleOpenModalInstallationPhotos(event, creditId) {
    this.setState({ isOpenModalInstallationImages: true }, () => {
      getCreditPhotosStatus(creditId).then((data) => {
        this.setState(
          { currentCredit: data },
          this.handleGetInstallationPhotos(creditId)
        )
      })
    })
    event.stopPropagation()
  }

  handleGetInstallationPhotos(creditId) {
    getListInstallationPhotosByCreditId(creditId).then((data) => {
      this.setState({ photos: data })
    })
  }

  handleCloseModalInstallationPhotos() {
    this.setState({
      currentCredit: null,
      photos: [],
      isOpenModalInstallationImages: false,
      statusPhotos: null,
    })
  }

  handleUpdateCreditStatus(newStatus, statusPhotos, extraFields) {
    let data = {
      status_photos: statusPhotos,
    }
    if (newStatus !== null) {
      data.credit_status = newStatus
    }
    if (extraFields !== null) {
      data = { ...data, ...extraFields }
    }
    updateCreditStatus(data, this.state.currentCredit.id).then((data) => {
      this.setState({ currentCredit: data })
      this.handleStatus({ key: this.state.showTable })
    })
  }

  handleDeletePhoto(photoId) {
    deleteInstallationPhoto(photoId).then(() => {
      const imagesWithoutDeleted = this.state.photos.filter(
        (image) => image.id !== photoId
      )
      this.setState({ photos: imagesWithoutDeleted })
    })
  }

  handleValidatePhoto(photoId, validated) {
    validateInstallationPhoto(photoId, validated).then((data) => {
      const { photos } = this.state
      const photosUpdated = photos.map((image) => {
        if (image.id === photoId) {
          image.validated = data.validated
        }
        return image
      })
      this.setState({ photos: photosUpdated })
    })
  }

  handleOpenModalDocuments(id) {
    this.setState({ isOpenModalDocuments: true }, () => {
      getCreditDetail(id).then((response) => {
        this.setState({ currentCredit: response.data })
      })
    })
  }

  handleOpenModalDetails(event, id) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ isOpenModalDetails: true }, () => {
      getCreditDetail(id).then((response) => {
        this.setState({ currentCredit: response.data })
      })
    })
  }

  handleCloseModalDetails() {
    this.setState({ isOpenModalDetails: false })
  }

  handleOpenModalConfirm() {
    this.setState({ isOpenModalConfirm: true })
  }

  handleOpenModalConfirmInstallationDates(event, data) {
    event.stopPropagation()
    this.setState({
      isOpenModalConfirmInstallationDates: true,
      currentCredit: data,
    })
  }

  handleCloseModalConfirmInstallationDates() {
    this.setState({
      isOpenModalConfirmInstallationDates: false,
      dates_proposed: null,
      status_dates_proposed: null,
      currentCredit: null,
    })
  }

  handleUpdatePartialCredit(data, id) {
    this.setState({ loading: { documents: true } })
    updateCreditStatus(data, id).then((data) => {
      this.setState(
        { currentCredit: data, loading: { documents: false } },
        this.handleStatus({ key: this.state.showTable })
      )
    })
  }

  onNewScanResult(decodedText, decodedResult) {
    // let decodedResults = this.state.decodedResults;
    // decodedResults.push(decodedResult);
    this.setState((state, props) => {
      state.decodedResults.push(decodedResult)
      return state
    })
  }

  handleResize() {
    this.setState({ width: window.innerWidth })
  }

  onChangeRegistrarButton() {
    const { width, qrExperience } = this.state
    const qr = qrExperience
    if (width < 672) {
      this.setState({ qrExperience: !qr })
      this.handleSubmitMerchant()
    } else {
      this.setState({ qrExperience: !qr, eventKey: '01' })
    }
  }

  onChangeModal() {
    this.setState({ modal: true })
  }

  closeModal() {
    this.setState({ modal: false, code: '' })
  }

  onChangeBackButton() {
    this.setState({ qrExperience: false })
  }

  render() {
    const {
      search,
      showTable,
      menuAccounts,
      rol,
      isStopped,
      activePage,
      itemsCountPerPage,
      totalItemsCount,
      currentCredit,
      photos,
      isOpenModalInstallationImages,
      isOpenModalDocuments,
      documents,
      isOpenModalConfirmInstallationDates,
      isOpenModalDetails,
      tableDefault,
      comments,
      notFound,
      code,
      decodedResults,
      qrExperience,
      eventKey,
      modal,
      spinner,
    } = this.state

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <h1 className="text-center">CUENTAS</h1>
            </div>
            <div className="col-lg-9 col-md-9">
              <SearchBars
                value={search}
                showTable={showTable}
                onChangeNamePhone={this.handleNamePhoneSearch}
                handleSubmitSearch={this.handleSubmitSearch}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <SideMenuComponent
                menu={menuAccounts || []}
                rol={rol}
                notFound={notFound}
                code={code}
                onClick={this.handleRedirectNewAccount}
                onSelectStatus={this.handleStatus}
                handleSubmitMerchant={this.handleSubmitMerchant}
                onChangeMerchant={this.onChangeMerchant}
                handleChangeModal={this.handleChangeModal}
                onDetectedMerchant={this.onDetectedMerchant}
                onNewScanResult={this.onNewScanResult}
                decodedResults={decodedResults}
                onChangeRegistrarButton={this.onChangeRegistrarButton}
                closeModal={this.closeModal}
                modal={modal}
                onChangeModal={this.onChangeModal}
              />
            </div>
            <div className="col-lg-9 col-md-9">
              <AccountsTable
                rol={rol}
                isStopped={isStopped}
                rows={this.state?.rows}
                hidden={showTable}
                activePage={activePage}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={totalItemsCount}
                credit={currentCredit}
                tableDefault={tableDefault}
                onClick={this.handleRedirectPageDetail}
                onOpenModalDocuments={this.handleOpenModalDocuments}
                onOpenModalDetails={this.handleOpenModalDetails}
                onOpenModalInstallationImages={
                  this.handleOpenModalInstallationPhotos
                }
                onOpenModalConfirmInstallationDates={
                  this.handleOpenModalConfirmInstallationDates
                }
                onStatus={this.handleStatus}
                onPaginator={this.handlePaginator}
                handleEstimatedDate={this.handleEstimatedDate}
                handleConfirmInstall={this.handleConfirmInstall}
                qrExperience={qrExperience}
                handleSubmitMerchant={this.handleSubmitMerchant}
                onChangeMerchant={this.onChangeMerchant}
                handleChangeModal={this.handleChangeModal}
                onDetectedMerchant={this.onDetectedMerchant}
                onNewScanResult={this.onNewScanResult}
                decodedResults={decodedResults}
                value={code}
                notFound={notFound}
                eventKey={eventKey}
                modal={modal}
                spinner={spinner}
                onChangeModal={this.onChangeModal}
                handleConfirm={this.handleConfirm}
                closeModal={this.closeModal}
                handleCancel={this.handleCancel}
                handleFinishVerification={this.handleFinishVerification}
                onChangeBackButton={this.onChangeBackButton}
              />
            </div>
          </div>
        </div>
        <ModalInstallationImages
          rol={rol}
          photos={photos}
          credit={currentCredit}
          isOpen={isOpenModalInstallationImages}
          onClose={this.handleCloseModalInstallationPhotos}
          onDeletePhoto={this.handleDeletePhoto}
          onValidatePhoto={this.handleValidatePhoto}
          onUpdatePhotos={this.handleGetInstallationPhotos}
          onUpdateCreditStatus={this.handleUpdateCreditStatus}
          onUpdatePartialCredit={this.handleUpdatePartialCredit}
        />
        <ModalDocuments
          isOpen={isOpenModalDocuments}
          onClose={() => {
            this.setState({
              isOpenModalDocuments: false,
              currentCredit: null,
            })
          }}
          credit={currentCredit}
          onUpdateCredit={() => {
            getCreditDetail(currentCredit.id).then((response) => {
              this.setState({ currentCredit: response.data })
            })
          }}
          role={rol}
        />
        <ModalConfirmInstallationDate
          rol={rol}
          loading={documents}
          credit={currentCredit}
          isOpen={isOpenModalConfirmInstallationDates}
          onClose={this.handleCloseModalConfirmInstallationDates}
          onOpenModalDetails={this.handleOpenModalConfirmInstallationDates}
          onUpdatePartialCredit={this.handleUpdatePartialCredit}
        />
        <ModalDetails
          rol={rol}
          credit={currentCredit}
          isOpen={isOpenModalDetails}
          comments={comments}
          handleUpdate={this.getCreditDetail}
          handleComments={(e) => this.handleComments(e)}
          handleCloseModalDetails={this.handleCloseModalDetails}
        />
      </>
    )
  }
}
