// React
import React from 'react'

/**
 * Form for promo's basic information (everything but products)
 * @param {Promo} data initial form data
 * @param {function} onChange onchange event listener for input data
 * @param {function} onSubmit onSubmit event listener
 */
function PromoForm({ data, onChange }) {
  /**
   * Calls `onChange` handler passing data with new input value
   * @param {InputEvent} e the onchange input event
   * @param {boolean} isCheckbox whether if `e.target` is a checkbox, and
   *  therefore using `target.checked` instead of `target.value`
   */
  const handleFormChange = (e, isCheckbox = false) => {
    onChange({
      ...data,
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    })
  }

  return (
    <>
      <div className="form-group">
        <label htmlFor="name">Nombre</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={data.name}
          onChange={handleFormChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Descripción</label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          value={data.description}
          onChange={handleFormChange}
        />
      </div>
      <div className="row align-items-end">
        <div className="col">
          <div className="form-group">
            <label htmlFor="description">Porcentaje</label>
            <input
              type="number"
              min="0"
              max="100"
              className="form-control"
              id="percentage"
              name="percentage"
              value={data.percentage}
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className="col-auto">
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="active"
              name="active"
              checked={data.active}
              onChange={(e) => handleFormChange(e, true)}
            />
            <label className="form-check-label" htmlFor="active">
              Activo
            </label>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="description">Fecha y hora de inicio</label>
        <input
          type="datetime-local"
          className="form-control"
          id="start_date"
          name="start_date"
          value={data.start_date.substr(0, 16)}
          onChange={handleFormChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Fecha y hora de término</label>
        <input
          type="datetime-local"
          className="form-control"
          id="expiration_date"
          name="expiration_date"
          value={data.expiration_date.substr(0, 16)}
          onChange={handleFormChange}
        />
      </div>
    </>
  )
}

export default PromoForm
