// React
import React, { useState } from 'react'

// Services
import msProductsService from '../../../services/ms/ms-products-service'

/**
 * Modal for promo.graviti_extra_percentage value update
 * @param {Promo} promo the promo to update
 * @param {boolean} show whether to show the popup
 * @param {(Promo) -> void} onSubmit a callback that is fired after `promo`
 * is updated successfuly
 * @param {() -> void} onClose a callback that is fired when the `Popup` is
 * closed
 * @returns
 */
function PromoExtraPercentageFieldUpdate({ promo, onSubmit }) {
  /**
   * the input field value
   */
  const [gravitiExtraPercentage, setGravitiExtraPercentage] = useState(
    promo.graviti_extra_percentage
  )

  /**
   * disables the submit button while `handleSubmit` task is excecuting
   */
  const [loading, setLoading] = useState(false)

  /**
   * onsubmit event handler. Updates promo.graviti_extra_percentage value and
   * calls `onSubmit` handler with the updated promo.
   * @param {FormEvent} e the onsubmit event
   */
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const data = { graviti_extra_percentage: gravitiExtraPercentage }
    try {
      await msProductsService.updatePromo(promo.id, data)
      onSubmit({ ...promo, ...data })
    } catch (exception) {}
    setLoading(false)
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="number"
          min="0"
          max="100"
          className="form-control"
          value={gravitiExtraPercentage}
          onChange={(e) => setGravitiExtraPercentage(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button disabled={loading} className="btn b-primary">
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            'Guardar'
          )}
        </button>
      </div>
    </form>
  )
}

export default PromoExtraPercentageFieldUpdate
