import React from 'react'

import './chart.css'
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from 'recharts'

export default function Chart({ title, data, grid }) {
  return (
    <div className="chart">
      <h3 className="chartTitle">{title}</h3>
      <ResponsiveContainer width="100%" aspect={4 / 1}>
        <LineChart data={data}>
          <YAxis fontSize={12} />
          <XAxis dataKey="month" stroke="#5550bd" />
          <Line type="monotone" dataKey="GVM" stroke="#5550bd" />
          <Tooltip />
          {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="8 8" />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
