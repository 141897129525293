import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import shopper from '../../../../services/ms/ms-users-service'
import { getRCC, resetFico, tagType } from '../../../../services/constants'
import {
  patchRequestAPI,
  getRequestAPI,
} from '../../../../services/requests.service'
import { updateCreditTags } from '../../../../services/accounts.service'

import './style.css'
import DocumentPhoto from './photo'

export default class GeneralInformationComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id,
      UserData:[],
      selectedTag: '',
      tags: this.props.creditsInfo?.[0]?.tags_list || [],
      tagGroupList: [],
      allTags: [],
      rol: localStorage.getItem('user_type'),
      credit: '',
      kyc_prescoring_info: '',
      hasPhotos: '',
      cleanFico: '',
      hasClient: false,
      client_info: [],
    }

    this.handleDeleteBadge = this.handleDeleteBadge.bind(this)
    this.handleSelectedTag = this.handleSelectedTag.bind(this)
    this.handleMergeTags = this.handleMergeTags.bind(this)
  }

  componentDidMount() {
    getRequestAPI(tagType()).then((response) => {
      this.setState({ tagGroupList: response.data })
      this.setState({ allTags: this.handleMergeTags(response.data) })
    })
    this.setState({ hasClient: this.state.UserData?.id === undefined })
  }

  componentDidUpdate(prevProps){
    if(this.props !== prevProps){
      if(this.props.creditsInfo.length == '0') 
        this.getUserData()
      else{
        this.setState({
          credit: this.props.creditsInfo[0], 
          client_info: this.props?.creditsInfo?.[0]?.client_info,
          kyc_prescoring_info: this.props?.creditsInfo?.[0]?.kyc_prescoring_info,
          hasPhotos: !!this.state?.client_info?.ine || !!this.state?.kyc_prescoring_info?.identification_front,
          address_reference: this.state?.client_info?.address_reference ? `Entre ${this.state?.client_info?.address_reference?.street} y ${this.state?.client_info?.address_reference?.street_other}, ` +
          `${this.state?.client_info?.address_reference?.additional_reference}`
          : '-',
          ficoConditions: [
            this.props?.rol === 'GR',
            this.state?.credit?.credit_status === 'EVA',
          ],
        })
      }
    }
  }

  handleMergeTags(tagList) {
    const tags = []

    for (let i = 0; i < tagList.length; i++) {
      if (tagList[i].tags.length !== 0) {
        tags.push(...tagList[i].tags)
      }
    }
    return tags
  }
  
  handleDeleteBadge(tag) {
    const tagsFiltered = this.state.tags.filter((tagOld) => tag !== tagOld)
    this.setState({ tags: tagsFiltered }, () => {
      const newTags = this.props.creditInfo.tags
        .split(',')
        .filter((item) => item !== tag)
      const tagsToString = newTags.join(',')
      updateCreditTags({ tags: tagsToString }, this.props.creditInfo.id).then(
        (response) => {
          if (response?.response?.status) {
            this.setState({ tags: this.props.creditInfo.tags.split(',') })
          } else {
            this.props.handleUpdate()
          }
        }
      )
    })
  }

  handleSelectedTag(e) {
    const { value } = e.target
    this.setState({ selectedTag: value }, () => {
      const oldTags = this.state.tags
      oldTags.push(this.state.selectedTag)
      this.setState({ tags: oldTags }, () => {
        const tagsProps = this.props.creditInfo.tags
          ? this.props.creditInfo.tags.split(',')
          : []
        tagsProps.push(this.state.selectedTag)
        const updatedTags = tagsProps.toString()
        updateCreditTags({ tags: updatedTags }, this.props.creditInfo.id).then(
          (response) => {
            if (response?.response?.status) {
              oldTags.pop()
              this.setState({ tags: oldTags })
            } else {
              this.props.handleUpdate()
            }
            this.setState({ selectedTag: '' })
          }
        )
      })
    })
  }

  getUserData(){
    shopper.getShopperId(this.props.id).then((data) => {
      this.setState({ UserData:data })
    })
  }

  render() {
    if (this.props.creditsInfo.length != '0'){
        this.state.cleanFico = () =>
        patchRequestAPI(resetFico(this.state.client_info), { fico_score: null }).then(
          (res) => {
            if (res.status === 202) {
              window.location.reload()
            }
          }
        )
      /**
       * Copies credit.autopath_link to clipboard
       */
      // const copyAutopathToClipboard = () => {
      //   navigator.clipboard.writeText(credits?.autopath_link)
      //   toast.success('Link copiado en el portapapeles', {
      //     position: toast.POSITION.TOP_RIGHT
      //   })
      // }
    }
    
    const {hasClient} = this.state
    const {UserData} = this.state
    const {client_info} = this.state
    const {credit} = this.state
    return (
      <Container fluid>
        {(this.props.creditsInfo.length != '0' || UserData.length != '0') && (
          <Row>
          <Col lg={4} md={4} sm={10} xs={10}>
            <table className="table table-borderless table-sm">
              <thead />
              <tbody>
                <tr>
                  <td className='headTable'>
                    <strong>ID Contrato:</strong>
                  </td>
                  <td>
                    {((hasClient) && (
                      client_info?.contract_id || '-') || '-'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Referencia:</strong>
                  </td>
                  <td>
                    {(hasClient && (
                      credit?.reference || '-') || '-'
                    )}
                  </td>
                </tr>
                {/* <tr>
                  <td className='headTable'>
                    <strong>Customer ID:</strong>
                  </td>
                  <td>
                    {((hasClient) && (
                    this.state.credit?.customer_id || '-') || (this.state?.UserData?.id || '-')
                  )}
                  </td>
                </tr> */}
                <tr>
                  <td className='headTable'><strong>Fecha de registro:</strong></td>
                  <td>{(hasClient && 
                    (client_info?.register_date) || 
                    new Date(UserData?.created_at * 1000).toUTCString())
                  }</td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>Nombre:</strong>
                  </td>
                  <td>
                    {hasClient && 
                      client_info?.full_name 
                      || `${UserData?.name || ''} ${UserData?.lastname || ''}`
                    }
                  </td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>Correo:</strong>
                  </td>
                  <td>{(((hasClient) && (
                      client_info?.mail || '-')) || (UserData?.email || '-')
                  )}</td>
                </tr>
                {(hasClient) && (credit?.credit_status === 'CC' && (
                  <tr>
                    <td className='headTable'>Castigado desde:</td>
                    <td>{credit?.readable_penalized_date || '-'}</td>
                  </tr>
                ))}
                <tr>
                  <td className="headTable">
                    <strong>RFC:</strong>{' '}
                  </td>
                  <td>{((hasClient) && (
                      client_info?.rfc || '-') || (UserData?.profile?.rfc || '-')
                    )}</td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>Celular:</strong>
                  </td>
                  <td>{((hasClient) && (
                      client_info?.phone) || (UserData?.phone)
                    )}</td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>Teléfono fijo:</strong>
                  </td>
                  <td>
                  {((hasClient) && (
                      client_info?.home_phone || '-') || (UserData?.profile?.home_phone || '-')
                    )}
                  </td>
                </tr>
                <tr>
                  <td className='headTable'><strong>CURP: </strong></td>
                  <td>{((hasClient) && (
                      client_info?.curp || '-') || (UserData?.profile?.curp || '-')
                    )}</td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>Estatus Civil:</strong>{' '}
                  </td>
                  <td>
                    {((hasClient) && (
                      client_info?.marital_status || '-') || (UserData?.profile?.marital_status || '-')
                    )}
                  </td>
                </tr>
                
                <tr>
                  <td className="headTable">
                    <strong>Genero: </strong>
                  </td>
                  <td>
                    {hasClient && 
                      client_info?.gender  || UserData?.profile?.gender ==='H' ? 'Hombre' : 'Mujer'
                    }
                  </td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>Domicilio:</strong>
                  </td>
                  <td>{hasClient && client_info?.address || 
                      `${UserData?.profile?.street || ''} 
                      ${UserData?.profile?.entity_birth_name || ''} 
                      ${UserData?.profile?.exterior || ''} 
                      ${UserData?.profile?.interior || ''} 
                      ${client_info?.profile?.municipality || ''} 
                      ${client_info?.profile?.state || ''} 
                      ${client_info?.profile?.zip || ''}` || '-'
                    }</td>
                </tr>  
                <tr>
                  <td className='headTable'>
                    <strong>Referencia de domicilio: </strong>
                  </td>
                  <td>{hasClient && this.state?.address_reference || '-'}</td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>FICOScore: </strong>
                  </td>
                  <td>{hasClient && client_info?.fico_score || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Mod. Ev. 2: </strong>
                  </td>
                  <td>{hasClient && credit?.alternative_calification_v2 || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Mod. Ev. 4: </strong>
                  </td>
                  <td>{hasClient && credit?.alternative_calification_v4 || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Quash Score: </strong>
                  </td>
                  <td>{hasClient && credit?.quash_score || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Comentarios de evaluacion</strong>
                  </td>
                  <td>{hasClient && credit?.comments || '-'}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <table className="table table-borderless table-sm">
              <thead />
              <tbody>
                <tr>
                  <td className="headTable">
                    <strong>Grado de estudios:</strong>
                  </td>
                  <td>{hasClient && client_info?.grade || UserData?.profile?.grade}</td>
                </tr>
                <tr>
                  <td className="headTable">
                    <strong>Tipo de Actividad:</strong>
                  </td>
                  <td>{hasClient && client_info?.type_activity || UserData?.profile?.type_activity}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Ingreso:</strong>
                  </td>
                  <td>{hasClient && client_info?.income || UserData?.income || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Ingreso familiar: </strong>
                  </td>
                  <td>{hasClient && client_info?.income_family || UserData?.income_family || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Gasto Mensual: </strong>
                  </td>
                  <td>{hasClient && client_info?.monthly_expenditure || UserData?.monthly_expenditure || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Hab. en el hogar: </strong>
                  </td>
                  <td>{hasClient && client_info?.count_home || UserData?.count_home || '-'}</td>
                </tr>
                <tr>
                  <td className='headTable'>
                    <strong>Niños en el hogar: </strong>
                  </td>
                  <td>{hasClient && client_info?.children || '-'}</td>
                </tr>
                {this.props.creditsInfo.length != '0' 
                  ? (['Lavadoras', 'Con Dispensador', 'Sin Dispensador'].includes(
                      credit?.product_info?.subcategory?.name
                    )
                      ? (
                        <tr>
                          <td className='headTable'><strong>Razón de compra: </strong></td>
                          <td>{this.props.credit?.answers['razon-de-compra']}</td>
                        </tr>
                        )
                      : (
                        <>
                          <tr>
                            <td className='headTable'><strong>Alternativa: </strong></td>
                            <td>
                              {this.props.credit?.answers ? this.props.credit?.answers['alternativa-para-calentar-agua'] : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className='headTable'><strong>Costo Alternativa: </strong></td>
                            <td>
                              {this.props.credit?.answers ? this.props.credit?.answers['razon-para-comprar-agua'] : '-'}
                            </td>
                          </tr>
                        </>
                        ))
                      : ''
                }

                <tr>
                  <td className='headTable'>
                    <strong>Tipo de vivienda: </strong>
                  </td>
                  <td>{hasClient && client_info?.deparment || UserData?.profile?.department}</td>
                </tr>

                <tr>
                  <td className="headTable">
                    <strong>Giro: </strong>
                  </td>
                  <td>{hasClient && client_info?.business_type || '-'}</td>
                </tr>

                <tr>
                  <td className="headTable">
                    <strong>Antiguedad:</strong>{' '}
                  </td>
                  <td>
                    {`${
                      hasClient && client_info?.time_activity_year ||
                      UserData?.profile?.time_activity_year ||
                      '0'
                    } - ${
                      hasClient && client_info?.time_activity_month ||
                      UserData?.profile?.time_activity_month ||
                      '0'
                    }`}{' '}
                  </td>
                </tr>

                <tr>
                  <td className="headTable">
                    <strong>Referencia 1: </strong>
                  </td>
                  <td>
                    {hasClient && client_info?.personal_references?.[0]?.name ||
                      UserData?.profile?.personal_references?.[0]?.name}{' '}
                    <br />{' '}
                    {hasClient && client_info?.personal_references?.[0]?.phone ||
                      UserData?.profile?.personal_references?.[0]?.phone}{' '}
                    <br />{' '}
                    {hasClient && client_info?.personal_references?.[0]?.relationship ||
                      UserData?.profile?.[0]?.relationship}
                  </td>
                </tr>

                <tr>
                  <td className="headTable">
                    <strong>Referencia 2: </strong>
                  </td>
                  <td>
                    {hasClient ? client_info?.personal_references?.[1]?.name : '-'} <br />{' '}
                    {hasClient ? client_info?.personal_references?.[1]?.phone : '-'} <br />{' '}
                    {hasClient ? client_info?.personal_references?.[1]?.relationship : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <Row>
              {UserData.length==0 && !!client_info?.conversation_url 
              && (
                <table className="table table-borderless table-sm">
                  <thead />
                  <tbody>
                    <tr>
                      <td className="headTable">
                        <strong>Frontapp/Yalo: </strong>
                      </td>
                      <td>
                        <a
                          href={
                            client_info?.conversation_url
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Conversation
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </Row>
            <Row>
              <Col md={12}>
                {this.state.rol === 'DS' && (
                  <button
                    className="btn b-secondary-2"
                    onClick={(e) =>
                      this.props.handleOpenModalConfirmInstallationDates(
                        e,
                        credits
                      )
                    }
                  >
                    Confirmar
                  </button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        )}
      </Container>
    )
  }
}
