import React from 'react'
import LoadingComponent from '../Shared/Loading.component'
import LeadValidationComponent from '../Graviti/Leads/LeadValidation.component'
import PersonalInformationComponent from '../Accounts/NewAccount/PersonalInformation.component'
import JobInformationComponent from '../Accounts/NewAccount/JobInformation.component'
import AdditionalInformationComponent from '../Accounts/NewAccount/AddicionalInformation.component'
import ProductSelectedComponent from '../Accounts/NewAccount/ProductSelected.component'
import { newLeadSteps, editLeadSteps } from './utils'
import './index.css'

export default function Wizard({
  newLead,
  tabIndex,
  isStopped,
  onChangeInput,
  onGenerateRFC,
  onClickButton,
  onGoBack,
  client,
  storedZip,
  credit,
  onGenerateCURP,
}) {
  const titles = newLead ? newLeadSteps : editLeadSteps

  return (
    <div className="box-white">
      <div className="row">
        {titles.map((step) => (
          <div className="col" key={step.id}>
            <span
              className={
                tabIndex === step.id
                  ? 'tab-section-selected'
                  : 'tab-section-unselected'
              }
            >
              {step.name}
            </span>
          </div>
        ))}
      </div>
      <hr className="separator" />
      {isStopped === false && (
        <>
          {newLead && (
            <div hidden={tabIndex !== 1}>
              <LeadValidationComponent
                onClickButton={onClickButton}
                handleChange={onChangeInput}
                client={client}
                credit={credit}
                hidden={tabIndex !== 1}
              />
            </div>
          )}
          <div
            hidden={(newLead && tabIndex !== 2) || (!newLead && tabIndex !== 1)}
          >
            <PersonalInformationComponent
              handleChange={onChangeInput}
              client={client}
              storedZip={storedZip}
              RFC={onGenerateRFC}
              CURP={onGenerateCURP}
            />
          </div>
          {!newLead && (
            <div hidden={tabIndex !== 2}>
              <ProductSelectedComponent
                handleChange={onChangeInput}
                credit={credit}
              />
            </div>
          )}
          <div hidden={tabIndex !== 3}>
            <JobInformationComponent
              handleChange={onChangeInput}
              client={client}
            />
          </div>
          <div hidden={tabIndex !== 4}>
            <AdditionalInformationComponent
              handleChange={onChangeInput}
              client={client}
            />
          </div>
        </>
      )}
      {isStopped === true && <LoadingComponent isStopped={isStopped} />}
      <br />
      <br />
      {isStopped === false &&
        (tabIndex === 1 && newLead ? null : (
          <div className="row">
            <div className="col-6" />
            <div className="col-2">
              {tabIndex === 2 && newLead ? null : (
                <button
                  className="btn-2 b-primary"
                  hidden={tabIndex <= 1}
                  onClick={() => onClickButton(-1)}
                >
                  Atrás
                </button>
              )}
            </div>
            <div className="col-2">
              <button
                className="btn-2 b-primary"
                onClick={() => onClickButton(1)}
              >
                {tabIndex === 4 ? 'Finalizar' : 'Siguiente'}
              </button>
            </div>
            <div className="col-2 text-right">
              <button className="btn-3" onClick={onGoBack}>
                Cancelar
              </button>
            </div>
          </div>
        ))}
    </div>
  )
}
