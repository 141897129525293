import React from 'react'
import * as CurrencyFormat from 'react-currency-format'
import LoadingComponent from '../../Shared/Loading.component'

export default function InstallationsDetailsTableComponent({
  isStopped,
  rows,
  hidden,
  handleClick,
  headers,
}) {
  const handleIdAccount = (item) => {
    handleClick(item)
  }

  return (
    <div className="box-table" hidden={hidden}>
      {isStopped && (
        <div className="table-responsive">
          <table className="table table-hover table-graviti">
            <thead>
              <tr>
                {headers.map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((item, i) => (
                <tr onClick={() => handleIdAccount(item.id)} key={i}>
                  <td className="textPush">{item.contract_id}</td>
                  <td className="textPush">{item.client_name}</td>
                  <td className="textPush">{item.product_name}</td>
                  <td className="textPush">{item.instalation_date}</td>
                  <td className="textPush">{item.distributor_name}</td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.total}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isStopped === false && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}
