// React
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// React popup
import Popup from 'reactjs-popup'

// Estilos
import './style.css'
import { updateCreditStatus } from '../../services/accounts.service'

/**
 * Modal para asignar fecha de instalación a un crédito, o un link y un número
 * de guía en caso de manejar el envío con un tercero.
 */
function ModalPrepareDelivery({ show, credit, onClose, onSubmitted }) {
  /**
   * Tab activa actual.
   */
  const [activeTab, setActiveTab] = useState(
    credit?.tracking_number ? 'tracking' : 'date'
  )

  /**
   * Fecha de instalación asignada para este producto
   */
  const [installationDate, setInstalationDate] = useState(
    credit?.instalation_date
  )

  /**
   * Link de rastreo del producto
   */
  const [trackingLink, setTrackingLink] = useState(credit?.tracking_link)

  /**
   * Número de guía
   */
  const [trackingNumber, setTrackingNumber] = useState(credit?.tracking_number)

  /**
   * Muestra un spinner en el botón de Guardar si es que hay
   * una petición en curso
   */
  const [loading, setLoading] = useState(false)

  /**
   * Bloquea el botón Guardar en estos casos:
   * - El tab activo es Fecha de instalación y no se ha asignado fecha
   * - El tab activo es Número de guía y no se ha asignado número de guía
   * ó link de rastreo
   */
  const disableSaveButton =
    (activeTab === 'date' && !installationDate) ||
    (activeTab === 'tracking' && (!trackingLink || !trackingNumber))

  /**
   * Actualiza los siguientes datos en el crédito en la
   * base de datos dependiendo de la tab activa:
   * - si la tab es Fecha de instalación, se envía la fecha
   * de instalación y se eliminan el número de guía y el link de rastreo
   * - en otro caso, se envía el link de rastreo y número de guía
   * y se elimina la fecha de instalación
   */
  const handlePrepareDelivery = async () => {
    setLoading(true)
    let data = {}

    if (activeTab === 'date') {
      data = {
        instalation_date: installationDate,
        estimated_install_date: installationDate.slice(0, 10),
      }
    } else {
      data = {
        tracking_link: trackingLink,
        tracking_number: trackingNumber,
      }
    }

    await updateCreditStatus(data, credit.id)
    setLoading(false)
    onSubmitted()
  }

  return (
    <Popup
      open={show}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      onClose={onClose}
      lockScroll
    >
      <div className="modal-container">
        <h4>Preparación de envío</h4>
        <span className="modal-close" onClick={onClose}>
          &times;
        </span>
        <ul className="nav nav-tabs" id="preparationTabs" role="tablist">
          <li className="nav-item">
            <span
              className={`cursor-pointer nav-link ${
                activeTab === 'date' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('date')}
            >
              Fecha de instalación
            </span>
          </li>
          <li className="nav-item">
            <span
              className={`cursor-pointer nav-link ${
                activeTab === 'tracking' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('tracking')}
            >
              Número de guía
            </span>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              activeTab === 'date' ? 'show active' : ''
            }`}
          >
            <h5 className="mb-4">Entrega con fecha de instalación.</h5>
            <div className="form-group">
              <label>Fecha de instalación</label>
              <input
                type="datetime-local"
                className="form-control"
                value={installationDate}
                onChange={(e) => setInstalationDate(e.target.value)}
              />
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === 'tracking' ? 'show active' : ''
            }`}
          >
            <h5 className="mb-4">
              Entrega con link de rastreo y número de guía
            </h5>
            <div className="form-group">
              <label>Link de rastreo</label>
              <input
                type="text"
                className="form-control"
                value={trackingLink}
                onChange={(e) => setTrackingLink(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Número de guía</label>
              <input
                type="text"
                className="form-control"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            disabled={disableSaveButton}
            className="btn b-primary"
            onClick={handlePrepareDelivery}
          >
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              'Guardar'
            )}
          </button>
          <button className="btn ml-3" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </Popup>
  )
}

ModalPrepareDelivery.propTypes = {
  /**
   * Bandera para mostrar el modal
   */
  show: PropTypes.bool.isRequired,

  /**
   * El crédito a modificar
   */
  credit: PropTypes.object,

  /**
   * Evento se ejecuta cuando el modal es cerrado
   */
  onClose: PropTypes.func,
}

export default ModalPrepareDelivery
