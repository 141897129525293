import axios from 'axios'
import { toast } from 'react-toastify'

export const getneighborhoods = (zip) =>
  axios({
    url: `https://graviti-zip.herokuapp.com/v2/codigo_postal/${zip}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'es-es',
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response)
      if (error.response.data.error_message) {
        toast.error(error.response.data.error_message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error('Error al obtener el ZIP.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      return error.response
    })
