import React from 'react'

import { FaCheck } from 'react-icons/fa'
import LoadingComponent from '../Shared/Loading.component'
import TableComponent from '../Shared/Table.component'
import { tagGroupListStatus } from './SectionsDetailsAccount/SectionGeneral/utilsStatus'
import { updateEstimatedDate } from '../../services/accounts.service'

export default function SharedTable(props) {
  const {
    isStopped,
    hidden,
    handleClick,
    rowsData = [],
    handleOpenModalDetails,
    rol,
    tableDefault,
    handleOpenModalDocuments,
    handleOpenModalInstallationImages,
    handleOpenModalConfirmInstallationDates,
    updateTable,
    headers,
    headersDefault,
    keysData,
    keysDataDefault,
  } = props

  function handleEstimatedDate(date, item) {
    item.estimated_install_date = date.slice(0, 10)
    item.instalation_date = date
    updateEstimatedDate(item).then(() => {
      updateTable()
    })
  }

  function getColumns() {
    // check if we are going to return default columns
    const [headersToIterate, keysToIterate] = tableDefault
      ? [headersDefault, keysDataDefault]
      : [headers, keysData]

    const columns = []
    for (let i = 0; i < headersToIterate.length; i++) {
      columns.push({
        Header: headersToIterate[i],
        accessor: keysToIterate[i],
      })
    }
    return columns
  }

  function colorStatus(status) {
    switch (status) {
      case 'Al corriente':
        return <span className="completedT">{status}</span>
      case 'Atraso':
        return <span className="pendingT">{status}</span>
      case 'Moratorio':
        return <span className="expiredT">{status}</span>
      default:
        break
    }
  }

  function getData(rows) {
    const data = []
    rows.forEach((row) => {
      data.push({
        contract_id: row.client_info?.contract_id,
        ID_Client: row.client_info?.id,
        profile: row.client_info?.profile,
        full_name: row.client_info?.full_name,
        phone: row.client_info?.phone,
        product: row.product_info?.model,
        penalized_date: row.readable_penalized_date,
        credit_pay_status: colorStatus(
          row.credit_pay_information.credit_pay_status
        ),

        documents: (
          <button
            className="btn b-primary"
            onClick={(e) => {
              e.stopPropagation()
              handleOpenModalDocuments(row.id)
            }}
          >
            Vista Previa
          </button>
        ),
        payed_distributor_date: row.payed_distributor_date || 'Pendiente',
        estatus:
          !tableDefault &&
          (row.client_info.bio_test == null ||
            !row.client_info.ine ||
            !row.client_info.ine_back ||
            !row.client_info.comprobante) ? (
            <>
              <span
                key={tagGroupListStatus[0].items[0].title}
                className={`badge badge-${tagGroupListStatus[0].items[0].color} mr-2 mb-2`}
              >
                <div className="tag-container">
                  {tagGroupListStatus[0].items[0].title}
                </div>
              </span>
              {row.client_info.bio_test == null ? (
                <span
                  key={tagGroupListStatus[0].items[4].title}
                  className={`badge badge-${tagGroupListStatus[0].items[4].color} mr-2 mb-2`}
                >
                  <div className="tag-container">
                    {tagGroupListStatus[0].items[4].title}
                  </div>
                </span>
              ) : null}
              {!row.client_info.comprobante ? (
                <span
                  key={tagGroupListStatus[0].items[2].title}
                  className={`badge badge-${tagGroupListStatus[0].items[2].color} mr-2 mb-2`}
                >
                  <div className="tag-container">
                    {tagGroupListStatus[0].items[2].title}
                  </div>
                </span>
              ) : null}
            </>
          ) : (
            <span
              key={tagGroupListStatus[0].items[3].title}
              className={`badge badge-${tagGroupListStatus[0].items[3].color} mr-2 mb-2`}
            >
              <div className="tag-container">
                {tagGroupListStatus[0].items[3].title}
              </div>
            </span>
          ),
        branch: row.branch_id || '--',
        nfc_available: !row.product_info?.nfc_available ? (
          '--'
        ) : row.system_info?.system_type === 'NFC' ? (
          <div className="custom-control custom-checkbox p-0">
            <input
              type="checkbox"
              className="custom-control-input"
              checked
              readOnly
            />
            <label className="custom-control-label centered" />
          </div>
        ) : (
          <div className="custom-control custom-checkbox p-0">
            <input type="checkbox" className="custom-control-input" readOnly />
            <label className="custom-control-label centered" />
          </div>
        ),
        service_remaining: row.credit_pay_information.service_remaining,
        estimated_install_date:
          tableDefault ||
          row.instalation_date ||
          row.estimated_instalation_date ||
          '--',
        comments: (
          <button
            className="btn b-primary"
            onClick={(e) => handleOpenModalDetails(e, row.id)}
          >
            <i className="fa fa-eye" aria-hidden="true" />
          </button>
        ),
        estatus_credito: tableDefault || row.credit_status_readable,
        action:
          row.credit_status === 'APR' ? (
            <button
              className="btn b-primary"
              onClick={(event) =>
                handleOpenModalInstallationImages(event, row.id)
              }
            >
              Adjuntar
            </button>
          ) : row.credit_status === 'EVAT' ? (
            rol === 'DS' &&
            (row.status_photos === 'UPL' ||
              row.status_photos === 'REJ' ||
              row.status_photos === 'APR') ? (
              <button
                className={
                  row.status_photos === 'REJ' ? 'btn-3' : 'btn b-primary'
                }
                onClick={(event) =>
                  handleOpenModalInstallationImages(event, row.id)
                }
              >
                {row.status_photos === 'REJ' ? 'Rechazadas' : 'Ver'}
              </button>
            ) : (
              <button
                className={
                  row.status_photos === 'REJ'
                    ? 'btn btn-warning'
                    : 'btn b-primary'
                }
                onClick={(event) =>
                  handleOpenModalInstallationImages(event, row.id)
                }
              >
                {rol === 'GR' && row.status_photos === 'APR'
                  ? 'Ver'
                  : row.status_photos === 'REJ'
                  ? 'Rechazadas'
                  : 'Ver'}
              </button>
            )
          ) : row.credit_status === 'CON' ? (
            <button
              className="btn btn-secondary"
              onClick={(e) => handleOpenModalConfirmInstallationDates(e, row)}
            >
              {row.status_dates_proposed === 'REV' && rol === 'GR' ? (
                <FaCheck />
              ) : (row.status_dates_proposed === 'REV' && rol === 'DS') ||
                (row.status_dates_proposed === 'REJ' && rol === 'GR') ? (
                'Esperando confirmación'
              ) : row.status_dates_proposed === 'REJ' && rol === 'DS' ? (
                'Fechas rechazadas'
              ) : (
                '-'
              )}
            </button>
          ) : row.credit_status === 'ATI' ? (
            <input
              className="dateInput"
              type="datetime-local"
              value={row.instalation_date !== '' ? row.instalation_date : ''}
              onChange={(e) =>
                handleEstimatedDate(e.target.value, row, updateTable)
              }
              onClick={(e) => e.stopPropagation()}
            />
          ) : null,

        id: row.id,
      })
    })
    return data
  }

  function getHiddenColumns(rol) {
    if (rol === 'DS') {
      return ['contract_id', 'service_remaining']
    }
    if (rol === 'GR') {
      return ['payed_distributor_date', 'product', 'location']
    }
    if (rol === 'FN') {
      return ['contract_id', 'service_remaining', 'product', 'location']
    }
    return []
  }

  return (
    <div className="box-table" hidden={hidden}>
      {isStopped && (
        <div className="table-responsive">
          <TableComponent
            rowsData={getData(rowsData)}
            columnsData={getColumns()}
            handleClick={handleClick}
            hiddenColumns={getHiddenColumns(rol)}
          />
        </div>
      )}

      {!isStopped && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}
