import React, { Component } from 'react'

import { Container, Row, Col, Form } from 'react-bootstrap'

import IconURLComponent from '../../Shared/IconUrl.component'
import image from '../../../assets/icons/image.svg'
import { getSystems, assignedSystem } from '../../../services/systems.service'
import LoadingComponent from '../../Shared/Loading.component'
import UploadFileComponent from '../../Shared/UploadFile'
import ModalPrepareDelivery from '../../ModalPrepareDelivery'

export default class ProductInformationComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      systemList: [],
      isStopped: false,
      id: null,
      instalation_date: props.creditInfo.instalation_date,
      id_credit: props.creditInfo.id,
      serie: null,
      equipo: null,
      showPrepareDeliveryModal: false,
    }

    this.handleSystem = this.handleSystem.bind(this)
    this.handleInputValue = this.handleInputValue.bind(this)

    getSystems().then((result) => {
      this.setState({ systemList: result })
    })
  }

  handleSystem() {
    this.setState({ isStopped: true })
    assignedSystem(this.state).then((response) => {
      window.location.reload(false)
    })
  }

  handleInputValue(value, input) {
    this.setState({ [input]: value })
  }

  render() {
    const { showPrepareDeliveryModal } = this.state
    const { creditInfo: credit } = this.props

    return (
      <>
        <Container fluid>
          <strong className="header-1"><h3>{this.props.creditInfo?.product_info?.model.toUpperCase()}</h3></strong>
            <Row>
              <Col>
                <table className="table table-borderless table-sm">
                  <thead />
                  <tbody>
                    <tr>
                      <td className="headTable">
                        <strong>Distribuidor:</strong>
                      </td>
                      <td>{this.props.creditInfo.distributor_info?.name}</td>
                    </tr>
                    <tr>
                      <td className="headTable">
                        <strong>Sucursal:</strong>
                      </td>
                      <td>{this.props.creditInfo.branch_id || '--'}</td>
                    </tr>
                    <tr>
                      <td className="headTable">
                        <strong>Tipo: </strong>
                      </td>
                      <td>{this.props.creditInfo.product_info?.size}</td>
                    </tr>
                    <tr>
                      <td className="headTable">
                        <strong>Garantía: </strong>
                      </td>
                      <td>{this.props.creditInfo.product_info?.warranty} años</td>
                    </tr>

                    <tr>
                      <td className="headTable">
                        <strong>Fecha estimada de Instalación:</strong>
                      </td>
                      <td>{this.props.creditInfo.estimated_install_date}</td>
                    </tr>

                    <tr>
                      <td className="headTable">
                        <strong>Monto a facturar:</strong>
                      </td>
                      <td>$ {this.props.creditInfo.distributor_amount} MXN</td>
                    </tr>
                  </tbody>
                </table>
              </Col>

              <Col>
                <table className="table table-borderless table-sm">
                  <thead />
                  <tbody>
                    <tr>
                      <td className="headTable">
                        <strong>Sistema asignado:</strong>
                      </td>
                      <td>
                        {this.props.creditInfo.system_info
                          ? this.props.creditInfo.system_info.serie
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="headTable">
                        <strong>Fecha de Instalación: </strong>
                      </td>
                      <td>{this.props?.creditInfo?.instalation_date || '-'}</td>
                    </tr>

                    <tr>
                      <td className="headTable">
                        <strong>Número de guía:&nbsp;</strong>
                      </td>
                      <td>{this.props.creditInfo.tracking_number}</td>
                    </tr>

                    <tr>
                      <td className="headTable">
                        <strong>Link de rastreo:&nbsp;</strong>
                      </td>
                      <td>{this.props.creditInfo.tracking_link}</td>
                    </tr>
                    <tr>
                      <td className="headTable">
                        <strong>Ubicación: </strong>
                      </td>
                      <td>
                        <a
                          href={this.props?.creditInfo?.client_info?.map}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <u>Ver mapa aqui</u>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            {this.props.creditInfo.equipo !== null &&
                    this.props.creditInfo.serie !== null && (
                <Row>
                  <IconURLComponent
                    text="Número de Serie"
                    url={this.props.creditInfo.serie}
                    icon={image}
                  />
                  <IconURLComponent
                    text="Equipo Instalado"
                    url={this.props.creditInfo.equipo}
                    icon={image}
                  />    
                </Row>
              )}
            <Row>
              <Col>
                {this.state.isStopped === false && (
                  <button
                    className="btn-2 b-primary"
                    onClick={(e) =>
                      this.setState({ showPrepareDeliveryModal: true })
                    }
                  >
                    Preparar
                  </button>
                )}

                {this.state.isStopped === true && (
                  <LoadingComponent isStopped={this.state.isStopped} />
                )}
              </Col>
            </Row>

            {/* {this.props.creditInfo.equipo === null && this.props.creditInfo.serie === null && this.props.creditInfo.system_info && */}
            <>
              <Col>
              {this.state.isStopped === false && (
                <><Row>
                    {this.props.creditInfo.serie === null && (
                      <Col>
                        <UploadFileComponent
                          typeFile="Número de Serie"
                          onChangeFile={(file) => this.handleInputValue(file, 'serie')}
                          id="serie" />
                      </Col>
                    )}

                    {this.props.creditInfo.equipo === null && (
                      <Col>
                        <UploadFileComponent
                          typeFile="Equipo Instalado"
                          onChangeFile={(file) => this.handleInputValue(file, 'equipo')}
                          id="equipo" />
                      </Col>
                    )}
                  </Row>
                  <Row lg={4} md={4} sm={12} xs={12}>
                    <Col>
                      <img
                        src={this.props.creditInfo.product_info?.image}
                        alt={this.props.creditInfo.product_info?.model}
                        style={{ width: '100%', padding: '10px' }}
                      />
                    </Col>
                    <Col>
                      <br />
                      <button
                        className="btn-2 b-primary"
                        style={{ marginTop: '5px' }}
                        onClick={this.handleSystem}
                      >
                        Subir evidencias
                      </button>
                    </Col>
                  </Row></>
              )}

              {this.state.isStopped === true && (
                <Col lg={12} md={12} sm={12} xs={12}>
                  <LoadingComponent isStopped={this.state.isStopped} />
                </Col>
              )}
              </Col>
            </>
            {/* } */}
        </Container>
        <ModalPrepareDelivery
          show={showPrepareDeliveryModal}
          credit={credit}
          onClose={() => this.setState({ showPrepareDeliveryModal: false })}
        />
      </>
    )
  }
}
