import React, { Component } from 'react'
import UploadFileComponent from '../../Shared/UploadFile'
import '../../Shared/css/uploadFile.css'
import { updateCredit } from '../../../services/accounts.service'
import LoadingComponent from '../../Shared/Loading.component'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
]

export default class ContractInformationComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.creditInfo.id,
      contrato: null,
      contrato_firmado: null,
      contract_validated: false,
      pay_day: null,
      contract_id: null,
      product: this.props.creditInfo.product,
      isStopped: true,
      rol: localStorage.getItem("user_type"), // eslint-disable-line
    }

    this.handleInputValue = this.handleInputValue.bind(this)
    this.handleUploadContract = this.handleUploadContract.bind(this)
  }

  handleInputValue(value, input) {
    this.setState({ [input]: value })
  }

  handleUploadContract() {
    this.setState({ isStopped: false })
    updateCredit(this.state).then((response) => {
      if (response.status === 202) {
        setTimeout(() => {
          window.location.reload(false)
        }, 1000)
      } else {
        this.setState({ isStopped: true })
      }
    })
  }

  render() {
    return (
      <Container>
        <Row>
          {this.state.isStopped === true &&
            this.props.creditInfo.contract_id === '' &&
            this.state.rol !== 'DS' && (
              <>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <br />
                  <select
                    className="input-g2"
                    onChange={(e) => this.setState({ pay_day: e.target.value })}
                  >
                    <option value="-">-</option>
                    {days.map((item, i) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <span className="labelInput">Día de corte</span>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <br />
                  <input
                    type="text"
                    className="input-g2"
                    placeholder="ID de Contrato"
                    onChange={(e) =>
                      this.setState({ contract_id: e.target.value })
                    }
                  />
                </Col>
                {/* <Col lg={3} md={3} sm={12} xs={12}>
                    <div>
                      ¿Contrato validado?
                      <label className='ml-4'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='contract_validated'
                          value='true'
                          onChange={(e) =>
                            this.handleInputValue(
                              e.currentTarget.value,
                              'contract_validated'
                            )}
                        />
                        Si
                      </label>
                    </div>
                    <div>
                      <label className='ml-4'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='contract_validated'
                          value='false'
                          onChange={(e) =>
                            this.handleInputValue(
                              e.currentTarget.value,
                              'contract_validated'
                            )}
                        />
                        No
                      </label>
                    </div>
                  </Col> */}
                <Col lg={3} md={3} sm={12} xs={12}>
                  <UploadFileComponent
                    typeFile="Contrato"
                    onChangeFile={(file) =>
                      this.handleInputValue(file, 'contrato')
                    }
                    id="contrato"
                  />
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <br />
                  <button
                    className="btn-2 b-primary"
                    onClick={this.handleUploadContract}
                  >
                    Actualizar Contrato
                  </button>
                </Col>
              </>
            )}

          {this.state.isStopped === true &&
            this.props.creditInfo.contract_id !== '' && (
              <>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <UploadFileComponent
                    typeFile="Contrato Firmado"
                    onChangeFile={(file) =>
                      this.handleInputValue(file, 'contrato_firmado')
                    }
                    id="contrato_firmado"
                  />
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <br />
                  <button
                    className="btn-2 b-primary"
                    onClick={this.handleUploadContract}
                  >
                    Actualizar Contrato
                  </button>
                </Col>
              </>
            )}

          {this.state.isStopped === false && (
            <LoadingComponent isStopped={this.state.isStopped} />
          )}
        </Row>
      </Container>
    )
  }
}
