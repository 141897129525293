import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Popup from 'reactjs-popup'
import './css/modal.css'
import { Button } from 'react-bootstrap'
import LoadingComponent from './Loading.component'

export default class ModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: null,
      isStopped: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal(close) {
    this.setState({ isStopped: false })
    close()
  }

  handleSubmit(close) {
    this.setState({ isStopped: true })
    this.props.onClick(this.state.comments, () => this.closeModal(close))
  }

  render() {
    return (
      <Popup
        onOpen={(e) => e.stopPropagation()}
        trigger={
          this.props.like === 'button' ? (
            <button className="btn b-primary">{this.props.text}</button>
          ) : this.props.like === 'link' ? (
            <button type="button" className="btn btn-link">
              <strong>{this.props.text}</strong>
            </button>
          ) : (
            <Button
              variant={`outline-${this.props.buttonVariant}`}
              className="font-weight-normal rounded-pill pt-0 pb-0 pl-4 pr-4"
              hidden={this.props.hidden}
            >
              {this.props.title}
            </Button>
          )
        }
        modal
        closeOnDocumentClick={false}
      >
        {(close) => (
          <Container style={{ padding: '40px' }}>
            <Row>
              <Col className="text-center">
                <h1>{this.props.h1}</h1>
                <br />
                <p style={{ fontSize: '12px' }}>{this.props.sub}</p>
              </Col>
            </Row>

            {this.state.isStopped === false && (
              <>
                <Row hidden={!this.props.textArea}>
                  <Col>
                    <input
                      className="input-g2"
                      type="textArea"
                      placeholder={
                        this.props.text === 'Restablecer contraseña'
                          ? 'Contraseña'
                          : 'Comentario'
                      }
                      onChange={(e) => this.props.comments(e.target.value)}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col />
                  <Col>
                    <button
                      className="btn-2 b-primary"
                      onClick={() => this.handleSubmit(close)}
                    >
                      {this.props.button}
                    </button>
                  </Col>
                  <Col>
                    <button className="btn-3" onClick={() => close()}>
                      Cancelar
                    </button>
                  </Col>
                  <div className="col" />
                </Row>
              </>
            )}

            {this.state.isStopped === true && (
              <LoadingComponent isStopped={this.state.isStopped} />
            )}
          </Container>
        )}
      </Popup>
    )
  }
}
