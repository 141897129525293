import React from 'react'
import { FaCheck } from 'react-icons/fa'
import LoadingComponent from '../Shared/Loading.component'
import TableComponent from '../Shared/Table.component'
import { updateEstimatedDate } from '../../services/accounts.service'
import { tagGroupListStatus } from './SectionsDetailsAccount/SectionGeneral/utilsStatus'

const headers = [
  'NOMBRE',
  'TELÉFONO',
  'PRODUCTO',
  'COMERCIO',
  'ESTATUS',
  'COMENTARIOS',
  'ESTAUS DEL CRÉDITO',
  'ACCIONES',
]

const headersDefault = [
  'NOMBRE',
  'TELÉFONO',
  'PRODUCTO',
  'COMERCIO',
  'ESTATUS',
  'COMENTARIOS',
]

export default function ToSignTableComponent(props) {
  const {
    handleClick,
    isStopped,
    hidden,
    rowsData,
    updateTable,
    handleOpenModalDetails,
    tableDefault,
    handleOpenModalInstallationImages,
    handleOpenModalConfirmInstallationDates,
    rol,
  } = props

  function handleEstimatedDate(date, item) {
    item.estimated_install_date = date.slice(0, 10)
    item.instalation_date = date
    updateEstimatedDate(item).then(() => {
      updateTable()
    })
  }

  function getColumns(headers, data) {
    const columns = []
    for (let i = 0; i < headers.length; i++) {
      columns.push({
        Header: headers[i],
        accessor: data[i],
      })
    }
    return columns
  }

  function getData(rows) {
    const data = []
    rows.forEach((row) => {
      data.push({
        full_name: row.client_info?.full_name,
        ID_Client: row.client_info?.id,
        profile: row.client_info?.profile,
        phone: row.client_info?.phone,
        product: row.product_info?.model,
        comercio: row.distributor_info?.name,
        estatus:
          row.client_info?.bio_test == null ||
          !row.client_info?.ine ||
          !row.client_info?.ine_back ||
          !row.client_info?.comprobante ? (
            <>
              <span
                key={tagGroupListStatus[0].items[0].title}
                className={`badge badge-${tagGroupListStatus[0].items[0].color} mr-2 mb-2`}
              >
                <div className="tag-container">
                  {tagGroupListStatus[0].items[0].title}
                </div>
              </span>
              {row.client_info?.bio_test == null ? (
                <span
                  key={tagGroupListStatus[0].items[4].title}
                  className={`badge badge-${tagGroupListStatus[0].items[4].color} mr-2 mb-2`}
                >
                  <div className="tag-container">
                    {tagGroupListStatus[0].items[4].title}
                  </div>
                </span>
              ) : null}
              {!row.client_info?.comprobante ? (
                <span
                  key={tagGroupListStatus[0].items[2].title}
                  className={`badge badge-${tagGroupListStatus[0].items[2].color} mr-2 mb-2`}
                >
                  <div className="tag-container">
                    {tagGroupListStatus[0].items[2].title}
                  </div>
                </span>
              ) : null}
            </>
          ) : (
            <span
              key={tagGroupListStatus[0].items[3].title}
              className={`badge badge-${tagGroupListStatus[0].items[3].color} mr-2 mb-2`}
            >
              <div className="tag-container">
                {tagGroupListStatus[0].items[3].title}
              </div>
            </span>
          ),
        comments: (
          <button
            className="btn b-primary"
            onClick={(e) => handleOpenModalDetails(e, row.id)}
          >
            <i className="fa fa-eye" aria-hidden="true" />
          </button>
        ),
        estatus_credito: tableDefault || row?.credit_status_readable,
        action:
          row?.credit_status === 'APR' ? (
            <button
              className="btn b-primary"
              onClick={(event) =>
                handleOpenModalInstallationImages(event, row.id)
              }
            >
              Adjuntar
            </button>
          ) : row?.credit_status === 'EVAT' ? (
            rol === 'DS' &&
            (row?.status_photos === 'UPL' ||
              row?.status_photos === 'REJ' ||
              row?.status_photos === 'APR') ? (
              <button
                className={
                  row.status_photos === 'REJ' ? 'btn-3' : 'btn b-primary'
                }
                onClick={(event) =>
                  handleOpenModalInstallationImages(event, row.id)
                }
              >
                {row.status_photos === 'REJ' ? 'Rechazadas' : 'Ver'}
              </button>
            ) : (
              <button
                className={
                  row?.status_photos === 'REJ'
                    ? 'btn btn-warning'
                    : 'btn b-primary'
                }
                onClick={(event) =>
                  handleOpenModalInstallationImages(event, row.id)
                }
              >
                {rol === 'GR' && row?.status_photos === 'APR'
                  ? 'Ver'
                  : row?.status_photos === 'REJ'
                  ? 'Rechazadas'
                  : 'Ver'}
              </button>
            )
          ) : row?.credit_status === 'CON' ? (
            <button
              className="btn btn-secondary"
              onClick={(e) => handleOpenModalConfirmInstallationDates(e, row)}
            >
              {row?.status_dates_proposed === 'REV' && rol === 'GR' ? (
                <FaCheck />
              ) : (row?.status_dates_proposed === 'REV' && rol === 'DS') ||
                (row?.status_dates_proposed === 'REJ' && rol === 'GR') ? (
                'Esperando confirmación'
              ) : row?.status_dates_proposed === 'REJ' && rol === 'DS' ? (
                'Fechas rechazadas'
              ) : (
                '-'
              )}
            </button>
          ) : row?.credit_status === 'ATI' ? (
            <input
              className="dateInput"
              type="datetime-local"
              value={row?.instalation_date !== '' ? row?.instalation_date : ''}
              onChange={(e) =>
                handleEstimatedDate(e.target.value, row, updateTable)
              }
              onClick={(e) => e.stopPropagation()}
            />
          ) : null,
        id: row.id,
      })
    })
    return data
  }

  return (
    <div className="box-table" hidden={hidden}>
      {isStopped && (
        <div className="table-responsive">
          {tableDefault ? (
            <TableComponent
              rowsData={getData(rowsData)}
              columnsData={getColumns(headersDefault, [
                'full_name',
                'phone',
                'product',
                'comercio',
                'estatus',
                'comments',
              ])}
              handleClick={handleClick}
            />
          ) : (
            <TableComponent
              rowsData={getData(rowsData)}
              columnsData={getColumns(headers, [
                'full_name',
                'phone',
                'product',
                'comercio',
                'estatus',
                'comments',
                'estatus_credito',
                'action',
              ])}
              handleClick={handleClick}
            />
          )}
        </div>
      )}
      {!isStopped && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}
