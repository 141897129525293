import React from 'react'
import * as CurrencyFormat from 'react-currency-format'
import LoadingComponent from '../../Shared/Loading.component'

export default function DepositsDetailsTableComponent({
  isStopped,
  summary,
  hidden,
  headers,
}) {
  return (
    <div className="box-table" hidden={hidden}>
      {isStopped === false && (
        <div className="table-responsive">
          <table className="table table-hover table-graviti">
            <thead>
              <tr>
                {headers.map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="textPush">{summary.transactions}</td>
                <td className="textPush">
                  <CurrencyFormat
                    value={summary.total_transacted}
                    displayType="text"
                    thousandSeparator
                    prefix="$ "
                  />
                </td>
                <td className="textPush">
                  <CurrencyFormat
                    value={summary.payed}
                    displayType="text"
                    thousandSeparator
                    prefix="$ "
                  />
                </td>
                <td className="textPush">
                  <CurrencyFormat
                    value={summary.total_commission}
                    displayType="text"
                    thousandSeparator
                    prefix="$ "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {isStopped && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}
