import React from 'react'
import { Card, Row, Col} from 'react-bootstrap'
const MobileQR = ({ onChangeModal }) => {
  return (
    <>
      <Row className='text-center'>
        <Col className='resultado-title'>
          <h6>VERIFICACIÓN DE CÓDIGO</h6>
          <p>Escanea el código del comprador</p>
        </Col>
      </Row>
      <button className='btn-2 b-secondary-2' onClick={onChangeModal}>
        Escanear
      </button>
      <div>
        <Card className='pb-0 pt-0' border='secondary'>
          <Card.Body>
            <Card.Text>
              <Row>
                <Col className='text-right'>
                  <svg
                    className='w-50'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                  >
                    <path d='M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z' />
                  </svg>
                </Col>
                <Col>
                  <svg
                    className='w-50'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 576 512'
                  >
                    <path d='M384 64H192C86 64 0 150 0 256s86 192 192 192h192c106 0 192-86 192-192S490 64 384 64zm0 320c-70.8 0-128-57.3-128-128 0-70.8 57.3-128 128-128 70.8 0 128 57.3 128 128 0 70.8-57.3 128-128 128z' />
                  </svg>
                </Col>
              </Row>
              <Row>
                <Col className='p-0 text-center'>
                  <small>
                    Recuerda que debes dar permiso a tu cámara con tu teléfono
                    móvil
                  </small>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default MobileQR
