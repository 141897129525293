import React from 'react'
import ProgressBar from './ProgressBar'
import BarItem from './BarItem'
import { creditStatusHeaders } from './utils'

function CreditProgressBar({ status, lastStatus = null }) {
  let stop = 0
  const statusToFind = ['REJ', 'NC'].includes(status) ? lastStatus : status
  stop = creditStatusHeaders.findIndex((item) => item.code === statusToFind) + 1
  const rejected = status === 'REJ'
  const unfinished = status === 'NC'
  return (
    <ProgressBar>
      {creditStatusHeaders.map((header, idx) => (
        <BarItem
          key={header.id}
          rejected={rejected && header.label === 'Rechazado'}
          unfinished={unfinished && header.label === 'Inconcluso'}
          idx={idx}
          stop={stop}
          header={header.label}
          count={creditStatusHeaders?.length}
        />
      ))}
    </ProgressBar>
  )
}

export default CreditProgressBar
