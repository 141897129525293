import React, { useState, useEffect, useCallback } from 'react'
import * as CurrencyFormat from 'react-currency-format'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import debounce from 'lodash.debounce'
import SearchProduct from './SearchProduct/searchProduct'
import PaginatorComponent from '../Shared/Paginator.component'
import LoadingComponent from '../Shared/Loading.component'

// Services
import msProductsService from '../../services/ms/ms-products-service'

const headers = [
  'Merchant',
  'Sku',
  'Brand',
  'Modelo',
  'Product Type',
  'Descripción',
  'Precio',
  'Stock',
  'Validar',
  'Rechazar',
]

function ProductsPendingComponent({ hidden }) {
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [itemsCountPerPage, setItemsCountPerPage] = useState(15)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)

  const loadProducts = useCallback(
    debounce((page, search) => {
      setLoading(true)
      msProductsService
        .getNonValidatedProduct(page, search)
        .then((products) => {
          setPage(products.page)
          setTotalItemsCount(products.total)
          setRows(products.results)
          setLoading(false)
        })
    }, 1000),
    []
  )

  useEffect(() => {
    loadProducts(page, search)
  }, [loadProducts, page, search])

  const handleValidated = (e, data) => {
    Swal.fire({
      title: '¿Estás seguro de que quieres validar el producto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        msProductsService
          .validateProduct(data.id)
          .then(() => {
            Swal.fire('Listo!')
            setRows(rows.filter((r) => r.id !== data.id))
          })
          .catch((err) =>
            Swal.fire(
              'Ocurrió un error',
              err.response.data.description,
              'error'
            )
          )
      }
    })
  }

  const handleRejected = (e, data) => {
    Swal.fire({
      title: '¿Estás seguro de que quieres eliminar el producto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        msProductsService
          .deleteProductBranch(data.id)
          .then(() => {
            Swal.fire('Listo!')
            setRows(rows.filter((r) => r.id !== data.id))
          })
          .catch((err) =>
            Swal.fire(
              'Ocurrió un error',
              err.response.data.description,
              'error'
            )
          )
      }
    })
  }

  const handlePaginator = (number) => {
    setPage(number)
  }

  if (loading) {
    return <LoadingComponent isStopped={loading} />
  }

  return (
    <>
      <div className="row pb-3">
        <div className="col-md-6 offset-3">
          <SearchProduct value={search} onChange={setSearch} />
        </div>
      </div>
      <div className="box-table" hidden={hidden}>
        <div className="table-responsive">
          <table className="table table-hover table-graviti">
            <thead>
              <tr>
                {headers.map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((item, i) => (
                <tr key={item.id}>
                  <td className="textPush">{item.merchant}</td>
                  <td className="textPush">{item.SKU}</td>
                  <td className="textPush">{item.brand}</td>
                  <td className="textPush">{item.model}</td>
                  <td className="textPush">{item.subcategory}</td>
                  <td className="textPush">{item.description}</td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.price}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                    />
                  </td>
                  <td className="textPush">{item.stock}</td>
                  <td className="textPush">
                    <Button
                      variant="info"
                      onClick={(e) => {
                        handleValidated(e, item)
                      }}
                    >
                      <i className="fa fa-check" aria-hidden="true" />
                    </Button>
                  </td>
                  <td className="textPush">
                    <Button
                      variant="danger"
                      onClick={(e) => handleRejected(e, item)}
                    >
                      <i className="fa fa-minus" aria-hidden="true" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <PaginatorComponent
        activePage={page}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        handlePageChange={(number) => handlePaginator(number)}
      />
    </>
  )
}

export default ProductsPendingComponent
