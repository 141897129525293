import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class FinancialInformationComponent extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <table className="table table-borderless table-sm">
              <thead />
              <tbody>
                <tr>
                  <td className="headTable">Plazo a meses:</td>
                  <td>{this.props.creditInfo.pay_data_info.weeks / 4} Meses</td>
                </tr>
                <tr>
                  <td className="headTable">Tasa: </td>
                  <td>
                    {this.props.creditInfo.financial_model_info?.interest} %
                  </td>
                </tr>
                <tr>
                  <td className="headTable">Monto desembolso: </td>
                  <td>
                    ${' '}
                    {this.props.creditInfo.financial_model_info?.financial_to_graviti.toLocaleString()}{' '}
                    MXN
                  </td>
                </tr>

                <tr>
                  <td className="headTable">Monto a recibir: </td>
                  <td>
                    ${' '}
                    {this.props.creditInfo.financial_model_info?.financial_net_pay?.toLocaleString() ||
                      0}{' '}
                    MXN
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col>
            <table className="table table-borderless table-sm">
              <thead />
              <tbody>
                <tr>
                  <td className="headTable">Día de corte:</td>
                  <td>{this.props.creditInfo.pay_day} </td>
                </tr>
                <tr hidden={this.props.creditInfo.amounts.opening_amount === 0}>
                  <td className="headTable">Cuota de apertura:</td>
                  <td>
                    ${' '}
                    {this.props.creditInfo.amounts.opening_amount.toLocaleString()}{' '}
                    MXN
                  </td>
                </tr>
                <tr>
                  <td className="headTable">Mensualidad: </td>
                  <td>
                    $ {this.props.creditInfo.amounts.month.toLocaleString()} MXN
                  </td>
                </tr>
                <tr hidden={this.props.creditInfo.amounts.initial_amount === 0}>
                  <td className="headTable">Pago inicial: </td>
                  <td>
                    ${' '}
                    {this.props.creditInfo.amounts.initial_amount.toLocaleString()}{' '}
                    MXN
                  </td>
                </tr>
                <tr>
                  <td className="headTable">Total a pagar usuario: </td>
                  <td>
                    ${' '}
                    {this.props.creditInfo.credit_initial_amount.toLocaleString()}{' '}
                    MXN
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    )
  }
}
