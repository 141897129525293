import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import { updateCreditStatus } from '../../services/accounts.service'
import './style.css'

function ModalDocuments({ isOpen, onClose, credit, onUpdateCredit, role }) {
  const [loading, setLoading] = useState(false)

  const handleChangeInput = (e) => {
    const data = {
      [e.target.name]: e.target.checked,
    }
    setLoading(true)
    updateCreditStatus(data, credit.id).then(() => {
      onUpdateCredit()
      setLoading(false)
    })
  }

  return (
    <Popup
      open={isOpen}
      onClose={onClose}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <div className="Modal">
        <div className="Modal__Header">
          <div className="Modal__Header-title">
            <h1>Estatus de documentos</h1>
            <p className="text-muted">
              Si algún documento no ha sido cargado, podrá hacerlo en el detalle
              de la cuenta.
            </p>
          </div>
          <span className="Modal__Header-close" onClick={onClose}>
            X
          </span>
        </div>

        <div className="Modal__Content row">
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Factura</h6>
                <p className="card-subtitle mb-2 text-muted">
                  <small>
                    {credit?.xml ? (
                      <a href={credit?.xml} target="_blank" rel="noreferrer">
                        XML
                      </a>
                    ) : (
                      'XML'
                    )}
                    {' y '}
                    {credit?.pdf ? (
                      <a href={credit?.pdf} target="_blank" rel="noreferrer">
                        PDF
                      </a>
                    ) : (
                      'PDF'
                    )}
                  </small>
                </p>
                {credit?.xml && (
                  <span className="badge badge-info text-wrap">
                    Factura XML Cargada
                  </span>
                )}
                {credit?.pdf && (
                  <span className="badge badge-warning text-wrap">
                    Factura PDF Cargada
                  </span>
                )}
                {credit?.xml_validated && (
                  <span className="badge badge-success text-wrap">
                    Factura XML Validada
                  </span>
                )}
                {credit?.pdf_validated && (
                  <span className="badge badge-success text-wrap">
                    Factura PDF Validada
                  </span>
                )}
              </div>
            </div>
            {credit?.xml && role === 'GR' && (
              <label>
                <input
                  type="checkbox"
                  checked={credit?.xml_validated}
                  name="xml_validated"
                  onChange={handleChangeInput}
                  disabled={loading}
                />
                {loading ? (
                  <div
                    className="spinner-border ml-2 spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ' Validar Factura XML'
                )}
              </label>
            )}
            {credit?.pdf && role === 'GR' && (
              <label>
                <input
                  type="checkbox"
                  checked={credit?.pdf_validated}
                  name="pdf_validated"
                  onChange={handleChangeInput}
                  disabled={loading}
                />
                {loading ? (
                  <div
                    className="spinner-border ml-2 spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ' Validar Factura PDF'
                )}
              </label>
            )}
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Contrato</h6>
                <p className="card-subtitle mb-2 text-muted">
                  <small>
                    {credit?.contrato_firmado ? (
                      <a
                        href={credit?.contrato_firmado}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Firmado
                      </a>
                    ) : (
                      'Firmado'
                    )}
                  </small>
                </p>
                {credit?.contrato_firmado && (
                  <span className="badge badge-warning text-wrap">
                    Firmado Cargado
                  </span>
                )}
                {credit?.contract_validated && (
                  <span className="badge badge-success text-wrap">
                    Firmado Validado
                  </span>
                )}
              </div>
            </div>
            {credit?.contrato_firmado && role === 'GR' && (
              <label>
                <input
                  type="checkbox"
                  checked={credit?.contract_validated}
                  name="contract_validated"
                  onChange={handleChangeInput}
                  disabled={loading}
                />
                {loading ? (
                  <div
                    className="spinner-border ml-2 spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ' Validar Contrato Firmado'
                )}
              </label>
            )}
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Imágenes de instalación</h6>
                <p className="card-subtitle mb-2 text-muted">
                  <small>
                    {credit?.equipo ? (
                      <a href={credit?.equipo} target="_blank" rel="noreferrer">
                        Equipo
                      </a>
                    ) : (
                      'Equipo'
                    )}
                    {' y '}
                    {credit?.serie ? (
                      <a href={credit?.serie} target="_blank" rel="noreferrer">
                        Medidor
                      </a>
                    ) : (
                      'Medidor'
                    )}
                  </small>
                </p>
                {credit?.equipo && (
                  <span className="badge badge-info text-wrap">
                    Imágen Equipo Cargada
                  </span>
                )}
                {credit?.serie && (
                  <span className="badge badge-info text-wrap">
                    Imágen Medidor Cargada
                  </span>
                )}
                {credit?.equipo_validated && (
                  <span className="badge badge-success text-wrap">
                    Imágen Equipo Validada
                  </span>
                )}
                {credit?.serie_validated && (
                  <span className="badge badge-success text-wrap">
                    Imágen Medidor Validada
                  </span>
                )}
              </div>
            </div>
            {credit?.equipo && role === 'GR' && (
              <label>
                <input
                  type="checkbox"
                  checked={credit?.equipo_validated}
                  name="equipo_validated"
                  onChange={handleChangeInput}
                  disabled={loading}
                />
                {loading ? (
                  <div
                    className="spinner-border ml-2 spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ' Validar Imágen Equipo'
                )}
              </label>
            )}
            {credit?.serie && role === 'GR' && (
              <label>
                <input
                  type="checkbox"
                  checked={credit?.serie_validated}
                  name="serie_validated"
                  onChange={handleChangeInput}
                  disabled={loading}
                />
                {loading ? (
                  <div
                    className="spinner-border ml-2 spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ' Validar Imágen Serie'
                )}
              </label>
            )}
          </div>
        </div>
        <div className="Modal__Footer row">
          <div className="col-4">
            <button className="btn-2 b-primary" onClick={onClose}>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default ModalDocuments
