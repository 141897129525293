import React, { useEffect, useState } from 'react'
import SideMenuComponent from '../Shared/SideMenu.component'
import InstallationsTableComponent from './installationsTable.component'
import DepositsTableComponent from './depositsTable.component'
import DepositsDetailsComponent from './AccountinDetails/depositsDetails.component'
import {
  getDepositsDistributor,
  getDisbursement,
  getMerchantPayouts,
  getPayoutsSumary,
} from '../../services/payments.service'
import PaginatorComponent from '../Shared/Paginator.component'
import HowToPayComponent from '../Shared/ModalHowToPay.component'
import pagoHelp from '../../assets/icons/PagoHelp.svg'
import invoices from '../../assets/icons/Facturas.svg'
import InvoicesComponent from '../Shared/ModalInvoices.component'
import { getDeposits } from '../../services/deposits.service'
import DepositsTableDSComponent from './depositsTableDS.component'
import DepositsDetailsTableDsComponent from './AccountinDetails/depositsDetailsTableDS.component'
import { getTransactionsPerMonth } from '../../services/transactions.service'
import TransactionsByMonthTableComponent from './transactionsByMonthTableComponent'
import { baseUrl, origin } from '../../services/constants'

const headersInstalls = [
  'Periodo',
  'Monto',
  'Concepto de pago',
  'Fecha límite de pago',
  'No. Instalaciones',
  'Fecha de Pago',
]
const headersDeposits = [
  'Periodo',
  'Fecha de depósito',
  'Cuenta destino',
  'Total depositado',
]
const headersDepositsDS = [
  'Periodo',
  'Monto',
  'No. Instalaciones',
  'Fecha de Pago',
]
const headersTransactions = [
  'Inicio del Período',
  'Capital',
  'Intereses',
  'IVA Intereses',
  'Comisiones',
  'IVA Comisiones',
  'Transaccionado',
  'No. de transacciones',
]

const headersDSDetails = [
  'Contrato',
  'Nombre',
  'Brand',
  'Modelo',
  'Instalation Date',
  'Amount',
]

const menuAccounting = [
  { id: 1, status: 'Desembolsos' },
  { id: 2, status: 'Depositos' },
  { id: 3, status: 'Transacciones' },
  { id: 4, status: '' },
]

const menuAccountingDS = [
  { id: 1, status: 'Depósitos' },
  { id: 2, status: '' },
]

export default function AccountingComponent() {
  const [showTable, setShowTable] = useState(1)
  const [rows, setRows] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [itemsCountPerPage] = useState(10)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [isStopped, setIsStopped] = useState(false)
  const [customers, setCustomers] = useState([])
  const [summary, setSummary] = useState(null)

  useEffect(() => {
    const user = localStorage.getItem('user_type')
    if (user !== 'DS') {
      // eslint-disable-line
      getDisbursement().then(({ results, count }) => {
        setRows(results)
        setIsStopped(false)
        setTotalItemsCount(count)
      })
    } else {
      getDepositsDistributor().then((response) => {
        setRows(response.pay)
        setIsStopped(false)
        setTotalItemsCount(response.lenght)
      })
    }
  }, [])

  const fetchData = (fetchFunction, ...args) => {
    fetchFunction(...args).then(({ results, count }) => {
      setRows(results)
      setIsStopped(false)
      setTotalItemsCount(count)
    })
  }

  const handleStatus = (item) => {
    setIsStopped(true)
    setShowTable(item.id)
    switch (item.id) {
      case 1:
        if (localStorage.getItem('user_type') !== 'DS') {
          // eslint-disable-line
          fetchData(getDisbursement)
        } else {
          getDepositsDistributor().then((response) => {
            setRows(response)
            setIsStopped(false)
            setTotalItemsCount(response.lenght)
          })
        }
        break
      case 2:
        getDeposits().then(({ payouts, count }) => {
          setRows(payouts)
          setIsStopped(false)
          setTotalItemsCount(count)
        })
        break
      case 3:
        fetchData(getTransactionsPerMonth)
        break
      default:
        break
    }
  }

  const handleRedirectPageDetail = (id) => {
    const path = `/contabilidad/instalaciones/detalles/${id}`
    window.location.pathname = path
  }

  const handleRedirectPageDetailDepositsDS = (item) => {
    setIsStopped(true)
    getMerchantPayouts(item).then((response) => {
      setCustomers(response)
      setShowTable(2)
      setIsStopped(false)
    })
  }

  const handleRedirectPageDetailDeposits = (startDate, endDate) => {
    const start = new Date(startDate).toISOString()
    const end = new Date(endDate).toISOString()
    setIsStopped(true)
    getPayoutsSumary(start, end).then((response) => {
      setSummary(response)
      setShowTable(4)
      setIsStopped(false)
    })
  }

  const handleRedirectPageTransactionsInMonth = (key) => {
    const path = `/contabilidad/transacciones/${key}/${activePage}`
    window.location.pathname = path
  }

  const handleRedirectPageCredit = (id) => {
    const path = `/cuentas/detalles/${id}`
    window.location.pathname = path
  }

  const handlePaginator = (number) => {
    setActivePage(number)
    setRows([])
    setIsStopped(true)
    switch (showTable) {
      case 1:
        fetchData(getDisbursement)
        break
      case 3:
        fetchData(getTransactionsPerMonth, number)
        break
      default:
        break
    }
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h1>CONTABILIDAD</h1>
        </div>
        <div
          className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
          style={{ marginTop: '20px' }}
          hidden={localStorage.getItem('user_type') === 'DS'}
            // eslint-disable-line
        >
          <div className="row">
            {showTable === 3 ? (
              <div>
                <a
                  href={`${origin}${baseUrl}transactions/sales-by-period/export/`}
                >
                  <black>Descargar reporte</black>
                </a>
              </div>
            ) : (
              <>
                <div className="col">
                  <InvoicesComponent icon={invoices} />
                </div>
                <div className="col">
                  <HowToPayComponent icon={pagoHelp} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-2 col-md-2">
          <SideMenuComponent
            onSelectStatus={handleStatus}
            menu={
                localStorage.getItem("user_type") !== "DS" // eslint-disable-line
                ? menuAccounting
                : menuAccountingDS
            }
          />
        </div>
        <div className="col-lg-10 col-md-10">
            {localStorage.getItem("user_type") !== "DS" && ( // eslint-disable-line
            <>
              <InstallationsTableComponent
                isStopped={isStopped}
                headers={headersInstalls}
                rows={rows}
                hidden={showTable !== 1}
                handleClick={handleRedirectPageDetail}
              />
              <DepositsTableComponent
                isStopped={isStopped}
                headers={headersDeposits}
                rows={rows}
                hidden={showTable !== 2}
                handleClick={handleRedirectPageDetailDeposits}
              />
              <TransactionsByMonthTableComponent
                isStopped={isStopped}
                headers={headersTransactions}
                hidden={showTable !== 3}
                rows={rows}
                handleClick={handleRedirectPageTransactionsInMonth}
              />
              <DepositsDetailsComponent
                isStopped={isStopped}
                hidden={showTable !== 4}
                handleClick={handleRedirectPageDetailDepositsDS}
                rows={rows}
                summary={summary}
              />
            </>
          )}
            {localStorage.getItem("user_type") === "DS" && ( // eslint-disable-line
            <>
              <DepositsTableDSComponent
                isStopped={isStopped}
                headers={headersDepositsDS}
                rows={rows}
                hidden={showTable !== 1}
                handleClick={handleRedirectPageDetailDepositsDS}
                customers={customers}
              />
              <DepositsDetailsTableDsComponent
                isStopped={isStopped}
                headers={headersDSDetails}
                hidden={showTable !== 2}
                handleClick={handleRedirectPageCredit}
                rows={rows}
                customers={customers}
              />
            </>
          )}

          <br />
          <PaginatorComponent
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            handlePageChange={(number) => {
              handlePaginator(number)
            }}
          />
        </div>
      </div>
    </div>
  )
}
