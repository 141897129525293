import React from 'react'
import DepositsDetailsTableComponent from './depositsDetailsTable.component'

import LoadingComponent from '../../Shared/Loading.component'

const headersDeposits = [
  'Transactions',
  'Total Transacciones',
  'Payed',
  'Total Comisiones',
]

export default function DepositsDetailsComponent({ isStopped, summary }) {
  return (
    <>
      {isStopped === false && summary !== null && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>DETALLE DE LOS DEPOSITOS</h1>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <DepositsDetailsTableComponent
                headers={headersDeposits}
                summary={summary}
                hidden={false}
                isStopped={isStopped}
              />
              <br />
              {isStopped && <LoadingComponent isStopped={isStopped} />}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
