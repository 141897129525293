import React from 'react'
import { Col, Row } from 'react-bootstrap'

function SearchMerchant(props) {
  const { value, onChangeMerchant, handleSubmitMerchant } = props

  return (
    <Row>
      <Col>
        <>
          <form onSubmit={handleSubmitMerchant}>
            <div className="box">
              <div className="container-1">
                <input
                  type="text"
                  id="search"
                  onChange={onChangeMerchant}
                  value={value}
                  className="form-control p-4"
                />
              </div>
            </div>
          </form>
        </>
      </Col>
    </Row>
  )
}

export default SearchMerchant
