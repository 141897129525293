import React, { useMemo } from 'react'
import * as CurrencyFormat from 'react-currency-format'
import LoadingComponent from '../Shared/Loading.component'

function ProductsTableComponent({
  hidden,
  isStopped,
  headers,
  filterProduct,
  onEdit,
  onDeleteClick,
  activate,
}) {
  const showActiveSwitch = useMemo(
    () => filterProduct?.[0]?.product?.merchant_name === 'Graviti',
    [filterProduct]
  )
  return (
    <div className="box-table" hidden={hidden}>
      {!isStopped && (
        <div className="table-responsive">
          <table className="table table-hover table-products">
            <thead>
              <tr>
                {headers
                  .filter((h) => (showActiveSwitch ? true : h !== 'Activo'))
                  .map((item, i) => (
                    <th key={i}>{item}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {filterProduct?.map((item, i) => (
                <tr key={item.id}>
                  <td className="textPush" hidden>
                    {item.id}
                  </td>
                  <td className="textPush">{item.product?.id}</td>
                  <td className="textPush">{item?.product?.SKU}</td>
                  <td className="textPush">{item.product?.model}</td>
                  <td className="textPush">{item.product?.brand?.name}</td>
                  <td className="textPush">{item?.product?.category?.name}</td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item?.price}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                    />
                  </td>
                  {showActiveSwitch && (
                    <td>
                      <label className="switchAD">
                        <input
                          type="checkbox"
                          checked={item?.active}
                          onChange={(e) => {
                            activate(item, e.target.checked)
                          }}
                        />
                        <span className="slidAD round" />
                      </label>
                    </td>
                  )}
                  <td>
                    <div className="dropdown">
                      <button
                        className="btn btn-link dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      />
                      <div className="dropdown-menu">
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => onEdit(item)}
                        >
                          Editar
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => onDeleteClick(item?.id)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isStopped && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}

export default ProductsTableComponent
