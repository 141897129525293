import React from 'react'
import './index.css'

function ProgressBar({ children }) {
  return (
    <div className="container-fluid bg-secondary p-2 progress-bar-component ">
      <div className="progress bg-secondary mt-2">{children}</div>
    </div>
  )
}

export default ProgressBar
