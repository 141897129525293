import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import HttpsRedirect from 'react-https-redirect'
import LoginComponent from './containers/Login'
import store from './store'
import AccountsComponent from './containers/Accounts'
import AccountDetailsComponent from './containers/AccountDetail'
import AccountingComponent from './components/Accounting/accounting.component'
import InstallatiosDetailsComponent from './components/Accounting/AccountinDetails/installationsDetails.component'
import DepositsDetailsComponent from './components/Accounting/AccountinDetails/depositsDetails.component'
import { AuthContext } from './auth'
import PrivateRoute from './privateRoute'
import 'react-toastify/dist/ReactToastify.css'
import GravitiComponent from './components/Graviti/graviti.component'
import DepositsDetailsDsComponent from './components/Accounting/AccountinDetails/depositsDetailsDS.component'
import ProfileComponent from './components/Profile/profile.component'
import ProductsComponent from './components/Products/products.component'
import DetailProductComponent from './components/Products/detailProduct.component'
import AccountForm from './containers/AccountForm'
import CreditForm from './containers/CreditForm'
import TransactionsDetailsComponent from './components/Accounting/AccountinDetails/transactionsDetails.component'
import Dashboard from './components/Accounting/dashboard.component'
import ProductsPendingComponent from './components/Products/productsPending'
import PromosComponent from './components/Promos/PromosComponent'
import PromoUpdateComponent from './components/Promos/PromoUpdateComponent'
import PromoDetailsComponent from './components/Promos/PromoDetailsComponent'

function App() {
  return (
    <AuthContext.Provider value={false}>
      <HttpsRedirect>
        <Router>
          <Provider store={store}>
            <ToastContainer hideProgressBar position="top-right" />
            <Switch>
              <Route exact path="/login" component={LoginComponent} />
              <PrivateRoute
                exact
                path="/graviti/"
                component={GravitiComponent}
              />
              <PrivateRoute
                exact
                path="/nuevo-lead/"
                component={() => <AccountForm newLead />}
              />
              <PrivateRoute
                exact
                path="/credito/crear/:id"
                component={() => <CreditForm />}
              />
              <PrivateRoute
                exact
                path="/productos/"
                component={ProductsComponent}
              />
              <PrivateRoute
                exact
                path="/producto/detalles/:id"
                component={DetailProductComponent}
              />
              <PrivateRoute
                exact
                path="/promociones/"
                component={PromosComponent}
              />
              <PrivateRoute
                exact
                path="/promociones/editar/:id"
                component={PromoUpdateComponent}
              />
              <PrivateRoute
                exact
                path="/promociones/detalles/:id"
                component={PromoDetailsComponent}
              />
              <PrivateRoute
                exact
                path="/promociones/crear/"
                component={PromoUpdateComponent}
              />
              {/* TODO: (ProfileComponent) reasearch if this url and component is usedm if not delete it */}
              <PrivateRoute
                exact
                path="/perfil/"
                component={ProfileComponent}
              />
              <PrivateRoute exact path="/" component={AccountsComponent} />
              <PrivateRoute
                exact
                path="/cuentas"
                component={AccountsComponent}
              />
              <PrivateRoute
                exact
                path="/cuentas/detalles/:id"
                component={AccountDetailsComponent}
              />
              <PrivateRoute
                exact
                path="/cuenta/editar/:id"
                component={AccountForm}
              />
              <PrivateRoute
                exact
                path="/contabilidad"
                component={AccountingComponent}
              />
              <PrivateRoute
                exact
                path="/contabilidad/instalaciones/detalles/:id"
                component={InstallatiosDetailsComponent}
              />
              <PrivateRoute
                exact
                path="/contabilidad/depositos/detalles/:id"
                component={DepositsDetailsComponent}
              />
              <PrivateRoute
                exact
                path="/contabilidad/depositosDS/detalles/:id"
                component={DepositsDetailsDsComponent}
              />
              <PrivateRoute
                exact
                path="/contabilidad/transacciones/:year/:month/:monthPage"
                component={TransactionsDetailsComponent}
              />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path="/notificaciones"
                component={ProductsPendingComponent}
              />
            </Switch>
          </Provider>
        </Router>
      </HttpsRedirect>
    </AuthContext.Provider>
  )
}

export default App
