import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import './style.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function ModalConfirmInstallationDate(props) {
  const { loading, isOpen, rol, credit, onClose, onUpdatePartialCredit } = props

  const [newFirstDate, setNewFirstDate] = useState('')
  const [newSecondDate, setNewSecondDate] = useState('')
  const [installationDate, setInstallationDate] = useState(null)

  const handleConfirmDate = () => {
    if (installationDate !== null) {
      const data = {
        instalation_date: installationDate,
        credit_status: 'ATI',
        status_dates_proposed: 'APR',
      }
      onUpdatePartialCredit(data, credit.id)
    } else {
      const dates = {}
      if (newFirstDate !== '') {
        dates.firstDate = newFirstDate
      }
      if (newSecondDate !== '') {
        dates.secondDate = newSecondDate
      }
      const status = rol === 'DS' ? 'REV' : 'REJ'
      const data = {
        status_dates_proposed: status,
        dates_proposed: JSON.stringify(dates),
      }
      onUpdatePartialCredit(data, credit.id)
    }
  }

  const handleChecked = (e) => {
    if (e.target.checked) {
      setInstallationDate(e.target.value)
      setNewFirstDate('')
      setNewSecondDate('')
    }
  }

  const handleCloseModal = () => {
    setNewFirstDate('')
    setNewSecondDate('')
    setInstallationDate(null)
    onClose()
  }

  if (!isOpen) {
    return null
  }
  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      lockScroll
    >
      <div className="Modal">
        <div className="Modal__header">
          <div className="Modal__header-title">
            <h4 className="">Fechas de instalación</h4>
            <span className="badge badge-dark">
              {credit?.status_dates_proposed === 'APR'
                ? 'Fecha Aprobada'
                : credit?.status_dates_proposed === 'REJ'
                ? 'Fechas Rechazadas'
                : credit?.status_dates_proposed === 'REV'
                ? 'Fechas En Revisión'
                : 'Subiendo Fechas'}
            </span>
          </div>
          <span className="Modal__header-close" onClick={handleCloseModal}>
            X
          </span>
        </div>

        <div className="Dates">
          {credit?.dates_proposed?.firstDate && (
            <div className="Dates__Item">
              <h4>Fecha 1</h4>
              <label>
                {((rol === 'DS' && credit?.status_dates_proposed !== 'REV') ||
                  (rol === 'GR' &&
                    credit?.status_dates_proposed !== 'REJ')) && (
                  <input
                    type="radio"
                    name="dateProposed"
                    value={credit?.dates_proposed?.firstDate}
                    onChange={handleChecked}
                  />
                )}
                {` ${credit?.dates_proposed?.readableFirstDate}`}
              </label>
            </div>
          )}

          {credit?.dates_proposed?.secondDate && (
            <div className="Dates__Item">
              <h4>Fecha 2</h4>
              <label>
                {((rol === 'DS' && credit?.status_dates_proposed !== 'REV') ||
                  (rol === 'GR' &&
                    credit?.status_dates_proposed !== 'REJ')) && (
                  <input
                    type="radio"
                    name="dateProposed"
                    value={credit?.dates_proposed?.secondDate}
                    onChange={handleChecked}
                  />
                )}
                {` ${credit?.dates_proposed?.readableSecondDate}`}
              </label>
            </div>
          )}
        </div>

        {((rol === 'DS' && credit?.status_dates_proposed !== 'REV') ||
          (rol === 'GR' && credit?.status_dates_proposed !== 'REJ')) &&
          !loading &&
          credit?.status_dates_proposed !== 'APR' && (
            <>
              <h5 className="subtitle text-warning">Proponer nuevas fechas.</h5>
              <div className="NewDates">
                <div className="form-group">
                  <label>Primera fecha</label>
                  <input
                    type="datetime-local"
                    name="firstDate"
                    className="form-control"
                    value={newFirstDate}
                    onChange={(e) => {
                      setNewFirstDate(e.target.value)
                      setInstallationDate(null)
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Segunda fecha</label>
                  <input
                    type="datetime-local"
                    name="secondDate"
                    className="form-control"
                    value={newSecondDate}
                    onChange={(e) => {
                      setNewSecondDate(e.target.value)
                      setInstallationDate(null)
                    }}
                  />
                </div>
              </div>
            </>
          )}

        {credit.status_dates_proposed === 'APR' && (
          <div className="approved-date">
            <h1 className="text-center">{credit?.instalation_date}</h1>
            <h1 className="text-center">Fecha aprobada</h1>
          </div>
        )}

        <Container>
          <Row className="ModalII__footer">
            {((rol === 'DS' && credit?.status_dates_proposed !== 'REV') ||
              (rol === 'GR' && credit?.status_dates_proposed !== 'REJ')) &&
              credit?.status_dates_proposed !== 'APR' && (
                <Col>
                  <button
                    className="btn-2 b-primary"
                    disabled={loading}
                    onClick={() => handleConfirmDate()}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : newFirstDate || newSecondDate ? (
                      'Proponer nueva fecha'
                    ) : (
                      'Confirmar Fecha'
                    )}
                  </button>
                </Col>
              )}
            <Col>
              <button className="btn-3" onClick={handleCloseModal}>
                Cerrar
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </Popup>
  )
}

export default ModalConfirmInstallationDate
