import React, { useState, useEffect } from 'react'
import { getUserInfo } from '../../services/profile.service'
import LoadingComponent from '../Shared/Loading.component'

function ProfileComponent() {
  const [userInfo, setUserInfo] = useState(null)
  const [isStopped, setIsStopped] = useState(false)

  useEffect(() => {
    getUserInfo().then((response) => {
      setUserInfo(response)
      setIsStopped(true)
    })
  }, [])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <h1>PERFIL</h1>
        </div>
      </div>
      <br />
      {userInfo && (
        <div className="row">
          <div className="col">
            <div className="box-table">
              <div className="container">
                <div className="row">
                  <div className="col">
                    {' '}
                    <img src={userInfo?.logo} alt={userInfo.name} />
                  </div>
                  <div className="col">
                    <table className="table table-borderless table-sm">
                      <thead />
                      <tbody>
                        <tr>
                          <td className="headTable">
                            <strong>Organización: </strong>
                          </td>
                          <td>{userInfo?.name}</td>
                        </tr>
                        <tr>
                          <td className="headTable">
                            <strong>Responsable: </strong>
                          </td>
                          <td>{userInfo?.responsible}</td>
                        </tr>
                        <tr>
                          <td className="headTable">
                            <strong>Correo: </strong>
                          </td>
                          <td>{userInfo?.email}</td>
                        </tr>
                        <tr>
                          <td className="headTable">
                            <strong>Teléfono: </strong>
                          </td>
                          <td>{userInfo?.phone}</td>
                        </tr>
                        <tr>
                          <td className="headTable">
                            <strong>RFC: </strong>
                          </td>
                          <td>{userInfo?.rfc}</td>
                        </tr>
                        <tr>
                          <td className="headTable">
                            <strong>Clabe: </strong>
                          </td>
                          <td>{userInfo?.clabe}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!userInfo && <LoadingComponent isStopped={isStopped} />}
    </div>
  )
}

export default ProfileComponent
