import { toast } from './toast'

export const defaultRequestErrorHandler = (err) => {
  console.log(err)
  const { data } = err.response
  console.log(data)
  let message = data.detail || data.description || data.errors || data
  if (message instanceof Object) {
    message = Object.keys(message)
      .map((key) => `${key}: ${message[key]}`)
      .join('<br/>')
  }
  toast(message || 'Error en el servidor, Intenta nuevamente', 'error')
}
