import React, { Component } from 'react'
import Pagination from 'react-js-pagination'

export default class PaginatorComponent extends Component {
  handlePageChange(pageNumber) {
    this.props.handlePageChange(pageNumber)
  }

  render() {
    return (
      <div className="row">
        <div className="col text-center">
          <div className="pag" style={{ display: 'inline-block' }}>
            <Pagination
              activePage={this.props.activePage}
              itemsCountPerPage={this.props.itemsCountPerPage}
              totalItemsCount={this.props.totalItemsCount}
              pageRangeDisplayed={3}
              innerClass="pagination"
              itemClass="page-item"
              linkClass="page-link"
              onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}
