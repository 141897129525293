import React from 'react'
import * as CurrencyFormat from 'react-currency-format'
import './widgetLg.css'

export default function WidgetLg ({ rows }) {
  return (
    <div className='widgetLg'>
      <h3 className='widgetLgTitle'>Latest transactions</h3>
      <table className='widgetLgTable'>
        <tr className='widgetLgTr'>
          <th className='widgetLgTh'>Products</th>
          <th className='widgetLgTh'>Price</th>
          <th className='widgetLgTh'>Sold</th>
          <th className='widgetLgTh'>Total</th>
        </tr>
        {rows.map((row) => (
          <tr className='widgetLgTr'>
            <td
              className='widgetLgUser'
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
            >
              <span className='widgetLgName'>{row?.model}</span>
            </td>
            <td className='widgetLgStatus'>
              <CurrencyFormat
                value={row?.price}
                displayType='text'
                thousandSeparator
                prefix='$ '
              />
            </td>
            <td className='widgetLgDate'>{row?.cantidad}</td>
            <td className='widgetLgStatus'>
              <CurrencyFormat
                value={row?.total}
                displayType='text'
                thousandSeparator
                prefix='$ '
              />
            </td>
          </tr>
        ))}
      </table>
    </div>
  )
}
