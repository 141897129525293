// React
import React, { useState, useEffect, useCallback } from 'react'

import debounce from 'lodash.debounce'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button } from 'react-bootstrap'
import * as Yup from 'yup'
import { useContext } from 'react'
import agua from '../../../assets/icons/agua.svg'
import garantia from '../../../assets/icons/garantia.svg'
import regadera from '../../../assets/icons/regadera.svg'
import temperatura from '../../../assets/icons/temperatura.svg'
import tubos from '../../../assets/icons/tubos.svg'
import vida from '../../../assets/icons/vida.svg'
import Autocomplete from '../../Autocomplete/Autocomplete.component'
import SelectComponent from '../../Shared/Inputs/select.component'
import IconURLComponent from '../../Shared/IconUrl.component'
import InputComponent from '../../Shared/Inputs/input'

// Services
import msProductsService from '../../../services/ms/ms-products-service'
import msFmEngineV2Prefix from '../../../services/ms/ms-fmengine-service'

// Contexts
import CreditFormContext from '../../../containers/CreditForm/context'

const ProductSchema = Yup.object().shape({
  weeks: Yup.string().required('Plazo requerido'),
  product_branch: Yup.string().required('Producto requerido'),
  discount: Yup.number()
    .positive('El descuento debe ser mayor a 0')
    .max(100, 'El descuento no puede ser mayor a 100')
    .nullable(),
})

function ProductSelected({ currentStep, afterValid, cancel }) {
  const [loadingProductFinancial, setLoadingProductFinancial] = useState(false)
  const [allProducts, setAllProducts] = useState([])
  const [terms, setTerms] = useState([])
  const [financialModels, setFinancialModels] = useState([])
  const [product, setProduct] = useState({})
  const [totalWithDiscount, setTotalWithDiscount] = useState(null)
  const [search, setSearch] = useState()

  const { setCreditInfo, clientInfo } = useContext(CreditFormContext)

  const searchProducts = useCallback(
    debounce(
      (query) =>
        query &&
        msProductsService
          .getAvailableProducts(null, null, null, query)
          .then((products) => setAllProducts(products)),
      300
    ),
    []
  )

  useEffect(() => {
    searchProducts(search)
  }, [search, searchProducts])

  if (currentStep !== 1) {
    return null
  }

  const handleGetTerms = async (value, setFieldValue) => {
    setLoadingProductFinancial(true)
    setFieldValue('product_branch', value.id)
    setProduct(value)
    try {
      const financialModels = await msFmEngineV2Prefix.getFinancialModels(
        value.id,
        value.discount || 0,
        clientInfo.id
      )
      setTerms(msFmEngineV2Prefix.parseToOptions(financialModels))
      setFinancialModels(financialModels)
    } finally {
      setLoadingProductFinancial(false)
    }
  }

  const applyDiscount = async (value, setFieldValue, reset = false) => {
    setLoadingProductFinancial(true)
    value.discount = reset ? 0 : value.discount
    try {
      const financialModels = await msFmEngineV2Prefix.getFinancialModels(
        value.product_branch,
        value.discount,
        clientInfo.id
      )
      setTerms(msFmEngineV2Prefix.parseToOptions(financialModels))
      setFinancialModels(financialModels)
      const path = value.weeks.split('-')
      const term = financialModels[path[0]][path[1]]
      setFieldValue('pay_data', term)
      setTotalWithDiscount(term.base_price)
    } finally {
      setLoadingProductFinancial(false)
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setCreditInfo((creditInfo) => ({
      ...creditInfo,
      ...values,
    }))
    setSubmitting(false)
    afterValid()
  }

  return (
    <Formik
      initialValues={{}}
      validationSchema={ProductSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      {({ isSubmitting, handleChange, setFieldValue, errors, values }) => (
        <Form>
          <div className="row my-4">
            <div className="col-12">
              <h3 className="text-primary-graviti font-weight-bold">
                Producto
              </h3>
            </div>
            <div className="col-12 col-md-6">
              <Autocomplete
                products={allProducts}
                onSearchChange={(value) => setSearch(value)}
                selectedHandler={(value) => {
                  handleGetTerms(value, setFieldValue)
                }}
              />
              <span className="labelInput">Producto</span>
              {errors.product_branch && (
                <div className="text-danger mt-3 ml-1">
                  {errors.product_branch}
                </div>
              )}
            </div>
            <div className="col-6 col-md-4">
              <SelectComponent
                label="Plazo"
                data={terms}
                option={(value) => {
                  setFieldValue('weeks', value)
                  const path = value.split('-')
                  const term = financialModels[path[0]][path[1]]
                  setFieldValue('pay_data', term)
                  setFieldValue('first_pay', path[0] === 'credit')
                }}
                value={values.weeks}
                id="id"
                name="weeks"
                loading={loadingProductFinancial}
              />
              {errors.weeks && (
                <div className="text-danger mt-3 ml-1">{errors.weeks}</div>
              )}
            </div>
          </div>
          {(values.weeks || '').startsWith('warranty') && (
            <div className="row my-4">
              <div className="col-12 col-md-2">
                <Field
                  name="discount"
                  component={InputComponent}
                  placeholder="Descuento"
                  type="number"
                  onChange={handleChange}
                />
                <span className="labelInput">Descuento</span>
                <ErrorMessage name="discount" component="div" />
              </div>
              <div className="col-12 col-md-2">
                <Button
                  variant="outline-light"
                  onClick={() => applyDiscount(values, setFieldValue)}
                  disabled={isSubmitting}
                  block
                >
                  Aplicar descuento
                </Button>
              </div>
              <div className="col-12 col-md-2">
                <Button
                  variant="outline-danger"
                  onClick={() => applyDiscount(values, true)}
                  disabled={isSubmitting}
                  block
                >
                  Reiniciar descuento
                </Button>
              </div>
            </div>
          )}
          <div className="row my-2">
            <div className="col-12 col-md-6 mt-4">
              <div className="col-12">
                <h4>{product?.model}</h4>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <img
                  src={product?.product?.image}
                  alt={product?.product?.model}
                  style={{ width: '50%' }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-4">
                  <IconURLComponent
                    text={`${product?.product?.life_time || ''} Vida útil`}
                    icon={vida}
                    url={null}
                  />
                </div>
                <div className="col-4">
                  <IconURLComponent
                    text={`${product?.product?.capacity || ''} Litro(s)`}
                    icon={agua}
                    url={null}
                  />
                </div>
                <div className="col-4">
                  <IconURLComponent
                    text={`${
                      product?.product?.warranty || ''
                    } año(s) de garantía`}
                    icon={garantia}
                    url={null}
                  />
                </div>
                <div className="col-4">
                  <IconURLComponent
                    text={`${product?.product?.services || ''} duchas al día`}
                    icon={regadera}
                    url={null}
                  />
                </div>
                <div className="col-4">
                  <IconURLComponent
                    text={product?.information?.temp_retention || ''}
                    icon={temperatura}
                    url={null}
                  />
                </div>
                <div className="col-4">
                  <IconURLComponent
                    text={product?.product?.size || ''}
                    icon={tubos}
                    url={null}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <h4>
                    Total: $ {values.pay_data?.final_price_before_discount} MXN
                  </h4>
                </div>
                {totalWithDiscount && (
                  <div className="col-12">
                    <h6>
                      Total con descuento: $ {values.pay_data?.final_price} MXN
                    </h6>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col">
                  <h4>Descripción</h4>
                  <p>{product?.product?.description}</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4>Garantía</h4>
                  <p>{product?.warranty_desc}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4" />
            <div className="col-12 col-md-4">
              <Button
                variant="outline-light"
                size="lg"
                onClick={cancel}
                disabled={isSubmitting}
                block
              >
                Cancelar
              </Button>
            </div>
            <div className="col-12 col-md-4">
              <Button type="submit" variant="secondary" size="lg" block>
                Continuar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ProductSelected
