// React
import React from 'react'
import LoadingComponent from '../../Shared/Loading.component'

/**
 * Promo tags
 * @param {Promo} promo the promo
 */
export function PromoTags({ promo }) {
  return (
    <>
      <span className={`badge badge-${promo.active ? 'success' : 'secondary'}`}>
        {promo.active ? 'Activo' : 'Desactivado'}
      </span>
      &nbsp;
      <span className={`badge badge-${promo.is_current ? 'info' : 'dark'}`}>
        {promo.is_current ? 'Vigente' : 'No vigente'}
      </span>
      &nbsp;
      {promo.is_graviti_discount ? (
        <span className="badge badge-danger">Descuento graviti</span>
      ) : (
        ''
      )}
    </>
  )
}

/**
 * Promos table item
 * @param {Promo} promo a promo instance
 * @param {function} onClick onclick event handler
 */
function PromoTableItem({ promo, onClick }) {
  return (
    <tr onClick={() => onClick(promo)} key={promo.id}>
      <td className="textPush">{promo.name}</td>
      <td className="textPush">
        <PromoTags promo={promo} />
      </td>
      <td className="textPush">
        {promo.percentage}%&nbsp;
        {promo.graviti_extra_percentage ? (
          <span className="badge badge-warning">
            + {promo.graviti_extra_percentage}% Graviti
          </span>
        ) : (
          ''
        )}
      </td>
      <td className="textPush">
        {promo.start_date.substring(0, 16)}
        <br />
        {promo.expiration_date.substring(0, 16)}
      </td>
    </tr>
  )
}

/**
 * Promos table component
 *
 * @param {list<Promo>} promos the list of promos to display
 * @param {boolean} loading whether to show a loading animation instead of the table
 * @param {function} onPromoClick item clicked handler
 */
function PromosTable({ promos, loading, onPromoClick }) {
  if (loading) {
    return (
      <div className="box-table">
        <LoadingComponent />
      </div>
    )
  }

  return (
    <div className="box-table">
      <div className="table-responsive">
        <table className="table table-hover table-graviti">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Status</th>
              <th>Porcentaje</th>
              <th>Vigencia</th>
            </tr>
          </thead>
          <tbody>
            {promos.map((promo) => (
              <PromoTableItem
                key={promo.id}
                promo={promo}
                onClick={onPromoClick}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PromosTable
