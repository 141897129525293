export const titleSteps = {
  1: {
    name: '01 Producto y Verificación',
  },
  2: {
    name: '02 Información personal',
  },
  3: {
    name: '03 Información laboral',
  },
  4: {
    name: '04 Información adicional',
  },
}
