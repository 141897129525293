import React, { useCallback, useEffect, useState } from 'react'
import { Button, Nav } from 'react-bootstrap'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { toast } from 'react-toastify'
import { First } from 'react-bootstrap/esm/PageItem'
import Tags from '../../../Tags'
import contractSignature from '../../../../assets/icons/contractSignature.svg'
import IconURLComponent from '../../../Shared/IconUrl.component'
import cardFront from '../../../../assets/icons/cardFront.svg'
import cardBack from '../../../../assets/icons/cardBack.svg'
import contract from '../../../../assets/icons/contract.svg'
import address from '../../../../assets/icons/address.svg'
import pdf from '../../../../assets/icons/pdf.svg'
import xml from '../../../../assets/icons/xml.svg'
import aprobar from '../../../../assets/icons/aprobar.svg'
import finalizar from '../../../../assets/icons/finalizar.svg'
import rechazar from '../../../../assets/icons/rechazar.svg'
import inconcluso from '../../../../assets/icons/inconcluso.svg'
import {
  patchRequestAPI,
  getRequestAPI,
} from '../../../../services/requests.service'
import {
  changeCreditStatus,
  unfinishedCredit,
  finishedCredit,
  tagType,
} from '../../../../services/constants'
import {
  updateCreditTags,
  approveCredit,
} from '../../../../services/accounts.service'
import CreditProgressBar from '../../../CreditProgressBar'
import './style.css'
import ModalComponent from '../../../Shared/Modal.component'

// Rejectable credit status list
const REJECTABLE_STATUS = ['EVA', 'APR', 'EVAT', 'TOS', 'ATI']

function CreditsComponent({ data, updateLocalCredit, history }) {
  const [creditTabId, setCreditTabId] = useState()
  const [creditInfo, setCreditInfo] = useState()
  const [rol] = useState(localStorage.getItem('user_type'))
  const [tags, setTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [groupTags, setGroupTags] = useState([])
  const [selectedTag, setSelectedTag] = useState('')
  const [loadingSendContract, setLoadingSendContract] = useState(false)
  const [ficoConditions, setFicoConditions] = useState([])
  const [comments, setComments] = useState('')
  const [client_info, setClient] = useState([])

  const handleTags = useCallback(() => {
    if (!data?.profile) {
      data.forEach((item) => {
        if (item.id === creditTabId) {
          setTags(item?.tags_list)
          setFicoConditions([rol === 'GR', item?.credit_status === 'EVA'])
        }
      })
    }
  }, [creditTabId])

  useEffect(() => {
    setCreditTabId(data[0]?.id)
    setCreditInfo(data[0])
    setClient(data[0]?.client_info)
  }, [data[0]?.id])

  useEffect(() => {
    handleTags()
  }, [creditTabId, handleTags])

  const handleSelect = (eventKey) => {
    setCreditTabId(eventKey)
    data.forEach((credit) => {
      if (credit.id === eventKey) {
        setCreditInfo(credit)
      }
    })
  }

  useEffect(() => {
    getRequestAPI(tagType()).then((response) => {
      setAllTags(handleMergeTags(response.data))
      setGroupTags(response.data)
    })
  }, [])

  const handleMergeTags = (tagList) => {
    const mergeTags = []
    for (let i = 0; i < tagList.length; i++) {
      if (tagList[i].tags.length !== 0) {
        mergeTags.push(...tagList[i].tags)
      }
    }
    return mergeTags
  }

  const handleSelectedTag = (e) => {
    const { value } = e.target
    setSelectedTag(() => {
      data.forEach((credit) => {
        if (credit.id === creditTabId) {
          const newTags = credit?.tags ? credit?.tags?.split(',') : []
          newTags.push(value)
          updateCreditTags({ tags: newTags.toString() }, creditTabId).then(
            (response) => {
              setTags(response?.tags_list)
              updateLocalCredit()
            }
          )
        }
      })
      return value
    })
  }

  const handleDeleteBadge = (tag) => {
    data.forEach((credit) => {
      if (credit.id === creditTabId) {
        const newTags = credit.tags.split(',')
        const newTagsFiltered = newTags.filter((item) => item !== tag)
        //
        updateCreditTags(
          { tags: newTagsFiltered.toString() },
          creditTabId
        ).then((response) => {
          setTags(response?.tags_list)
          updateLocalCredit()
        })
      }
    })
  }

  /**
   * Copies credit.autopath_link to clipboard
   */
  const copyAutopathToClipboard = (credit) => {
    navigator.clipboard.writeText(credit?.autopath_link)
    toast.success('Link copiado en el portapapeles', {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  const canPenalizeCredit = () =>
    ['GR', 'FN'].includes(rol) && creditInfo?.credit_status === 'INS'

  // Change Status
  const setCreditStatus = (status, closeModal) => {
    setCreditInfo(() => {
      creditInfo.credit_status = status
      patchRequestAPI(changeCreditStatus(creditInfo.id), {
        credit_status: status,
        comments: comments || '',
      }).then((response) => {
        if (response.status === 200) {
          updateLocalCredit()
          closeModal()
        }
      })
      return creditInfo
    })
  }

  // Change Status - NC
  const handleUnfinished = (status, closeModal) => {
    setCreditInfo(() => {
      creditInfo.credit_status = status
      patchRequestAPI(unfinishedCredit(creditInfo.id), creditInfo).then(
        (response) => {
          if (response.status === 200) {
            updateLocalCredit()
            closeModal()
          }
        }
      )
      return creditInfo
    })
  }

  const handleApproved = (status, closeModal) => {
    setCreditInfo(() => {
      creditInfo.credit_status = status
      const data = {
        credit_status: status,
        comments: comments || '',
        id: creditInfo.id,
      }
      approveCredit(data).then((r) => {
        if (r.status === 200) {
          updateLocalCredit()
          closeModal()
        }
      })
      return creditInfo
    })
  }

  const handleFinished = (status, closeModal) => {
    setCreditInfo(() => {
      creditInfo.credit_status = status
      patchRequestAPI(finishedCredit(creditInfo.id), creditInfo).then(
        (response) => {
          if (response.status === 200) {
            updateLocalCredit()
            closeModal()
          }
        }
      )
      return creditInfo
    })
  }

  const item = data?.[0]
  const rejected_date = item?.rejected_date!='' ? new Date(item?.rejected_date) : ''

  return (
    <Container fluid>
      <Row>
        <Col md={4}>
          <h4>CRÉDITOS:</h4>
        </Col>
        {rol !== 'DS' && client_info != undefined &&(
          <Col>
            <Row>
              <div className="col text-center">
                <Button
                  variant="outline-primary"
                  className="font-weight-bold rounded-pill pt-0 pb-0  pl-4 pr-4"
                  onClick={() => history.push(`/cuenta/editar/${creditTabId}`)}
                >
                  Editar
                </Button>
              </div>
              {creditInfo && (
                <>
                  <Col className="text-center">
                    <ModalComponent
                      icon={aprobar}
                      title="Aprobar"
                      buttonVariant="primary"
                      onClick={(e, closeModal) =>
                        handleApproved('APR', closeModal)
                      }
                      h1="¿Estás seguro de aprobar la cuenta para el financiamiento?"
                      sub="Una vez hecho el cambio no podrá revertirse, tendrás que contactar al administrador de Graviti Aquarius"
                      button="Confirmar"
                      textArea={false}
                      state={creditInfo}
                      hidden={
                        !(rol !== 'DS' && creditInfo.credit_status === 'EVA')
                      }
                    />
                  </Col>
                  {canPenalizeCredit() && (
                    <Col className="text-center">
                      <ModalComponent
                        like="link"
                        text="CASTIGAR"
                        onClick={(e, closeModal) =>
                          setCreditStatus('CC', closeModal)
                        }
                        comments={(e) => setComments(e)}
                        h1="¿Estás seguro de querer castigar esta cuenta?"
                        sub="Una vez hecho el cambio no podrá revertirse, tendrás que contactar al administrador de Graviti Aquarius"
                        button="Confirmar"
                        textArea
                        state={creditInfo}
                        hidden={false}
                      />
                    </Col>
                  )}
                  <Col className="text-center">
                    <ModalComponent
                      icon={rechazar}
                      title="Rechazar"
                      buttonVariant="danger"
                      onClick={(e, closeModal) =>
                        handleApproved('REJ', closeModal)
                      }
                      comments={(e) => setComments(e)}
                      h1="¿Estás seguro de rechazar la cuenta para el financiamiento?"
                      sub="Una vez hecho el cambio no podrá revertirse, tendrás que contactar al administrador de Graviti Aquarius"
                      button="Confirmar"
                      textArea
                      state={creditInfo}
                      hidden={
                        !(
                          rol !== 'DS' &&
                          REJECTABLE_STATUS.some(
                            (status) => creditInfo.credit_status === status
                          )
                        )
                      }
                    />
                  </Col>
                  {rol === 'GR' &&
                    ((creditInfo?.credit_status === 'NC' &&
                      creditInfo.last_credit_status !== 'EVA') ||
                      (creditInfo?.credit_status !== 'EVA' &&
                        creditInfo?.credit_status !== 'NC')) && (
                      <div
                        className={
                          creditInfo.credit_status !== 'NC' ? 'col' : 'col-6'
                        }
                      >
                        <ModalComponent
                          title="Ev. Financiera"
                          buttonVariant="secondary"
                          onClick={(e, closeModal) =>
                            setCreditStatus('EVA', closeModal)
                          }
                          comments={(e) => setComments(e)}
                          h1="¿Estás seguro de regresar el crédito a Ev. Financiera?"
                          sub="Una vez hecho el cambio no podrá revertirse, tendrás que contactar al administrador de Graviti Aquarius"
                          button="Confirmar"
                          textArea
                          state={creditInfo}
                          hidden={['FN', 'CC'].includes(
                            creditInfo.credit_status
                          )}
                        />
                      </div>
                    )}
                  {rol === 'GR' && (
                    <Col className="text-center">
                      <ModalComponent
                        icon={inconcluso}
                        title="Inconcluso"
                        buttonVariant="danger"
                        onClick={(e, closeModal) =>
                          handleUnfinished('NC', closeModal)
                        }
                        comments={(e) => setComments(e)}
                        h1="¿Estás seguro de cambiar el estatus a inconcluso?"
                        sub="Una vez hecho el cambio no podrá revertirse, tendrás que contactar al administrador de Graviti Aquarius"
                        button="Confirmar"
                        textArea
                        state={creditInfo}
                        hidden={['NC', 'FN', 'CC'].includes(
                          creditInfo.credit_status
                        )}
                      />
                    </Col>
                  )}
                  {rol === 'GR' && (
                    <Col className="text-center">
                      <ModalComponent
                        icon={finalizar}
                        title="Finalizar"
                        buttonVariant="secondary"
                        onClick={(e, closeModal) =>
                          handleFinished('FN', closeModal)
                        }
                        h1="¿Estás seguro que ya hizo el último pago?"
                        sub="Una vez hecho el cambio no podrá revertirse, tendrás que contactar al administrador de Graviti Aquarius"
                        button="Confirmar"
                        textArea={false}
                        state={creditInfo}
                        hidden={creditInfo.credit_status !== 'INS'}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Col>
        )}
      </Row>
      {rol !== 'DS' && (
        <Row>
          <Col>
            {client_info == undefined &&(
              <Button
                variant="outline-primary"
                className="font-weight-bolder"
                onClick={() =>
                  history.push(
                    `/credito/crear/${
                      data?.aq_id ||
                      data?.id ||
                      data?.shopper
                    }`,
                  )
                }
              >
                Agregar crédito
              </Button>  
            )}
            {client_info != undefined  &&(
              <Button
                variant="outline-primary"
                className="font-weight-bolder"
                onClick={() =>
                  history.push(
                    `/credito/crear/${
                      client_info?.aq_id ||
                      client_info?.id ||
                      data[0]?.shopper
                    }`,
                    { lastCredit: { ...data[0], creditId: data[0]?.id } }
                  )
                }
              >
                Agregar crédito
              </Button>  
            )}
          </Col>
        </Row>
      )}
      <hr />
      {client_info != undefined &&(
        <Row>
          <Col>
            <Nav variant="tabs">
              <Nav.Link
                className="bg-primary-tabs-graviti"
                active={item?.id}
              >
                <strong>{item?.product_info?.model}</strong>
              </Nav.Link>
                    
            </Nav>
            {!data?.profile &&
              (
                    <div key={item?.id}>
                      <Row>&nbsp;</Row>
                      <Row>
                        {!!item?.client_info?.ine && (
                          <Col className="d-flex align-items-center justify-content-center">
                            <IconURLComponent
                              text="Identificación Oficial"
                              url={item?.client_info?.ine}
                              icon={cardFront}
                            />
                          </Col>
                        )}
                        {!!item?.client_info?.ine_back && (
                          <Col className="d-flex align-items-center justify-content-center">
                            <IconURLComponent
                              text="Identificación Oficial"
                              url={item?.client_info?.ine_back}
                              icon={cardBack}
                            />
                          </Col>
                        )}
                        {!!item?.client_info?.selfie && (
                          <Col className="d-flex align-items-center justify-content-center">
                            <IconURLComponent
                              text="Selfie"
                              url={item?.client_info.selfie}
                              icon={cardFront}
                            />
                          </Col>
                        )}

                        {!!item?.client_info?.comprobante && (
                          <Col className="d-flex align-items-center justify-content-center">
                            <IconURLComponent
                              text="Comprobante de domicilio"
                              url={item?.client_info?.comprobante}
                              icon={address}
                            />
                          </Col>
                        )}
                        {rol !== 'DS' && (
                          <>
                            {!!item?.contrato && (
                              <Col className="d-flex align-items-center justify-content-center">
                                <IconURLComponent
                                  text="Contrato sin firma"
                                  url={item?.contrato}
                                  icon={contract}
                                  loading={loadingSendContract}
                                />
                              </Col>
                            )}

                            {!!item?.contrato_firmado && (
                              <Col className="d-flex align-items-center justify-content-center">
                                <IconURLComponent
                                  text="Contrato firmado"
                                  url={item?.contrato_firmado}
                                  icon={contractSignature}
                                />
                              </Col>
                            )}

                            {!!item?.pdf && (
                              <Col className="d-flex align-items-center justify-content-center">
                                <IconURLComponent
                                  text="Factura PDF"
                                  url={item?.pdf}
                                  icon={pdf}
                                />
                              </Col>
                            )}

                            {!!item?.xml && (
                              <Col className="d-flex align-items-center justify-content-center">
                                <IconURLComponent
                                  text="Factura XML"
                                  url={item?.xml}
                                  icon={xml}
                                />
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                      {rol !== 'DS' && (
                        <Col>
                          <Row>
                            <Col>
                              <Row>
                                {['GR', 'FN'].includes(rol) ? (
                                  <>
                                    <strong className="mr-2">
                                      Link de seguimiento:
                                    </strong>
                                    <button
                                      className="btn b-primary"
                                      onClick={() =>
                                        copyAutopathToClipboard(item)
                                      }
                                    >
                                      Copiar link
                                    </button>
                                  </>
                                ) : (
                                  ''
                                )}
                              </Row>
                              <Row>
                                <strong className="mr-2">ID Contrato:</strong>
                                <span>
                                  {item?.client_info?.contract_id || '-'}
                                </span>
                              </Row>
                              <Row>
                                <strong className="mr-2">Referencia:</strong>
                                <span>{item?.reference || '-'}</span>
                              </Row>
                              <Row>
                                <strong className="mr-2">Customer ID:</strong>
                                <span>{item?.customer_id || '-'}</span>
                              </Row>
                              <Row>
                                <strong className="mr-2">Ingreso:</strong>
                                <span>
                                  {`$ ${Number(
                                    item?.client_info?.income
                                  )?.toFixed(2)}` || '-'}
                                </span>
                              </Row>
                              <Row>
                                <strong className="mr-2">
                                  Ingreso Familiar:
                                </strong>
                                <span>
                                  {`$ ${Number(
                                    item?.client_info?.income_family
                                  )?.toFixed(2)}` || '-'}
                                </span>
                              </Row>
                              <Row>
                                <strong className="mr-2">Gasto Mensual:</strong>
                                <span>
                                  {`$ ${Number(
                                    item?.client_info?.monthly_expenditure
                                  )?.toFixed(2)}` || '-'}
                                </span>
                              </Row>
                              <Row>
                                <strong className="mr-2">Hab.en el hogar:</strong>
                                <span>{item?.count_home || '-'}</span>
                              </Row>
                              <Row>
                                <strong className="mr-2">Alternativa:</strong>
                                <span>
                                  {
                                    item?.answers[
                                      'alternativa-para-calentar-agua'
                                    ]
                                  }
                                </span>
                              </Row>
                              <Row>
                                <strong className="mr-2">
                                  Costo alternativa:
                                </strong>
                                <span>
                                  {item?.answers[
                                    'gasto-mensual-para-calentar-agua'
                                  ] &&
                                  item?.answers[
                                    'gasto-mensual-para-calentar-agua'
                                  ] !== 'undefined'
                                    ? item.answers[
                                        'gasto-mensual-para-calentar-agua'
                                      ]
                                    : '-'}
                                </span>
                              </Row>
                              <Row>
                                <strong className="mr-2">
                                  Tipo de vivienda:
                                </strong>
                                <span>{item?.client_info?.deparment || '-'}</span>
                              </Row>
                              {item?.credit_status == "REJ" && (
                                <Row>
                                  <strong className="mr-2">
                                    Fecha de rechazo:
                                  </strong>
                                  <span>{String(rejected_date.getDate()).padStart(2, '0')+'/'+String(rejected_date.getMonth()+1).padStart(2, '0')+'/'+rejected_date.getFullYear()}</span>
                                </Row>
                              )}
                              {/* <Row>
                              <strong className='mr-2'>FICOScore:</strong>
                              <span>
                                {item?.client_info?.fico_score || '-'}
                              </span>
                            </Row> */}
                            </Col>
                            <Col>
                              <strong>Etiquetas</strong>
                              <br />
                              {tags?.map((title) => {
                                const tagsConcat = allTags || []
                                const tagFound = tagsConcat.find(
                                  (tagConcat) => tagConcat.title === title
                                )
                                return (
                                  <Tags
                                    title={title}
                                    key={title}
                                    tagFound={tagFound}
                                    handleDeleteBadge={handleDeleteBadge}
                                  />
                                )
                              })}
                              <select
                                className="form-control mb-5"
                                value={selectedTag}
                                onChange={handleSelectedTag}
                              >
                                <option>Agregar etiqueta</option>
                                {groupTags.map((group) => (
                                  <optgroup key={group.name} label={group.name}>
                                    {group.tags.map((tag, key) => {
                                      if (tag.active) {
                                        const found = tags?.find(
                                          (title) => title === tag.title
                                        )
                                        return (
                                          <option
                                            value={tag.title}
                                            key={tag.title + key}
                                            disabled={found}
                                          >
                                            {tag.title}
                                          </option>
                                        )
                                      }
                                      return null
                                    })}
                                  </optgroup>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Col>
                            <CreditProgressBar
                              status={item?.credit_status}
                              lastStatus={item?.last_credit_status}
                            />
                          </Col>
                        </Col>
                      )}
                    </div>
                  )
              }
          </Col>
        </Row>
      )}
      <br />
    </Container>
  )
}

export default CreditsComponent
