import React, { useState, useEffect } from 'react'

import Chart from '../chart/Chart'
import FeaturedInfo from '../featuredInfo/FeaturedInfo'
import './home.css'
import WidgetLg from '../widgetLg/WidgetLg'
import {
  getSalesDistributor,
  getDistributorSalesProducts,
  getDistributorDeposits,
  getDistributorSalesChart,
} from '../../../../services/payments.service'

export default function Dashboard() {
  const [rows, setRows] = useState([])
  const [activePage, setActivePage] = useState(1)
  const date = new Date()

  const [month, setMonth] = useState(date.getMonth() + 1)
  const [year, setYear] = useState(date.getFullYear())
  const [sales, setSales] = useState(0)
  const [lastSales, setLastSales] = useState(0)
  const [gmv, setGmv] = useState(null)
  const [gmvLast, setLastGmv] = useState(null)
  const [deposits, setDeposits] = useState(0)
  const [chart, setChart] = useState([
    {
      month: 'Jan',
      GMV: 0,
    },
    {
      month: 'Feb',
      GMV: 0,
    },
    {
      month: 'Mar',
      GMV: 0,
    },
    {
      month: 'Apr',
      GMV: 0,
    },
    {
      month: 'May',
      GMV: 0,
    },
    {
      month: 'Jun',
      GMV: 0,
    },
    {
      month: 'Jul',
      GMV: 0,
    },
    {
      month: 'Agu',
      GMV: 0,
    },
    {
      month: 'Sep',
      GMV: 0,
    },
    {
      month: 'Oct',
      GMV: 0,
    },
    {
      month: 'Nov',
      GMV: 0,
    },
    {
      month: 'Dec',
      GMV: 0,
    },
  ])
  const itemsCountPerPage = 100

  useEffect(() => {
    getSalesDistributor(year, month).then((response) => {
      setSales(response.credit_currenty)
      setLastSales(response.credit_last)
      setGmv(response.gvm)
      setLastGmv(response.last_gvm)
    })
    getDistributorSalesChart(year).then((response) => {
      setChart(response)
    })
    getDistributorDeposits(year, month).then((response) => {
      setDeposits(response)
    })
    getDistributorSalesProducts(year, month).then((response) => {
      const duplicateProduct = response.reduce((accumulator, currentValue) => {
        const existsElement = accumulator.find(
          (element) => element.id === currentValue.id
        )
        if (existsElement) {
          return accumulator.map((element) => {
            if (element.id === currentValue.id) {
              return {
                ...element,
                cantidad: element.cantidad + currentValue.cantidad,
                total: element.price + currentValue.price,
              }
            }

            return element
          })
        }

        return [...accumulator, currentValue]
      }, [])

      setRows(duplicateProduct)
    })
  }, [activePage, itemsCountPerPage])

  return (
    <div className="home">
      <FeaturedInfo
        gmv={gmv}
        gmvLast={gmvLast}
        sales={sales}
        lastSales={lastSales}
        deposits={deposits}
      />
      <Chart data={chart} title="User Analytics" grid />
      <div className="homeWidgets">
        <WidgetLg rows={rows} />
      </div>
    </div>
  )
}
