import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Lottie } from '@crello/react-lottie'
import orbitaAzul from '../../assets/orbita_azul.json'
import orbitaBlanca from '../../assets/orbita_blanca.json'

export default function LoadingComponent(props) {
  const { isStopped, isPaused, white } = props

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orbitaAzul,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  if (white) {
    defaultOptions.animationData = orbitaBlanca
  }

  return (
    <Container>
      <Row className="align-items-center">
        <Col
          className="align-self-center"
          style={{ textAlign: '-webkit-center' }}
        >
          <br />
          <br />
          <Lottie
            config={defaultOptions}
            height={100}
            width={100}
            isStopped={isStopped}
            isPaused={isPaused}
          />
        </Col>
      </Row>
    </Container>
  )
}
