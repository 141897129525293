import React, { useState, useEffect } from 'react'
import { getRequestAPI } from '../../services/requests.service'
import { detailProduct } from '../../services/constants'
import IconURLComponent from '../Shared/IconUrl.component'
import vida from '../../assets/icons/vida.svg'
import agua from '../../assets/icons/agua.svg'
import garantia from '../../assets/icons/garantia.svg'
import regadera from '../../assets/icons/regadera.svg'
import temperatura from '../../assets/icons/temperatura.svg'
import tubos from '../../assets/icons/tubos.svg'
import LoadingComponent from '../Shared/Loading.component'

function DetailProductComponent(props) {
  const [product, setProduct] = useState(null)
  const { id } = props.match.params
  const [isStopped, setIsStopped] = useState(true)

  useEffect(() => {
    getRequestAPI(detailProduct(id)).then((response) => {
      setProduct(response.data)
      setIsStopped(false)
    })
    return function cleanup() {
      setProduct(null)
      setIsStopped(true)
    }
  }, [id])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h1>INFORMACIÓN DEL PRODUCTO</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col">
          <div className="box-table">
            {product && (
              <>
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <img
                      src={product?.image}
                      alt={product?.model}
                      style={{ width: '100%', padding: '10px' }}
                    />
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div className="row">
                      <div className="col">
                        <h4>{product?.model}</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col" style={{ display: 'inline-flex' }}>
                        <IconURLComponent
                          text={`${product?.information?.life_time} Vida útil`}
                          icon={vida}
                          url={null}
                        />
                        <IconURLComponent
                          text={product?.information?.capacity}
                          icon={agua}
                          url={null}
                        />
                        <IconURLComponent
                          text={`${product?.warranty} años de garantía`}
                          icon={garantia}
                          url={null}
                        />
                        <IconURLComponent
                          text={`${product?.information?.services} duchas al día`}
                          icon={regadera}
                          url={null}
                        />
                        <IconURLComponent
                          text={product?.information?.temp_retention}
                          icon={temperatura}
                          url={null}
                        />
                        <IconURLComponent
                          text={product?.information?.size}
                          icon={tubos}
                          url={null}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col">
                        {/* <p>Total: $ {this.state.productDetail.price} MXN</p> */}
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h4>Descripción</h4>
                    <p>{product?.description}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h4>Garantía</h4>
                    <p>{product?.warranty_desc}</p>
                  </div>
                </div>
              </>
            )}
            {!product && <LoadingComponent isStopped={isStopped} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailProductComponent
