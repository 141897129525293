export const clientDefault = {
  email: "",
  phone: "",
  name: "",
  lastname: "",
  second_lastname: "",
  rfc: "",
  curp: "",
  home_phone: "",
  birthday: "",
  gender: "",
  grade: "",
  marital_status: "",
  municipality: "",
  department: "",
  street: "",
  state: "",
  state_name: "",
  exterior: "",
  interior: "",
  neighborhood: "",
  entity_birth: "",
  zip: "",
  monthly_expenditure: 0,
  income: 0,
  income_family: 0,
  city: "",
  count_home: 0,
  children: 0,
  country: "",
  company_name: "",
  type_activity: "",
  business_type: "",
  position: "",
  time_activity_year: 0,
  time_activity_month: 0,
  reference_street: "",
  reference_street_other: "",
  additional_reference: "",
  internet: '',
  password: Math.floor(Math.random() * 160000000000).toString(16),
  personal_references: [
    {
      name: "",
      phone: "",
      relationship: "",
    },
    {
      name: "",
      phone: "",
      relationship: "",
    },
  ],
};

export const creditDefault = {
  id: "",
  client_data: {},
  product_branch: {},
  pay_data: {},
  business_type: "",
  income: 0,
  count_home: 0,
  income_family: 0,
  monthly_expenditure: 0,
  answers: {},
  created_from: "ADMIN",
  utm_source: "admin",
  first_pay: false,
};
