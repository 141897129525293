// React component for input
// Language: javascript
// Path: src/components/Shared/Inputs/input.js

import React from 'react'

function InputComponent({ field, ...props }) {
  return <input type="text" className="input-g2" {...field} {...props} />
}

export default InputComponent
