import { useEffect, useState } from 'react'

import { useParams, useLocation } from 'react-router-dom'
import LoadingComponent from '../../components/Shared/Loading.component'
import WizardClient from '../../components/WizardClient'

// Servicess
import { getClientDetail } from '../../services/accounts.service'
import msUsersService from '../../services/ms/ms-users-service'

// Context
import CreditFormContext from './context'

// CreditForm stateless func
function CreditForm() {
  const [loading, setLoading] = useState(true)
  const [clientInfo, setClientInfo] = useState({})
  const [creditInfo, setCreditInfo] = useState({
    created_from: 'ADMIN',
    utm_source: 'admin',
    answers: {
      'razon-para-comprar-agua': '',
    },
  })

  const { id } = useParams()
  const location = useLocation()

  useEffect(() => {
    loadClient(id)
  }, [id])

  const loadClient = async (id) => {
    try {
      // trying to get client from MS
      let user = await msUsersService.getShopper(id, false)
      user = { ...user, ...user.profile }
      setClientInfo(user)
    } catch (err) {
      if (err.response.status === 404) {
        // trying to get client by aq_id
        const user = await msUsersService.getShopperByAqId(id)
        setClientInfo(user)
      }
    }
    setLoading(false)
  }

  const contextValue = {
    clientInfo,
    setClientInfo,
    creditInfo,
    setCreditInfo,
    lastCredit: location?.state?.lastCredit,
  }

  return (
    <CreditFormContext.Provider value={contextValue}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Crear Credito</h1>
          </div>
        </div>
        <br />
        <div className="box-white">
          {loading ? (
            <LoadingComponent isStopped={loading} />
          ) : (
            clientInfo && <WizardClient />
          )}
        </div>
      </div>
    </CreditFormContext.Provider>
  )
}

export default CreditForm
