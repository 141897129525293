import React, { Component } from 'react'
import * as CurrencyFormat from 'react-currency-format'
import SelectComponent from '../../Shared/Inputs/select.component'

const options = [
  { id: 1, name: 'Si' },
  { id: 2, name: 'No' },
]

export default function AddicionalInformationComponent({
  handleChange,
  client,
  credit,
}) {
  const renderer = (questions, question, answers) => {
    const questionRenderer = {
      select_params: (
        <SelectComponent
          key={questions[question].text}
          label={questions[question].text}
          data={Object.keys(questions[question].answers || {}).map((q) => ({
            name: q,
            id: q,
          }))}
          option={(value) => {
            const prevAnswers = answers
            prevAnswers[question] = value
            handleChange(prevAnswers, 'answers')
          }}
          value={answers[question]}
          id="id"
          name="name"
        />
      ),
      select: (
        <SelectComponent
          key={questions[question].text}
          label={questions[question].text}
          data={Object.keys(questions[question].answers || {}).map((q) => ({
            name: questions[question].answers[q],
            id: q,
          }))}
          option={(value) => {
            const prevAnswers = answers
            prevAnswers[question] = value
            handleChange(prevAnswers, 'answers')
          }}
          value={answers[question]}
          id="name"
          name="name"
        />
      ),
      boolean: (
        <SelectComponent
          key={questions[question].text}
          label={questions[question].text}
          data={Object.keys(questions[question].answers || {}).map((q) => ({
            name: q,
            id: questions[question].answers[q],
          }))}
          option={(value) => {
            const prevAnswers = answers
            prevAnswers[question] = value
            handleChange(prevAnswers, 'answers')
          }}
          value={answers[question]}
          id="id"
          name="name"
        />
      ),
      int: (
        <CurrencyFormat
          key={questions[question].text}
          placeholder={questions[question].text}
          prefix={questions[question].prefix}
          suffix={questions[question].sufix}
          className="input-g2"
          onChange={(e) => {
            const prevAnswers = answers
            prevAnswers[question] = e.target.value
            handleChange(prevAnswers, 'answers')
          }}
          value={
            answers[question] ? parseInt(answers[question].split('$')[1]) : ''
          }
        />
      ),
      str: (
        <input
          key={questions[question].text}
          placeholder={questions[question].text}
          type="text"
          className="input-g2"
          onChange={(e) => {
            const prevAnswers = answers
            prevAnswers[question] = e.target.value
            handleChange(prevAnswers, 'answers')
          }}
          value={answers[question]}
        />
      ),
    }
    return questionRenderer[questions[question].type]
  }

  const questions = {}
  return (
    <>
      <br />
      <br />
      <br />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <input
              type="number"
              placeholder="¿Cuántos habitantes hay en el hogar?"
              className="input-g2"
              onChange={(e) => handleChange(e.target.value, 'count_home')}
              value={client?.count_home || ''}
            />
            <input
              type="number"
              placeholder="¿Ingreso mensual familiar?"
              className="input-g2"
              onChange={(e) => handleChange(e.target.value, 'income_family')}
              value={client?.income_family || ''}
            />
            <input
              type="number"
              placeholder="Gasto mensual"
              className="input-g2"
              onChange={(e) =>
                handleChange(e.target.value, 'monthly_expenditure')
              }
              value={client?.monthly_expenditure || ''}
            />
            <select
              className="input-g2"
              onChange={(e) => handleChange(e.target.value, 'department')}
              value={client?.department || ''}
            >
              <option value="-">-- Selecciona tipo de vivienda --</option>
              <option value="Propia pagandose">Propia pagándose</option>
              <option value="Propia pagada">Propia pagada</option>
              <option value="Rentada">Rentada</option>
              <option value="Familiar">Familiar</option>
            </select>
            {/* <span className='labelInput'>Tipo de vivienda</span> */}
            {/* <SelectComponent
              label='¿Cuenta con internet/señal?'
              data={options}
              option={(value) => handleChange(value, 'internet')}
              value={client?.internet || ''}
              id='name'
              name='name'
            /> */}
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            {questions &&
              Object.keys(questions).map((question) =>
                renderer(questions, question, credit.answers)
              )}
          </div>
        </div>
      </div>
    </>
  )
}
