import React from 'react'
import SelectComponent from '../../Shared/Inputs/select.component'

const grade = [
  { id: 0, name: 'N/A' },
  { id: 1, name: 'Primaria' },
  { id: 2, name: 'Secundaria' },
  { id: 3, name: 'Preparatoria' },
  { id: 4, name: 'Universidad' },
  { id: 5, name: 'Postgrado' },
]

const cargos = [
  'Albañil',
  'Chofer',
  'Comerciante',
  'Dueño',
  'Empleado',
  'Encargado',
  'Independiente',
  'Negocio propio',
  'OTROS',
  'Propietario',
  'Vendedor',
]

const industrias = [
  'Alimentos',
  'Automotriz',
  'Comercio',
  'Comida',
  'Construcción',
  'Educación',
  'Gobierno',
  'Independiente',
  'OTROS',
  'Publico',
  'Restaurante',
  'Retail',
  'Salud',
  'Servicios',
  'Transporte',
]

export default function JobInformationCompoenent({ handleChange, client }) {
  const handlePersonalReferencesChange = (index, key, value) => {
    const references = [...client?.personal_references]
    while (references.length < 2) {
      references.push({ name: '', phone: '', relationship: '' })
    }
    references[index][key] = value
    handleChange(references, 'personal_references')
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="text-danger labelInput">**Campo obligatorio</span>
          <select
            className="input-g2"
            onChange={(e) => handleChange(e.target.value, 'position')}
            value={client?.position || ''}
          >
            <option hidden selected>
              Puesto
            </option>
            {cargos.map((cargo) => (
              <option value={cargo}>{cargo}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Nombre de la empresa"
            onChange={(e) => handleChange(e.target.value, 'company_name')}
            value={client?.company_name || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Giro de la empresa"
            onChange={(e) => handleChange(e.target.value, 'business_type')}
            value={client?.business_type || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="text-danger labelInput">**Campo obligatorio</span>
          <select
            className="input-g2"
            onChange={(e) => handleChange(e.target.value, 'type_activity')}
            value={client?.type_activity || ''}
          >
            <option hidden selected>
              Industria
            </option>
            {industrias.map((industria) => (
              <option value={industria}>{industria}</option>
            ))}
          </select>
          <span className="labelInput">Tipo de actividad</span>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="text-danger labelInput">**Campo obligatorio</span>
          <input
            type="number"
            className="input-g2"
            placeholder="Años"
            onChange={(e) => handleChange(e.target.value, 'time_activity_year')}
            value={client?.time_activity_year || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <input
            input="number"
            className="input-g2"
            placeholder="Meses"
            onChange={(e) =>
              handleChange(e.target.value, 'time_activity_month')
            }
            value={client?.time_activity_month || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="text-danger labelInput">**Campo obligatorio</span>
          <input
            type="number"
            className="input-g2"
            placeholder="Ingreso mensual"
            onChange={(e) => handleChange(e.target.value, 'income')}
            value={client?.income || ''}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <SelectComponent
            label="Grado de estudios"
            data={grade}
            option={(value) => handleChange(value, 'grade')}
            value={client?.grade || ''}
            id="name"
            name="name"
          />
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col">
          <h4>Referencias personales</h4>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Nombre completo"
            onChange={(e) =>
              handlePersonalReferencesChange(0, 'name', e.target.value)
            }
            value={client?.personal_references?.[0]?.name || ''}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Teléfono"
            onChange={(e) =>
              handlePersonalReferencesChange(0, 'phone', e.target.value)
            }
            value={client?.personal_references?.[0]?.phone || ''}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <input
            type="text"
            className="input-g2"
            placeholder="Parentesco"
            onChange={(e) =>
              handlePersonalReferencesChange(0, 'relationship', e.target.value)
            }
            value={client?.personal_references?.[0]?.relationship || ''}
          />
        </div>
      </div>
    </>
  )
}
