export const creditStatusHeaders = [
  {
    id: 1,
    label: 'En Proceso',
    code: 'PRO',
  },
  {
    id: 2,
    label: 'Evaluación Financiera',
    code: 'EVA',
  },
  {
    id: 3,
    label: 'Aprobado',
    code: 'APR',
  },
  {
    id: 4,
    label: 'Rechazado',
    code: 'REJ',
  },
  {
    id: 5,
    label: 'Evaluación Técnica',
    code: 'EVAT',
  },
  {
    id: 6,
    label: 'Por Firmar',
    code: 'TOS',
  },
  {
    id: 7,
    label: 'Por Instalar',
    code: 'ATI',
  },
  {
    id: 8,
    label: 'Clientes',
    code: 'INS',
  },
  {
    id: 9,
    label: 'Inconcluso',
    code: 'NC',
  },
  {
    id: 10,
    label: 'Castigado',
    code: 'CC',
  },
]
