import React from 'react'
import * as CurrencyFormat from 'react-currency-format'
import LoadingComponent from '../Shared/Loading.component'

export default function DepositsTableDSComponent({
  isStopped,
  headers,
  rows,
  hidden,
  handleClick,
}) {
  const handleIdAccount = (item) => handleClick(item)

  return (
    <div className="box-table" hidden={hidden}>
      {isStopped === true && <LoadingComponent isStopped={isStopped} />}

      {isStopped === false && (
        <div className="table-responsive">
          <table className="table table-hover table-graviti">
            <thead>
              <tr>
                {headers.map((item, i) => (
                  <th key={i}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.pay?.map((item, i) => (
                <tr onClick={() => handleIdAccount(item.id)} key={i}>
                  <td className="textPush">{item?.period}</td>
                  <td className="textPush">
                    <CurrencyFormat
                      value={item.total}
                      displayType="text"
                      thousandSeparator
                      prefix="$ "
                    />
                  </td>
                  <td className="textPush">{item?.installations}</td>
                  <td className="textPush">
                    {item.creation_date === null ? (
                      <strong style={{ color: '#E0AA25' }}>Pendiente</strong>
                    ) : (
                      item.creation_date
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
