import React from 'react'

const formatUrl = (url) => url.split('?')[0]

function IconURLComponent({ icon, text, url, actions, loading }) {
  return (
    <div className="box-icon">
      <img src={icon} alt={text} style={{ width: '30px' }} />
      <span>
        <br />
        <br />
        {text}
      </span>
      {loading ? (
        <div className="mt-1">
          <span className="spinner-border spinner-border-sm" />
        </div>
      ) : (
        <div className="row justify-content-center">
          {url && (
            <div>
              <span className="link-donwload">
                <strong>
                  <a href={formatUrl(url)} className="text-reset">
                    Descargar
                  </a>
                </strong>
              </span>
            </div>
          )}
          {actions &&
            actions.map(
              (e, index) =>
                e && (
                  <div key={`${e.text}_${index}`}>
                    <span
                      key={e.text}
                      className="link-donwload"
                      onClick={() => e.action()}
                    >
                      <strong>{`/${e.text}`}</strong>
                    </span>
                  </div>
                )
            )}
        </div>
      )}
    </div>
  )
}

export default IconURLComponent
