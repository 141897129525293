import React, { useState, useEffect } from 'react'
import * as CurrencyFormat from 'react-currency-format'
import { getDepositsDistribuitorDetails } from '../../../services/payments.service'
import DepositsDetailsTableDsComponent from './depositsDetailsTableDS.component'
import PaginatorComponent from '../../Shared/Paginator.component'

const headersDepositsDS = [
  'ID',
  'Nombre',
  'Producto',
  'Fecha de Instalación',
  'Monto',
]

export default function DepositsDetailsDsComponent({ match, customers }) {
  const [id, setId] = useState(match.params.id)
  const [rows, setRows] = useState([])
  const [summary, setSummary] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [isStopped, setIsStopped] = useState(true)

  useEffect(() => {
    getDepositsDistribuitorDetails(id, activePage).then(
      ({ results, count }) => {
        setRows(results)
        setTotalItemsCount(count)
        setIsStopped(false)
      }
    )
  }, [id])

  const handlePaginator = (number) => {
    setActivePage(number)
    setRows([])
    setIsStopped(true)
    getDepositsDistribuitorDetails(id, number).then(({ results, count }) => {
      setRows(results)
      setTotalItemsCount(count)
      setIsStopped(false)
    })
  }

  const handleRedirectPageDetail = (id) => {
    const path = `/cuentas/detalles/${id}`
    window.location.pathname = path
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-7 col-md-7">
          <h1>DETALLE DE LOS DEPÓSITOS</h1>
        </div>
        {summary !== null && (
          <div className="col-lg-5 col-md-5">
            <div className="box-information">
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <table>
                    <tbody>
                      <tr className="celdaText text-center">
                        <td>Núm. de instalaciones:</td>
                        <td>
                          <strong>{summary?.instalation_number}</strong>
                        </td>
                      </tr>
                      <tr className="celdaText">
                        <td>Total depositado:</td>
                        <td>
                          <strong>
                            <CurrencyFormat
                              value={summary?.total}
                              displayType="text"
                              thousandSeparator
                              prefix="$ "
                            />
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-4 col-md-4">
                  <table>
                    <tbody>
                      <tr className="celdaText text-center">
                        <td>Fecha de deposito:</td>
                      </tr>
                      <tr className="celdaText">
                        <td>
                          <strong>{summary?.paymend_date}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <DepositsDetailsTableDsComponent
            headers={headersDepositsDS}
            rows={rows}
            hidden={false}
            isStopped={isStopped}
            handleClick={handleRedirectPageDetail}
              customers={customers} // eslint-disable-line
          />
          <br />
          <PaginatorComponent
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            handlePageChange={(number) => {
              handlePaginator(number)
            }}
          />
        </div>
      </div>
    </div>
  )
}
