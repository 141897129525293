import React, { useState, useEffect, useRef } from 'react'
import './autocomplete.css'

function Autocomplete({
  defaultProduct,
  products,
  selectedHandler,
  onSearchChange,
}) {
  const [display, setDisplay] = useState(false)
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [items, setItems] = useState(products || [])
  const wrapperRef = useRef(null)

  useEffect(() => {
    const res = sortOptions(products || [])
    setOptions(res)
    setItems(res)
  }, [products])

  useEffect(() => {
    if (products && defaultProduct) {
      const filtered = items.filter((item) => defaultProduct === item.id)
      setSearch(filtered[0]?.name)
    }
  }, [items])

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    const res = matchItems(search, items)
    onSearchChange && onSearchChange(search)
    setOptions(res)
  }, [search])

  const sortOptions = (products) => {
    const allOptions = products.map((product) => ({
      ...product,
      name: product.product.model_display,
    }))

    const sortedOptions = allOptions?.sort((a, b) => {
      const nameA = a?.name?.toUpperCase()
      const nameB = b?.name?.toUpperCase()

      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

    return sortedOptions
  }

  const matchItems = (term, items) =>
    items?.filter(
      (element) =>
        element?.name?.toLowerCase().indexOf(term?.toLowerCase()) !== -1
    )

  const handleClickOutside = (e) => {
    const { current: wrap } = wrapperRef
    if (wrap && !wrap.contains(e.target)) {
      setDisplay(false)
    }
  }

  const selectOption = (option) => {
    setSearch(option.name)
    setDisplay(false)
    if (selectedHandler) {
      selectedHandler(option)
    }
  }

  return (
    <div ref={wrapperRef} className="autocomplete-container">
      <input
        className="input"
        value={search}
        placeholder="Inserta el Producto"
        onChange={(e) => setSearch(e.target.value)}
        onClick={() => {
          setDisplay(!display)
        }}
      />
      {display && (
        <div className="option-list">
          {options.map((value, i) => (
            <div
              value={value.id}
              onClick={() => selectOption(value)}
              className="option"
              key={i}
              tabIndex="0"
            >
              <p>{value.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Autocomplete
