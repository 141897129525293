import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Lightbox from 'react-awesome-lightbox'
import LoadingComponent from '../../../Shared/Loading.component'
import UploadFile from '../../../Shared/UploadFile'
import {
  assignSystemByDistributor,
  getSystemsInStock,
} from '../../../../services/systems.service'
import {
  getListInstallationPhotosByCreditId,
  createInstallationPhoto,
} from '../../../../services/accounts.service'
import 'react-awesome-lightbox/build/style.css'
import './style.css'

class SpecificationsComponent extends Component {
  constructor(props) {
    super(props)
    this.uploadRef = React.createRef()
    this.state = {
      system_id: null,
      isStopped: true,
      isDisabled: false,
      id_credit: props.creditInfo.id,
      systems: [],
      lightboxStartIndex: 0,
      lightboxOpen: false,
      images: [],
      newPhotoToUpload: null,
      loading: false,
    }

    this.handleAssignSystem = this.handleAssignSystem.bind(this)
    this.handleToggleLightbox = this.handleToggleLightbox.bind(this)
    this.handleUploadInstallLocationPhoto =
      this.handleUploadInstallLocationPhoto.bind(this)
    this.handleGetInstallLocationPhotos =
      this.handleGetInstallLocationPhotos.bind(this)
  }

  componentDidMount() {
    getSystemsInStock().then((response) => {
      this.setState({ systems: response })
    })
    this.handleGetInstallLocationPhotos()
  }

  handleGetInstallLocationPhotos() {
    getListInstallationPhotosByCreditId(this.props.creditInfo.id).then(
      (data) => {
        const images = data.map((img, key) => ({
          url: img.image,
          title: `Image ${key}`,
        }))
        this.setState({ images })
      }
    )
  }

  handleAssignSystem(e) {
    this.setState(
      { system_id: e.target.value, isStopped: false, isDisabled: true },
      () => {
        assignSystemByDistributor(this.state).then((response) => {
          this.setState({ isStopped: true, isDisabled: false })
          window.location.reload(false)
        })
      }
    )
  }

  handleToggleLightbox(index) {
    this.setState({ lightboxStartIndex: index }, () => {
      this.setState({ lightboxOpen: !this.state.lightboxOpen })
    })
  }

  handleUploadInstallLocationPhoto() {
    this.setState({ loading: true })
    createInstallationPhoto(
      this.state.newPhotoToUpload,
      this.props.creditInfo.id
    ).then(() => {
      this.setState({ newPhotoToUpload: null, loading: false })
      this.handleGetInstallLocationPhotos()
      this.uploadRef.current.handleInitialState()
    })
  }

  render() {
    const { answers } = this.props.creditInfo
    const questions = {}

    return (
      <Container>
        <Row>
          <Col md={4}>
            <table className="table table-borderless table-sm" width="100%">
              <thead />
              <tbody>
                {Object.keys(questions).map((q) => (
                  <tr key={q}>
                    <td className="headTable">{questions[q].text}</td>
                    <td>
                      {answers[q]
                        ? answers[q]
                        : 'No se ha proporcionado información'}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="headTable">¿Cuenta con Internet/Señal?: </td>
                </tr>
                <tr>
                  <td>
                    {
                      this.props.creditInfo?.client_info?.additional_info
                        ?.internet
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          {this.props.rol !== 'FN' &&
            this.props.creditInfo.credit_status === 'ATI' && (
              <Col md={8}>
                <h4>Generar códigos de instalación:</h4>
                <select
                  className="input-g2"
                  onChange={this.handleAssignSystem}
                  disabled={this.state.isDisabled}
                >
                  <option value="">Ninguno</option>
                  {this.state.systems.length > 0 &&
                    this.state.systems.map((item) => (
                      <option
                        value={item.id}
                        key={item.id}
                        selected={
                          this.props.creditInfo.system === item.id
                            ? 'selected'
                            : ''
                        }
                      >
                        {item.system_id}
                      </option>
                    ))}
                </select>
                <span className="labelInput">
                  Seleccione el medidor a asignar
                </span>
                <br />
                {!this.state.isStopped && (
                  <>
                    <LoadingComponent isStopped={this.state.isStopped} />
                    <p className="text-center">
                      Generando códigos de instalación...
                    </p>
                  </>
                )}

                {this.props.creditInfo.system && (
                  <table
                    className="table table-borderless table-sm"
                    width="100%"
                  >
                    <thead />
                    <tbody>
                      <tr>
                        <td className="headTable" style={{ width: '300px' }}>
                          Código de instalación (1 hr):
                        </td>
                        <td>
                          {
                            this.props.creditInfo?.system_info
                              ?.code_installation
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="headTable" style={{ width: '300px' }}>
                          Código de 1 semana:
                        </td>
                        <td>
                          {this.props.creditInfo?.system_info?.code_first_week}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </Col>
            )}
        </Row>
        {this.state.images.length > 0 && (
          <Row>
            <hr />
            <Col md={6}>
              <h4>Fotos del lugar de instalación</h4>
              <div className="grid-gallery">
                {this.state.images.map((img, index) => (
                  <button
                    className="grid-gallery__item"
                    onClick={() => this.handleToggleLightbox(index)}
                    key={index}
                  >
                    <img className="grid-gallery__image" src={img.url} />
                  </button>
                ))}
              </div>
            </Col>
            <Col md={6}>
              <UploadFile
                ref={this.uploadRef}
                typeFile="Foto/s"
                onChangeFile={(file) =>
                  this.setState({ newPhotoToUpload: file })
                }
                id="image"
              />
              <br />
              <button
                className="btn b-primary"
                onClick={this.handleUploadInstallLocationPhoto}
                disabled={!this.state.newPhotoToUpload || this.state.loading}
              >
                {this.state.loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" />
                    {' Subiendo...'}
                  </>
                ) : (
                  'Subir imagen'
                )}
              </button>
            </Col>
            {this.state.lightboxOpen && (
              <Lightbox
                startIndex={this.state.lightboxStartIndex}
                images={this.state.images}
                onClose={this.handleToggleLightbox}
              />
            )}
          </Row>
        )}
      </Container>
    )
  }
}

export default SpecificationsComponent
