import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import Html5QrcodePlugin from './Html5QrcodePlugin.jsx'

class QRScanner extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      decodedResults: []
    }

    // This binding is necessary to make `this` work in the callback.
    this.onNewScanResult = this.onNewScanResult.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    const { closeModal, setModal } = this.props
    if (closeModal) {
      closeModal()
    } else {
      setModal(false)
    }
  }

  render () {
    return (
      <div>
        <Modal show={this.props.modal} onHide={this.toggle}>
          <Modal.Header closeButton>
            <Modal.Title>Ingresa el código QR</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className='App-section'>
              <Html5QrcodePlugin
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={this.onNewScanResult}
              />
            </section>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggle}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  onNewScanResult (decodedText, decodedResult) {
    this.setState((state, props) => {
      state.decodedResults.push(decodedResult)
      return state
    })
    this.props.onDetectedMerchant(this.state.decodedResults)
  }
}

export default QRScanner
