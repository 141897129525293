import React, { useState, useEffect } from 'react'
import SideMenuComponent from '../Shared/SideMenu.component'
import FinancialTableComponent from './financialTable.component'
import DistributorTableComponent from './distributorTable.component'
import {
  getCountsGraviti,
  getDistribuitor,
  getFinancials,
} from '../../services/graviti.service'

function GravitiComponent() {
  const [showTable, setShowTable] = useState(1)
  const [rows, setRows] = useState([])
  const [isStopped, setIsStopped] = useState(false)
  const [menuAccounting, setMenuAccounting] = useState([])

  useEffect(() => {
    getCountsGraviti().then((response) => {
      setMenuAccounting([
        { id: 1, status: 'Finaciera', count: response.data?.financial },
        { id: 2, status: 'Distribuidores', count: response.data?.distributor },
      ])
    })

    getFinancials().then((response) => {
      setRows(response.data.results)
      setIsStopped(true)
    })
  }, [])

  function handleStatus(item) {
    setShowTable(item.id)
    setIsStopped(false)
    switch (item.id) {
      case 1:
        getFinancials().then((response) => {
          setRows(response.data.results)
          setIsStopped(true)
        })
        break
      case 2:
        getDistribuitor().then((response) => {
          setRows(response.data.results)
          setIsStopped(true)
        })
        break
      default:
        break
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <h1>GRAVITI</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-3 col-md-3">
          <SideMenuComponent
            onSelectStatus={handleStatus}
            menu={menuAccounting}
          />
        </div>
        <div className="col-lg-9 col-md-9">
          <FinancialTableComponent
            isStopped={isStopped}
            rows={rows}
            headers={[
              'Nombre',
              'Responsable',
              'Teléfono',
              'Dirección',
              'Fecha de Registro',
            ]}
            hidden={showTable !== 1}
          />
          <DistributorTableComponent
            isStopped={isStopped}
            hidden={showTable !== 2}
            headers={[
              'Nombre',
              'Responsable',
              'Teléfono',
              'Dirección',
              'Fecha de Registro',
            ]}
            rows={rows}
          />
        </div>
      </div>
    </div>
  )
}

export default GravitiComponent
