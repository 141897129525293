// React
import { useEffect, useState } from 'react'

// React-bootstrap
import { Modal } from 'react-bootstrap'

// MUI
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { confirmInstallation } from '../../services/systems.service'
import { toast } from '../../helpers/toast'
import { defaultRequestErrorHandler } from '../../helpers/requests'

export function ModalConfirmInstallation({
  show,
  creditId,
  onClose,
  onSubmitted,
}) {
  const [date, setDate] = useState(moment())
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      await confirmInstallation(creditId, date.format('YYYY-MM-DD[T]hh:mm:ss'))
      toast('Entrega confirmada')
      onSubmitted()
    } catch (err) {
      defaultRequestErrorHandler(err)
    }
    setSubmitting(false)
  }

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Body>
        <div className="d-flex">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={date}
              onChange={setDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <div className="d-flex flex-column justify-content-between">
            <p className="header-1 text-blue pt-4">
              ¿Desea confirmar la instalación
            </p>
            <p className="text text-blue">
              Una vez hecho el cambio no podrá revertirse. Por favor, ingresa
              la&nbsp;
              <b>fecha exacta de la instalación</b>
            </p>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn-2 btn-block b-primary"
                  disabled={submitting}
                  onClick={handleSubmit}
                >
                  Confirmar instalación
                </button>
              </div>
              <div className="col-6">
                <button className="btn-2 btn-block b-light" onClick={onClose}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
