import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.css'

export default class UploadFileComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      name: null,
      size: null,
      type: null,
      url: null,
      typeDownload: null,
    }

    this.handleChangeFile = this.handleChangeFile.bind(this)
  }

  componentDidMount() {
    const { url } = this.props
    this.setState({ url })
    const regex = /\.pdf/
    if (regex.test(url)) {
      this.setState({ typeDownload: 'pdf' })
    }
  }

  handleChangeFile(event) {
    if (event.target.files.length > 0) {
      const { onChangeFile } = this.props
      this.setState({
        file: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
        name: event.target.files[0].name,
        size: (event.target.files[0].size / 1024).toFixed(1),
        type: event.target.files[0].type,
      })
      onChangeFile(event.target.files[0])
    }
  }

  handleInitialState() {
    this.setState({
      file: null,
      name: null,
      size: null,
      type: null,
      url: this.props.url,
    })
  }

  render() {
    const { size, file, name, type, typeDownload } = this.state
    const { typeFile, id } = this.props
    return (
      <>
        <div className="preview">
          {file ? (
            type === 'application/pdf' ? (
              <object width="99%" height="200px" data={this.state.url} />
            ) : (
              <img src={this.state.url} alt="Image selected" />
            )
          ) : type === 'application/pdf' ? (
            <object width="99%" height="200px" data={this.props.url} />
          ) : typeDownload === 'pdf' ? (
            <object width="99%" height="200px" data={this.props.url} />
          ) : (
            <img src={this.props.url} alt={this.props.id} />
          )}
        </div>
        <span className="fileName">{typeFile}</span>
        <input
          type="file"
          name={id}
          id={id}
          className="inputfile"
          onChange={this.handleChangeFile}
        />
        <label htmlFor={id}>
          {file || this.props.url ? 'Cambiar archivo' : 'Selecciona un archivo'}
        </label>
        <span className="fileName">
          {name || this.props.url ? '' : 'Ningún archivo seleccionado'}
        </span>
        <br />
        {size && (
          <span
            className="fileName"
            style={
              size > 9216
                ? {
                    color: 'red',
                  }
                : {}
            }
          >
            {size < 9216
              ? `Tamaño: ${(size / 1024).toFixed(2)} MB`
              : size > 9216
              ? 'Tamaño permitido 9MB. Intenta nuevamente'
              : ''}
          </span>
        )}
      </>
    )
  }
}

UploadFileComponent.propTypes = {
  onChangeFile: PropTypes.func.isRequired,
  url: PropTypes.string,
  typeFile: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}
