import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import {Col, Row, Container, Card} from 'react-bootstrap'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

// Styles
import './styles.css'

function Previews({ files, onFilesAccepted }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
      onFilesAccepted([...files, ...newFiles])
    },
  })

  const thumbs = files
    .filter((file) => !!file)
    .map((file) => (
      <div className="thumb" key={file.name || file}>
        <img
          src={file.preview || file}
          alt={file.name || file}
          className="thumb-image"
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <button
          type="button"
          className="thumb-close"
          onClick={() =>
            onFilesAccepted(
              files.filter((f) => f.name !== file.name || f !== file)
            )
          }
        >
          &times;
        </button>
      </div>
    ))

  useEffect(
    () => () =>
      files.forEach(
        (file) => typeof file === File && URL.revokeObjectURL(file.preview)
      ),
    []
  )

  return (
    <Container fluid="md" className="col-md-12">
      <Row className="col-12">
        <Col className="col-2">
          <Card
            body
            style={{ width: '5rem', height: '5rem', alignContent: 'center' }}
          >
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <AddCircleOutlineIcon fontSize="large" />
            </div>
          </Card>
        </Col>

        <Col className="col-10">
          <aside className="thumbs-container">{thumbs}</aside>
        </Col>
      </Row>
    </Container>
  )
}

export default Previews
